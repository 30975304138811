import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';

import { API_IMAGE, ICONS } from "../../constants";
import { userAction } from "../../redux/actions";
import { t } from "../../utils";

class PMAvatar extends React.Component {
    constructor(props) {
        super(props);

        this.avatarList = {};
		this.arrowLeft = {};
		this.arrowRight = {};
		this.animeScroll = null;
    };

    componentDidMount() {
        const { fetchAvatarsList } = this.props;
        fetchAvatarsList();
  	};

    componentWillUnmount() { 
		clearInterval( this.animeScroll );
		window.removeEventListener( 'mouseup',() => { clearInterval( this.animeScroll ); } );
	};

    checkScroll = ( key ) => {
		if ( this.avatarList[ key ].current.scrollLeft === 0 ) {
            this.arrowLeft[ key ].current.classList.add( 'disabled' );
		} else {
            this.arrowLeft[ key ].current.classList.remove( 'disabled' );
		}
		if ( this.avatarList[ key ].current.scrollWidth - this.avatarList[ key ].current.clientWidth === this.avatarList[ key ].current.scrollLeft ) {
			this.arrowRight[ key ].current.classList.add( 'disabled' );
		} else {
			this.arrowRight[ key ].current.classList.remove( 'disabled' );
		}
	};
 
	scrollLeft = ( key ) => {
		this.animeScroll = setInterval( () => { this.avatarList[ key ].current.scrollLeft -= 5; this.checkScroll( key ); }, 10);
		window.addEventListener( 'mouseup',() => { clearInterval( this.animeScroll ); } );
	};

	scrollRight = ( key ) => {
		this.animeScroll = setInterval( () => { this.avatarList[ key ].current.scrollLeft += 5; this.checkScroll( key ); }, 10);
		window.addEventListener( 'mouseup',() => { clearInterval( this.animeScroll ); } );
	};
 
    handleChange = ( avatar, avatarId ) => { 
        const { fetchProfile, handlePageChange, type } = this.props;
        fetchProfile({ type: type, avatar: avatar, avatarId: avatarId });
        handlePageChange( 'left', type )
    };

    render() {
        const { avatarsList, handlePageChange, language, type, userInfoDB } = this.props;

        let avatarsCat = [];
        let i = 1;
        Object.keys( avatarsList ).forEach( el => {
            if ( el !== '' ) avatarsCat.push( { id: i, name: el } );
            i ++; 
        });

        let Avatars = <></>;
		if ( avatarsCat && avatarsCat.length > 0 ) {
			Avatars = avatarsCat.map( item => {
				if ( avatarsList[ item.name ] && avatarsList[ item.name ].length > 0 ) {
                    let newAvatars = avatarsList[ item.name ];
                    let k = 0;
                    while ( newAvatars.length < 12 ) {
                        newAvatars.push( {
                            id: item.name + '-default-' + k,
                            default: true,
                        });
                        k ++;
                    }

                    let avatars = <></>;
                    avatars = newAvatars.map( ( el ) => {
                        let avatarClass = 'user-avatar';
                        if ( userInfoDB.avatar === el.imagePath ) avatarClass += ' selected';

                        if ( el.default ) {
                            return (
                                <div className='avatar-box' key={ el.id }>
                                    <img
                                        className='user-avatar user-avatar-default'
                                        src={`${API_IMAGE}avatar-default.png`}
                                        alt='avatar'
                                        id={ 'user_avatar_' + el.id }/>
                                </div>  
                            );
                        }

                        return (
                            <div className='avatar-box' key={ el.id }>
                                <img
                                    className= { avatarClass }
                                    src={API_IMAGE + el.imagePath + ".jpg"}
                                    alt='avatar'
                                    onClick={ () => this.handleChange( el.imagePath, el.id ) } />
                            </div> 
                        );
                    });

                    this.avatarList[ item.id ] = React.createRef();
                    this.arrowLeft[ item.id ] = React.createRef();
                    this.arrowRight[ item.id ] = React.createRef();

					return (
                        <div className="avatar-list-box" key={ item.id }>
                            <div className="avatar-list-title">{ item.name }</div>
                            <div className="arrow arrow-left disabled" onMouseDown={ this.scrollLeft.bind( this, item.id ) } ref={ this.arrowLeft[ item.id ] }><i className="fas fa-chevron-left"></i></div>	
                            <div className="arrow arrow-right" onMouseDown={ this.scrollRight.bind( this, item.id ) } ref={ this.arrowRight[ item.id ] }><i className="fas fa-chevron-right"></i></div>
                            <div className="avatar-list" ref={ this.avatarList[ item.id ] }>
                                { avatars }
                            </div>
                        </div>   
					);
				}
				return <></>;
			});
		}

        const $content =
            <>
                <div id='profile_container' onMouseUp={ this.scrollInit }>
                    <div id='profile_nav'>
                        <span className='profile_nav' onClick={ () => { handlePageChange( 'left', 'change' ) } }>{ t( 'profileManager:title', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span className='profile_nav' onClick={ () => { handlePageChange( 'left', type ) } }>{ t( `profileManager:${ type }`, language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span>Avatar</span>
                    </div>
                    <div className='profile_box_center'>
                        <div id='profile_header' className='profile_header_title'>
                            <h1>{ t( 'profile:editAvatar', language ) }</h1>
                        </div>
                        { Avatars }
                    </div>
				</div>
            </>;   

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
	avatarsList: state.userReducer.avatarsList,
    language: state.translatingReducer.language,
	userInfoDB: state.userReducer.userInfoDB,
});

const dispatchToProps = dispatch => ({
	fetchAvatarsList: () => dispatch(userAction.fetchAvatarsList()),
    fetchProfile: data => dispatch(userAction.fetchProfile( data )),
    fetchUserInfo: userId => dispatch(userAction.fetchUserInfo( userId )),
});

export default connect( stateToProps, dispatchToProps )( PMAvatar );