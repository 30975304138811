import React from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";

import InfiniteCarousel from 'react-leaf-carousel';
import Loader from "react-loader-spinner";

import VideoPlayer from "../../components/Videoplayer";

import { API_IMAGE, DATA_VLOG, DISPLAY, IMAGES } from "../../constants";
import { moviesAction, userAction } from "../../redux/actions";
import { authSubUser } from "../../services/user";
import { checkEmail, getUrlParams, getUserInfo, handleLoader, isGuest, isProgressTimeValid, t } from "../../utils";

class IframeComponent extends React.Component {
	static contextTypes = { router : Proptypes.object };

	constructor(props,context) {
		super(props, context);

		this.logBtnExit = this.logBtnExit.bind(this);

		this.height = getUrlParams().h ? getUrlParams().h : '360';
		this.width = getUrlParams().w ? getUrlParams().w : '360';
		this.slidesNumber = 6;
		if ( this.width < 768 ) {
			this.slidesNumber = 1;
		} else if ( this.width < 1025 ) {
			this.slidesNumber = 2;
		} else if ( this.slidesNumber < 1200 ) {
			this.slidesNumber = 3
		}

		this.state = {
			email: '',
			errorMessage: '',
			imageLoadNoCache: true,
			imageLoadError: true,
			password: '',
			playerShow: false,
			playerHeight: 0,
			playerWidth: 0,
			showLogin: false,
			videoJsOptions: { autoplay: false, playing: false },
		};
	};

	componentDidMount() {
		this.backListener = this.context.router.history.listen( ( location, action, state ) => {
			if ( action === "POP" && this.state.playerShow && location.hash !== '#play/' ) this.logBtnExit();
		});

        const fixParamArr = window.location.toString().split( '/browse/' )[ 1 ].split( '#/iframe/' );
		if ( fixParamArr && fixParamArr[ 0 ] === '' ) {
			const fixParam = fixParamArr[ 1 ].split( '?' )[ 0 ];
			window.location.assign( "/browse/?iframe=" + fixParam + "/#/iframe/" + fixParam );
			return;
		}

		const $body = document.body;
		if ( $body ) {
			$body.style.height = `${ this.height }px`;
			$body.style.width = `${ this.width }px`;
		}

		const { fetchSearchedIframeMovies, profileVersion } = this.props;
        const userId = getUserInfo().NoCli;
		const source = getUrlParams().source ? getUrlParams().source : 'zone300';

        const paramsArr = window.location.toString().split( '/browse/' )[ 1 ].split( '#/iframe/' );
        if ( paramsArr[ 1 ] ) {
			fetchSearchedIframeMovies( paramsArr[ 1 ].split( '?' )[ 0 ], userId, profileVersion, source );
        }
  	};

	checkErrors = () => {
        const { language } = this.props;

        if ( this.state.email === '' ) {
            this.setState({ errorMessage: t( 'form:emailError', language ) });
            return false;
        } else if ( ! checkEmail( this.state.email ) ) {
            this.setState({ errorMessage: t( 'form:emailValidError', language ) });
            return false;
        } else if ( this.state.password === '' ) {
            this.setState({ errorMessage: t( 'form:passwordEmpty', language ) });
            return false;
        }
        this.setState({ errorMessage: '' });
        return true;
    };

	handleChange = event => {
        let value = event.target.value;
        const name = event.target.name;
        if ( name === 'email' ) value = value.toLowerCase().trim();
        this.setState({ [name]: value });
    };

	handleSubmit = () => {
        const { language } = this.props;

        if ( this.checkErrors() ) {
            handleLoader( 'iframe_loader', 'add' );
            const dataUser = { email: this.state.email, password: this.state.password };
            authSubUser( dataUser )
                .then( res => {
                    if ( ! res.ret ) {
                        switch( res.error ) {
                            case 'authentication':
                                this.setState ({ errorMessage: t( 'form:passwordError', language ) });
                                break;
                            case 'discarded':
                                this.setState ({ errorMessage: 'Compte désactivé' });
                                break;
                            default:
                                this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                        }
                        handleLoader( 'iframe_loader', 'remove' );
                        return;
                    } 

                    localStorage.setItem( DATA_VLOG, JSON.stringify( res.data ) );
                    const { fetchUserInfo } = this.props;
                    if ( res.data.is_guest === '0' ) fetchUserInfo( res.data.NoCli );
                    handleLoader( 'iframe_loader', 'remove' );

                    window.location.reload();
                })
                .catch( error => {
                    console.log( error );
                    this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                    handleLoader( 'iframe_loader', 'remove' );
                });   
        }
    };

	logBtnExit = () => {
		if( document.location.hash.includes( "#play/" ) ) this.context.router.history.goBack();
		this.setState( { playerShow: false, playerHeight: 0, playerWidth: 0, videoJsOptions: { autoplay: false, playing: false } } );
		if ( this.props.isGlobalPlayingPage ) {
			window.location.href = window.location.href.replace( "#play/", "" );
			window.location.reload();
		}
	};

	navClose = () => {
		this.setState( { showLogin: false } );
	};
		
	navCloseIf = ( event, condition ) => {
		if ( event.target.classList.value !== condition ) return false;
		this.navClose();
	};

	onClickFullscreen = movie => {
		const { searchedIframeMovies, userDiscarded } = this.props;
		const movieList = searchedIframeMovies[0].movies;

		if ( isGuest() || userDiscarded ) {
			this.setState({ showLogin: true })
			return;
		}

		const progressInfo = isProgressTimeValid( movie.progressTime, movie.timeTotal ) ? { progressTime: movie.progressTime, timeTotal: movie.timeTotal } : null;
		const movieKey = movieList.indexOf( movieList.filter( el => el.transaction === movie.transaction )[ 0 ] );
			
		this.setState({
			playerShow: true,
			playerHeight: "100%",
			playerWidth: "100%",
			videoJsOptions: {
				logBtnExit: this.logBtnExit,
				isIframe: true,
				movieKey: movieKey,
				movieList: movieList, 
				muted: true,
				playing: true,
				progressInfo: progressInfo,
			},
		});

		window.location.href = window.location.href + "#play/";
	};

	renderPic = ( movie, style ) => {
	if ( movie.transaction !== null ) {
		let posterFileName = movie.posterFileName;
		let squareFileName = movie.squareFileName;
		let thumbnailFileName = movie.thumbnailFileName;
		const imageAlt = movie.imageAlt ? movie.imageAlt : movie.titre;

		let srcImage = `${API_IMAGE}${ thumbnailFileName }.jpg&w=500&h=281&crop-to-fit`;
		let srcErrorImage = IMAGES.placeholderThumbnail;
		
		if ( style === DISPLAY.POSTER ) {
			srcImage = `${API_IMAGE}${ posterFileName }.jpg&w=500&h=667`;
			srcErrorImage = IMAGES.placeholderPoster;
		} else if ( style === DISPLAY.SQUARE ) {
			srcImage = `${API_IMAGE}${ squareFileName }.jpg&w=500&h=500`;
			srcErrorImage = IMAGES.placeholderSquare;
		}

		return (
			<img
				className="tile-img"
				alt={ imageAlt }
				src={ srcImage } 
				onError={e => {
					if ( this.state.imageLoadNoCache ) {
						this.setState({ imageLoadNoCache: false });
						e.target.src = `${ srcImage }&no-cache`;
					} else if ( this.state.imageLoadError ) {
						this.setState({ imageLoadError: false });
						e.target.src = srcErrorImage;
					}
				}} />
		);
	}

		return (
			<img
				alt="img"
				className="tile-img"
				src={ IMAGES.placeholderThumbnail } />
		);
	};

	renderTitle = title => {
    	if (title.length < 35) { return <h1 className="search-tile-title long-title">{ title }</h1>; }
    	return <h1 className="search-tile-title longer-title">{ title }</h1>;
  	};

	render() {
		const { language, searchedIframeMovies } = this.props;

		return (
            <div className='iframe-container'>
                <div className='iframe-logo'>
                    <img src={ IMAGES.logoImg } alt='logo zone300' />
                </div>
                {
                    searchedIframeMovies && searchedIframeMovies.length > 0 &&
                    searchedIframeMovies.map( ( item, index ) =>
                        <div className="list-item" key={ index }>
                            <div className="GBListItem">
								<div className="grid for-image">
									<InfiniteCarousel
										breakpoints={[{ breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1 } }]}
										dots={false}
										slidesSpacing={5}
										showSides={true}
										sidesOpacity={.5}
										sideSize={.1}
										slidesToScroll={ this.slidesNumber }
										slidesToShow={ this.slidesNumber }
										animationDuration={600}
										lazyLoad={true}
										placeholderImageSrc={ IMAGES.placeholderThumbnail }>
										{
											item.movies && item.movies.length > 0 &&
											item.movies.map( (item_, index_) =>
												<div key={ index_ } >
													<div className='shine-container'>
														<figure
															className='effect-thumbnail-movie collect_flag'
															id={ `iframe-movie-${ item_.transaction }` }
															onClick={ () => this.onClickFullscreen( item_ ) }>
															{ this.renderPic( item_, item.display.style ) }	
															<figcaption>
																{
																	( item_.aff_titre === '1' || ! this.state.imageLoadError ) &&
																	this.renderTitle( item_.titre )
																}
															</figcaption>
															<div className='movie_progress_play'>
																<i className="fa fa-play" aria-hidden="true"></i>
															</div>
														</figure>
													</div>
												</div> 
											)
										}
									</InfiniteCarousel>
								</div>
                            </div>
                        </div>
                    )
                }
				<div className="grid">
					{
						this.state.showLogin &&
						<div className="iframe_popup">
							<div className="iframe_popup_info" style={{ width: `${ this.width }px` }}>
								<div id="iframe_loader"><Loader type="TailSpin" color="#fba00b" height={100} width={100} /></div>
								<div className='iframe-logo'>
									<img src={ IMAGES.logoImg } alt='logo zone300' />
								</div>
								<div className="iframe_popup_form">
									<div className="iframe_popup_input">
										<input
											type="email"
											name="email"
											value={ this.state.email }
											onChange={ this.handleChange }
											placeholder="Email" />
									</div>
									<div className="iframe_popup_input">
										<input
											type="password"
											name="password"
											value={ this.state.password }
											onChange={ this.handleChange }
											onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
											placeholder={ t( 'form:password', language ) } />
									</div>
									<div className="iframe_popup_error">{ this.state.errorMessage }</div>
									<div className="iframe_popup_valid_button" onClick={ this.handleSubmit }>
										{ t( 'login:confirm', language ) }
									</div>
									<a className="iframe_popup_valid_button" href={ searchedIframeMovies[ 0 ].subLink } target="_blank" rel="noopener noreferrer">
										{ t( 'freemium:sub', language ) }
									</a>
								</div>
								<div className='iframe_popup_close'>
									<button className="close-button fa fa-window-close" onClick={ this.navClose }></button>
								</div>
							</div>
						</div>		
					}	
					<div id="z3pOverlay" className="z3PlayOverlay" style={{display : this.state.playerShow ? 'block' : 'none', width: this.state.playerWidth, height: this.state.playerHeight }}>
						<VideoPlayer className="video-js" id="VideoAccueil" { ...this.state.videoJsOptions } />
					</div>
				</div>
            </div>
		);
	}
}

const stateToProps = state => ({
	language: state.translatingReducer.language,
	profileVersion: state.userReducer.profileVersion,
	searchedIframeMovies: state.moviesReducer.searchedIframeMovies,
	userDiscarded: state.userReducer.userDiscarded,
});

const dispatchToProps = dispatch => ({
	fetchSearchedIframeMovies: ( iframeLink, userId, version, source ) => dispatch( moviesAction.fetchSearchedIframeMovies( iframeLink, userId, version, source )),
	fetchUserInfo: userId => dispatch(userAction.fetchUserInfo( userId )),
});

export default connect( stateToProps, dispatchToProps )( IframeComponent );