import React from "react";
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';

import MoviePage from "../../components/MovieDisplay/MoviePage";
import MozaicRender from "../../components/tools/MozaicRender";
import ProposalImage from "./ProposalImage";
import RowRender from "../../components/tools/RowRender";

import { DISPLAY, ICONS } from "../../constants";
import { moviesAction, routingAction } from "../../redux/actions";
import { logEvent } from "../../services/tracking";
import { getRoles, getUserInfo, t } from "../../utils";

class Search extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
            imageLoadNoCache: true,
            imageLoadError: true,
            proposals: [],
            proposalsIndexSelected: null,
			searchText: '',
		};
	}

	componentDidMount() {
        this.focusSearchInput();

        const fixParamArr = window.location.toString().split( '/browse/' )[ 1 ].split( '#/search/' );
		if ( fixParamArr && fixParamArr[ 0 ] === '' ) {
			const fixParam = fixParamArr[ 1 ].split( '?' )[ 0 ];
			window.location.assign( "/browse/?search=" + fixParam + "/#/search/" + fixParam );
			return;
		} 

		const {
            fetchCurrentRoutingByUrl,
            fetchMostViewedMovies,
            fetchSearchedMovies,
            fetchSearchProposals,
            profileVersion,
        } = this.props;

		const userId = getUserInfo().NoCli;
        fetchMostViewedMovies( 0, userId, profileVersion );
        fetchSearchProposals( userId, profileVersion );
        fetchCurrentRoutingByUrl( 'search' );

        const paramsArr = window.location.toString().split( '/browse/' )[ 1 ].split( '#/search/' );
        if ( paramsArr[ 1 ] && paramsArr[ 1 ] !== 'new_search' ) {
			fetchSearchedMovies( paramsArr[ 1 ].split( '?' )[ 0 ], userId, profileVersion );
			this.setState({ searchText: decodeURI( paramsArr[ 1 ] ) });
        }
  	};

    componentDidUpdate( prevProps ) {
		if ( this.props.searchedMovies !== prevProps.searchedMovies ) {
            this.focusSearchInput();
			const { hideSelected, searchedMovies } = this.props;
            if ( searchedMovies && searchedMovies.length > 0 && searchedMovies[ 0 ].seo_page ) hideSelected();
        }
	};

    focusSearchInput = () => {
        document.querySelector('body').scrollTo(0,0);
        const $searchInput = document.getElementById( 'search_input' );
		if ( $searchInput ) $searchInput.focus();
    };

	handleInputChange = e => { 
        const { language, searchProposals } = this.props;
        const inputValue = this.sanitalizeForProposal( e.target.value );
        let newProposals = [];
        let newProposalsMoviesToAdd = [];
        
        if ( searchProposals && inputValue.length > 0 ) {
            const categoriesMax = 2;
            const distributionMax = 2;
            const moviesMax = 2;
            const moviesToAddMax = 6;
            const seriesMax = 2;
            const proposalsMax = 8;

            let categoriesCount = 0;
            let distributionCount = 0;
            let moviesCount = 0;
            let moviesToAddCount = 0;
            let seriesCount = 0;

            for( let i = 0; i < searchProposals.series.high.length; i++ ) {
                if ( this.sanitalizeForProposal( searchProposals.series.high[ i ].title ).includes( inputValue ) ) {
                    if ( seriesCount >= seriesMax ) break;
                    searchProposals.series.high[ i ].type = 'Série';
                    newProposals.push( searchProposals.series.high[ i ] );
                    seriesCount ++;
                }
            }
            if ( seriesCount < seriesMax ) {
                for( let i = 0; i < searchProposals.series.medium.length; i++ ) {
                    if ( this.sanitalizeForProposal( searchProposals.series.medium[ i ].title ).includes( inputValue ) ) {
                        if ( seriesCount >= seriesMax ) break;
                        searchProposals.series.medium[ i ].type = 'Série';
                        newProposals.push( searchProposals.series.medium[ i ] );
                        seriesCount ++;
                    }
                }
            }
            if ( seriesCount < seriesMax ) {
                for( let i = 0; i < searchProposals.series.low.length; i++ ) {
                    if ( this.sanitalizeForProposal( searchProposals.series.low[ i ].title ).includes( inputValue ) ) {
                        if ( seriesCount >= seriesMax ) break;
                        searchProposals.series.low[ i ].type = 'Série';
                        newProposals.push( searchProposals.series.low[ i ] );
                        seriesCount ++;
                    }
                }
            }

            for( let i = 0; i < searchProposals.movies.high.length; i++ ) {
                if ( this.sanitalizeForProposal( searchProposals.movies.high[ i ].title ).includes( inputValue ) ) {
                    if ( moviesToAddCount >= moviesToAddMax ) break;
                    searchProposals.movies.high[ i ].type = 'Film';
                    if ( moviesCount >= moviesMax ) {
                        newProposalsMoviesToAdd.push( searchProposals.movies.high[ i ] );
                        moviesToAddCount ++;
                    } else {
                        newProposals.push( searchProposals.movies.high[ i ] );
                        moviesCount ++;
                    }
                }
            }
            if ( moviesToAddCount < moviesToAddMax ) {
                for( let i = 0; i < searchProposals.movies.medium.length; i++ ) {
                    if ( this.sanitalizeForProposal( searchProposals.movies.medium[ i ].title ).includes( inputValue ) ) {
                        if ( moviesToAddCount >= moviesToAddMax ) break;
                        searchProposals.movies.medium[ i ].type = 'Film';
                        if ( moviesCount >= moviesMax ) {
                            newProposalsMoviesToAdd.push( searchProposals.movies.medium[ i ] );
                            moviesToAddCount ++;
                        } else {
                            newProposals.push( searchProposals.movies.medium[ i ] );
                            moviesCount ++;
                        }
                    }
                }
            }
            if ( moviesToAddCount < moviesToAddMax ) {
                for( let i = 0; i < searchProposals.movies.low.length; i++ ) {
                    if ( this.sanitalizeForProposal( searchProposals.movies.low[ i ].title ).includes( inputValue ) ) {
                        if ( moviesToAddCount >= moviesToAddMax ) break;
                        searchProposals.movies.low[ i ].type = 'Film';
                        if ( moviesCount >= moviesMax ) {
                            newProposalsMoviesToAdd.push( searchProposals.movies.low[ i ] );
                            moviesToAddCount ++;
                        } else {
                            newProposals.push( searchProposals.movies.low[ i ] );
                            moviesCount ++;
                        }
                    }
                }
            }

            for( let i = 0; i < searchProposals.distribution.high.length; i++ ) {
                if ( this.sanitalizeForProposal( searchProposals.distribution.high[ i ].title ).includes( inputValue ) ) {
                    if ( distributionCount >= distributionMax ) break;
                    searchProposals.distribution.high[ i ].type = getRoles( searchProposals.distribution.high[ i ].role, language );
                    newProposals.push( searchProposals.distribution.high[ i ] );
                    distributionCount ++;
                }
            }
            if ( distributionCount < distributionMax ) {
                for( let i = 0; i < searchProposals.distribution.medium.length; i++ ) {
                    if ( this.sanitalizeForProposal( searchProposals.distribution.medium[ i ].title ).includes( inputValue ) ) {
                        if ( distributionCount >= distributionMax ) break;
                        searchProposals.distribution.medium[ i ].type = getRoles( searchProposals.distribution.medium[ i ].role, language );
                        newProposals.push( searchProposals.distribution.medium[ i ] );
                        distributionCount ++;
                    }
                }
            }
            if ( distributionCount < distributionMax ) {
                for( let i = 0; i < searchProposals.distribution.low.length; i++ ) {
                    if ( this.sanitalizeForProposal( searchProposals.distribution.low[ i ].title ).includes( inputValue ) ) {
                        if ( distributionCount >= distributionMax ) break;
                        searchProposals.distribution.low[ i ].type = getRoles( searchProposals.distribution.low[ i ].role, language );
                        newProposals.push( searchProposals.distribution.low[ i ] );
                        distributionCount ++;
                    }
                }
            }

            for( let i = 0; i < searchProposals.categories.high.length; i++ ) {
                if ( this.sanitalizeForProposal( searchProposals.categories.high[ i ].title ).includes( inputValue ) ) {
                    if ( categoriesCount >= categoriesMax ) break;
                    searchProposals.categories.high[ i ].type = 'Catégorie';
                    newProposals.push( searchProposals.categories.high[ i ] );
                    categoriesCount ++;
                }
            }
            if ( categoriesCount < categoriesMax ) {
                for( let i = 0; i < searchProposals.categories.medium.length; i++ ) {
                    if ( this.sanitalizeForProposal( searchProposals.categories.medium[ i ].title ).includes( inputValue ) ) {
                        if ( categoriesCount >= categoriesMax ) break;
                        searchProposals.categories.medium[ i ].type = 'Catégorie';
                        newProposals.push( searchProposals.categories.medium[ i ] );
                        categoriesCount ++;
                    }
                }
            }
            if ( categoriesCount < categoriesMax ) {
                for( let i = 0; i < searchProposals.categories.low.length; i++ ) {
                    if ( this.sanitalizeForProposal( searchProposals.categories.low[ i ].title ).includes( inputValue ) ) {
                        if ( categoriesCount >= categoriesMax ) break;
                        searchProposals.categories.low[ i ].type = 'Catégorie';
                        newProposals.push( searchProposals.categories.low[ i ] );
                        categoriesCount ++;
                    }
                }
            }

            if ( newProposals.length < proposalsMax ) {
                let indexToAdd = 0;
                for( let j = 0; j < newProposals.length; j++ ) {
                    if ( newProposals[ j ].type === 'Film' && ( ! newProposals[ j + 1 ] || newProposals[ j + 1 ].type !== 'Film' ) ) {
                        indexToAdd = j + 1;
                        break;
                    }
                }

                for( let i = 0; i < newProposalsMoviesToAdd.length; i++ ) {
                    if ( newProposals.length >= proposalsMax ) break;
                    newProposals.splice( indexToAdd, 0, newProposalsMoviesToAdd[ i ] );
                    indexToAdd ++;
                }
            }
        }

        this.setState({ proposals: newProposals, searchText: e.target.value }); 
	};
	
	handleInputSubmit = () => {
		const search = this.state.searchText.trim();
		if ( search.length <= 0 ) return;
         
        if ( this.sanitalizeForProposal( search ) === 'chasse' ) {
            window.location.href = '/browse/?chasse/#/chasse/';
            return;
        } else if ( this.sanitalizeForProposal( search ) === 'peche' ) {
            window.location.href = '/browse/?peche/#/peche/';
            return
        }

        const userId = getUserInfo().NoCli ? getUserInfo().NoCli : '';
        logEvent({ NoCli: userId, event: 'search-submit', eventDetails: search });
		window.location.href = "/browse/?search=" + search + "/#/search/" + search;
	};

    handleProposalSubmit = url => {
        const search = this.state.searchText.trim();
        const userId = getUserInfo().NoCli ? getUserInfo().NoCli : '';
        logEvent({ NoCli: userId, event: 'search-proposal', eventDetails: `${ search } | ${ url }` });
		window.location.href=`/browse/?search=${ url }/#/search/${ url }`;
    };

    isInitial = () => {
        const paramsArr = window.location.toString().split( '/browse/' )[ 1 ].split( '#/search/' );
        return paramsArr[ 1 ] && paramsArr[ 1 ] === 'new_search';
    };

    sanitalizeForProposal = stg => {
        return stg
                .toLowerCase()
                .replace(/[àâäá]/g,'a')
                .replace(/[èêéë]/g,'e')
                .replace(/[ïîí]/g,'i')
                .replace(/[ôöó]/g,'o')
                .replace(/[ùûüú]/g,'u')
                .replace(/[yÿ]/g,'y')
                .replace(/[ç]/g,'c')
                .replace(/[ñ]/g,'n');
    };

	render() {
		const { language, mostViewedMovies, searchedMovies, searchedMoviesDone } = this.props;
        const { proposals, proposalsIndexSelected, searchText } = this.state;

        const isSpecialPage = searchedMovies && searchedMovies.length > 0 && searchedMovies[ 0 ].seo_page;
        const isMoviePage = isSpecialPage && searchedMovies[ 0 ].seo_page === DISPLAY.SHEET_ID;
        const showInput = this.isInitial() || ( ! this.isInitial() && ! isSpecialPage && searchedMoviesDone );

		return (
            <div className={ `SearchPage-container ${ isMoviePage ? 'movie-page' : '' }` }>
                <div className="slider search-top"></div>
                <div id="result-container1" className={`grid ${ isSpecialPage && searchedMovies[ 0 ].seo_page !== 'videos_participants' ? 'catAcc_result' : '' }`} style={{ padding: "8px", color: "#fff" }}> 
                    {
                        showInput &&
                        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={8}>
                            <div className='search_box'>
                                <input
                                    id='search_input'
                                    className={ proposals && proposals.length > 0 && searchText.length > 0 ? 'with-proposals' : '' }
                                    type='text'
                                    placeholder={ t( 'search:placeholder', language ) }
                                    onChange={ this.handleInputChange }
                                    onKeyDown={ e => {
                                        if ( e.keyCode === 13 ) {
                                            if ( proposalsIndexSelected === null ) {
                                                this.handleInputSubmit();
                                            } else if ( proposals[ proposalsIndexSelected ] && proposals[ proposalsIndexSelected ].url ) {
                                                this.handleProposalSubmit( proposals[ proposalsIndexSelected ].url );
                                            }
                                        }
                                        if ( e.keyCode === 38 ) {
                                            e.preventDefault();
                                            if ( proposalsIndexSelected !== null ) {
                                                if ( proposalsIndexSelected === 0 ) {
                                                    this.setState({ proposalsIndexSelected: null })
                                                } else {
                                                    this.setState({ proposalsIndexSelected: proposalsIndexSelected - 1 });
                                                }
                                            }
                                        }
                                        if ( e.keyCode === 40 ) {
                                            e.preventDefault();
                                            if ( proposalsIndexSelected !== null ) {
                                                if ( proposalsIndexSelected < proposals.length - 1 ) {
                                                    this.setState({ proposalsIndexSelected: proposalsIndexSelected + 1 });
                                                }
                                            } else {
                                                this.setState({ proposalsIndexSelected: 0 });
                                            }
                                        }
                                    }}
                                    />
                                {
                                    proposals && proposals.length > 0 &&
                                    <div className={ `search_proposals_box ${ searchText.length > 0 ? 'show' : '' }` }>
                                        {
                                            proposals.map( ( el, index ) => (
                                                <div
                                                    key={ index }
                                                    className={ `search_proposals_row ${ index === proposalsIndexSelected ? 'selected' : '' }` }
                                                    onClick={ () => { if ( el.url ) this.handleProposalSubmit( el.url ); } }>
                                                    <div className={ el.completed ? 'completed' : '' }>
                                                        <ProposalImage item={ el } />
                                                    </div>
                                                    <div className='search_proposals_title'>{ el.title }</div>
                                                    <div className="f-grow-1"></div>     
                                                    <div className='search_proposals_type'>{ el.type }</div>        
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                                <div
                                    id='search_btn_small'
                                    className={ proposals && proposals.length > 0 && searchText.length > 0 ? 'with-proposals' : '' }
                                    onClick={ this.handleInputSubmit }>
                                    <img className="nav_icon" src={ ICONS.search } alt='icone recherche' />
                                </div>  
                            </div>
                            <div id='search_btn' onClick={ this.handleInputSubmit }>{ t( 'search:confirm', language ) }</div>
                        </Grid>	
                    }
                    <div style={{textAlign: 'left'}}>
                        {
                            searchedMovies && searchedMovies.length > 0 && searchedMovies[ 0 ].movies && searchedMovies[ 0 ].movies.length > 0 ?
                            <>
                                {
                                    isMoviePage ?
                                    <MoviePage />
                                    :
                                    <MozaicRender movieListInfo={ searchedMovies } />
                                }
                            </>
                            :
                            <>
                                {
                                    searchedMoviesDone &&
                                    <div className='default-favorite'>
                                        <h3>{ t( 'search:default', language ) }</h3>
                                    </div>
                                }
                            </>
                            
                        }
                        {
                            ( showInput || window.location.toString().split( '#pla' )[ 1 ] ) &&
                            <RowRender movieListInfo = { mostViewedMovies } />
                        }
                    </div>
                </div>
            </div>
		);
	}
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
	mostViewedMovies: state.moviesReducer.mostViewedMovies,
    profileVersion: state.userReducer.profileVersion,
	searchedMovies: state.moviesReducer.searchedMovies,
	searchedMoviesDone: state.moviesReducer.searchedMoviesDone,
	searchProposals: state.moviesReducer.searchProposals,
});

const dispatchToProps = dispatch => ({
	fetchCurrentRoutingByUrl: url => dispatch( routingAction.fetchCurrentRoutingByUrl( url )),
	fetchMostViewedMovies: ( themeId, userId, version ) => dispatch( moviesAction.fetchMostViewedMovies( themeId, userId, version )),
	fetchSearchedMovies: ( searchText, userId, version ) => dispatch( moviesAction.fetchSearchedMovies( searchText, userId, version )),
	fetchSearchProposals: ( userId, version ) => dispatch( moviesAction.fetchSearchProposals( userId, version )),
	hideSelected: () => dispatch( routingAction.hideSelected()),
});

export default connect( stateToProps, dispatchToProps )( Search );