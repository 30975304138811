import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';
import Loader from 'react-loader-spinner';

import { activeCampaignEvent, logEvent, gtmEvent } from "../../../services/tracking";
import { reactivateUserSub } from "../../../services/user";
import { convertTypeAbo, formatDateForUser, handleLoader, t } from "../../../utils";

class PReactivate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
            successMessage: '',
        }
    };

    handleReactivate = () => {
        const { cancelAt, language, userInfoDB } = this.props;
        this.setState ( { errorMessage: '', successMessage: '' } );
        handleLoader( 'profile_loader', 'add' );
        const dataUser = { NoCli: userInfoDB.NoCli };
        reactivateUserSub( dataUser )
            .then( res => {
                if ( ! res.ret ) {
                    this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                    handleLoader( 'profile_loader', 'remove' );
                    return;
                }

                handleLoader( 'profile_loader', 'remove' );
                this.setState ({ successMessage: t( 'reactivate:success', language ) });

                let acData = {
                    email: userInfoDB.email,
                    eventDetails: 'account browse',
                    eventName: 'utilisateur reactive'
                };
                acData[ 'date désabonnement' ] = '';

                logEvent({ NoCli: userInfoDB.NoCli, event: 'reactivate sub', eventDetails: `planified for ${ cancelAt }` });
                gtmEvent({ event: 'utilisateur reactive' });
                activeCampaignEvent( acData );
            } )
            .catch( error => { console.log( error ); } );  
    };
jete 
    render() {
        const { cancelAt, handlePageChange, language, userInfoDB } = this.props;
        const { errorMessage, successMessage } = this.state;

        const $content = 
            <>
                <div id="profile_container">
                    <div id='profile_loader'><Loader type='TailSpin' color='#fba00b' height={100} width={100} /></div>
                    <div className="profile-unsub-form">
                        <div id='profile_nav2'>
                            <div className='profile_nav' onClick={ () => handlePageChange( 'left', 'menu' ) }>{ t( 'shared:back', language ) }</div>
                        </div>
                        <div>
                            <h1 className='profile_unsub_title'>{ t( 'reactivate:title', language ) }</h1>
                        </div>
                        <ul className='profile_unsub_list'>
                            <li>{ `${ t( 'reactivate:infoPart1', language ) }${ convertTypeAbo( userInfoDB.TypeAbo, language ).adj }${ t( 'reactivate:infoPart2', language ) }` }</li>
                            {
                                cancelAt > 0 &&
                                <li>{ `${ t( 'reactivate:details', language ) }${ formatDateForUser( cancelAt, language ) }.` }</li>
                            }
                        </ul>
                        <div className="unsub_form_confirmation">
                            { errorMessage && <div className="mt-3 profile_edit_usual_error">{ errorMessage }</div> }
                            { successMessage && <div className="mt-3 profile_edit_usual_success">{ successMessage }</div> }
                        </div>
                    </div>
                </div>
                <div className="profile_unsub_nav_btns">
                    {
                        successMessage ?
                        <div id="reactivate_step1" className="profile_btn_return" onClick={ () => window.location.href = '/browse/#/' }>
                            <span>{ t( 'shared:backPlatform', language ) }</span>
                        </div>
                        :
                        <div id="reactivate_step1" className="profile_btn_return" onClick={this.handleReactivate}>
                            <span>{ t( 'reactivate:confirm', language ) }</span>
                        </div>
                    }  
                </div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
	cancelAt: state.userReducer.cancelAt,
    language: state.translatingReducer.language,
	userInfoDB: state.userReducer.userInfoDB,
});

export default connect( stateToProps )( PReactivate );