import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';

import CheckBox from "../partials/CheckBox";
import SearchModule from '../partials/SearchModule';

import { userAction } from '../../../redux/actions'
import { getUnsubForm, postUnsubComment, postUnsubForm, updateUnsubStep } from "../../../services/user";
import { t } from "../../../utils";

class PUnsubStep2 extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			catValuePeche: [],
			catValueChasse: [],
			checkedState: [],
			disabledCheckbox: false,
			errorMessage: '',
			pathChasse: [],
			pathPeche: [],
			searchChasse: '',
			searchPeche: '',
			textAreaValue: '',
			toppings: [],
		};
		
		this.checkBoxMax = 3;
	};

	componentDidMount() {
		const { fetchCategoriesList } = this.props;
		fetchCategoriesList();
		getUnsubForm( "" )
			.then( res => this.setState({ toppings: res.data, checkedState: new Array(res.data.length).fill(false) }))   
			.catch(err => console.log(err));
	};

	componentDidUpdate() {
		let trueCheckBox = this.state.checkedState.filter(element => element === true);
		if( trueCheckBox.length >= this.checkBoxMax && !this.state.disabledCheckbox ) this.setState({disabledCheckbox: true});
	};

	catToDisplay = type => {
		const { categoriesList } = this.props;
		const catToDisplay = type === "chasse" ? categoriesList.hunting : categoriesList.fishing;
		const searchPage =  type === "chasse" ? this.state.searchChasse : this.state.searchPeche;
		const catValue = type === "chasse" ? this.state.catValueChasse : this.state.catValuePeche;

		let result = [];

		let tampon = [];
		if ( searchPage !== "" ) {
			catToDisplay.forEach( (element, i) => {
				if ( element.title.toLowerCase().indexOf( searchPage.toLowerCase() ) !== -1 ) {
					catToDisplay.forEach( elementTest => {
						if ( element.path[element.path.length-1].replace("|", '') === elementTest.parent  && !tampon.includes(element.id) ) tampon = [...tampon, element.title];
					});
					
					if ( !tampon.includes(element.title) && !catValue.includes(element) ) {
						result = [ ...result,
							<div key={`display${type}-${i}`} className="cat-select" onClick={ () => this.checkCatChoice( element, catToDisplay, type ) }>
								{ element.title }
							</div>
						];
					}
				}
			})
			return result;
		}

		tampon = [];
		catToDisplay.forEach( (element, i ) => {
			catToDisplay.forEach( elementTest => {
				if ( element.path[element.path.length-1].replace("|", '') === elementTest.parent  && !tampon.includes(element.id) ) tampon = [...tampon, element.title];
			});
			
			if ( !tampon.includes(element.title) && !catValue.includes(element) ) {
				result = [ ...result,
					<div key={`display${type}-${i}`} className="cat-select" onClick={() => this.checkCatChoice( element, catToDisplay, type ) }>
						{ element.title }
					</div>
				];
			}
		});

		result = result.splice(0, 3);
		return result;
	};

	checkCatChoice = ( categorie, catTab, type ) => {
		let result = [];
		let catValue = type === "chasse" ? "catValueChasse": "catValuePeche";

		catTab.forEach( element => {
			if ( element.parent ===  categorie.path[categorie.path.length-1].replace("|", '') ) result = [...result, element ];
		});

		if ( result.length === 0 ) {
			if ( !this.state[catValue].includes(categorie) ) {
				this.setState({ [catValue]: [...this.state[catValue], categorie] });
				return;
			}

			let tampon = this.state[catValue];
			tampon.splice( this.state[catValue].indexOf(categorie),1);
			this.setState({ [catValue]: tampon });
			return;
		}
	};

	handleChange = event => this.setState({ [event.target.id]: event.target.value });

	handleChangeCheckBox = position => {
		const checkedState = this.state.checkedState.map( (element, index) => index === position ? !element : element )
		this.setState({ checkedState, disabledCheckbox: false  })
	};

	path = type => {
		const { categoriesList } = this.props;
		const catList = type === "chasse" ? categoriesList.hunting : categoriesList.fishing;
		const catValue = type === "chasse" ? this.state.catValueChasse: this.state.catValuePeche;

		let result = [];

		catValue.forEach( element => {	
			result = [ ...result, 
				<div 
					key={`selected-cat-${type}-${element.id}`} 
					className="selected-cat" 
					onClick={ () => this.checkCatChoice( element, catList, type ) }>
					{ element.title } 
					<svg className="ml-2" xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 0 24 24" width="22px" fill="#E4E4E4">
						<path d="M0 0h24v24H0V0z" fill="none" opacity=".87"/>
						<path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm4.3 14.3c-.39.39-1.02.39-1.41 0L12 13.41 9.11 16.3c-.39.39-1.02.39-1.41 0-.39-.39-.39-1.02 0-1.41L10.59 12 7.7 9.11c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L12 10.59l2.89-2.89c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41L13.41 12l2.89 2.89c.38.38.38 1.02 0 1.41z"/>
					</svg>
				</div>
			];
		})

		return result;
	};

	unsubStep = event => {
		const { language, userInfoDB } = this.props;
		const { checkedState } = this.state;
		const valueId = event.currentTarget.id;
		const trueElementsNumber = this.state.checkedState.filter(element => element === true).length
		let data = { NoCli: userInfoDB.NoCli, data: '' };
		if ( valueId !== 'sub_step2' && ( trueElementsNumber > this.checkBoxMax || trueElementsNumber === 0 ) ) {
			this.setState({ errorMessage: t( 'form:checkboxError', language ) });
			return;
		};

		let checkedStg = '';
		this.state.toppings.forEach( ( element, id ) => { if ( checkedState[id] ) checkedStg += `|${element.id}|`; } );
		let categoriesFishingStg = '';
		this.state.catValuePeche.forEach( element => categoriesFishingStg += `|${element.id }|` );
		let categoriesHuntingStg = '';
		this.state.catValueChasse.forEach( element => categoriesHuntingStg += `|${element.id }|` );

		switch ( valueId ) {
			case 'sub_step2':   
				data.data = 'new-step2 - sub';
				updateUnsubStep( data )
					.then( res => { console.log( res ); } )
					.catch( error => { console.log( error ); } );
				window.location.href="/browse/#/";
				break;
			case 'unsub_step2':
				data.data = 'new-step2 - unsub';
				updateUnsubStep( data )
					.then( () => {
						postUnsubForm({ NoCli: userInfoDB.NoCli, categoriesFishing: categoriesFishingStg, categoriesHunting: categoriesHuntingStg, checkedState: checkedStg }); 
						if ( this.state.textAreaValue.length > 0 ) postUnsubComment({ NoCli: userInfoDB.NoCli, comment: this.state.textAreaValue });
					})
					.catch( error => { console.log( error ); } );

					this.props.handlePageChange( 'right', 'unsubStep3' );
				break;
			default:
				break;
		}
	};

	render() {
		const { handlePageChange, language } = this.props; 
		const { checkedState, disabledCheckbox, errorMessage } = this.state;
		
		const $content = 
			<>
				<div id='profile_container'>
					<div className="profile-unsub-form">
						<div id='profile_nav2'>
							<div className='profile_nav' onClick={ () => { handlePageChange( 'left', 'unsubStep1' ) } }>{ t( 'shared:back', language ) }</div>
							<div>{ t( 'unsub:step', language ) } 2/3</div>
						</div>
						<div className="mb-3">
							<h1>{ t( 'unsub:step1Title', language ) }</h1>
							<p className="mb-3">{ t( 'unsub:step2Text1', language ) }<br /></p>   
							<p>{ t( 'unsub:step2Text2', language ) }</p>
						</div>
						<div className="checkbox-section-container">
							<div>{ t( 'form:reason', language ) } ({this.checkBoxMax} max)</div>
							{
								this.state.toppings.map( (element, index) => {
									let disable = disabledCheckbox;
									if ( checkedState[ index ] === true ) disable = false;      
									return (
										<div  key={`check-${index}`}>
											<div className="checkbox-wrapper">
												<CheckBox
													id={ index }
													label={ element.label[0].toUpperCase() +  element.label.slice(1) }
													value={ checkedState[index] }
													handleOnChange={ this.handleChangeCheckBox }
													toggle={ disable } />
											</div>
											{
												index === 1 && this.state.checkedState[1] &&
												<SearchModule
													handleChange = {this.handleChange}
													search = { this.state.searchChasse }
													typeCat = "chasse"
													path = { this.path }
													catToDisplay = { this.catToDisplay } />
											}
											{
												index === 2 && this.state.checkedState[2] &&
												<SearchModule
													handleChange = {this.handleChange}
													search = { this.state.searchPeche }
													typeCat = "peche"
													path = { this.path }
													catToDisplay = { this.catToDisplay } />
											}
										</div>
									)
								})
							}
						</div>
						<div className="form-textarea">
							<label htmlFor="textAreaValue">
								{ t( 'form:comment', language ) } <span>({ t( 'form:optional', language ) })</span>
							</label>
							<textarea name="comment" id="textAreaValue" onChange={ this.handleChange }></textarea>
							<div className="profile_edit_usual_error">{ errorMessage }</div>
						</div>
					</div>
				</div>
				<div className="profile_unsub_nav_btns">
					<div id="sub_step2" className="profile_btn_return" onClick={ event => this.unsubStep(event) } >
						<span>{ t( 'unsub:cancel', language ) }</span>
					</div>
					<div id="unsub_step2" className="profile_btn_continuer" onClick={ event => this.unsubStep(event) } >
						<span>{ t( 'unsub:continue', language ) }</span>
					</div>
				</div>
			</>

		if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
		if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

		return <></>;
	};
}

const stateToProps = state => ({
	categoriesList: state.userReducer.categoriesList,
	language: state.translatingReducer.language,
	userInfoDB: state.userReducer.userInfoDB,
});

const dispatchToProps = dispatch => ({
	fetchCategoriesList: () => dispatch( userAction.fetchCategoriesList()),
});

export default connect( stateToProps, dispatchToProps )( PUnsubStep2 );