import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';
import Loader from 'react-loader-spinner';
import { Elements } from "@stripe/react-stripe-js";

import SCStripeDefaultCard from "./SCStripeDefaultCard";
import SCStripeForm from "./SCStripeForm";

import { API_IMAGE, CURRENCY, ICONS, IMAGES, LANGUAGE, STRIPE_KEY } from "../../constants";
import { storeAction } from "../../redux/actions";
import { checkStoreCode } from "../../services/store";
import { formatEuroPrice, getUserInfo, handleLoader, t } from "../../utils";

import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe( STRIPE_KEY );

class SCPayment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: '',
            errorMessage: '',
            isCodeValid: false,
            priceStgCurrent: this.isWithDefaultDiscount() ? formatEuroPrice( this.applyDefaultDiscount( this.getPriceTotal() ) ) : formatEuroPrice( this.getPriceTotal() ),
            priceStgOld: this.isWithDefaultDiscount() ? formatEuroPrice( this.getPriceTotal() ) : '',
            showStripeForm: false,
            successMessage: '',
        };
    };

    applyDefaultDiscount = ( price ) => {
        const { userDiscarded, userInfoDB } = this.props;
        if ( ! userDiscarded && userInfoDB.default_discount ) return Math.floor( price - ( ( userInfoDB.default_discount * price ) / 100 ) );
        return price;
    };

    applyDiscount = ( price, amount, type ) => {
        if ( type === '%' ) return Math.floor( price - ( ( amount * price ) / 100 ) );
        if ( type === '€' ) return price - ( amount * 100 );
    };

    checkErrors = () => {
        const { language } = this.props;

        if ( this.state.code === '' ) {
            this.setState({ errorMessage: t( 'form:codeEmpty', language ) });
            return false;
        }
        this.setState({ errorMessage: '' });
        return true;
    };

    codeSubmit = () => {
        if ( this.checkErrors() ) {
            handleLoader( 'profile_loader', 'add' );
            const { language, storeCart } = this.props;
            const { code, priceStgCurrent } = this.state;

            const dataCode = { cart: this.stringifyCart( storeCart ), code: code, NoCli: getUserInfo().NoCli };

            checkStoreCode( dataCode )
                .then( res => {
                    if ( ! res.ret ) {
                        switch( res.data ) {
                            case 'code unknown':
                                this.setState ({ errorMessage: t( 'form:codeError', language ) });
                                break;
                            default:
                                this.setState ({ errorMessage: t( 'shared:genericError', language ) });       
                        }
                        handleLoader( 'profile_loader', 'remove' );
                        return;
                    }

                    this.setState({
                        discountAmountStg: `- ${ res.data.amount }${ res.data.type }`,
                        isCodeValid: true,
                        priceStgCurrent: formatEuroPrice( this.applyDiscount( this.getPriceTotal(), res.data.amount, res.data.type ) ),
                        priceStgOld: priceStgCurrent,
                        errorMessage: '',
                        successMessage: t( 'payment:codeSuccess', language ),
                    });

                    handleLoader( 'profile_loader', 'remove' );
                })
                .catch( error => {
                    console.log( error );
                    this.setState ( { errorMessage: t( 'shared:genericError', language ) } );
                    handleLoader( 'profile_loader', 'remove' );
                });   
        }
    };

    codeRemove = () => this.setState({
        code: '',
        discountAmountStg: '',
        errorMessage: '',
        isCodeValid: false,
        priceStgCurrent: this.isWithDefaultDiscount() ? formatEuroPrice( this.applyDefaultDiscount( this.getPriceTotal() ) ) : formatEuroPrice( this.getPriceTotal() ),
        priceStgOld: this.isWithDefaultDiscount() ? formatEuroPrice( this.getPriceTotal() ) : '',
        successMessage: '',
    });

    getPriceTotal = () => {
        const { storeCart } = this.props;
        let result = 0;
        storeCart.forEach( el => {
            const price = this.getProductInfo( el ).price;
            if ( price ) result += ( price * el.productQuantity );
        });
        return result;
    };

    getProductInfo = product => {
        const { storeSelectedProducts } = this.props;
        const productInfo = storeSelectedProducts.find( el => el.productId === product.productId );
        return productInfo ? productInfo : {};
    };

    handleChange = event => {
        let value = event.target.value;
        const name = event.target.name;
        if ( name === 'email' ) value = value.toLowerCase().trim();
        this.setState({ [name]: value });
    };

    isWithDefaultDiscount = () => {
        const { userDiscarded, userInfoDB } = this.props;
        return userInfoDB && userInfoDB.default_discount && ! userDiscarded;
    };

    renderPic = product => {
		if ( product.mainFileName ) {
			const srcImage = `${ API_IMAGE }${ product.mainFileName }.png&w=500&h=500&crop-to-fit`;
			const srcErrorImage = IMAGES.placeholderSquare;
			
			return (
                <img
                    className='tile-img'
                    alt={ product.name }
                    src={ srcImage } 
                    onError={e => {
                        if ( this.state.imageLoadNoCache ) {
                            this.setState({ imageLoadNoCache: false });
                            e.target.src = `${ srcImage }&no-cache`;
                        } else if ( this.state.imageLoadError ) {
                            this.setState({ imageLoadError: false });
                            e.target.src = srcErrorImage;
                        }
                    }} />
			);
		}

		return (
			<img
				alt="img"
				className="tile-img"
				src={ IMAGES.placeholderSquare } />
		);
	};

    stringifyCart = cart => {
        let result = '';
        if( cart && cart.length > 0 ) {
            cart.forEach( el => {
                result += '__';
                result += `|${ el.productId ? el.productId : 'null' }|`;
                result += `|${ el.productColor ? el.productColor : 'null' }|`;
                result += `|${ el.productSize ? el.productSize : 'null' }|`;
                result += `|${ el.productQuantity ? el.productQuantity : 0 }|`;
                result += '__';
            });
        }
        return result;
    };

    render() { 
        const { clearCart, handlePageChange, language, storeCart, storeCartLength, stripeCard, userInfoDB } = this.props;
        const { code, discountAmountStg, errorMessage, isCodeValid, priceStgCurrent, priceStgOld, showStripeForm, successMessage } = this.state;

        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_loader' className='bottom-loader'><Loader type='TailSpin' color='#fba00b' height={100} width={100} /></div>
                    <div id='profile_nav'>
                            <span className='profile_nav' onClick={ () => { handlePageChange( 'left', 'validation' ) } }>{ t( 'nav:cart', language ) }</span>
                            <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                            <span className='profile_nav' onClick={ () => { handlePageChange( 'left', 'address' ) } }>{ t( 'nav:address', language ) }</span>
                            <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                            <span>{ t( 'nav:payment', language ) }</span>
                    </div>
                    <div id='profile_body'>
                        {
                            storeCartLength > 0 && storeCart.map( ( item, index ) => {
                                const product = this.getProductInfo( item );
                                return (
                                    <div className='profile_menu' key={ index }>
                                        <div className='profile_scale cart_product_details'>
                                            <div className='cart_product_image'>{ this.renderPic( product ) }</div>
                                            <div className='cart_product_title'>{ product.name }</div>
                                            <div className='cart_product_price'>
                                                {
                                                    item.productQuantity > 1 ?
                                                    <span>
                                                        { `${ formatEuroPrice( product.price ) } x ${ item.productQuantity } = ${ formatEuroPrice( product.price * item.productQuantity ) }` }
                                                    </span>
                                                    :
                                                    <span>{ formatEuroPrice( product.price ) }</span>
                                                }
                                            </div>
                                            <div className='list-title-product-cart'>
                                                {
                                                    item.productColor &&
                                                    <div className='list-title-product-custom-select'>
                                                        <label>{ t( 'store:color', language ) }</label>
                                                        <div className='list-title-product-select-selected disabled'>{ item.productColor }</div>
                                                    </div>
                                                }
                                                {
                                                    item.productSize &&
                                                    <div className='list-title-product-custom-select'>
                                                        <label>{ t( 'store:size', language ) }</label>
                                                        <div className='list-title-product-select-selected disabled'>{ item.productSize }</div>
                                                    </div>
                                                }
                                                <div className='list-title-product-quantity-box'>
                                                    <label>{ t( 'store:quantity', language ) }</label>
                                                    <div className='list-title-product-quantity-input disabled'>
                                                        <input
                                                            type='number'
                                                            min='1'
                                                            name='productQuantity'
                                                            value={ item.productQuantity }
                                                            onChange={ () => {} }
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                        <div className='profile_menu menu_priceTotal'>
                            {
                                storeCartLength > 0 && this.getPriceTotal() > 0 ?
                                <>
                                    <div className='profile_scale cart_product_price tall'>
                                        { t( 'payment:total', language ) } : { priceStgOld ? <span className='cart_product_price_old'>{ priceStgOld }</span> : '' } { priceStgCurrent }
                                    </div>
                                    {
                                        discountAmountStg &&
                                        <>
                                            <div className='f-grow-1'></div>
                                            <div className='cart_product_discount_box'>
                                                { t( 'payment:codeLabel', language ) } <span>{ discountAmountStg }</span>
                                            </div>
                                        </>
                                    }
                                    {
                                        ! discountAmountStg && this.isWithDefaultDiscount() &&
                                        <>
                                            <div className='f-grow-1'></div>
                                            <div className='cart_product_defualt_discount_box'>
                                                - { userInfoDB.default_discount } % { t( 'store:forSub', language ) }
                                            </div>
                                        </>
                                    }
                                </>
                                :
                                <div className='profile_scale cart_product_title'>{ t( 'store:cartEmpty', language ) }</div>
                            }
                        </div>
                        <div className='profile_menu menu_stripe'>
                            <div>
                                <div>{ t( 'payment:codeLabel', language ) }</div>
                                <div className="product_code_form">
                                    <input
                                        type="text"
                                        name="code"
                                        value={ code }
                                        onChange={ this.handleChange }
                                        onKeyDown={ event => { if (event.keyCode === 13) this.codeSubmit(); }}
                                        disabled={ isCodeValid } />
                                    {
                                        ! isCodeValid ?
                                        <div className="product_code_form_valid" onClick={ this.codeSubmit }>{ t( 'payment:codeConfirm', language ) }</div>
                                        : 
                                        <div className="product_code_form_remove" onClick={ this.codeRemove }>{ t( 'payment:codeRemove', language ) }</div>
                                    }
                                </div>
                                <div className="product_code_form_results">
                                    <div className="product_code_form_error">{ errorMessage }</div>
                                    <div className="product_code_form_success">{ successMessage }</div>
                                </div>
                            </div>
                        </div>
                        {
                            stripeCard &&
                            <Elements stripe={ stripePromise }>
                                <SCStripeDefaultCard
                                    showStripeForm={ showStripeForm }
                                    toggleShowStripeForm={ () => this.setState({ showStripeForm: ! showStripeForm }) }
                                    stripeCard={ stripeCard }
                                    clearCart={ clearCart }
                                    codeId={ code }
                                    currency={ CURRENCY.EUR }
                                    handlePageChange={ handlePageChange }
                                    isWithDefaultDiscount={ this.isWithDefaultDiscount() }
                                    language={ LANGUAGE.FRENCH }
                                    priceStgCurrent={ priceStgCurrent }
                                    cartStg={ this.stringifyCart( storeCart ) }
                                    userId={ getUserInfo().NoCli } />
                            </Elements>
                        }
                        {
                            ( ! stripeCard || showStripeForm ) &&
                            <div className='profile_menu menu_stripe'>
                                <div className="local_stripe_container">
                                    <Elements stripe={ stripePromise }>
                                        <SCStripeForm
                                            clearCart={ clearCart }
                                            codeId={ code }
                                            currency={ CURRENCY.EUR }
                                            handlePageChange={ handlePageChange }
                                            isWithDefaultDiscount={ this.isWithDefaultDiscount() }
                                            language={ LANGUAGE.FRENCH }
                                            priceStgCurrent={ priceStgCurrent }
                                            cartStg={ this.stringifyCart( storeCart ) }
                                            userId={ getUserInfo().NoCli } />
                                    </Elements>
                                    <div className="local_stripe_info">
                                        <div className="local_stripe_info_text">
                                            <i className="fas fa-lock" aria-hidden="true"></i>
                                            { t( 'payment:info', language ) }
                                        </div>
                                        <div className="local_stripe_info_img">
                                            <img src={ IMAGES.cbAcceptedImg } alt='paiements acceptes'/>
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        }
                    </div>
                </div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
	storeCart: state.storeReducer.storeCart,
	storeCartLength: state.storeReducer.storeCartLength,
    storeSelectedProducts: state.storeReducer.storeSelectedProducts,
    stripeCard: state.userReducer.stripeCard,
    userDiscarded: state.userReducer.userDiscarded,
    userInfoDB: state.userReducer.userInfoDB,
});

const dispatchToProps = dispatch => ({
    clearCart: cart => dispatch(storeAction.clearCart( cart )),
});

export default connect( stateToProps, dispatchToProps )( SCPayment );