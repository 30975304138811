import React from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';

import { API_IMAGE, DEVICE_VERSION, ICONS, IMAGES, URL_VIMEO } from '../../constants';
import { moviesAction, playerAction, playlistAction } from '../../redux/actions';
import { updateFreeProgressMovies, updateProgressMovies } from '../../services/movies';
import { updatePlaylistProgressMovies } from '../../services/playlist';
import { toggleThumbs } from '../../services/user';
import { authFailed, findProperNextIndex, formatTimeAdvertising, getProgressBarStyle, getUserInfo, isGuest, t } from '../../utils';

import 'react-confirm-alert/src/react-confirm-alert.css';

class VideoPlayer extends React.Component {

	constructor( props ) {
		super( props );

		this.state = {
			advertisingKey: null,
			advertisingList: [],
			advertisingProgressStg: null,
			advertisingProgressStyle: null,
			controls: true,
			countEnding: null,
			episodeKey: null,
			imageLoadNoCache: true,
			imageLoadError: true,
			imageProductLoadNoCache: true,
			imageProductLoadError: true,
			isAdvertising: false,
			isBonus: false,
			isEpisode: false,
			isFreeMovie: false,
			isIframe: false,
			isPlaylist: false,
			isTrailer: false,
			key: 0,
			light: false,
			loop: false,
			modalEnding: false,
			modalShow: false,
			movieKey: null,
			movieList: [],
			muted: true,
			nextAdvertisingKey: null,
			nextEpisodeKey: null,
			nextMovieKey: null,
			pip: false,
			playbackRate: 1.0,
			playing: false,
			playlistMovieProgress: null,
			progressInfo: null,
			volume: 1.0,
		};

		this.intervalId = null;
		this.needSeekTo = true;
		this.newSeriesLocal = null;
		this.timeTotal = null;
		this.videoLimit = null;

		this.thumbUpList = [];
        this.thumbDownList = [];

		this.nextVideoBeforeEnd = 12;
		this.nextVideoCount = 10;
		this.progressTimeInterval = 10;
		this.pubInterval = 300;
		this.pubNumber = 1;
		this.randTimeRange = 0;
		this.randTimeStart = 0;
	};

	componentWillReceiveProps( newProps ) {
		const {
			advertisingKey, advertisingList, controls, episodeKey,
			isBonus, isEpisode, isFreeMovie, isIframe, isPlaylist, isTrailer,
			movieKey, muted, movieList, platformConfig, playing, progressInfo, reload
		} = newProps;

		if ( newProps.movieKey !== undefined ) {
			this.setState({
				advertisingKey: isPlaylist ? advertisingKey : null,
				advertisingList: isPlaylist ? advertisingList : [],
				episodeKey: episodeKey,
				isBonus: isBonus,
				isEpisode: isEpisode,
				isFreeMovie: isFreeMovie,
				isIframe: isIframe,
				isPlaylist: isPlaylist,
				isTrailer: isTrailer,
				movieKey: movieKey,
				movieList: movieList,
				nextAdvertisingKey: isPlaylist ? findProperNextIndex( advertisingKey, advertisingList ) : null,
				nextEpisodeKey: isEpisode ? findProperNextIndex( episodeKey, movieList[ movieKey ].episodes ) : null, 
				nextMovieKey: isBonus || isTrailer ? movieKey : findProperNextIndex( movieKey, movieList ),
				playing: playing,
				progressInfo: progressInfo,
				muted: !! muted
			});
		} 
		if ( controls === false ) this.setState({ controls: false });
		if ( muted !== undefined ) this.setState({ muted: muted });
		if ( platformConfig !== undefined ) {
			if ( platformConfig.nextVideoBeforeEnd ) this.nextVideoBeforeEnd = parseInt( platformConfig.nextVideoBeforeEnd );
			if ( platformConfig.nextVideoCount ) this.nextVideoCount = parseInt( platformConfig.nextVideoCount );
			if ( platformConfig.nextVideoCount && platformConfig.nextVideoCount !== this.state.countEnding ) this.setState({ countEnding: parseInt( platformConfig.nextVideoCount ) });
			if ( platformConfig.playlistPubInterval ) this.pubIxnterval = parseInt( platformConfig.playlistPubInterval );
			if ( platformConfig.playlistPubNumber ) this.pubNumber = parseInt( platformConfig.playlistPubNumber );
			if ( platformConfig.playlistRandTimeRange ) this.randTimeRange = parseInt( platformConfig.playlistRandTimeRange );
			if ( platformConfig.playlistRandTimeStart ) this.randTimeStart = parseInt( platformConfig.playlistRandTimeStart );
			if ( platformConfig.progressTimeInterval ) this.progressTimeInterval = parseInt( platformConfig.progressTimeInterval );
		}
		if ( playing !== undefined ) this.setState({ playing: playing });
		if ( reload ) this.player.seekTo( 0 );
		
		return null;
	};

	componentWillUnmount() { 
		clearInterval( this.intervalId );
	};
	
	btnSrcThumbUp = () => {
		const { language } = this.props;
		const movieId = this.getMovieId();
		this.thumbUpList = this.props.thumbsUp;
		if ( this.thumbUpList.includes( movieId ) ) {
            return { 
                bubble: <></>, 
                class: 'movie_icon selected', 
                src: ICONS.thumbWhiteFilled 
            };
        }
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:like', language ) }</div>,
            class: 'movie_icon',
            src: ICONS.thumbWhite
        };
    };

	btnSrcThumbDown = () => {
		const { language } = this.props;
		const movieId = this.getMovieId();
		this.thumbDownList = this.props.thumbsDown;
        if ( this.thumbDownList.includes( movieId ) ) {
            return { 
                bubble: <></>, 
                class: 'movie_icon icon_revert selected', 
                src: ICONS.thumbWhiteFilled 
            };
        }
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:dislike', language ) }</div>,
            class: 'movie_icon icon_revert',
            src: ICONS.thumbWhite
        };
    };

	continueVideoAfterAdvertising = () => {
		this.setState({
			countEnding: this.nextVideoCount,
			isAdvertising: false,
			modalEnding: false,
			modalShow: false,
			nextCountShow: false,
			playing: true,
			progressInfo: null,
		});
	};

	getMovieId = () => {
		const { episodeKey, isEpisode, movieKey, movieList } = this.state;
		return isEpisode ? movieList[ movieKey ].episodes[ episodeKey ].transaction : movieList[ movieKey ].transaction;
	};

	getMovieUrl = () => {
		const {
			advertisingKey, advertisingList, episodeKey,
			isAdvertising, isBonus, isEpisode, isFreeMovie, isPlaylist, isTrailer,
			movieKey, movieList
		} = this.state;
		const { isVodPage, userDiscarded } = this.props;
		if ( movieList && movieList[ movieKey ] ) {
			if ( isAdvertising && advertisingList[ advertisingKey ] ) {
				return `${ URL_VIMEO }${ advertisingList[ advertisingKey ].vimeo_VideoId }`;
			} 
			if ( isBonus && movieList[ movieKey ].bonus && movieList[ movieKey ].bonus[ 0 ] ) {
				return `${ URL_VIMEO }${ movieList[ movieKey ].bonus[ 0 ].vimeo_VideoId }`;
			}
			if ( isTrailer && movieList[ movieKey ].trailer && movieList[ movieKey ].trailer[ 0 ] ) {
				return `${ URL_VIMEO }${ movieList[ movieKey ].trailer[ 0 ].vimeo_VideoId }`;
			}
			if ( isEpisode && movieList[ movieKey ].episodes && movieList[ movieKey ].episodes[ episodeKey ] ) {
				return `${ URL_VIMEO }${ ! isPlaylist && ! isVodPage && ( isGuest() || userDiscarded ) ? movieList[ movieKey ].episodes[ episodeKey ].vimeo_free_VideoID : movieList[ movieKey ].episodes[ episodeKey ].vimeo_VideoId }`;
			}
			return `${ URL_VIMEO }${ ! isFreeMovie && ! isPlaylist && ! isVodPage && ( isGuest() || userDiscarded ) ? movieList[ movieKey ].vimeo_free_VideoID : movieList[ movieKey ].vimeo_VideoId }`;
		}
		return '';
	};

	nextAdvertising = () => {
		const { advertisingList, nextAdvertisingKey } = this.state;
		this.setState({
			advertisingKey: nextAdvertisingKey,
			advertisingProgressStg: null,
			advertisingProgressStyle: null,
			countEnding: this.nextVideoCount,
			isAdvertising: true,
			modalEnding: false,
			modalShow: false,
			nextAdvertisingKey: findProperNextIndex( nextAdvertisingKey, advertisingList ),
			nextCountShow: false,
			playing: true,
			progressInfo: null,
		});
	};

	nextVideo = () => {
		clearInterval( this.intervalId );
		const { isEpisode, isFreeMovie, isPlaylist, movieKey, movieList, nextEpisodeKey, nextMovieKey } = this.state;
		const { fetchPlaylistMovieIndex } = this.props;

		if ( isPlaylist ) fetchPlaylistMovieIndex( nextMovieKey );
		if ( isFreeMovie ) this.onExit( null );

		if ( isEpisode ) {
			if ( nextEpisodeKey === 0 ) {
				this.onExit( null );
			} else {
				this.setState({
					countEnding: this.nextVideoCount,
					episodeKey: nextEpisodeKey,
					modalEnding: false,
					modalShow: false,
					nextEpisodeKey: findProperNextIndex( nextEpisodeKey, movieList[ movieKey ].episodes ),
					nextCountShow: false,
					playing: true,
					progressInfo: null,
				});
			}
		} else {
			this.setState({
				countEnding: this.nextVideoCount,
				isBonus: false,
				isTrailer: false,
				modalEnding: false,
				modalShow: false,
				movieKey: nextMovieKey,
				nextCountShow: false,
				nextMovieKey: findProperNextIndex( nextMovieKey, movieList ),
				playing: true,
				progressInfo: null,
			});
		}
	};

	onDuration = duration  => {
		this.timeTotal = duration;
		this.videoLimit = duration - this.nextVideoBeforeEnd;
	};

	onEnded = () => {
		const { advertisingKey, isAdvertising, isBonus, isPlaylist, isTrailer } = this.state;
		const { movieList, movieKey, userDiscarded } = this.props;

		if ( isPlaylist ) {
			if ( isAdvertising && ( advertisingKey % this.pubNumber === 0 || this.pubNumber === 1 ) ) {
				this.continueVideoAfterAdvertising();
				return;
			}
			this.nextVideo();
			return;
		} else if ( isGuest() || userDiscarded || ! movieList[ movieKey ].transaction || isTrailer || isBonus ) {
			this.onExit( null );
			return;
		}
		this.nextVideo();
	};

	onExit = e => {
		if ( e ) e.stopPropagation();
		const { fetchSeriesLocal, isTrailerHeader, logBtnExit } = this.props;
		if ( this.newSeriesLocal ) fetchSeriesLocal( this.newSeriesLocal );
		if ( ! isTrailerHeader ) logBtnExit();
	};

	onPlay = () => this.setState({ playing: true, modalShow: false });
	onPause = () => this.setState({ playing: false, modalShow: true });
	
	onProgress = progress => {
		const {
			countEnding, episodeKey,
			isAdvertising, isBonus, isEpisode, isFreeMovie, isPlaylist, isTrailer,
			movieKey, movieList, nextCountShow, nextEpisodeKey, playlistMovieProgress
		} = this.state;
		const { userDiscarded, playlistPeriodId, profileVersion } = this.props;
		// remove modalEnding if unexpecting show state after video changing
		if ( ! isPlaylist && progress.playedSeconds < 7 ) { this.setState({ modalEnding: false }); return false; }

		const device = DEVICE_VERSION;
		const movieId = this.getMovieId();
		const periodId = playlistPeriodId ? playlistPeriodId : 0;
		const timeProgress = parseInt( progress.playedSeconds );
		const timeTotal = this.timeTotal;
		const userType = userDiscarded ? 'discarded' : ( isGuest() ? 'free' : 'sub' );
		
		if ( isFreeMovie ) {
			if ( timeProgress !== 0 && timeProgress % this.progressTimeInterval === 0 ) {
				updateFreeProgressMovies( `?NoCli=${ getUserInfo().NoCli }&userType=${ userType }&movieId=${ movieId }&progressTime=${ timeProgress }&timeTotal=${ timeTotal }&device=${ device }` )
					.then( function( res ) { console.log( res ); } )
					.catch( function( error ) { console.log( error ); } );
			}
		} else if ( isPlaylist ) {
			if ( ! isAdvertising && timeProgress !== 0 && timeProgress % this.progressTimeInterval === 0 ) {
				updatePlaylistProgressMovies( `?NoCli=${ getUserInfo().NoCli }&userType=${ userType }&movieId=${ movieId }&progressTime=${ timeProgress }&timeTotal=${ timeTotal }&device=${ device }&periodId=${ periodId }&movieIndex=${ movieKey }` )
					.then( function( res ) { console.log( res ); } )
					.catch( function( error ) { console.log( error ); } );
			}

			if ( isGuest() || userDiscarded ) {
				if ( ! isAdvertising ) {
					if ( ( ! playlistMovieProgress || timeProgress > playlistMovieProgress ) && timeProgress !== 0 && timeProgress % parseInt( this.pubInterval ) === 0 ) {
						this.setState({ playlistMovieProgress: timeProgress });
						this.nextAdvertising();
					}
				} else if ( this.timeTotal >= timeProgress ) {
					this.setState({
						advertisingProgressStg: formatTimeAdvertising( this.timeTotal - timeProgress ),
						advertisingProgressStyle: getProgressBarStyle( timeProgress, this.timeTotal ),
					});
				}
			}
		} else {
			if ( timeProgress > this.videoLimit && ! isBonus && ! isTrailer && nextEpisodeKey !== 0 ) {
				if ( nextCountShow ) {
					this.setState({ modalEnding: true, nextCountShow: false });
					clearInterval( this.intervalId );
					this.intervalId = setInterval( () => {
						if ( countEnding <= 0 ) {
							this.nextVideo();
						} else {
							this.setState({ countEnding: countEnding - 1 });
						}
					}, 1000 );
				}
				this.setState({ nextCountShow: true });
			}

			if ( timeProgress !== 0 && timeProgress % this.progressTimeInterval === 0 && ! isBonus && ! isTrailer ) {
				updateProgressMovies( `?NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ movieId }&progressTime=${ timeProgress }&timeTotal=${ timeTotal }&device=${ device }` )
					.then( function( res ) { if ( ! res.data.ret && res.data.data === "authentication" ) authFailed(); } )
					.catch( function( error ) { console.log( error ); } );
	
				if ( isEpisode ) {
					const { seriesLocal } = this.props;
					let newSeriesLocal = [ ...seriesLocal ];
					const seriesIds = seriesLocal.map( el => el.transaction );
					const now =  Math.floor( Date.now() / 1000 );
					if ( seriesIds.includes( movieList[ movieKey ].transaction ) ) {
						newSeriesLocal[ movieList[ movieKey ].transaction ].episodes[ episodeKey ].time = timeProgress;
						newSeriesLocal[ movieList[ movieKey ].transaction ].episodes[ episodeKey ].updatedAt = now;
					} else {
						let serieToAdd = movieList[ movieKey ]; 
						serieToAdd.episodes[ episodeKey ].time = timeProgress;
						serieToAdd.episodes[ episodeKey ].updatedAt = now;
						newSeriesLocal.push( serieToAdd );
					}
					this.newSeriesLocal = newSeriesLocal;
				}
			}
		}	
	};

	onReady = () => {};

	onStart = () => {
		const { playlistTimeProgress } = this.props;
		const { playlistMovieProgress, isAdvertising, isPlaylist, progressInfo } = this.state;

		if ( this.needSeekTo ) {
			this.needSeekTo = false;
			if ( progressInfo && progressInfo.progressTime ) {
				this.player.seekTo( this.props.progressInfo.progressTime );
			} else if ( isPlaylist ) {
				if ( playlistTimeProgress ) {
					this.player.seekTo( playlistTimeProgress );
				} else {
					this.player.seekTo( Math.floor( Math.random() * this.randTimeRange ) + this.randTimeStart );
				}
			} 
		} else if ( isPlaylist && ! isAdvertising && playlistMovieProgress ) {
			this.player.seekTo( playlistMovieProgress );
		} 
		const { fetchPlayingState } = this.props;
		fetchPlayingState({ isPlayingStarted: true });
	};

	ref = player => this.player = player;

	renderCurrentMovieTitle = () => {
		const { isEpisode, episodeKey, language, movieKey, movieList } = this.state;
		if ( isEpisode ) {
			return movieList[ movieKey ].episodes[ episodeKey ][ t( 'keys:titre', language ) ] ?
				movieList[ movieKey ].episodes[ episodeKey ][ t( 'keys:titre', language ) ]
				:
				movieList[ movieKey ].episodes[ episodeKey ].titre;
		}

		if ( movieList[ movieKey ].transaction !== null ) {
			return movieList[ movieKey ][ t( 'keys:titre', language ) ] ?
				movieList[ movieKey ][ t( 'keys:titre', language ) ]
				:
				movieList[ movieKey ].titre;
		}
		return '';
	};

	renderNextMoviePic = () => {
		const { isEpisode, movieKey, movieList, nextEpisodeKey, nextMovieKey } = this.state;
		let srcImage = `${API_IMAGE}${movieList[ nextMovieKey ].thumbnailFileName}.jpg&w=500&h=281&crop-to-fit`;
		let srcErrorImage = IMAGES.placeholderThumbnail;

		if ( isEpisode ) {
			srcImage = `${API_IMAGE}${movieList[ movieKey ].episodes[ nextEpisodeKey ].thumbnailFileName}.jpg&w=500&h=281&crop-to-fit`;

			return (
				<img
					className="tile-img"
					alt={
							movieList[ movieKey ].episodes[ nextEpisodeKey ].imageAlt ?
							movieList[ movieKey ].episodes[ nextEpisodeKey ].imageAlt
							:
							movieList[ movieKey ].episodes[ nextEpisodeKey ].titre
						}
					src={ srcImage } 
					onError={e => {
						if ( this.state.imageLoadNoCache ) {
							this.setState({ imageLoadNoCache: false });
							e.target.src = `${ srcImage }&no-cache`;
						} else if ( this.state.imageLoadError ) {
							this.setState({ imageLoadError: false });
							e.target.src = srcErrorImage;
						}
					}} />
			);
		}

		if ( movieList[ nextMovieKey ].transaction !== null ) {
			return (
				<img
					className="tile-img"
					alt={
							movieList[ nextMovieKey ].imageAlt ?
							movieList[ nextMovieKey ].imageAlt
							:
							movieList[ nextMovieKey ].titre
						}
					src={ srcImage } 
					onError={e => {
						if ( this.state.imageLoadNoCache ) {
							this.setState({ imageLoadNoCache: false });
							e.target.src = `${ srcImage }&no-cache`;
						} else if ( this.state.imageLoadError ) {
							this.setState({ imageLoadError: false });
							e.target.src = IMAGES.placeholderThumbnail;
						}
					}} />
			);
		}
		return (
			<img
				alt="img"
				className="tile-img"
				src={ IMAGES.placeholderThumbnail } />
		);
	};

	renderProductPic = ( fileName, name ) => {
		const srcImage = `${API_IMAGE}${fileName}.png&w=500&h=500&crop-to-fit`;
		const srcErrorImage = IMAGES.placeholderSquare;

		return (
			<img
				className="tile-img"
				alt={ name }
				src={ srcImage } 
				onError={e => {
					if ( this.state.imageProductLoadNoCache ) {
						this.setState({ imageProductLoadNoCache: false });
						e.target.src = `${ srcImage }&no-cache`;
					} else if ( this.state.imageProductLoadError ) {
						this.setState({ imageProductLoadError: false });
						e.target.src = srcErrorImage;
					}
				}} />
		);
	};

	toggleThumbDown = e => {
        if ( e ) e.stopPropagation();
        if ( isGuest() ) return;

		const { profileVersion } = this.props;
		const movieId = this.getMovieId();
        const action = this.props.thumbsDown.includes( movieId ) ? 'remove-down' : 'add-down';
        let newThumbDownList = this.thumbDownList;
        let newThumbUpList = this.thumbUpList;
        newThumbDownList = ( action === 'remove-down' ) 
            ? newThumbDownList.splice( newThumbDownList.indexOf( movieId ), 1 )
            : newThumbDownList.push( movieId );	
        if ( action === 'add-down' && newThumbUpList.includes( movieId ) ) newThumbUpList.splice( newThumbUpList.indexOf( movieId ), 1 );
        this.setState( { thumbDownList: newThumbDownList, thumbUpList: newThumbUpList } );

        toggleThumbs( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ movieId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
	};

	toggleThumbUp = e => {
        if ( e ) e.stopPropagation();
        if ( isGuest() ) return;

		const { profileVersion } = this.props;
		const movieId = this.getMovieId();
        const action = this.props.thumbsUp.includes( movieId ) ? 'remove-up' : 'add-up';
        let newThumbDownList = this.thumbDownList;
        let newThumbUpList = this.thumbUpList;
        newThumbUpList = ( action === 'remove-up' ) 
            ? newThumbUpList.splice( newThumbUpList.indexOf( movieId ), 1 )
            : newThumbUpList.push( movieId );
        if ( action === 'add-up' && newThumbDownList.includes( movieId ) ) newThumbDownList.splice( newThumbDownList.indexOf( movieId ), 1 );    	
        this.setState( { thumbDownList: newThumbDownList, thumbUpList: newThumbUpList } );

        toggleThumbs( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ movieId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
    };

	render() {
		const {
			advertisingKey,
			advertisingProgressStg,
			advertisingProgressStyle,
			controls,
			countEnding,
			isAdvertising,
			isFreeMovie,
			isIframe,
			isPlaylist,
			key,
			light,    
			loop,
			modalEnding,
			modalShow,
			movieKey,
			movieList,
			muted,
			nextMovieKey,
			pip,
			playbackRate,
			playing,
			volume,
		} = this.state;

		const { language, userDiscarded } = this.props;

		return (
			<>
				<div className='player-wrapper'>
					<ReactPlayer
						ref={ this.ref }
						key={ key }
						id='video-player'
						className='react-player'
						width='100%'
						height='100%'
						url={ this.getMovieUrl() }
						pip={ pip }
						playsinline={ true }
						playing={ playing }
						controls={ controls }
						light={ light }
						loop={ loop }
						playbackRate={ playbackRate }
						volume={ volume }
						muted={ muted }
						onReady={ this.onReady }
						onStart={ this.onStart }
						onPlay={ this.onPlay }
						onEnablePIP={ this.onEnablePIP }
						onDisablePIP={ this.onDisablePIP }
						onPause={ this.onPause }
						onBuffer={ () => console.log('onBuffer') }
						onSeek={ e => console.log('onSeek', e) }
						onEnded={ this.onEnded }
						onError={ e => console.log('onError', e) }
						onProgress={ this.onProgress }
						onDuration={ this.onDuration }
						config={{
							vimeoConfig: {
								api: true,
								portrait: false,
							}
						}} >
					</ReactPlayer>
				</div>
				{
					modalShow && ! modalEnding &&
					<div className="player-overlay" onClick={ () => this.setState({ modalShow: false, playing: true }) }>
						<div className={ `player-overlay-content ${ isFreeMovie ? 'free-movie-content' : '' }` }>
							<div className="player-overlay-back" onClick={ e => this.onExit( e ) }>
								<img className="player-overlay-back-arrow" alt="arrow" src={ ICONS.arrow }/>
								{ t( 'shared:backNavigation', language ) }
							</div>
							{
								movieList && movieList[ movieKey ] &&
								<>
									<div className="player-overlay-title">
										{ this.renderCurrentMovieTitle() }
									</div>
									{
										! isIframe && movieList[ movieKey ].productRelated &&
										<a
											className="player-overlay-product-related"
											href={ `/browse/?boutique=${ movieList[ movieKey ].productRelated.url }/#/boutique/${ movieList[ movieKey ].productRelated.url }` }
											onClick={ e => e.stopPropagation() }
											target='_blank'
											rel="noopener noreferrer">
											<div>{ t( 'shared:relatedProduct', language ) }</div>
											<div className="player-overlay-product-related-img">
												{ this.renderProductPic( movieList[ movieKey ].productRelated.img, movieList[ movieKey ].productRelated.name ) }
												<div className="player-overlay-product-related-name">{  movieList[ movieKey ].productRelated.name }</div>
											</div>
											
										</a>
									}
								</>
							}
						</div>
					</div>
				}
				{
					modalEnding &&
					<div className="player-overlay --end">
						<div className="player-overlay-content">
							<div className="player-overlay-back" onClick={ e => this.onExit( e ) }>
								<img className="player-overlay-back-arrow" alt="arrow" src={ ICONS.arrow }/>
								{ t( 'shared:backNavigation', language ) }
							</div>
							{
								movieList && movieList[ movieKey ] &&
								<>
									<div className="player-overlay-title">
										{ this.renderCurrentMovieTitle() }
									</div>
									<div className="icons_box_overlay">	
										<div
											className={ `movie_btn_box collect_flag` }
											onClick={ e => this.toggleThumbUp( e ) }
											id={ `thumbUpBtn--overlay-player-movie-${ this.getMovieId() }` } >
											<div className='icon_box'>
												<img
													className={ this.btnSrcThumbUp().class }
													src={ this.btnSrcThumbUp().src }
													alt='icone puce en haut' />
												{ this.btnSrcThumbUp().bubble }    
											</div>
										</div>
										<div
											className={ `movie_btn_box collect_flag` }
											onClick={ e => this.toggleThumbDown( e ) }
											id={ `thumbDownBtn--overlay-player-movie-${ this.getMovieId() }` } >
											<div className='icon_box'>
												<img
													className={ this.btnSrcThumbDown().class }
													src={ this.btnSrcThumbDown().src }
													alt='icone favoris' />
												{ this.btnSrcThumbDown().bubble }
											</div>
										</div>
									</div>
								</>
							}	
							{
								movieList && movieList[ nextMovieKey ] && 
								<div className="player-overlay-next" onClick={ this.nextVideo }>
									{ this.renderNextMoviePic() }
									<div className="player-overlay-next-btn">{ t( 'shared:nextVideo', language ) }{ countEnding } { t( 'shared:second', language ) }{ countEnding > 1 ? 's' : '' }</div>	
								</div>
							}
						</div>
					</div>
				}
				{
					isPlaylist && ( isGuest() || userDiscarded ) &&
					<div className="adverstisement-container">
						{
							isAdvertising ?
							<>
								<div id="advertisement_pub_count" className="collect_flag">
									<a className="btn btn-sm btn-secondary go_offer" href='/offres/'>{ t( 'freemium:subscribeAdds', language ) }</a>
									{
										this.pubNumber > 1 &&
										<h3>
											{ t( 'freemium:adds', language ) }
											{ this.pubNumber > 1 && <span>{ ` ${ advertisingKey % this.pubNumber === 0 ? this.pubNumber : advertisingKey % this.pubNumber } sur ${ this.pubNumber } ` }</span> }
											{ advertisingProgressStg ? <span id='pubCountSpan'> { advertisingProgressStg }</span> : <></> }
										</h3>	
									}
								</div>
								{
									advertisingProgressStyle ? 
									<div id="advertisement_pub_progress" className="collect_flag">
										<div id="progressBar" style={ advertisingProgressStyle }></div>
									</div>
									:
									<></>
								}
							</>
							:
							<div id="advertisement_pub" className="collect_flag">
								<h3>{ t( 'freemium:infoAdds', language ) }</h3>
								<a className="btn btn-sm btn-secondary go_offer" href='/offres/'>{ t( 'freemium:subscribeAdds', language ) }</a>
							</div>
						}
					</div>
				}
				{
					isFreeMovie && ( isGuest() || userDiscarded ) &&
					<div className="free-movie-container">
						<h3>{ t( 'freemium:info', language ) }</h3>
						<a className="btn btn-sm btn-secondary btnOffer" href='/offres/'>{ t( 'freemium:subscribe', language ) }</a>
					</div>
				}
			</>
		)
	};
}

const stateToProps = state => ({
	isVodPage: state.routingReducer.isVodPage,
	language: state.translatingReducer.language,
	platformConfig: state.moviesReducer.platformConfig,
	playlistPeriodId: state.playlistReducer.playlistPeriodId,
	playlistTimeProgress: state.playlistReducer.playlistTimeProgress,
	profileVersion: state.userReducer.profileVersion,
	seriesLocal: state.moviesReducer.seriesLocal,
	thumbsUp: state.userReducer.thumbsUp,
    thumbsDown: state.userReducer.thumbsDown,
	userDiscarded: state.userReducer.userDiscarded,
});

const dispatchToProps = dispatch => ({
	fetchPlayingState: playingState =>  dispatch( playerAction.fetchPlayingState( playingState )), 
	fetchPlaylistMovieIndex: movieIndex =>  dispatch( playlistAction.fetchPlaylistMovieIndex( movieIndex )), 
	fetchSeriesLocal: serie =>  dispatch( moviesAction.fetchSeriesLocal( serie )), 
})

export default connect( stateToProps, dispatchToProps )( VideoPlayer );