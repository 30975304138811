import React from "react";

import { IMAGES } from "../../../constants";
 
class HeaderLogo extends React.Component {
    render() {
        return (
            <div className="ils_popup_logo_box">
                <img className="ils_popup_logo_tall" src={IMAGES.logoImg} alt='logo zone300'/>
            </div>
        );
    }
}

export default HeaderLogo;
