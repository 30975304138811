import React from "react";
import { connect }  from "react-redux";

import { popupAction } from "../../../redux/actions";
 
class ButtonClose extends React.Component {
    render() {
        const { popupClose, popupName } = this.props;

        return (
            <div className="ils_popup_close">
                <button className="close-button fa fa-window-close" onClick={ () => popupClose( popupName ) }></button>
            </div>
        );
    }
}

const stateToProps = state => ({ });

const dispatchToProps = dispatch => ({
    popupClose: popupName => dispatch(popupAction.popupClose( popupName )),
});

export default connect(stateToProps, dispatchToProps)(ButtonClose);
