import React from 'react';
import Proptypes from "prop-types";
import { connect } from "react-redux";

import MoviePageHeader from '../Header/MoviePageHeader';
import MoviePageReview from './MoviePageReview';
import MoviePageRows from './MoviePageRows';
import VideoPlayer from "../../components/Videoplayer";

import { isGuest, isProgressTimeValid } from "../../utils";

class MoviePage extends React.Component {
    static contextTypes = { router : Proptypes.object };

	constructor( props, context ) {
		super( props, context );

		this.logBtnExit = this.logBtnExit.bind(this);
		this.playBtnClick = this.playBtnClick.bind(this);

		this.state = {
			isTrailerMuted: true,
			playerMovieShow: false,
			playerMovieHeight: 0,
			playerMovieWidth: 0,
			videoMovieJsOptions: { autoplay: false, playing: false },
		};
	}; 

    componentDidMount() {
		this.backListener = this.context.router.history.listen( ( location, action, state ) => {
			if ( action === "POP" && this.state.playerMovieShow && location.hash !== '#play/' ) this.logBtnExit();
		});
	};

    logBtnExit = () => {
		if( document.location.hash.includes( "#play/" ) ) this.context.router.history.goBack();
		this.setState({ playerMovieShow: false, playerMovieHeight: 0, playerMovieWidth: 0, videoMovieJsOptions: { autoplay: false, playing: false } });
	};

	onClickFullscreen = () => {
		const { searchedMovies, userDiscarded } = this.props;

		if ( isGuest() ) {
			window.location.href = "/offres/";
			return;
		} else if ( userDiscarded ) {
			this.goToChangePaymentPage();
			return;
		}
			
		this.setState({
			playerMovieShow: true,
			playerMovieHeight: "100%",
			playerMovieWidth: "100%",
			videoMovieJsOptions: {
				logBtnExit: this.logBtnExit,
				movieKey: 0,
				movieList: searchedMovies[ 0 ].movies, 
				playing: true,
				progressInfo: null,
			},
		});

		window.location.href = window.location.href + "#play/";
	};

	onClickFullscreenAddOn = type => {
		const { searchedMovies, userDiscarded } = this.props;

		if ( type !== 'trailer' ) {
			if ( isGuest() ) {
				window.location.href = "/offres/";
				return;
			} else if ( userDiscarded ) {
				this.goToChangePaymentPage();
				return;
			}
		}

		this.setState({
			playerMovieShow: true,
			playerMovieHeight: "100%",
			playerMovieWidth: "100%",
			videoMovieJsOptions: {
				isBonus: type === 'bonus',
				isTrailer: type === 'trailer',
				logBtnExit: this.logBtnExit,
				movieKey: 0,
				movieList: searchedMovies[ 0 ].movies, 
				playing: true,
				progressInfo: null,
			},
		});

		window.location.href = window.location.href + "#play/";
	};

	playBtnClick = ( movie, type ) => {
        const { searchedMovies, userDiscarded } = this.props;

		if ( isGuest() ) {
			window.location.href = "/offres/";
			return;
		} else if ( userDiscarded ) {
			this.goToChangePaymentPage();
			return;
		}

		
		if ( type === 'episode' ) {
			const episodeKey = searchedMovies[ 0 ].movies[ 0 ].episodes.indexOf( searchedMovies[ 0 ].movies[ 0 ].episodes.filter( el => el.transaction === movie.transaction )[ 0 ] );
			const progressInfo = isProgressTimeValid( movie.time, movie.timeTotal ) ? { progressTime: movie.time, timeTotal: movie.timeTotal } : null;

			this.setState({
				playerMovieShow: true,
				playerMovieHeight: "100%",
				playerMovieWidth: "100%",
				videoMovieJsOptions: {
					episodeKey: episodeKey,
					isEpisode: true,
					logBtnExit: this.logBtnExit,
					movieKey: 0,
					movieList: searchedMovies[ 0 ].movies, 
					playing: true,
					progressInfo: progressInfo,
				},
			});
		} else if ( [ 'trailer', 'bonus' ].includes( type ) ) {
			const trailerKey = searchedMovies[ 0 ].movies[ 0 ][ type ].indexOf( searchedMovies[ 0 ].movies[ 0 ][ type ].filter( el => el.vimeo_VideoId === movie.vimeo_VideoId )[ 0 ] );
			let movieList = [ ...searchedMovies[ 0 ].movies ];
			movieList[ 0 ][ type ] = [ movieList[ 0 ][ type ][ trailerKey ] ];

			this.setState({
				playerMovieShow: true,
				playerMovieHeight: "100%",
				playerMovieWidth: "100%",
				videoMovieJsOptions: {
					isBonus: type === 'bonus',
					isTrailer: type === 'trailer',
					logBtnExit: this.logBtnExit,
					movieKey: 0,
					movieList: movieList, 
					playing: true,
					progressInfo: null,
				},
			});
		} else {
			return;
		}
        
		window.location.href = window.location.href + "#play/";
	};

    render() {
		const { searchedMovies } = this.props;
        const { playerMovieShow, playerMovieHeight, playerMovieWidth, videoMovieJsOptions } = this.state;

		let movie = null;
        if ( searchedMovies && searchedMovies.length > 0 ) movie = searchedMovies[ 0 ].movies[ 0 ];

        return (
            <div className="movie-page">
                <MoviePageHeader
                    onClickFullscreen={ this.onClickFullscreen }
                    onClickFullscreenAddOn={ this.onClickFullscreenAddOn }
                    playBtnClick={ this.playBtnClick } />
                <MoviePageRows playBtnClick={ this.playBtnClick } />
				{ ! isGuest() && movie && <MoviePageReview isMoviePage={ true } movie={ movie } /> }
                <div id="z3pOverlay" className="z3PlayOverlay" style={{display : playerMovieShow ? 'block' : 'none', width: playerMovieWidth, height: playerMovieHeight }}>
                    <VideoPlayer className="video-js" id="VideoAccueil" { ...videoMovieJsOptions } />
                </div>
            </div>
        )
    }
}

const stateToProps = state => ({
	searchedMovies: state.moviesReducer.searchedMovies,
	userDiscarded: state.userReducer.userDiscarded,
});

export default connect( stateToProps )( MoviePage );