import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';
import InfiniteCarousel from 'react-leaf-carousel';
import InfinteCarrouselReRender from "../tools/InfinteCarrouselReRender";
import MoviePageReview from "./MoviePageReview";

import { API_IMAGE, DISPLAY, ICONS, IMAGES } from "../../constants";
import { toggleFavorites, toggleThumbs, updateUserPaymentStatus } from "../../services/user";
import {
    convertToCsaKey, convertToDefKey,
    formatTimeLeftForAvailability,
    getMovieAdditionalInfo, getProgressBarStyle, getRoles, getShortSynopsis, getStarWidth, getUserInfo,
    isGuest, isProgressTimeValid, t
} from "../../utils";
import { isCanadian } from "../../utils/getLocalTimeZone";

class MoviePopup extends React.Component {
	constructor( props, context ) {
		super( props, context );
		this.state = { context: context };

        this.playBtnClick = this.playBtnClick.bind(this);

        this.favoriteList = [];
        this.thumbUpList = [];
        this.thumbDownList = [];
	}; 

    btnSrcIsInList = movieId => {
        const { language } = this.props;
        this.favoriteList = this.props.favorites;
		if ( this.favoriteList.includes( movieId ) ) {
            return {
                bubble: <div className="bubble bubble-bottom">{ t( 'shared:listRemoveTo', language ) }</div>,
                class: 'movie_icon selected',
                src: ICONS.checked
            };
        } 
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:listAddTo', language ) }</div>,
            class: 'movie_icon',
            src: ICONS.favorites
        };
    };

	btnSrcThumbUp = movieId => {
        const { language } = this.props;
		this.thumbUpList = this.props.thumbsUp;
		if ( this.thumbUpList.includes( movieId ) ) {
            return { 
                bubble: <></>, 
                class: 'movie_icon selected', 
                src: ICONS.thumbFilled 
            };
        }
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:like', language ) }</div>,
            class: 'movie_icon',
            src: ICONS.thumb
        };
    };

	btnSrcThumbDown = movieId => {
        const { language } = this.props;
		this.thumbDownList = this.props.thumbsDown;
        if ( this.thumbDownList.includes( movieId ) ) {
            return { 
                bubble: <></>, 
                class: 'movie_icon icon_revert selected', 
                src: ICONS.thumbFilled 
            };
        }
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:dislike', language ) }</div>,
            class: 'movie_icon icon_revert',
            src: ICONS.thumb
        };
    };

    getImageFile = ( movie, type ) => {
        const { version } = this.props;
        if ( movie.versionImages &&  movie.versionImages.length > 0 ) {
        	const index = version % movie.versionImages.length;
        	if ( movie.versionImages[ index ][ type ] ) return movie.versionImages[ index ][ type ];
        }
        return movie[ type ];
    };

    getPlayBtnText = () => {
        const { language, isVodPage, movie, userDiscarded } = this.props;

        if ( ! isVodPage ) {
            if ( isGuest() ) t( 'shared:play', language );
            if ( userDiscarded ) return t( 'freemium:reactivate', language );
        }

        if ( movie.progressTime &&  movie.progressTime > 0 ) return t( 'shared:resume', language );
        return t( 'shared:play', language );
    };

    goToChangePaymentPage = () => {
        const { changePaymentToken } = this.props;
        if ( changePaymentToken && changePaymentToken !== '' ) {
            updateUserPaymentStatus( `?token=${ changePaymentToken }&type=discarded` );
            window.location.href = "/modifier-paiement/?session_id=" + changePaymentToken;
        } 
	};

    playBtnClick = episode => {
        const { isVodPage, movie, parent, userDiscarded } = this.props;

        if ( ! isVodPage ) {
            if ( isGuest() ) {
                window.location.href = "/offres/";
                return;
            } else if ( userDiscarded ) {
                this.goToChangePaymentPage();
                return;
            }
        }
        
		const movieList = parent.props.movieList;
		const progressInfo = isProgressTimeValid( episode.time, episode.timeTotal ) ? { progressTime: episode.time, timeTotal: episode.timeTotal } : null;
		const episodeKey = movie.episodes.indexOf( movie.episodes.filter( el => el.transaction === episode.transaction )[ 0 ] );
		const movieKey = movieList.indexOf( movieList.filter( el => el.transaction === movie.transaction )[ 0 ] );
			
		parent.setState({
			playerShow: true,
			playerHeight: "100%",
			playerWidth: "100%",
			videoJsOptions: {
                episodeKey: episodeKey,
				isEpisode: true,
				logBtnExit: parent.logBtnExit,
				movieKey: movieKey,
				movieList: movieList, 
				playing: true,
				progressInfo: progressInfo,
			},
		});

		window.location.href = window.location.href + "#play/";
	};

	toggleInMyList = ( e, movieId ) => {
        e.stopPropagation();
        if ( isGuest() ) return;
        
        const { profileVersion } = this.props;
		const action = this.props.favorites.includes( movieId ) ? 'remove' : 'add';
        let newFavoriteList = this.favoriteList;
        newFavoriteList = ( action === 'remove' ) 
            ? newFavoriteList.splice( newFavoriteList.indexOf( movieId ), 1 )
            : newFavoriteList.push( movieId );	
        this.setState( { favoriteList: newFavoriteList } );

        toggleFavorites( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ movieId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
    };

    toggleThumbDown = ( e, movieId ) => {
        e.stopPropagation();
        if ( isGuest() ) return;

        const { profileVersion } = this.props;
        const action = this.props.thumbsDown.includes( movieId ) ? 'remove-down' : 'add-down';
        let newThumbDownList = this.thumbDownList;
        let newThumbUpList = this.thumbUpList;
        newThumbDownList = ( action === 'remove-down' ) 
            ? newThumbDownList.splice( newThumbDownList.indexOf( movieId ), 1 )
            : newThumbDownList.push( movieId );	
        if ( action === 'add-down' && newThumbUpList.includes( movieId ) ) newThumbUpList.splice( newThumbUpList.indexOf( movieId ), 1 );
        this.setState( { thumbDownList: newThumbDownList, thumbUpList: newThumbUpList } );

        toggleThumbs( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ movieId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
	};

	toggleThumbUp = ( e, movieId ) => {
        e.stopPropagation();
        if ( isGuest() ) return;

        const { profileVersion } = this.props;
        const action = this.props.thumbsUp.includes( movieId ) ? 'remove-up' : 'add-up';
        let newThumbDownList = this.thumbDownList;
        let newThumbUpList = this.thumbUpList;
        newThumbUpList = ( action === 'remove-up' ) 
            ? newThumbUpList.splice( newThumbUpList.indexOf( movieId ), 1 )
            : newThumbUpList.push( movieId );
        if ( action === 'add-up' && newThumbDownList.includes( movieId ) ) newThumbDownList.splice( newThumbDownList.indexOf( movieId ), 1 );    	
        this.setState( { thumbDownList: newThumbDownList, thumbUpList: newThumbUpList } );

        toggleThumbs( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ movieId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
    };

    renderMovieEpisodes = () => {
        const { language, movie, seriesLocal } = this.props;
        if ( ! movie.episodes ) return;

        let episodes = movie.episodes;
        let index = null;
        seriesLocal.forEach( ( series, i ) => { if ( series.transaction === movie.transaction ) index = i } );
        if( index !== null ) episodes = seriesLocal[ index ].episodes;
        
        return episodes.map( (episode, i) =>
            <div key={ `episode-${i}` } onClick={ () => this.playBtnClick(episode) } className="episode-box">
                <div style={{position:'relative'}}>
                    <img
                        className='movie_episode_img'
                        src={ `${ API_IMAGE }${ this.getImageFile( episode, 'thumbnailFileName' ) || this.getImageFile( movie, 'thumbnailFileName' ) }.jpg&h=140&w=250&crop-to-fit` }
                        alt={ episode.imageAlt ? episode.imageAlt : episode.titre } />
                    <div className='movie_progress_play'>
                        <i className="fa fa-play" aria-hidden="true"></i>
                    </div>    
                    {
                        isProgressTimeValid( episode.time, episode.timeTotal ) ?
                        <div className='movie_progress'>
                            <div className="progress_bar" style={ getProgressBarStyle( episode.time, episode.timeTotal ) }></div>
                        </div>
                        :
                        <div className='movie_progress_no'></div>
                    }
                </div>
                <div className="episodes-thumbnails">
                    <div className="number-episode">{ t( 'shared:episode', language ) } { i + 1 }</div>
                    <div className="title-episode">
                        {
                            episode[ t( 'keys:titre', language ) ] ?
                            episode[ t( 'keys:titre', language ) ].replace( / *\([^)]*\) */g, '' )
                            :
                            episode.titre.replace( / *\([^)]*\) */g, '' )
                        }
                    </div>
                    <div className="synopsis-episode">
                        {
							episode[ t( 'keys:synopsis_txt', language ) ] ?
							getShortSynopsis( episode[ t( 'keys:synopsis_txt', language ) ] )
							:
							getShortSynopsis( episode.synopsis_txt )
						}
                    </div>
                </div>
            </div>
        )
    };

    checkEpisodeToStart = () => {
        const { seriesLocal, movie } = this.props;
        if ( ! movie.episodes ) return;
        let episodes = movie.episodes;
        let index = null;
        seriesLocal.forEach( ( series, i ) => { if ( series.transaction === movie.transaction ) index = i } );
        if ( index !== null ) episodes = seriesLocal[index].episodes;
     
        // Send last episode watched
        return [ ...episodes ].sort((a, b)  => b.updatedAt - a.updatedAt)[0]; 
    };

    participantToShow = () => {
        const { language, movie } = this.props
        if( ! movie.distribution ) return;
        
        return movie.distribution.map( ( el, i ) => 
            <div key={ `participant-${ i }` }
                onClick={ () => { if( el.url ) window.location.href = `/browse/?search=${ el.url }/#/search/${ el.url }` } } 
                className={ `distribution-box ${ el.url && "distribution-interraction" }` }>
                <img
                    className='movie_distribution_img'
                    src={ `${ API_IMAGE }${ el.imagePath }.jpg` }
                    alt={ el.title }
                    onError= { (e) => { e.target.src = IMAGES.avatarDefault } } />
                <div className="distribution-thumbnails">{ el.title } </div>
                <div className="distribution-thumbnails">{ getRoles( el.role, language ) }</div>
            </div>
        )
    };

    render () {
        const { language, movie, parent, userDiscarded } = this.props;
        const imageAlt = movie.imageAlt ? movie.imageAlt : movie.titre;

        return (
            <Fade bottom duration={ 800 } >
                <div className={ `movie_popup_content ${ movie.type === "series" && 'serie_popup_content' }` }>
                    <div
                        className='movie_popup_img_box collect_flag'
                        id={ `playBtnImg--cat-${ parent.props.movieListIndex }-movie-${ movie.transaction }` }
                        onClick={ () => movie.type === "series" ? this.playBtnClick( this.checkEpisodeToStart() ) : parent.onClickFullscreen() } >
                        <img
                            className='movie_popup_img'
                            src={ `${ API_IMAGE }${ this.getImageFile( movie, 'backgroundFileName' ) || this.getImageFile( movie, 'thumbnailFileName' ) }.jpg&h=720&crop-to-fit` }
                            alt={ imageAlt } />
                        <div className='popup_img_play_btn'><i className="fa fa-play icnBtn" aria-hidden="true"></i></div>    
                    </div>
                    {
                        movie[ t( 'keys:titlePngFileName', language ) ] ?
                        <img className="movie_popup_img_title" 
                            alt={ movie.titre }
                            src={ `${ API_IMAGE }${ movie[ t( 'keys:titlePngFileName', language ) ] }.png&h=160&crop-to-fit` }
                            onError={ e => e.target.style.display = 'none' } />
                        :
                        <h2>{ movie[ t( 'keys:titre', language ) ] ? movie[ t( 'keys:titre', language ) ] : movie.titre }</h2>
                    }
                    {
                        isProgressTimeValid( movie.progressTime, movie.timeTotal ) &&
                        <div className='movie_popup_progress_box'>
                            <div className='movie_popup_progress'>
                                <div className="progress_bar" style={ getProgressBarStyle( movie.progressTime, movie.timeTotal ) }></div>
                            </div>    
                            <div className="progress_bar_time">
                                { Math.floor(( movie.timeTotal - parseInt( movie.progressTime ) ) / 60) }
                                {
                                    Math.floor(( movie.timeTotal - parseInt( movie.progressTime ) ) / 60) > 1 ?
                                    t( 'shared:minutesLeft', language )
                                    :
                                    t( 'shared:minuteLeft', language )
                                }
                            </div>
                        </div>
                    }
                    {
                        movie.typeBadges && movie.typeBadges.length > 0 &&
                        movie.typeBadges.map( ( item, index ) =>
                            item.split( '||' )[ 0 ] === DISPLAY.SOON_OFFLINE && item.split( '||' )[ 1 ] &&
                            <div key={ index } className='movie_popup_soon_offline'>{ formatTimeLeftForAvailability( parseInt( item.split( '||' )[ 1 ] ), language ) }</div>
                        )
                    }
                    <div className='movie_btns'>
                        <button
                            className="btn-movie collect_flag"
                            onClick={ () => movie.type === "series" ? this.playBtnClick( this.checkEpisodeToStart() ) : parent.onClickFullscreen() }
                            id={ `playBtn--cat-${ parent.props.movieListIndex }-movie-${ movie.transaction }` } >
                            <i className="fa fa-play icnBtn" aria-hidden="true"></i>
                            { this.getPlayBtnText() }          
                        </button>
                        {
                            movie.trailer && movie.trailer.length > 0 &&
                            <button
                                className="btn-movie btn-trailer collect_flag"
                                onClick={ () => parent.onClickFullscreenAddOn( 'trailer' ) }
                                id={ `playTrailerBtn--cat-${ parent.props.movieListIndex }-movie-${ movie.transaction }` } >
                                <i className="fa fa-play icnBtn" aria-hidden="true"></i>{ t( 'shared:trailer', language ) }
                            </button>
                        }
                        {
                            ! isGuest() && ! userDiscarded && movie.bonus && movie.bonus.length > 0 &&
                            <button
                                className="btn-movie btn-bonus collect_flag"
                                onClick={ () => parent.onClickFullscreenAddOn( 'bonus' ) }
                                id={ `playBonusBtn--cat-${ parent.props.movieListIndex }-movie-${ movie.transaction }` } >
                                <i className="fa fa-play icnBtn" aria-hidden="true"></i>{ t( 'shared:bonus', language ) }
                            </button>
                        }
                        <div className="icons_box">
                            <div
                                className={ `movie_btn_box collect_flag ${ isGuest() && 'disabled' }` }
                                onClick={ e => this.toggleInMyList( e, movie.transaction ) }
                                id={ `favoritesBtn--cat-${ parent.props.movieListIndex }-movie-${ movie.transaction }` } >
                                <div className='icon_box'>
                                    <img
                                        className={ this.btnSrcIsInList( movie.transaction ).class }
                                        src={ this.btnSrcIsInList( movie.transaction ).src }
                                        alt='icone favoris' />
                                    { this.btnSrcIsInList( movie.transaction ).bubble }    
                                </div>
                            </div>
                            <div
                                className={ `movie_btn_box collect_flag ${ isGuest() && 'disabled' }` }
                                onClick={ e => this.toggleThumbUp( e, movie.transaction ) }
                                id={ `thumbUpBtn--cat-${ parent.props.movieListIndex }-movie-${ movie.transaction }` } >
                                <div className='icon_box'>
                                    <img
                                        className={ this.btnSrcThumbUp( movie.transaction ).class }
                                        src={ this.btnSrcThumbUp( movie.transaction ).src }
                                        alt='icone puce en haut' />
                                    { this.btnSrcThumbUp( movie.transaction ).bubble }    
                                </div>
                            </div>
                            <div
                                className={ `movie_btn_box collect_flag ${ isGuest() && 'disabled' }` }
                                onClick={ e => this.toggleThumbDown( e, movie.transaction ) }
                                id={ `thumbDownBtn--cat-${ parent.props.movieListIndex }-movie-${ movie.transaction }` } >
                                <div className='icon_box'>
                                    <img
                                        className={ this.btnSrcThumbDown( movie.transaction ).class }
                                        src={ this.btnSrcThumbDown( movie.transaction ).src }
                                        alt='icone favoris' />
                                    { this.btnSrcThumbDown( movie.transaction ).bubble }
                                </div>
                            </div>
                            {
                                ( movie.type === 'movie' || movie.type === 'series' ) &&
                                <a
                                    className={ `movie_btn_box collect_flag` }
                                    href={ `/browse/?search=${ movie.url ? movie.url : movie.transaction }/#/search/${ movie.url ? movie.url : movie.transaction }` }
                                    id={ `infoBtn--cat-${ parent.props.movieListIndex }-movie-${ movie.transaction }` } >
                                    <div className='icon_box'>
                                        <img
                                            className="movie_icon"
                                            src={ ICONS.info }
                                            alt='icone info' />
                                    </div>
                                </a>
                            }
                        </div>
                    </div>
                    {
                        movie.type === "series" &&
                        <div className="episode-serie">
                            {
                                this.checkEpisodeToStart()[ t( 'keys:titre', language ) ] ?
                                this.checkEpisodeToStart()[ t( 'keys:titre', language ) ]
                                :
                                this.checkEpisodeToStart().titre
                            }
                        </div>
                    }
                    {
                        <div className="movie_additional_info">
                            <span>{ getMovieAdditionalInfo( movie, language ) }</span>
                            {
                                movie.csa && movie.csa !== '' &&
                                <img className="movie_icon"
                                    src={ ICONS[ convertToCsaKey( movie.csa ) ] }
                                    alt='icone csa' />
                            }
                            {
                                movie.definition && movie.definition !== '' &&
                                <img className="movie_icon"
                                    src={ ICONS[ convertToDefKey( movie.definition ) ] }
                                    alt='icone definition' />
                            }
                        </div>
                    }
                    <div className='movie_popup_categories'>
                        {
                            isCanadian() && movie.categories_Canada && movie.categories_Canada.length > 0 ?
                            <>
                            {
                                movie.categories_related_Canada && movie.categories_related_Canada.length > 0 &&
                                movie.categories_related_Canada.map( ( el, key ) => ( 
                                    <span
                                        key={ key }
                                        className={ `catSpan catSpan-web ${ el.url ? 'catSpanLinked' : '' }` }
                                        onClick={ () => { if ( el.url ) window.location.href=`/browse/?search=${ el.url }/#/search/${ el.url }` } } >
                                        { el[ t( 'keys:name', language ) ] ? el[ t( 'keys:name', language ) ] : el.name  }
                                    </span>
                                ))
                            }
                            {
                                movie.categories_Canada && movie.categories_Canada.length > 0 &&
                                movie.categories_Canada.map( ( el, key ) => (
                                    <span
                                        key={ key }
                                        className={ `catSpan catSpan-mobile ${ el.url ? 'catSpanLinked' : '' }` }
                                        onClick={ () => { if ( el.url ) window.location.href=`/browse/?search=${ el.url }/#/search/${ el.url }` } } >
                                        { el[ t( 'keys:name', language ) ] ? el[ t( 'keys:name', language ) ] : el.name  }
                                    </span>                
                                ))
                            }
                            </>
                            :
                            <>
                            {
                                movie.categories_related && movie.categories_related.length > 0 &&
                                movie.categories_related.map( ( el, key ) => ( 
                                    <span
                                        key={ key }
                                        className={ `catSpan catSpan-web ${ el.url ? 'catSpanLinked' : '' }` }
                                        onClick={ () => { if ( el.url ) window.location.href=`/browse/?search=${ el.url }/#/search/${ el.url }` } } >
                                        { el[ t( 'keys:name', language ) ] ? el[ t( 'keys:name', language ) ] : el.name  }
                                    </span>
                                ))
                            }
                            {
                                movie.categories && movie.categories.length > 0 &&
                                movie.categories.map( ( el, key ) => (
                                    <span
                                        key={ key }
                                        className={ `catSpan catSpan-mobile ${ el.url ? 'catSpanLinked' : '' }` }
                                        onClick={ () => { if ( el.url ) window.location.href=`/browse/?search=${ el.url }/#/search/${ el.url }` } } >
                                        { el[ t( 'keys:name', language ) ] ? el[ t( 'keys:name', language ) ] : el.name  }
                                    </span>                
                                ))
                            }
                            </>
                        }
                    </div>
                    <div className='movie_popup_synopsis'>
                        { movie[ t( 'keys:synopsis_txt', language ) ] ? movie[ t( 'keys:synopsis_txt', language ) ] : movie.synopsis_txt  }
                    </div>
                    {
                        movie.rating ?
                        <div className="soon-rate-star-box">
                            <div className="soon-rate-average">
                                { movie.rating.average.toString().replace( '.', ',' ) }
                            </div>
                            <div className="soon-rate-star">
                                <i className="far fa-star"></i>
                                <span
                                    style={{ width: `${ getStarWidth( 1, movie.rating.average ) }px` }}>
                                    <i className="fas fa-star"></i>
                                </span>
                            </div>
                            <div className="soon-rate-star">
                                <i className="far fa-star"></i>
                                <span
                                    style={{ width: `${ getStarWidth( 2, movie.rating.average ) }px` }}>
                                    <i className="fas fa-star"></i>
                                </span>
                            </div>
                            <div className="soon-rate-star">
                                <i className="far fa-star"></i>
                                <span
                                    style={{ width: `${ getStarWidth( 3, movie.rating.average ) }px` }}>
                                    <i className="fas fa-star"></i>
                                </span>
                            </div>
                            <div className="soon-rate-star">
                                <i className="far fa-star"></i>
                                <span
                                    style={{ width: `${ getStarWidth( 4, movie.rating.average ) }px` }}>
                                    <i className="fas fa-star"></i>
                                </span>
                            </div>
                            <div className="soon-rate-star">
                                <i className="far fa-star"></i>
                                <span
                                    style={{ width: `${ getStarWidth( 5, movie.rating.average ) }px` }}>
                                    <i className="fas fa-star"></i>
                                </span>
                            </div>
                            <div className="soon-rate-count">
                                { movie.rating.count } { t( 'shared:opinion', language ) }
                            </div>
                        </div>
                        :
                        <></>
                    }
                    <div className="episodes-container">
                        <InfinteCarrouselReRender condition={ true }>
                            <InfiniteCarousel
                                breakpoints={[
                                    { breakpoint: 350, settings: { arrows: false, slidesSpacing: 2, showSides: true, sideSize: .1, slidesToShow: 1, slidesToScroll: 1 } },
                                    { breakpoint: 500, settings: { arrows: false, slidesSpacing: 1, showSides: true, sideSize: .2, slidesToShow: 1, slidesToScroll: 1 } },
                                    { breakpoint: 768, settings: { arrows: false, showSides: true, sideSize: .1, slidesToShow: 2, slidesToScroll: 2 } },
                                    { breakpoint: 1024, settings: { showSides: true, sideSize: .1, slidesToShow: 2, slidesToScroll: 2 } },
                                    { breakpoint: 1200, settings: { arrows: false, showSides: true, sideSize: .2, slidesSpacing: 1, slidesToShow: 2, slidesToScroll: 2 } },
                                    { breakpoint: 3000, settings: { slidesToShow: 3, slidesToScroll: 3 } }
                                ]}
                                dots={false}
                                slidesSpacing={2}
                                showSides={true}
                                sidesOpacity={.2}
                                sideSize={.1}
                                slidesToScroll={3}
                                slidesToShow={3}
                                animationDuration={600}
                                lazyLoad={true} >
                                { this.renderMovieEpisodes() }
                            </InfiniteCarousel>
                        </InfinteCarrouselReRender>
                    </div>
                    <div className="distribution-container">
                        <InfinteCarrouselReRender condition={ true }>
                            <InfiniteCarousel
                                breakpoints={[
                                    { breakpoint: 450, settings: { arrows: false, slidesToShow: 3, slidesToScroll: 3 } },
                                    { breakpoint: 800, settings: { arrows: false, slidesToShow: 6, slidesToScroll: 6 } },
                                ]} 
                                dots={false}
                                slidesSpacing={5}
                                showSides={true}
                                sidesOpacity={.5}
                                sideSize={.1}
                                slidesToScroll={6}
                                slidesToShow={6}
                                animationDuration={600}
                                placeholderImageSrc={ IMAGES.placeholderThumbnail }>
                                { this.participantToShow() }
                            </InfiniteCarousel>
                        </InfinteCarrouselReRender>
                    </div>
                    { ! isGuest() && <MoviePageReview movie={ movie } /> }
                    <MoviePageReview />
                </div>
            </Fade>
        );
    };
};

const stateToProps = state => ({
    changePaymentToken: state.userReducer.changePaymentToken,
    favorites: state.userReducer.favorites,
    isVodPage: state.routingReducer.isVodPage,
    language: state.translatingReducer.language,
    profileVersion: state.userReducer.profileVersion,
    seriesLocal: state.moviesReducer.seriesLocal,
    thumbsUp: state.userReducer.thumbsUp,
    thumbsDown: state.userReducer.thumbsDown,
    userDiscarded: state.userReducer.userDiscarded,
    version: state.routingReducer.version,
});

export default connect( stateToProps )( MoviePopup );