import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';

import { API_IMAGE, COMMENT_TYPE, ICONS, IMAGES, STORE_DEFAULT_CODE, STORE_COLISSIMO_URL, STORE_PURCHASE_STATES } from "../../../constants";
import { commentAction } from '../../../redux/actions';
import { convertPurchaseState, formatEuroPrice, t } from "../../../utils";

class POrdersDetails extends React.Component {
    constructor(props) {
        super(props);

		this.state = {
			currentIndex: 0,
		};
    };

    getPriceTotalBeforeDiscount = () => {
        const { userPurchases, type } = this.props;
        const { currentIndex } = this.state;

        let amount = 0;
        let purchase = null;
        if ( userPurchases && userPurchases[ type ] && userPurchases[ type ][ currentIndex ] ) purchase = userPurchases[ type ][ currentIndex ];
        if ( purchase ) {
            purchase.purchases.forEach( item => {
                amount += item.amount * item.quantity;
            });
        }

        return amount > 0 ? formatEuroPrice( amount ) : '';
    };

    handleCommentClick = product => {
        const { fetchCommentItem, handlePageChange } = this.props;
        console.log( product );

        fetchCommentItem( COMMENT_TYPE.PRODUCT, product );
        handlePageChange( 'right', 'comment' );
    };

    renderPic = product => {
		if ( product.mainFileName ) {
			const srcImage = `${ API_IMAGE }${ product.mainFileName }.png&w=500&h=500&crop-to-fit`;
			const srcErrorImage = IMAGES.placeholderSquare;
			
			return (
                <img
                    className='tile-img'
                    alt={ product.name }
                    src={ srcImage } 
                    onError={e => {
                        if ( this.state.imageLoadNoCache ) {
                            this.setState({ imageLoadNoCache: false });
                            e.target.src = `${ srcImage }&no-cache`;
                        } else if ( this.state.imageLoadError ) {
                            this.setState({ imageLoadError: false });
                            e.target.src = srcErrorImage;
                        }
                    }} />
			);
		}

		return (
			<img
				alt="img"
				className="tile-img"
				src={ IMAGES.placeholderSquare } />
		);
	};

    render() {
        const { language, userPurchases, type } = this.props;
        const { currentIndex } = this.state;
        let purchase = null;
        if ( userPurchases && userPurchases[ type ] && userPurchases[ type ][ currentIndex ] ) purchase = userPurchases[ type ][ currentIndex ];

        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_nav'>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'menu' ) } }>{ t( 'profile:title', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'orders' ) } }>{ t( 'profile:orders', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span>{ t( type === 'completed' ? 'profile:ordersCompleted' : 'profile:ordersInProgress', language ) }</span>
                    </div>
                    {
                        purchase ?
                        <>
                            <div id='profile_header' className='orders_header'>
                                <div className={ `${ currentIndex > 0 ? 'orders_btn_active' : 'orders_btn_inactive' }` } onClick={ () => this.setState( { currentIndex: currentIndex - 1 } ) }>
                                    <span><img className='profile_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                                </div>
                                <div className='f-grow-1'></div>
                                <h1>{ t( 'profile:order', language ) } ({ currentIndex + 1 }/{ userPurchases[ type ].length })</h1>
                                <div className='f-grow-1'></div>
                                <div
                                    className={ `${ currentIndex < userPurchases[ type ].length - 1 ? 'orders_btn_active' : 'orders_btn_inactive' }` }
                                    onClick={ () => this.setState( { currentIndex: currentIndex + 1 } ) }>
                                    <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                                </div>
                            </div>
                            <div id='profile_body'>
                                <div className='profile_menu'>
                                    <div className='profile_scale purchase_general'>
                                        <div>{ t( 'profile:orderId', language ) } : { purchase.purchaseId }</div>
                                        <div>{ t( 'profile:date', language ) } : { purchase.date }</div>
                                        <div className={ `purchase_status_${ purchase.state }` }>
                                            <span>{ convertPurchaseState( purchase.state, language ) }</span>
                                        </div>
                                        {
                                            purchase.colissimoId && purchase.state !== STORE_PURCHASE_STATES.DELIVERED &&
                                            <div className='cart_product_delivery'> 
                                                <a href={ `${ STORE_COLISSIMO_URL }?code=${ purchase.colissimoId }` } target='_blank' rel='noopener noreferrer'>
                                                    { t( 'payment:trackLink', language ) }
                                                </a>
                                            </div>
                                        }
                                        {
                                            purchase.code &&
                                            <div>{ t( 'payment:totalBeforeDiscount', language ) } : { this.getPriceTotalBeforeDiscount() }</div>
                                        }
                                        {
                                            purchase.code && purchase.code === STORE_DEFAULT_CODE && purchase.codeAmount &&
                                            <div>{ t( 'payment:discount', language ) } { purchase.codeAmount }% { t( 'payment:forSubZ3', language ) }</div>
                                        }
                                        {
                                            purchase.code && purchase.code !== STORE_DEFAULT_CODE && purchase.codeAmount &&
                                            <div>{ t( 'payment:codeLabelDiscount', language ) } { purchase.codeAmount }%</div>
                                        }
                                        <div className='cart_product_price tall'>{ t( 'payment:total', language ) } : { purchase.priceTotal }</div>
                                        {
                                            purchase.pdfFileUrl &&
                                            <div className='cart_comment_btn_box'>
                                                <a href={ purchase.pdfFileUrl } target='_blank' rel='noopener noreferrer' className='cart_comment_btn'>
                                                    { t( 'payment:bill', language ) }
                                                </a>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {
                                    purchase.purchases.map( ( item, index ) => {
                                        return (
                                            <div className='profile_menu' key={ index }>
                                                <div className='profile_scale cart_product_details'>
                                                    <div className='cart_product_image'>{ this.renderPic( item ) }</div>
                                                    <div className='cart_product_title'>{ item.name }</div>
                                                    <div className='cart_product_price'>
                                                        {
                                                            item.quantity > 1 ?
                                                            <span>
                                                                { `${ formatEuroPrice( item.amount ) } x ${ item.quantity } = ${ formatEuroPrice( item.amount * item.quantity ) }` }
                                                            </span>
                                                            :
                                                            <span>{ formatEuroPrice( item.amount ) }</span>
                                                        }
                                                    </div>
                                                    <div className='list-title-product-cart'>
                                                        {
                                                            item.color &&
                                                            <div className='list-title-product-custom-select'>
                                                                <label>{ t( 'store:color', language ) }</label>
                                                                <div className='list-title-product-select-selected disabled'>{ item.color }</div>
                                                            </div>
                                                        }
                                                        {
                                                            item.size &&
                                                            <div className='list-title-product-custom-select'>
                                                                <label>{ t( 'store:size', language ) }</label>
                                                                <div className='list-title-product-select-selected disabled'>{ item.size }</div>
                                                            </div>
                                                        }
                                                        <div className='list-title-product-quantity-box'>
                                                            <label>{ t( 'store:quantity', language ) }</label>
                                                            <div className='list-title-product-quantity-input disabled'>
                                                                <input
                                                                    type='number'
                                                                    min='1'
                                                                    name='productQuantity'
                                                                    value={ item.quantity }
                                                                    onChange={ () => {} }
                                                                    />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        type === 'completed' &&
                                                        <div className='cart_comment_btn_box'>
                                                            <div className='cart_comment_btn' onClick={ () => this.handleCommentClick( item ) }>
                                                                { t( 'form:leaveReview', language ) }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                <div className='profile_menu'>
                                    <div className='profile_scale purchase_general'>
                                        <div className='purchase_general_title'>{ t( 'store:deliveryAddress', language ) } :</div>
                                        <div>{ purchase.address.delivery.lastName } { purchase.address.delivery.firstName }</div>
                                        <div>{ purchase.address.delivery.address1 }</div>
                                        { purchase.address.delivery.address2 && <div>{ purchase.address.delivery.address2 }</div> }
                                        <div>{ purchase.address.delivery.postalCode } { purchase.address.delivery.city }</div>
                                        <div>{ purchase.address.delivery.country }</div>
                                        <div className='purchase_general_phone'>{ t( 'form:phoneSmall', language ) } : { purchase.address.phoneNumber }</div>
                                    </div>
                                </div>
                                <div className='profile_menu'>
                                    <div className='profile_scale purchase_general'>
                                        <div className='purchase_general_title'>{ t( 'store:billingAddress', language ) } :</div>
                                        <div>{ purchase.address.billing.lastName } { purchase.address.billing.firstName }</div>
                                        <div>{ purchase.address.billing.address1 }</div>
                                        { purchase.address.billing.address2 && <div>{ purchase.address.billing.address2 }</div> }
                                        <div>{ purchase.address.billing.postalCode } { purchase.address.billing.city }</div>
                                        <div>{ purchase.address.billing.country }</div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <div id='profile_body'>
                            <div className='profile_menu'>
                                <div className='profile_scale cart_product_title'>
                                    { t( type === 'completed' ? 'profile:ordersCompletedNone' : 'profile:ordersInProgressNone', language ) }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
    userPurchases: state.userReducer.userPurchases,
});

const dispatchToProps = dispatch => ({
	fetchCommentItem: ( type, item ) => dispatch( commentAction.fetchCommentItem( type, item )),
});

export default connect( stateToProps, dispatchToProps )( POrdersDetails );