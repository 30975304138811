import { PLAYLIST_REDUCER } from '../../constants';
import { getPlaylistMovies } from "../../services/playlist";

export default {
    // fetchCurrentPlaylist: ( playlistId ) => dispatch => {
    //     dispatch( {
    //         type: PLAYLIST_REDUCER.FETCH_CURRENT_PLAYLIST,
    //         payload: playlistId
    //     });
    // },
    // fetchCurrentPlaylistItem: ( movie ) => dispatch => {
    //     dispatch( {
    //         type: PLAYLIST_REDUCER.FETCH_CURRENT_PLAYLIST_ITEM,
    //         payload: movie
    //     });
    // },

	fetchPlaylistMovieIndex: movieIndex => dispatch => {
        dispatch( {
            type: PLAYLIST_REDUCER.FETCH_PLAYLIST_MOVIE_INDEX,
            payload: movieIndex
        });
    },
    fetchPlaylistMovies: ( userId, version ) => async dispatch => {
		try {
			const params = userId ? `?NoCli=${ userId }&version=${ version }` : "";
			const response = await getPlaylistMovies( params );
			const { data } = response;

			if ( data.data && data.data.length > 0 ) {
				dispatch({
					type: PLAYLIST_REDUCER.FETCH_PLAYLIST_MOVIES,
					payload: data.data[ 0 ]
				});
			} else {
				dispatch({
					type: PLAYLIST_REDUCER.API_ERROR,
					payload: "error : playlist API"
				});
			}
		} catch (err) {
			dispatch({
				type: PLAYLIST_REDUCER.API_ERROR,
				payload: "error : playlist API"
			});
		}
	},
};