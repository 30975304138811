import React from 'react';

import { connect } from "react-redux";
import { routingAction } from "../../redux/actions";

import Header from '../../components/Header';
import ListRows from "./ListRows";
import ListCats from '../../components/ListCats';

class Hunting extends React.Component {
    componentDidMount() {
        const { fetchCurrentRouting } = this.props;
        fetchCurrentRouting( 2 );
  	};

    render() {
        return (
            <div className="homepage-container">
                <Header />
                <ListCats />
                <ListRows />
            </div>
        )
    }
}

const stateToProps = state => ({});

const dispatchToProps = dispatch => ({
	fetchCurrentRouting: themeId => dispatch( routingAction.fetchCurrentRouting( themeId )),
});

export default connect( stateToProps, dispatchToProps )( Hunting );
