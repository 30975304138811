import React from "react";
import { connect }  from "react-redux";

import { API_IMAGE, CURRENCY, PAYMENT_REDUCER } from "../../../../constants";
import { t } from "../../../../utils";
 
class PaymentDetailsItem extends React.Component {
    render() {
        const { item, language, productType, userGeoInfo } = this.props;
        const currency = userGeoInfo && userGeoInfo.currency ? userGeoInfo.currency : CURRENCY.EUR;

        return (
            <div className="popup-payment-details-item">
                <div className="popup-payment-details-item-img-box">
                    <img 
                        alt={ item.alt }
                        src={ `${ API_IMAGE }${ item.thumbnailFileName }.jpg&h=60&crop-to-fit` }
                        onError={ e => e.target.style.display = 'none' } />
                </div>
                <div className="popup-payment-details-item-title-box">
                    <div className="popup-payment-details-item-title">
                        { productType === PAYMENT_REDUCER.TYPE_RENT && `${ t( 'shared:rent', language ) } : ` }
                        {
                            item[ t( 'keys:title', language ) ] ?
                            item[ t( 'keys:title', language ) ]
                            :
                            item.title
                        }
                    </div>
                    <div className="popup-payment-details-item-linkAddon">
                        {
                            item.linksAddon && item.linksAddon.length > 0 &&
                            item.linksAddon.map( ( item_, index_ ) => 
                                <div
                                    key={ index_ }
                                    className={ `btn-addon ${ item_.link ? 'collect_flag linked' : '' }` }
                                    onClick={ () => { if ( item_.link ) window.location.href = item_.link; } }
                                    id={ `linkAddonBtn--popup-payment-link-${ index_ }` }>
                                    {
                                        item_[ t( 'keys:linkBtn', language ) ] ?
                                        item_[ t( 'keys:linkBtn', language ) ]
                                        :
                                        item_.linkBtn
                                    }         
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className='f-grow-1'></div>
                <div className="popup-payment-details-item-price-box">
                    {
                        productType === PAYMENT_REDUCER.TYPE_PURCHASE &&
                        <div className="popup-payment-details-item-price">
                            { item.prices[ currency ].priceOverlineStg ? <span className="popup-payment-details-item-priceOverlineStg">{ item.prices[ currency ].priceOverlineStg }</span> : <></> }
                            { item.prices[ currency ].priceStg ? <span className="popup-payment-details-item-priceStg">{ item.prices[ currency ].priceStg }</span> : <></> }
                        </div>  
                    }
                    {
                        productType === PAYMENT_REDUCER.TYPE_RENT &&
                        <div className="popup-payment-details-item-price">
                            { item.pricesRent[ currency ].priceOverlineStg ? <span className="popup-payment-details-item-priceOverlineStg">{ item.pricesRent[ currency ].priceOverlineStg }</span> : <></> }
                            { item.pricesRent[ currency ].priceStg ? <span className="popup-payment-details-item-priceStg">{ item.pricesRent[ currency ].priceStg }</span> : <></> }
                        </div>  
                    }
                    {
                        item.details ?
                        <div className="popup-payment-details-item-details">
                            {
                                item[ t( 'keys:details', language ) ] ?
                                item[ t( 'keys:details', language ) ]
                                :
                                item.details
                            }
                        </div>
                        :
                        <></>
                    }
                </div>
            </div>
        );
    }
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
	productType: state.paymentReducer.productType,
	userGeoInfo: state.userReducer.userGeoInfo,
});

export default connect( stateToProps )( PaymentDetailsItem );
