import React from 'react';
import { connect } from "react-redux";

import PRouter from "./PRouter";

import { routingAction, userAction } from "../../redux/actions";
import { getUserInfo, isGuest } from "../../utils";

class Profile extends React.Component {
    componentDidMount() {
        const { fetchCurrentRoutingByUrl, fetchPlansList, fetchUserPurchases, fetchUserStripeInfo } = this.props;
		const userId = getUserInfo().NoCli;
        if ( userId ) {
            fetchUserPurchases( userId );
            if ( ! isGuest() ) {
                fetchUserStripeInfo( userId );
                fetchPlansList();
            }
        } 
        fetchCurrentRoutingByUrl( 'mon-compte' );
  	};

    render() {
        return (
            <div className="homepage-container special-nav-menu">
                <PRouter />
            </div>
        )
    }
}

const stateToProps = state => ({});

const dispatchToProps = dispatch => ({
	fetchCurrentRoutingByUrl: url => dispatch(routingAction.fetchCurrentRoutingByUrl( url )),
    fetchPlansList: () => dispatch(userAction.fetchPlansList()),
    fetchUserPurchases: userId => dispatch(userAction.fetchUserPurchases( userId )),
    fetchUserStripeInfo: userId => dispatch(userAction.fetchUserStripeInfo( userId )),
});

export default connect( stateToProps, dispatchToProps )( Profile );