export const translation_en = {
    available: {
        last: 'Last day to watch this movie',
        morePart1: 'You have ',
        morePart2: ' days left to watch this movie',
        one: 'You have 1 day left to watch this movie',
    },
    badge: {
        eachFriday: 'Each Friday',
        eachWeek: 'Each week',
        new: 'New',
        newEpisode: 'New episodes',
        newSeason: 'New season',
    },
    categories: {
        advice: 'Suggested categories :',
        search: 'Search my category',
        title: 'Indicate the category concerned',
    },
    changePayment: {
        confirm: 'Change my payment method',
        text1: 'The last payment for your Zone300 subscription has failed.',
        text2: 'Change your payment method to continue enjoying all our reports, films and series unlimited.',
        title: 'Change your payment method',
    },
    clock: {
        days: 'DAYS',
        hours: 'HOURS',
        minutes: 'MINUTES',
        seconds: 'SECONDS',
    },
    completed: {
        default: 'You haven\'t finished any movies yet',
    },
    distribution: {
        actor: 'Actor',
        director: 'Director',
        producer: 'Producer',
    },
    favorites: {
        default: 'You haven\'t added any movies to your list yet',
        info: 'Add movies to your list by clicking "+ my list"',
    },
    forgottenPassword: {
        confirm: 'Send',
        success: 'We have just sent you an email with the procedure to follow.',
        text1: 'Enter your email address.',
        text2: 'We will send you a link to reset your password.',
        title: 'Forgot your password ?',
    },
    form: {
        addComment: 'Add a comment',
        address1: 'Address Line 1',
        address1Info: 'Street, post office box, company name',
        address2: 'Address line 2 (optional)',
        adress2Info: 'Apartment, building, floor...',
        addressError: 'Please enter your address',
        ageValid: 'I confirm that I am over 15 years old',
        ageValidError: 'You must confirm that you are over 15 years old',
        birthday: 'Date of birth',
        birthdayInfo: 'We will use your date of birth for sending gifts or future promotions',
        birthdayError: 'Please enter your date of birth',
        checkboxError: 'You have not ticked enough boxes',
        city: 'City',
        cityError: 'Please enter your city',
        codeEmpty: 'Please enter your code',
        codeError: 'Wrong code',
        comment: 'Remark',
        comments: 'Remarks',
        commentSuccess: 'Your opinion has been recorded',
        country: 'Country',
        countryError: 'Please enter your country',
        defaultSelect: '- Select -',
        editSuccess: 'Change made successfully',
        emailError: 'Please enter your email',
        emailExists: 'Email already used',
        emailNew: 'Please enter your new email',
        emailNewLabel: 'New email',
        emailOld: 'Current email address :',
        emailValidError: 'Please enter a valid email',
        firstName: 'First name',
        firstNameError: 'Please enter your first name',
        gender: 'Gender',
        genderFemale: 'Female',
        genderMale: 'Male',
        genderError: 'Please enter your gender',
        isp: 'Internet box operator',
        ispError: 'Please enter your Internet Box operator',
        lastName: 'Last name',
        lastNameError: 'Please enter your name',
        leaveReview: 'Leave a review',
        newsletter: 'I do not wish to receive the newsletter',
        optional: 'optional',
        password: 'Password',
        passwordConfirm: 'Password confirmation',
        passwordConfirmError: 'Passwords do not match',
        passwordConfirmNewEmpty: 'Please retype your new password',
        passwordEmpty: 'Please enter your password',
        passwordError: 'Incorrect password',
        passwordNew: 'New password',
        passwordNewConfirm: 'Retype your new password',
        passwordNewEmpty: 'Please enter your new password',
        passwordOld: 'Current password',
        passwordRequired: 'Please enter your password (required)',
        phone: 'Phone number',
        phoneError: 'Please enter your phone number',
        phoneInfo: 'We will not text you without your consent',
        phoneSmall: 'Phone',
        postalCode: 'Postal code',
        postalCodeError: 'Please enter your postal code',
        productUnknown: 'Unknown product',
        pseudo: 'Nickname',
        pseudoError: 'Please enter your nickname',
        rateStarErrorMovie: 'Please rate the movie',
        rateStarErrorProduct: 'Please rate the product',
        rateStarErrorSerie: 'Please note the series',
        reason: 'Select at least 1 reason',
        region: 'State / Region / Township (if applicable)',
        requiredFields: '* required fields',
        saveUpdate: 'Save changes',
        tshirtSize: 'T-shirt size',
        tshirtSizeError: 'Please enter your t-shirt size',
        tokenExpired: 'Timeout expired, please try again.',
        userExists: 'Already existing user',
        userUnknown: 'Unknown user',
        yourOpinion: 'Your opinion on',
        yourOpinionProduct: 'Your opinion on the product',
    },
    freemium: {
        adds: 'Commercial',
        connect: 'LOGIN',
        disconnect: 'LOGOUT',
        freeMonthMovie: 'Free movie of the month',
        freePlay: 'Watch for free',
        info: 'Unlimited access to all movies',
        infoAdds: 'Subscribe and enjoy unlimited Zone300 without advertising.',
        reactivate: 'Reactivate',
        reactivateAdds: 'Reactivate my account',
        removeAdds: 'Remove commercial',
        sub: 'Subscribe',
        subscribe: 'SUBSCRIBE',
        subscribeAdds: 'Remove commercials and choose my program',
    },
    keys: {
        description: 'description_en',
        details: 'details_en',
        freeBtn: 'freeBtn_en',
        info: 'info_en',
        linkBtn: 'linkBtn_en',
        name: 'name_en',
        playBtn: 'playBtn_en',
        preTitre: 'preTitre_en',
        priceBtn: 'priceBtn_en',
        priceRentBtn: 'priceRentBtn_en',
        priceInfo: 'priceInfo_en',
        synopsis_txt: 'synopsis_txt_en',
        text: 'text_en',
        text_1: 'text_1_en',
        text_2: 'text_2_en',
        title: 'title_en',
        titlePngFileName: 'titlePngFileName_en',
        titre: 'titre_en',
    },
    login: {
        confirm: 'Login',
        forgottenPassword: 'Forgot your password?',
        text1: 'First visit ?',
        text2: 'Signin',
        title: 'Login',
    },
    moviePage: {
        navDistribution: 'Distribution',
        navEpisodes: 'Episodes',
        navExtras: 'Extras',
        navProducts: 'Products',
        navRelated: 'Related',
    },
    nav: {
        address: 'Address',
        cart: 'My cart',
        confirmation: 'Confirmation',
        favorites: 'My list',
        home: 'My Zone300',
        language: 'Language',
        notifications: 'Notifications',
        payment: 'Payment',
        playlist: 'Live',
        search: 'Search',
        store: 'Store',
        training: 'Courses',
    },
    partner: {
        cap: 'the Zone300 cap',
        capGift: '1 Zone300 cap + goodies',
        copiedLink: 'copied link',
        copyLink: 'Copy link',
        giftPreviousPart1: 'With ',
        giftPreviousPart2: ' subscriptions you won ',
        giftNextPart1: 'Only ',
        giftNextPart2: ' subscription',
        giftNextPart3: ' to unlock ',
        info1: 'How it works ?',
        info2: 'Fill in the following information so that we can send you the prizes for each level.',
        info3: '*Please note that the subscription must be taken during a single browsing session after clicking on the tracked link.',
        info4: 'Any unsubscription of a partner causes all of the subscriptions collected to be lost and no longer gives right to the prizes.',
        info5: 'Any request for change of batches by stage will not be processed.',
        intro1: 'Become a partner',
        intro2: 'I become a partner',
        link: 'My unique link',
        listGift: 'Here is the list of prizes to be won according to the subscriptions you generate :',
        moreGift: 'And many more gifts to come !',
        permit: 'your hunting license',
        permitGift: 'Your annual national hunting license validation',
        shareLink: 'Share your personalized link with your friends and win prizes !',
        subscriptions: 'subscriptions',
        succeeded: 'My successful referrals',
        succeededTotal: 'Total successful referrals',
        successConfirm: 'Access the dashboard',
        successTitle: 'Validation',
        successText1: 'Congratulation !',
        successText2: 'You are now a Zone300 partner and can now share your unique link with your friends.',
        sweat: 'the Zone300 sweatshirt',
        sweatGift1: '1 Zone300 sweatshirt',
        sweatGift2: '1 personalized sweatshirt with your name and the "Zone300 team" logo',
        tshirt: 'the Zone300 t-shirt',
        tshirtGift: '1 limited edition Zone300 t-shirt + goodies',
    },
    payment: {
        bill: 'Bill',
        cardInfo: 'Card information',
        cardName: 'Name of the card holder',
        codeConfirm: 'Apply',
        codeLabel: 'Promo code',
        codeLabelDiscount: 'Promo code: discount',
        codeRemove: 'Remove',
        codeSuccess: 'The code has been applied',
        discount: 'discount',
        error: 'Payment failed',
        forSubZ3: 'for Zone300 subscribers',
        info: 'Secure payment. Encrypted banking transactions.',
        nav1: '1 - Order summary',
        nav2: '2 - Payment',
        orderConfirm: 'Confirm order',
        payConfirm: 'Pay',
        success: 'Payment made successfully',
        successInfo: 'A confirmation email has been sent to you.',
        successTitle: 'Thank you for your purchase',
        successTrainingConfirm: 'Start training',
        total: 'Total',
        totalBeforeDiscount: 'Total before discount',
        trackLink: 'Track your package here',
        useCard: 'Use my card',
        useOtherCard: 'Use another payment method',
    },
    preferences: {
        error: 'Please choose at least three categories',
        selection: 'Select at least three categories',
        title: 'Customize your Zone300',
    },
    profile: {
        aboutUs: 'About us',
        account: 'Account',
        billingHistory: 'Billing history',
        cookies: 'Cookie Terms',
        confidentiality: 'Confidentiality declaration',
        data: 'GDPR',
        date: 'Date',
        details: 'Account details',
        disconnect: 'Logout',
        disconnectQuestion: 'Do you want to logout ?',
        distributors: 'Our distributors',
        editAvatar: 'Change avatar',
        editEmail: 'Edit email',
        editFirstName: 'Edit first name',
        editInfo: 'Edit my information',
        editLastName: 'Edit last name',
        editPassword: 'Edit password',
        editPayment: 'Edit my payment method',
        editProfile: 'Edit my profile',
        editSubscription: 'Edit my subscription',
        furtherInfo: 'Further information',
        legal: 'Legal notice',
        media: 'Media',
        offers: 'See our offers',
        order: 'Order',
        orderId: 'Order ID',
        orders: 'Orders',
        ordersCompleted: 'Orders completed',
        ordersCompletedNone: 'You don\'t have any orders completed',
        ordersInProgress: 'Orders in progress',
        ordersInProgressNone: 'You don\'t have any orders in progress',
        partner: 'Partner',
        partners: 'Our partners',
        paymentFailed: 'Payment failed',
        personalInfo: 'Personal information',
        personalProfile: 'Personal profile',
        personalize: 'Personalize',
        preferences: 'Your preferences',
        profile: 'Profile',
        questions: 'Questions ? Contact Diane',
        review: 'Review',
        social: 'Follow us on the networks',
        subscription: 'My subscription',
        subscriptionSince: 'Subscriber since',
        subscriptionUpdated: 'Your subscription has been changed',
        terms: 'TOS',
        title: 'My account',
        unsubscribe: 'Cancel my subscription',
        whoAreWe: 'Who are we ?',
    },
    profileManager: {
        add: 'Add profile',
        change: 'Change profile',
        edit: 'Edit profile',
        remove: 'Remove profile',
        removeImpossible: 'Impossible to delete initial profile',
        removeInfo: 'Please note this action is irreversible.',
        removeQuestion: 'Are you sure you want to delete this profile ?',
        title: 'My profiles',
    },
    progress: {
        default: 'You have no movies to finish yet',
    },
    reactivate: {
        details: 'Your next direct debit will be on ',
        confirm: 'Réactiver mon abonnement',
        infoPart1: 'If you reactivate your ',
        infoPart2: ' subscription now, you will continue to enjoy all our reports, films and series unlimited.',
        success: 'Your subscription has been reactivated',
        title: 'We are happy to see you back !',
    },
    reactivatePayment: {
        confirm: 'Reactivate my accounte',
        text1: 'You can reactivate it now to enjoy all our reports, films and series unlimited.',
        title: 'Your Zone 300 account is on pause',
    },
    registration: {
        confirm: 'Confirm',
        confirmInfo: 'By clicking on "Confirm" you accept our general conditions of subscription.',
        text1: 'Already have a Zone300 account?',
        text2: 'Login',
        title: 'Signin',
        titleFreeMovie: 'Signin and enjoy your movie for free',
        titlePlaylist: 'Signin and enjoy our free channel',
    },
    resetPassword: {
        confirm: 'Send',
        text1: 'Enter your new password.',
        title: 'Forgot your password ?',
        success: 'Your password has been updated.',
    },
    search: {
        confirm: 'Launch the search',
        default: 'Video not available at the moment',
        placeholder: 'SEARCH...',
    },
    shared: {
        add: 'Add',
        atDate: 'at',
        availableDate: 'Available',
        back: 'Back',
        backNavigation: 'Back to navigation',
        backPlatform: 'Back to platform',
        bonus: 'Bonus',
        confirm: 'Confirm',
        continue: 'Continue',
        description: 'Description',
        details: 'Details',
        dislike: 'I don\'t like',
        edit: 'Edit',
        episode: 'Episode',
        episodeSmall: 'episode',
        explore: 'Explore',
        fishing: 'Fishing',
        genericError: 'An error has occurred',
        hunting: 'Hunting',
        like: 'I like',
        listAddTo: 'Add to My List',
        listRemoveTo: 'Remove to My List',
        nextVideo: 'Next video in ',
        no: 'No',
        minutesLeft: ' minutes left',
        minuteLeft: ' minute left',
        on: 'on',
        onAir: 'Currently broadcast',
        opinion: 'opinions',
        or: 'OR',
        other: 'Other',
        play: 'Play',
        relatedProduct: 'You will surely like',
        remove: 'Remove',
        rent: 'RENTAL',
        resume: 'Resume',
        save: 'Save',
        second: 'second',
        trailer: 'Trailer',
        trailers: 'Trailers',
        yes: 'Yes',
    },
    store: {
        add : 'Add to cart',
        addSuccess: 'The product has been added to your basket',
        billingAddress: 'Billing address',
        cartEmpty: 'Your cart is empty',
        cartRemoveQuestion: 'Do you want to remove this product from your cart ?',
        color: 'Color',
        continue: 'Continue shopping',
        delivered: 'Delivered',
        deliveryAddress: 'Delivery address',
        deliveryProgress: 'In delivery process',
        deliveryStart: 'In preparation',
        errorBillingAddress: 'Please enter your billing address',
        errorBillingCity: 'Please enter your billing city',
        errorBillingCountry: 'Please enter your billing country',
        errorBillingFirstName: 'Please enter your billing first name',
        errorBillingLastName: 'Please enter your billing last name',
        errorBillingPostalCode: 'Please enter your billing zip code',
        errorCountryUnavailable: 'Delivery is not possible in your country.',
        errorColor: 'Please indicate color',
        errorDeliveryAddress: 'Please enter your delivery address',
        errorDeliveryCity: 'Please enter your delivery city',
        errorDeliveryCountry: 'Please enter your delivery country',
        errorDeliveryFirstName: 'Please enter your delivery first name',
        errorDeliveryLastName: 'Please enter your delivery last name',
        errorDeliveryPostalCode: 'Please enter your delivery zip code',
        errorQuantity: 'Please indicate the number of products',
        errorSize: 'Please indicate the size',
        expirationDate: 'Expiration date',
        forSub: 'FOR SUBSCRIBERS',
        outOfStock: 'Out of stock',
        paymentMethod: 'payment method',
        quantity: 'Quantity',
        sameAddress: 'Same as delivery address',
        successInfo1: 'Thank you for your purchase',
        successInfo2: 'An email confirmation has been sent to you',
        successInfo3: 'You can track your order in the menu',
        size: 'Size',
        valid: 'Validate my cart',
    },
    subscription: {
        confirm: 'Confirm change',
        current: 'CURRENT PACKAGE',
        month1Adj: 'monthly',
        month1Stg: '1 mois',
        month1StgLong: 'Abonnement mensuel',
        month3Adj: 'quaterly',
        month3Stg: '3 months',
        month3StgLong: '3 months subscription',
        month6Adj: 'semi-annual',
        month6Stg: '6 months',
        month6StgLong: '6 months subscription',
        month12Adj: 'annual',
        month12Stg: '12 months',
        month12StgLong: '12 months subscription',
        text1: 'If you change your subscription now you will be charged ',
        text2: 'Your subscription has been modified, you will be charged ',
        the: 'the ',
    },
    time: {
        day: 'day',
        days: 'days',
        hour: 'hour',
        hours: 'hours',
        minute: 'minute',
        minutes: 'minutes',
        month: 'month',
        months: 'months',
        since: 'since',
        year: 'year',
        years: 'years',
    },
    unsub: {
        cancel: 'Stay on Zone300',
        confirm: 'Cancel my subscription',
        continue: 'Proceed with termination',
        step: 'Step',
        step1Title: 'Are you sure you want to leave ?',
        step1Text1: 'Did you know that Zone300 releases 4 new exclusive movies per week ?',
        step1Text2: 'Have you looked at the latest recommendations made for you ?',
        step2Text1: 'Because your opinion is important. Please let us know the reason for your departure, in order to improve ourselves (to find us better).',
        step2Text2: 'Is it a technical problem? Contact Diane on 09 72 16 43 58.',
        step3Title: 'Are you leaving us ?',
        step3Text1: 'The cancellation will be effective at the end of the current billing period',
        step3Text2: 'You can reactivate your subscription from your space by clicking on "Reactivate my subscription".',
        step3Text3: 'If you wish to unsubscribe now, enter your password to confirm your identity and click on "Cancel my subscription".',
    },
};