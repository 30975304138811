import React from "react";
import { connect } from "react-redux";

import { API_IMAGE, ICONS, VERSION_PROFILE_EDIT } from "../../constants";
import { moviesAction } from "../../redux/actions";
import { togglePreferences } from "../../services/user";
import { getUserInfo, isGuest, t } from "../../utils";

class HomeList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			errorMessage: '',
			preferencesTypeSelected: 'hunting',
		};

		this.preferencesList = [];

		this.chasseIcon = require( "../../assets/img/deer-antlers.svg" );
		this.pecheIcon = require( "../../assets/img/fish.svg" );
	};

	componentDidMount() {
		if ( isGuest() ) { window.location.href = "/offres/"; return; } 

		const { fetchPreferencesList } = this.props;
		fetchPreferencesList();
		sessionStorage.removeItem( VERSION_PROFILE_EDIT );
	};

	choosePreferencesType = preferencesType => this.setState({ preferencesTypeSelected: preferencesType });

	handleSubmit = () => {
		const { language } = this.props;

		if ( this.preferencesList.length < 3 ) {
			this.setState( { errorMessage: t( 'preferences:error', language ) } );
			return false;
		}
		window.location.href="/browse/#/";
	};

	renderPic = preference => {
		if ( preference.imagePathMobile && preference.imagePathMobile !== '' ) {
			return (
				<img
					className="tile-img"
					alt={preference.name}
					src={`${API_IMAGE}${preference.imagePathMobile}.jpg&w=500&h=281&crop-to-fit`} 
					onError={ e => e.target.src = `${API_IMAGE}${ preference.parent === '2' ? 'default-chasse' : 'default-peche' }.jpg&w=500&h=281&crop-to-fit` }
				/>
			);
		}
		return (
			<img
				alt="img"
				className="tile-img"
				src={`${API_IMAGE}${ preference.parent === '2' ? 'default-chasse' : 'default-peche' }.jpg&w=500&h=281&crop-to-fit`}
			/>
		);
	};

	btnSrcIsInPreference = catId => {
		const { profileEditVersion, userInfoDB, userPreferences } = this.props;

		if ( profileEditVersion === 0 ) {
			this.preferencesList = userPreferences;
		} else {
			if ( userInfoDB && userInfoDB.profiles && userInfoDB.profiles.length > 0 ) {
                for ( let i = 0; i < userInfoDB.profiles.length; i++ ) {
                    if ( profileEditVersion === userInfoDB.profiles[ i ].version ) {
						this.preferencesList = userInfoDB.profiles[ i ].preferences.preferences;
						break;
					}
                }
            }
		}
		
		let btnInfo = { class: 'icon_add', src: ICONS.favorites, figure: 'effect-thumbnail-movie' };
		if ( this.preferencesList.includes( catId ) ) btnInfo = { class: 'icon_add selected', src: ICONS.checked, figure: 'effect-thumbnail-movie selected' };
		return btnInfo;
    };
    
	toggleInPreference = catId => {
		const { profileEditVersion } = this.props;

		let action = '';
		let newPreferencesList = this.preferencesList;
		if ( this.preferencesList.includes( catId ) ) {
			action = 'remove';
			newPreferencesList.splice( newPreferencesList.indexOf( catId ), 1 );
		} else {
			action = 'add';
			newPreferencesList.push( catId );
		}

		togglePreferences( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileEditVersion }&catId=${ catId }` )
			.then( function( res ) { console.log( res.data ); } )
			.catch( function( error ) { console.log( error ); } );

		this.setState( { preferencesList: newPreferencesList } );
	};

	render() {
		const { language, preferencesFishingList, preferencesHuntingList } = this.props;

		return (
			<div id='preference_container'>
				<h1>{ t( 'preferences:title', language ) }</h1>
				<div className='preference_option'>
					<div>{ t( 'preferences:selection', language ) }</div>
					<div id='btns_choice'>
						<div
							id='chasse_choice'
							className={ `collect_flag ${ this.state.preferencesTypeSelected === 'hunting' && 'selected' }` }
							onClick={ () => this.choosePreferencesType( 'hunting' ) } >
							<img className="btns_choice_ico" src={ this.chasseIcon } alt='icone chasse' />
							<div>{ t( 'shared:hunting', language ) }</div>
						</div>
						<div
							id='peche_choice'
							className={ `collect_flag ${ this.state.preferencesTypeSelected === 'fishing' && 'selected' }` }
							onClick={ () => this.choosePreferencesType( 'fishing' ) } >
							<div>{ t( 'shared:fishing', language ) }</div>
							<img className="btns_choice_ico" src={ this.pecheIcon } alt='icone peche' />
						</div>
					</div>
					<div className='f-grow'></div>
					<div id='btn_submit' onClick={ this.handleSubmit } >{ t( 'shared:confirm', language ) }</div>
				</div>
				<div className='preference_error'> { this.state.errorMessage } </div>
				<div className={ `preference_container ${ this.state.preferencesTypeSelected !== 'hunting' && 'hide' }` }>
					{
						preferencesHuntingList && preferencesHuntingList.length > 0 &&
						preferencesHuntingList.map( item =>
							<div className='categ_box' onClick={ this.toggleInPreference.bind( this, item.id ) } key={ item.id }>
								<div className="movie_mozaic_name_box">
									<div className="movie_mozaic_name">{ item.name }</div>
									<div className="f-grow-1"></div>
									<div>
										<img
											className={ this.btnSrcIsInPreference( item.id ).class }
											src={ this.btnSrcIsInPreference( item.id ).src }
											alt='icone favoris' />
									</div>
								</div>
								<div className="movie_mozaic">
									<figure className={ this.btnSrcIsInPreference( item.id ).figure }>
										{ this.renderPic( item ) }
									</figure>
								</div>
							</div>
						)
					}
				</div>
				<div className={ `preference_container ${ this.state.preferencesTypeSelected !== 'fishing' && 'hide' }` }>
					{
						preferencesFishingList && preferencesFishingList.length > 0 &&
						preferencesFishingList.map( item =>
							<div className='categ_box' onClick={ this.toggleInPreference.bind( this, item.id ) } key={ item.id }>
								<div className="movie_mozaic_name_box">
									<div className="movie_mozaic_name">{ item.name }</div>
									<div className="f-grow-1"></div>
									<div>
										<img
											className={ this.btnSrcIsInPreference( item.id ).class }
											src={ this.btnSrcIsInPreference( item.id ).src }
											alt='icone favoris' />
									</div>
								</div>
								<div className="movie_mozaic">
									<figure className={ this.btnSrcIsInPreference( item.id ).figure }>
										{ this.renderPic( item ) }
									</figure>
								</div>
							</div>
						)
					}
				</div>
			</div>
		);
	}
}

const stateToProps = state => ({
	preferencesFishingList: state.moviesReducer.preferencesFishingList,
	preferencesHuntingList: state.moviesReducer.preferencesHuntingList,
	profileEditVersion: state.userReducer.profileEditVersion,
	language: state.translatingReducer.language,
	userInfoDB: state.userReducer.userInfoDB,
	userPreferences: state.userReducer.userPreferences,
});

const dispatchToProps = dispatch => ({
	fetchPreferencesList: () => dispatch( moviesAction.fetchPreferencesList()),
});

export default connect( stateToProps, dispatchToProps )( HomeList );
