import React from "react";
import { connect } from "react-redux";

import MozaicRender from "../../components/tools/MozaicRender";

import { ICONS } from "../../constants";
import { moviesAction } from "../../redux/actions";
import { getUserInfo, t } from "../../utils";

class HomeList extends React.Component {
	componentDidMount() {
		const { fetchFavoritesMovies, profileVersion } = this.props;
		const userId = getUserInfo().NoCli;
		fetchFavoritesMovies( 0, userId, profileVersion );
	};

	render() {
		const { favoriteMovies, language } = this.props;

		return (
			<div id='favorite_Homelist'>
				{
					favoriteMovies && favoriteMovies.length > 0 && favoriteMovies[ 0 ].movies && favoriteMovies[ 0 ].movies.length > 0 ?
					<MozaicRender movieListInfo = { favoriteMovies } />
					:
					<div className ='default-favorite'>
						<h3>{ t( 'favorites:default', language ) }</h3>
						<div className='default-favorite-icon'><img src={ ICONS.favorites } alt='icone favoris' /></div>
						<div className='default-favorite-info'>{ t( 'favorites:info', language ) }</div>
					</div>
				}
			</div>
		);
	}
}

const stateToProps = state => ({
	favoriteMovies: state.moviesReducer.favoriteMovies,
	language: state.translatingReducer.language,
	profileVersion: state.userReducer.profileVersion,
});

const dispatchToProps = dispatch => ({
	fetchFavoritesMovies: ( themeId, userId, version ) => dispatch( moviesAction.fetchFavoritesMovies( themeId, userId, version )),
});

export default connect( stateToProps, dispatchToProps )( HomeList );
