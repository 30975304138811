import { MOVIES_REDUCER } from '../../constants';
import {
	getCatsList,
	getFavoritesMovies,
	getForUserMovies,
	getFreeMovieHeader,
	getMomentMovies,
	getMostViewedMovies,
	getNewForUserMovies,
	getNewMovies,
	getPageMovies,
	getPinnedMovies,
	getPlatformConfig,
	getPreferencesList,
	getProgressMovies,
	getSearchedIframeMovies,
	getSearchedMovies,
	getSearchProposals,
	getSliderMovies,
	getSoonAvailableHeader,
	getSpotlightedMovies,
} from '../../services/movies';
import { getLocalTimeZone } from '../../utils/getLocalTimeZone';
const countryCode = getLocalTimeZone().countryCode;

export default {
	fetchCatsList: themeId => async dispatch => {
		try {
			const response = await getCatsList( `?themeId=${ themeId }&country=${ countryCode }` );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_CATS_LIST,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
	fetchFavoritesMovies: ( themeId, userId, version ) => async dispatch => {
		try {
			const params = userId ? `?themeId=${ themeId }&NoCli=${ userId }&version=${ version }` : `?themeId=${ themeId }`;
			const response = await getFavoritesMovies( params );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_FAVORITES_MOVIES,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
	fetchForUserMovies: ( themeId, userId, version ) => async dispatch => {
		try {
			const params = userId ? `?themeId=${ themeId }&NoCli=${ userId }&version=${ version }` : `?themeId=${ themeId }`;
			const response = await getForUserMovies( params );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_FOR_USER_MOVIES,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
	fetchFreeMovieHeader: ( themeId, userId, version ) => async dispatch => {
		try {
			const params =
				userId ?
				`?themeId=${ themeId }&NoCli=${ userId }&version=${ version }&country=${ countryCode }`
				:
				`?themeId=${ themeId }&country=${ countryCode }`
			;
			const response = await getFreeMovieHeader( params );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_FREE_MOVIE_HEADER,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
	fetchMomentMovies: ( themeId, userId, version ) => async dispatch => {
		try {
			const params = userId ? `?themeId=${ themeId }&NoCli=${ userId }&version=${ version }` : `?themeId=${ themeId }`;
			const response = await getMomentMovies( params );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_MOMENT_MOVIES,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
	fetchMostViewedMovies: ( themeId, userId, version ) => async dispatch => {
		try {
			const params =
				userId ?
				`?themeId=${ themeId }&NoCli=${ userId }&version=${ version }&country=${ countryCode }`
				:
				`?themeId=${ themeId }&country=${ countryCode }`
			;
			const response = await getMostViewedMovies( params );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_MOST_VIEWED_MOVIES,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
	fetchNewForUserMovies: ( userId, version ) => async dispatch => {
		try {
			const params = `?NoCli=${ userId }&version=${ version }`;
			const response = await getNewForUserMovies( params );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_NEW_FOR_USER_MOVIES,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
	fetchNewMovies: ( themeId, userId, version ) => async dispatch => {
		try {
			const params =
				userId ?
				`?themeId=${ themeId }&NoCli=${ userId }&version=${ version }&country=${ countryCode }`
				:
				`?themeId=${ themeId }&country=${ countryCode }`
			;
			const response = await getNewMovies( params );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_NEW_MOVIES,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
	fetchPageMovies: ( themeId, userId, version ) => async dispatch => {
		try {
			const params =
				userId ?
				`?themeId=${ themeId }&NoCli=${ userId }&version=${ version }&country=${ countryCode }`
				:
				`?themeId=${ themeId }&country=${ countryCode }`
			;
			const response = await getPageMovies( params );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_PAGE_MOVIES,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},	
	fetchPinnedMovies: ( themeId, userId, version, type ) => async dispatch => {
		try {
			const params = userId ? `?themeId=${ themeId }&type=${ type }&NoCli=${ userId }&version=${ version }` : `?themeId=${ themeId }&type=${ type }`;
			const response = await getPinnedMovies( params );
			const { data } = response;
			
			let dispatchType = MOVIES_REDUCER.FETCH_PINNED_MOVIES;
			if ( type === 'first' ) {
				dispatchType = MOVIES_REDUCER.FETCH_PINNED_FIRST_MOVIES;
			} else if ( type === 'second' ) {
				dispatchType = MOVIES_REDUCER.FETCH_PINNED_SECOND_MOVIES;
			}

			dispatch({
				type: dispatchType,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},	
	fetchPlatformConfig: () => async dispatch => {
		try {
			const response = await getPlatformConfig("");
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_PLATFORM_CONFIG,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
	fetchPreferencesList: () => async dispatch => {
		try {
			const response = await getPreferencesList( "" );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_PREFERENCES_LIST,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
	fetchProgressMovies: ( themeId, userId, version ) => async dispatch => {
		try {
			const params = userId ? `?themeId=${ themeId }&NoCli=${ userId }&version=${ version }` : `?themeId=${ themeId }`;
			const response = await getProgressMovies( params );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_PROGRESS_MOVIES,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
	fetchSearchedIframeMovies: ( iframeLink, userId, version, source ) => async dispatch => {
		try {
			const params = `?iframeLink=${ iframeLink }&NoCli=${ userId }&version=${ version }&source=${ source }`;
			const response = await getSearchedIframeMovies( params );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_SEARCHED_IFRAME_MOVIES,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
	fetchSearchedMovies: ( searchText, userId, version ) => async dispatch => {
		try {
			const params =
				userId ?
				`?searchText=${ searchText }&NoCli=${ userId }&version=${ version }&country=${ countryCode }`
				:
				`?searchText=${ searchText }&country=${ countryCode }`
			;
			const response = await getSearchedMovies( params );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_SEARCHED_MOVIES,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
	fetchSearchProposals: ( userId, version ) => async dispatch => {
		try {
			const params =
				userId ?
				`?NoCli=${ userId }&version=${ version }&country=${ countryCode }`
				:
				`?country=${ countryCode }`
			;
			const response = await getSearchProposals( params );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_SEARCH_PROPOSALS,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
	fetchSeriesLocal: serie => dispatch => {
        dispatch( {
            type: MOVIES_REDUCER.FETCH_SERIES_LOCAL,
            payload: serie
        });
    },	
	fetchSliderMovies: ( themeId, userId, version ) => async dispatch => {
		try {
			const params =
				userId ?
				`?themeId=${ themeId }&NoCli=${ userId }&version=${ version }&country=${ countryCode }`
				:
				`?themeId=${ themeId }&country=${ countryCode }`
			;
			const response = await getSliderMovies( params );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_SLIDER_MOVIES,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
	fetchSoonAvailableHeader: ( themeId, userId, version ) => async dispatch => {
		try {
			const params =
				userId ?
				`?themeId=${ themeId }&NoCli=${ userId }&version=${ version }&country=${ countryCode }`
				:
				`?themeId=${ themeId }&country=${ countryCode }`
			;
			const response = await getSoonAvailableHeader( params );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_SOON_AVAILABLE_HEADER,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
	fetchSpotlightedMovies: ( themeId, userId, version ) => async dispatch => {
		try {
			const params = userId ? `?themeId=${ themeId }&NoCli=${ userId }&version=${ version }` : `?themeId=${ themeId }`;
			const response = await getSpotlightedMovies( params );
			const { data } = response;
			dispatch({
				type: MOVIES_REDUCER.FETCH_SPOTLIGHTED_MOVIES,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: MOVIES_REDUCER.API_ERROR,
				payload: "error : movies API"
			});
		}
	},
};
