import React from "react";
import { connect } from "react-redux";

import Loader from 'react-loader-spinner';

import { API_IMAGE, IMAGES, COMMENT_TYPE } from "../../constants";
import { createComment } from "../../services/comment";
import { getUserInfo, formatTimeSince, handleLoader, t } from "../../utils";
 
class MoviePageReview extends React.Component {
	constructor(props) {
		super(props);

        const { userInfoDB } = this.props;

        this.state = {
            comment: '',
            errorMessage: '',
            isCommentDone: false,
            pseudo: userInfoDB && userInfoDB.pseudo ? userInfoDB.pseudo : '',
            rateStarNumber: 0,
            showCommentForm: false,
            successMessage: '',
        };
	};

    checkErrors = () => {
        const { language, movie } = this.props;

        if ( this.state.rateStarNumber === 0 ) {
            this.setState ({ errorMessage: movie.type === 'series' ? t( 'form:rateStarErrorSerie', language ) : t( 'form:rateStarErrorMovie', language ) });
            return false;
        }
        if ( this.state.comment !== '' && this.state.pseudo === '' ) {
            this.setState ({ errorMessage: t( 'form:pseudoError', language ) });
            return false;
        }

        this.setState ({ errorMessage: '' });
        return true;
    };

    handleChange = ( event ) => { 
        const value = event.target.value;
        const name = event.target.name;
        this.setState ( { [name]: value, successMessage: '' } );
    };

    handleStarChange = starNumber => { 
        if ( starNumber > 0 ) this.setState ( { rateStarNumber: starNumber } );
    };

    handleSubmit = () => {
        const { language, movie } = this.props;
        const { comment, pseudo, rateStarNumber } = this.state;

        const itemId =  movie.transaction;
        const commentType = movie.type === 'series' ? COMMENT_TYPE.SERIES : COMMENT_TYPE.MOVIE;

        if ( this.checkErrors() ) {
            handleLoader( 'profile_loader', 'add' );
            const data = {
                NoCli: getUserInfo().NoCli,
                comment: comment,
                itemId: itemId,
                pseudo: pseudo,
                rateStarNumber: rateStarNumber,
                type: commentType,
            };
            createComment( data )
                .then( res => {
                    if ( ! res.ret ) {
                        this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                    } else {
                        this.setState ({ isCommentDone: true, successMessage: t( 'form:commentSuccess', language ) });
                    }
                    handleLoader( 'profile_loader', 'remove' );
                })
                .catch( error => {
                    console.log( error );
                    this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                    handleLoader( 'profile_loader', 'remove' );
                });
        }
    };

    toogleCommentForm = () => this.setState({ showCommentForm: ! this.state.showCommentForm });

	render() {
		const { isMoviePage, language, movie } = this.props;
        const { comment, isCommentDone, pseudo, rateStarNumber, showCommentForm } = this.state;

		return (
			<>
                {
                    movie &&
                    <div id='profile_container' className={ isMoviePage ? 'movie_comment_page' : 'movie_comment_popup' }>
                        <div id='profile_loader' className='bottom-loader'><Loader type='TailSpin' color='#fba00b' height={100} width={100} /></div>
                        <div className='profile_edit_usual'>
                            <div className='profile_edit_usual_form'>
                                <div className='movie_comment_title'>{ t( 'form:comments', language ) }</div>
                                {
                                    ! showCommentForm && movie.comments && movie.comments.length > 0 ?
                                    <div className='profile_edit_usual_btn comment_box'>
                                        { ! isCommentDone && <button onClick={ this.toogleCommentForm }>{ t( 'form:addComment', language ) }</button> }
                                    </div>
                                    :
                                    <>
                                        {
                                            showCommentForm &&
                                            <div className='profile_edit_usual_btn comment_box_back'>
                                                {
                                                    ! isCommentDone &&
                                                    <button onClick={ this.toogleCommentForm } style={{ backgroundColor: '#aeaeae' }}>
                                                        { t( 'shared:back', language ) }
                                                    </button>
                                                }
                                            </div>
                                        }
                                        <h3>{ t( 'form:yourOpinion', language ) } { movie.type === 'series' ? 'la série' : 'le film' }</h3>
                                        <div className='comment_star_rating_box'>
                                            <div
                                                className='comment_star_box'
                                                onClick={ () => this.handleStarChange( 1 ) }>
                                                { rateStarNumber > 0 ? <i className="fas fa-star"></i> : <i className="far fa-star"></i> }
                                            </div>
                                            <div
                                                className='comment_star_box'
                                                onClick={ () => this.handleStarChange( 2 ) }>
                                                { rateStarNumber > 1 ? <i className="fas fa-star"></i> : <i className="far fa-star"></i> }
                                            </div>
                                            <div
                                                className='comment_star_box'
                                                onClick={ () => this.handleStarChange( 3 ) }>
                                                { rateStarNumber > 2 ? <i className="fas fa-star"></i> : <i className="far fa-star"></i> }
                                            </div>
                                            <div
                                                className='comment_star_box'
                                                onClick={ () => this.handleStarChange( 4 ) }>
                                                { rateStarNumber > 3 ? <i className="fas fa-star"></i> : <i className="far fa-star"></i> }
                                            </div>
                                            <div
                                                className='comment_star_box'
                                                onClick={ () => this.handleStarChange( 5 ) }>
                                                { rateStarNumber > 4 ? <i className="fas fa-star"></i> : <i className="far fa-star"></i> }
                                            </div>
                                        </div>
                                        <div className='profile_edit_usual_input'>
                                            <label htmlFor='pseudo'>{ t( 'form:pseudo', language ) }</label>
                                            <input
                                                type='text'
                                                name='pseudo'
                                                value={ pseudo }
                                                onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                                onChange={ event => this.handleChange( event ) }
                                                />
                                        </div>
                                        <div className='profile_edit_usual_textarea'>
                                            <label htmlFor="textAreaValue">{ t( 'form:comment', language ) }</label>
                                            <textarea
                                                name="comment"
                                                value={ comment }
                                                onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                                onChange={ event => this.handleChange( event ) }
                                                />
                                        </div>
                                        <div className="profile_edit_usual_message">
                                            <div className='profile_edit_usual_error'>{ this.state.errorMessage }</div>
                                            <div className='profile_edit_usual_success'>{ this.state.successMessage }</div>
                                        </div>
                                        <div className='profile_edit_usual_btn comment_box'>
                                            { ! isCommentDone && <button onClick={ this.handleSubmit }>{ t( 'shared:confirm', language ) }</button> }
                                        </div>
                                    </>
                                }
                                {
                                    movie.comments && movie.comments.length > 0 &&
                                    <div className='comment-box-container'>
                                        {
                                            movie.comments.map( ( el, key ) => (
                                                <div className='comment-box' key={ key }>
                                                    <div className='comment-user-box'>
                                                        <div className='comment-avatar-box'>
                                                            <img
                                                                className='comment-avatar'
                                                                src={ el.avatar && el.avatar !== '' ? `${ API_IMAGE }${ el.avatar }.jpg` : IMAGES.avatarDefault }
                                                                alt='avatar'/>
                                                        </div>
                                                        <div className='comment-pseudo'>{ el.pseudo }</div>
                                                        <div className='comment-date'>{ formatTimeSince( el.date, language ) }</div>
                                                    </div>
                                                    <div className='comment-text'>{ el.comment }</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                            </div> 
                        </div>
                    </div>
                }
            </>
		);
	}
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
    userInfoDB: state.userReducer.userInfoDB,
});

export default connect( stateToProps )( MoviePageReview );