import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';
import Loader from 'react-loader-spinner';

import { API_IMAGE, ICONS, IMAGES } from "../../constants";
import { userAction } from "../../redux/actions";
import { addProfile } from "../../services/user";
import { handleLoader, t } from "../../utils";

class PMAdd extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
        };
    };

    checkErrors = () => {
        const { language, profileAddFirstName } = this.props;

        if ( profileAddFirstName === '' ) {
            this.setState ({ errorMessage: t( 'form:firstNameError', language ) });
            return false;
        }
        this.setState ( { errorMessage: '' } );
        return true;
    };

    getAvatarPath = avatar => {
        return avatar && avatar !== '' ? `${ API_IMAGE }${ avatar }.jpg` : IMAGES.avatarDefault;
    };

    handleChange = ( event ) => { 
        const { fetchProfile } = this.props;
        fetchProfile({ type: 'add', firstName: event.target.value });
    };

    handleSubmit = () => {
        const { fetchUserInfo, handlePageChange, language, profileAddAvatarId, profileAddFirstName, userInfoDB } = this.props;

        if ( this.checkErrors() ) {
            handleLoader( 'profile_loader', 'add' );
            const data = {
                avatarId: profileAddAvatarId,
                firstName: profileAddFirstName,
                NoCli: userInfoDB.NoCli,
            };
            addProfile( data )
                .then( res => {
                    if ( ! res.ret ) {
                        this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                    } else {
                        fetchUserInfo( userInfoDB.NoCli );
                        handlePageChange( 'left', 'change' );
                    }
                    handleLoader( 'profile_loader', 'remove' );
                })
                .catch( () => {
                    this.setState ( { errorMessage: t( 'shared:genericError', language ) } );
                    handleLoader( 'profile_loader', 'remove' );
                });
        }
    };

    render() {
        const { handlePageChange, language, profileAddAvatar, profileAddFirstName, userInfoDB } = this.props;
        const { errorMessage, } = this.state;
        
        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_loader'><Loader type='TailSpin' color='#fba00b' height={100} width={100} /></div>
                    <div id='profile_nav'>
                        <span className='profile_nav' onClick={ () => { handlePageChange( 'left', 'change' ) } }>{ t( 'profileManager:title', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span>{ t( 'profileManager:add', language ) }</span>
                    </div>
                    <div className='profile_box_center'>
                        <div id='profile_header'  className='profile_header_title'>
                            <h1>{ t( 'profileManager:add', language ) }</h1>
                        </div>
                    </div>
                    {
                        userInfoDB &&
                        <div className='profile_edit_usual profileManager'>
                            <div className='profile_edit_usual_current'>
                                <div className='user-avatar-box'>
                                    <div className='avatar_box' onClick={ () => { handlePageChange( 'right', 'avatarAdd' ) } }>
                                        <img className='user-avatar' src={ this.getAvatarPath( profileAddAvatar ) } alt='avatar' />
                                        <div className='addon_icon_box'>
                                            <img className='profile_icon addon_icon' src={ ICONS.pen  } alt='icone crayon'/>
                                        </div>
                                    </div> 
                                    <div className='profile_edit_usual'>
                                        <div className='profile_edit_usual_form'>
                                            <div className='profile_edit_usual_input'>
                                                <label htmlFor='firstName'>{ t( 'form:firstName', language ) }</label>
                                                <input
                                                    type='text'
                                                    name='firstName'
                                                    value={ profileAddFirstName }
                                                    onChange={ event => this.handleChange( event ) }
                                                    />
                                            </div> 
                                        </div>
                                        <div className='profile_edit_usual_error'>
                                            { errorMessage }
                                        </div>
                                        <div className='profile_edit_usual_btn tall'>
                                            <button className='collect_flag' id='edit_firstName_submit_btn' onClick={ this.handleSubmit }>
                                                { t( 'shared:save', language ) }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
    profileAddAvatar: state.userReducer.profileAddAvatar,
    profileAddAvatarId: state.userReducer.profileAddAvatarId,
    profileAddFirstName: state.userReducer.profileAddFirstName,
	userInfoDB: state.userReducer.userInfoDB,
});

const dispatchToProps = dispatch => ({
	fetchProfile: data => dispatch(userAction.fetchProfile( data )),
	fetchUserInfo: userId => dispatch(userAction.fetchUserInfo( userId )),
});

export default connect( stateToProps, dispatchToProps )( PMAdd );