import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';
import md5 from 'md5';

import { ICONS } from "../../../constants";
import { userAction } from "../../../redux/actions";
import { t } from "../../../utils";

class PSponsorStep3 extends React.Component {
    constructor(props) {
        super(props);

        const { language, userInfoDB } = this.props;
        let linkId = '';
        if ( userInfoDB && userInfoDB.NoCli ) linkId = md5( userInfoDB.NoCli );

        this.state = {
            copyBtnText: t( 'partner:copyLink', language ),
            link: 'https://www.zone300.com/?utm_campaign='+linkId+'&utm_source=affiliation&utm_medium=link',
        };
    };

    copyLink = () => {
        const { language } = this.props;

        const $link = document.getElementById( 'profile_link' );
        if ( $link ) {
            $link.select();
            document.execCommand( 'copy' );
            this.setState({ copyBtnText: t( 'partner:copiedLink', language ) });
        } 
    };
    
    render() {
        const { language } = this.props;

        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_nav'>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'menu' ) } }>{ t( 'profile:title', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'sponsor' ) } }>{ t( 'profile:partner', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span>{ t( 'partner:successTitle', language ) }</span>
                    </div>
                    <div id='profile_header'>
                        <img className='profile_success_icon' src={ ICONS.check } alt='icone suivant'/>
                    </div>
                    <div className='profile_card_box'>
                        <div className='profile_card half'>
                            <div className='profile_card_title'>{ t( 'partner:successText1', language ) }</div>
                            <div className='profile_card_small'>{ t( 'partner:successText2', language ) }</div>
                            <div className='profile_card_button'>
                                <input id='profile_link' spellCheck="false" value={ this.state.link } readOnly />
                                <button className='collect_flag' id='profile_link_step3' onClick={ this.copyLink }>{ this.state.copyBtnText }</button>
                            </div>
                            <div className='profile_card_button'>
                                <button className='gray' onClick={ () => { this.props.handlePageChange( 'right', 'sponsor' ) } }>
                                    { t( 'partner:successConfirm', language ) }
                                </button>
                            </div>
                        </div>
                        <div className='profile_card half'>
                            <div className='profile_card_title'>{ t( 'partner:info1', language ) }</div>
                            <div className='profile_card_small'>{ t( 'partner:info2', language ) }</div>
                            <div className='profile_card_small'>{ t( 'partner:info3', language ) }</div>
                            <div className='profile_card_small'>{ t( 'partner:info4', language ) }</div>
                            <div className='profile_card_small'>{ t( 'partner:info5', language ) }</div>
                        </div>
                    </div>
				</div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
    userInfoDB : state.userReducer.userInfoDB,
	userPartnerInfoDB: state.userReducer.userPartnerInfoDB,
});

const dispatchToProps = dispatch => ({
	fetchUserPartnerInfo: userId => dispatch( userAction.fetchUserPartnerInfo( userId )),
});

export default connect( stateToProps, dispatchToProps )( PSponsorStep3 );