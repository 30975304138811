import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';
import Loader from 'react-loader-spinner';

import { ICONS } from "../../../constants";
import { userAction } from "../../../redux/actions";
import { updatePartnerInfoStep2 } from "../../../services/user";
import { handleLoader, t } from "../../../utils";

class PSponsorStep2 extends React.Component {
    constructor(props) {
        super(props);

        const { userPartnerInfoDB } = this.props;

        this.state = {
            birthday: ( userPartnerInfoDB && userPartnerInfoDB.DateNaissance ) ? userPartnerInfoDB.DateNaissance : '',
            gender: ( userPartnerInfoDB && userPartnerInfoDB.Genre ) ? userPartnerInfoDB.Genre : '',
            tshirtSize: ( userPartnerInfoDB && userPartnerInfoDB.TailleTshirt ) ? userPartnerInfoDB.TailleTshirt : '',
            iSP: ( userPartnerInfoDB && userPartnerInfoDB.Operateur ) ? userPartnerInfoDB.Operateur : '',
            errorMessage: '',
        };
    };

    componentDidMount() {
        const $buttonMale = document.getElementById( 'button_male' );
        const $buttonFemale = document.getElementById( 'button_female' );
        if ( $buttonMale && this.state.gender === 'Homme' ) $buttonMale.classList.add( 'selected' );
        if ( $buttonFemale && this.state.gender === 'Femme' ) $buttonFemale.classList.add( 'selected' ); 
        
        const $selectTshirtSize = document.getElementById( 'select_tshirtSize' );
        if ( $selectTshirtSize && this.state.tshirtSize !== '' ) $selectTshirtSize.innerHTML = this.state.tshirtSize;

        const $selectISP = document.getElementById( 'select_ISP' );
        if ( $selectISP && this.state.iSP !== '' ) $selectISP.innerHTML = this.state.iSP;
    };

    checkErrors = () => {
        const { language } = this.props;

        if ( this.state.birthday === '' ) {
            this.setState ({ errorMessage: t( 'form:birthdayError', language ) });
            return false;
        }
        if ( this.state.gender === '' ) {
            this.setState ({ errorMessage: t( 'form:genderError', language ) });
            return false;
        }
        if ( this.state.tshirtSize === '' ) {
            this.setState ({ errorMessage: t( 'form:tshirtSizeError', language ) });
            return false;
        }
        if ( this.state.iSP === '' ) {
            this.setState ({ errorMessage: t( 'form:ispError', language ) });
            return false;
        }
        this.setState ({ errorMessage: '' });
        return true;
    };

    handleChange = ( event ) => { 
        const value = event.target.value;
        const name = event.target.name;
        this.setState ( { [name]: value, successMessage: '' } );
    };

    handleChangeGender = ( event ) => {
        const $buttonMale = document.getElementById( 'button_male' );
        const $buttonFemale = document.getElementById( 'button_female' );

        const valueId = event.target.id;
        switch ( valueId ) {
            case 'button_male':
                if ( $buttonMale ) $buttonMale.classList.add( 'selected' );
                if ( $buttonFemale ) $buttonFemale.classList.remove( 'selected' );
                this.setState({ gender: 'Homme' });
                break;
            case 'button_female':
                if ( $buttonFemale ) $buttonFemale.classList.add( 'selected' );
                if ( $buttonMale ) $buttonMale.classList.remove( 'selected' );
                this.setState({ gender: 'Femme' });
                break;
            default:
                break;
        }
    }; 

    handleChangeISP = ( event ) => {
        const $selectISP = document.getElementById( 'select_ISP' );
        const $selectISPArr = [
            { id: 'select_ISP_Bouygues', htmlElement: document.getElementById( 'select_ISP_Bouygues' ) },
            { id: 'select_ISP_Free', htmlElement: document.getElementById( 'select_ISP_Free' ) },
            { id: 'select_ISP_Orange', htmlElement: document.getElementById( 'select_ISP_Orange' ) },
            { id: 'select_ISP_SFR', htmlElement: document.getElementById( 'select_ISP_SFR' ) },
            { id: 'select_ISP_Autres', htmlElement: document.getElementById( 'select_ISP_Autres' ) },
        ];

        const valueId = event.target.id;
        switch ( valueId ) {
            case 'select_ISP':
                $selectISPArr.forEach( el => {
                    if ( el.htmlElement ) el.htmlElement.classList.toggle( 'show' ); 
                });  
                if ( $selectISP ) $selectISP.classList.toggle( 'open' );  
                break;
            case 'select_ISP_Bouygues':
            case 'select_ISP_Free':
            case 'select_ISP_Orange':
            case 'select_ISP_SFR':
            case 'select_ISP_Autres':
                $selectISPArr.forEach( el => {
                    if ( el.htmlElement ) el.htmlElement.classList.remove( 'show' ); 
                });
                if ( $selectISP ) $selectISP.classList.remove( 'open' );
                let ISP = valueId.split( '_' )[ 2 ] ? valueId.split( '_' )[ 2 ] : '';
                if ( ISP ) {
                    if ( ISP === 'Bouygues' ) ISP = 'Bouygues Telecom'; 
                    if ( $selectISP ) $selectISP.innerHTML = ISP;
                    this.setState( { iSP: ISP } ); 
                }  
                break;
            default:
                break;
        }
    };

    handleChangeTshirtSize = ( event ) => {
        const $selectTshirtSize = document.getElementById( 'select_tshirtSize' );
        const $selectTshirtSizeArr = [
            { id: 'select_tshirtSize_S', htmlElement: document.getElementById( 'select_tshirtSize_S' ) },
            { id: 'select_tshirtSize_M', htmlElement: document.getElementById( 'select_tshirtSize_M' ) },
            { id: 'select_tshirtSize_L', htmlElement: document.getElementById( 'select_tshirtSize_L' ) },
            { id: 'select_tshirtSize_XL', htmlElement: document.getElementById( 'select_tshirtSize_XL' ) },
            { id: 'select_tshirtSize_XXL', htmlElement: document.getElementById( 'select_tshirtSize_XXL' ) },
            { id: 'select_tshirtSize_XXXL', htmlElement: document.getElementById( 'select_tshirtSize_XXXL' ) },
        ];

        const valueId = event.target.id;
        switch ( valueId ) {
            case 'select_tshirtSize':
                $selectTshirtSizeArr.forEach( el => {
                    if ( el.htmlElement ) el.htmlElement.classList.toggle( 'show' ); 
                });  
                if ( $selectTshirtSize ) $selectTshirtSize.classList.toggle( 'open' );  
                break;
            case 'select_tshirtSize_S':
            case 'select_tshirtSize_M':
            case 'select_tshirtSize_L':
            case 'select_tshirtSize_XL':
            case 'select_tshirtSize_XXL':
            case 'select_tshirtSize_XXXL':
                $selectTshirtSizeArr.forEach( el => {
                    if ( el.htmlElement ) el.htmlElement.classList.remove( 'show' ); 
                });
                if ( $selectTshirtSize ) $selectTshirtSize.classList.remove( 'open' );
                const tshirtSize = valueId.split( '_' )[ 2 ] ? valueId.split( '_' )[ 2 ] : '';
                if ( tshirtSize ) {
                    if ( $selectTshirtSize ) $selectTshirtSize.innerHTML = tshirtSize;
                    this.setState( { tshirtSize: tshirtSize } ); 
                }  
                break;
            default:
                break;
        }
    };

    handleSubmit = () => {
        const { fetchUserPartnerInfo, language, userInfoDB, userPartnerInfoDB } = this.props;
        const alreadyPartner = ( userPartnerInfoDB.step1Completed === 1 && userPartnerInfoDB.step2Completed === 1 );

        if ( this.checkErrors() ) {
            handleLoader( 'profile_loader', 'add' );
            const data = {
                NoCli: userInfoDB.NoCli,
                birthday: this.state.birthday,
                gender: this.state.gender,
                tshirtSize: this.state.tshirtSize,
                iSP: this.state.iSP,
            };
            updatePartnerInfoStep2( data )
                .then( res => {
                    if ( ! res.ret ) {
                        this.setState ({ errorMessage: t( 'shared:genericError', language ) });       
                    } else {
                        fetchUserPartnerInfo( userInfoDB.NoCli );
                        if ( alreadyPartner ) {
                            this.props.handlePageChange( 'right', 'sponsor' );
                        } else {
                            this.props.handlePageChange( 'right', 'sponsorStep3' );
                        }
                    }
                    handleLoader( 'profile_loader', 'remove' );
                })
                .catch( error => {
                    console.log( error );
                    this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                    handleLoader( 'profile_loader', 'remove' );
                });
        }
    };

    toggleBirthdayHelpAddon = () => {
        const $profileInfoBubbleBirthday = document.getElementById( 'profile_info_bubble_birthday' );
        if ( $profileInfoBubbleBirthday ) $profileInfoBubbleBirthday.classList.toggle( 'show' );
    };
    
    render() {
        const { language } = this.props;

        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_loader'><Loader type='TailSpin' color='#fba00b' height={100} width={100} /></div>
                    <div id='profile_nav'>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'menu' ) } }>{ t( 'profile:title', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'sponsor' ) } }>{ t( 'profile:partner', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span>{ t( 'profile:furtherInfo', language ) }</span>
                    </div>
                    <div className='profile_box_center'>
                        <div id='profile_header' className='profile_header_title'>
                            <h1>{ t( 'profile:furtherInfo', language ) }</h1>
                        </div>
                        <div className='profile_edit_usual'>
                            <div className='profile_edit_usual_form'>
                                <div className='profile_edit_usual_input'>
                                    <label htmlFor='birthday'>
                                        { t( 'form:birthday', language ) }
                                        <span className='profile_info_bubble_box'>
                                            <img className='profile_icon small_icon hover_icon' src={ ICONS.help } alt='icone aide' onClick={ this.toggleBirthdayHelpAddon }/>
                                            <span className='profile_info_bubble' id='profile_info_bubble_birthday' onClick={ this.toggleTelBirthdayAddon }>
                                                { t( 'form:birthdayInfo', language ) }
                                            </span>
                                        </span>
                                    </label>
                                    <input
                                        type='date'
                                        name='birthday'
                                        value={ this.state.birthday }
                                        onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                        onChange={ event => this.handleChange( event ) }
                                        />
                                </div>
                                <div className='profile_edit_custom_select'>
                                    <label>{ t( 'form:gender', language ) }</label>
                                    <div className='profile_edit_custom_select_box'>
                                        <button id='button_male' onClick={ event => this.handleChangeGender( event ) }>{ t( 'form:genderMale', language ) }</button>
                                        <button id='button_female' onClick={ event => this.handleChangeGender( event ) }>{ t( 'form:genderFemale', language ) }</button>
                                    </div>
                                </div>
                                <div className='profile_edit_custom_select'>
                                    <label>{ t( 'form:tshirtSize', language ) }</label>
                                    <div className='profile_select_selected' id='select_tshirtSize' onClick={ event => this.handleChangeTshirtSize( event ) }>{ t( 'form:defaultSelect', language ) }</div>
                                    <div className='profile_select_option' id='select_tshirtSize_S' onClick={ event => this.handleChangeTshirtSize( event ) }>S</div>
                                    <div className='profile_select_option' id='select_tshirtSize_M' onClick={ event => this.handleChangeTshirtSize( event ) }>M</div>
                                    <div className='profile_select_option' id='select_tshirtSize_L' onClick={ event => this.handleChangeTshirtSize( event ) }>L</div>
                                    <div className='profile_select_option' id='select_tshirtSize_XL' onClick={ event => this.handleChangeTshirtSize( event ) }>XL</div>
                                    <div className='profile_select_option' id='select_tshirtSize_XXL' onClick={ event => this.handleChangeTshirtSize( event ) }>XXL</div>
                                    <div className='profile_select_option last' id='select_tshirtSize_XXXL' onClick={ event => this.handleChangeTshirtSize( event ) }>XXXL</div>
                                </div>
                                <div className='profile_edit_custom_select'>
                                    <label>{ t( 'form:isp', language ) }</label>
                                    <div className='profile_select_selected' id='select_ISP' onClick={ event => this.handleChangeISP( event ) }>{ t( 'form:defaultSelect', language ) }</div>
                                    <div className='profile_select_option' id='select_ISP_Bouygues' onClick={ event => this.handleChangeISP( event ) }>Bouygues Telecom</div>
                                    <div className='profile_select_option' id='select_ISP_Free' onClick={ event => this.handleChangeISP( event ) }>Free</div>
                                    <div className='profile_select_option' id='select_ISP_Orange' onClick={ event => this.handleChangeISP( event ) }>Orange</div>
                                    <div className='profile_select_option' id='select_ISP_SFR' onClick={ event => this.handleChangeISP( event ) }>SFR</div>
                                    <div className='profile_select_option last' id='select_ISP_Autres' onClick={ event => this.handleChangeISP( event ) }>{ t( 'shared:other', language ) }</div>
                                </div>
                            </div>
                            <div className='profile_edit_usual_error'>
                                { this.state.errorMessage }
                            </div>
                            <div className='profile_edit_usual_btn'>
                                <button className='collect_flag' id='edit_email_submit_btn' onClick={ this.handleSubmit }>
                                    { t( 'shared:continue', language ) }
                                </button>
                            </div>
                        </div>
                    </div>
				</div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
    userInfoDB : state.userReducer.userInfoDB,
	userPartnerInfoDB: state.userReducer.userPartnerInfoDB,
});

const dispatchToProps = dispatch => ({
	fetchUserPartnerInfo: userId => dispatch( userAction.fetchUserPartnerInfo( userId )),
});

export default connect( stateToProps, dispatchToProps )( PSponsorStep2 );