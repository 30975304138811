import React, { useState } from "react";

import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { POPUP_REDUCER } from "../../../../constants";
import { stripeCreateIntent, stripeTreatSuccess } from "../../../../services/payment";
import { activeCampaignEvent, gtmEvent } from "../../../../services/tracking";
import { handleLoader, t } from "../../../../utils";

const StripeCheckoutForm = ( props ) => {
    const [succeeded, setSucceeded] = useState(null);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const stripe = useStripe();
    const elements = useElements();
    const language = props.language;

    const style = {
        base: { fontSize: '20px', color: '#FFF', '::placeholder': { color: '#FFF' } },
        invalid: { color: ' #E33939' },
    };

    const handleChange = async ( event ) => {
        setDisabled( event.empty );
        setError( event.error ? event.error.message : '' );
    };

    const handleSubmit = async ( event ) => {
        event.preventDefault();
        const cardNumberElement = elements.getElement( CardNumberElement );
        setProcessing( true );
        handleLoader( 'ils_loader', 'add' );

        let data = { currency: props.currency, NoCli: props.userId, productId: props.productId, productType: props.productType };
        if ( props.codeId ) data.codeId = props.codeId;

        stripeCreateIntent( data )
            .then( res => {
                if ( ! res.ret ) {
                    switch( res.data ) {
                        case 'user unknown':
                            setError( t( 'form:userUnknown', language ) );
                            break;
                        case 'product unknown':
                            setError( t( 'form:productUnknown', language ) );
                            break;
                        default:
                            setError( t( 'shared:genericError', language ) );     
                    }
                    setProcessing( false );
                    handleLoader( 'ils_loader', 'remove' );
                    return;
                }

                stripe.confirmCardPayment( res.data.clientSecret, { payment_method: { card: cardNumberElement } } )
                    .then( res2 => {
                        if ( res2.error ) {
                            setError( `${ t( 'payment:error', language ) } : ${ res2.error.message }` );
                            setProcessing( false );
                            handleLoader( 'ils_loader', 'remove' );
                        } else {
                            if ( res2.paymentIntent ) {
                                if ( res2.paymentIntent.id ) data.paymentId = res2.paymentIntent.id;
                                if ( res2.paymentIntent.amount ) data.amount = res2.paymentIntent.amount;
                                if ( res2.paymentIntent.currency ) data.currency = res2.paymentIntent.currency;
                            }
                            
                            stripeTreatSuccess( data )
                                .then( res3 => {
                                    if ( ! res3.ret ) {
                                        setError( t( 'shared:genericError', language ) );     
                                        setProcessing( false );
                                        handleLoader( 'ils_loader', 'remove' );
                                        return;
                                    }

                                    setError( null );
                                    setProcessing( false );
                                    handleLoader( 'ils_loader', 'remove' );
                                    setSucceeded( t( 'payment:success', language ) );
                                    if ( res3.data.GTMEvent ) gtmEvent( res3.data.GTMEvent );
                                    if ( res3.data.email && res3.data.ACEventName ) activeCampaignEvent({ email: res3.data.email, eventName: res3.data.ACEventName, eventDetails: 'browse' });
                                    props.popupOpen( POPUP_REDUCER.POPUP_PAYMENT_SUCCESS );
                                })
                        }
                    });
            })
            .catch( error => {
                console.log( error );
                setError( t( 'shared:genericError', language ) );
                setProcessing( false );
                handleLoader( 'ils_loader', 'remove' );
            });
    };

    return (
        <form onSubmit={ handleSubmit } className="local_stripe_form">
            <label>{ t( 'payment:cardName', language ) }</label>
            <div>
                <input type="text" name="name" className="local_stripe_input"/>
            </div>
            <label>{ t( 'payment:cardInfo', language ) }</label>
            <CardNumberElement
                className="local_stripe_card"
                options={{ style: style }}
                onChange={ handleChange } />
            <div className="local_stripe_card_info_box">
                <CardExpiryElement
                    className="local_stripe_expiry"
                    options={{ style: style }}
                    onChange={ handleChange } />
                <CardCvcElement
                    className="local_stripe_cvc"
                    options={{ style: style }}
                    onChange={ handleChange } />
            </div>
            <div className="local_stripe_results">
                <div className="local_stripe_error">{ error }</div>
                <div className="local_stripe_success">{ succeeded }</div>
            </div>
            <button className="local_stripe_button" type="submit" disabled={ ! stripe || processing || disabled || succeeded }>
                { `${ t( 'payment:payConfirm', language ) } ${ props.priceStgCurrent }` } 
            </button>
        </form>
    );
};

export default StripeCheckoutForm;