import React from 'react';
import { connect } from "react-redux";

import HomeList from "./HomeList";

import { routingAction } from "../../redux/actions";
import { isGuest } from "../../utils";

class Favorite extends React.Component {
    componentDidMount() {
        if ( isGuest() ) { window.location.href = "/offres/"; return; } 

        const { fetchCurrentRoutingByUrl } = this.props;
        fetchCurrentRoutingByUrl( 'ma-liste' );
  	};

    render() {
        return (
			<div className="homepage-container">
				<HomeList />
			</div>
        )
    }
}

const stateToProps = state => ({});

const dispatchToProps = dispatch => ({
	fetchCurrentRoutingByUrl: url => dispatch( routingAction.fetchCurrentRoutingByUrl( url )),
});

export default connect( stateToProps, dispatchToProps )( Favorite );