import React from 'react';
import { connect } from "react-redux";

import ProductDetails from "./ProductDetails";
import ProductsMozaic from "./ProductsMozaic";

import { routingAction, storeAction } from "../../redux/actions";
import { getUserInfo } from "../../utils";

class StoreDetails extends React.Component {
    componentDidMount() {
        const fixParamArr = window.location.toString().split( '/browse/' )[ 1 ].split( '#/boutique/' );
		if ( fixParamArr && fixParamArr[ 0 ] === '' ) {
			const fixParam = fixParamArr[ 1 ].split( '?' )[ 0 ];
			window.location.assign( "/browse/?boutique=" + fixParam + "/#/boutique/" + fixParam );
			return;
		} 

        const { fetchCurrentRoutingByUrl, fetchStoreSearchedProducts } = this.props;
        const userId = getUserInfo().NoCli;
        fetchCurrentRoutingByUrl( 'boutique-details' );

        const paramsArr = window.location.toString().split( '/browse/' )[ 1 ].split( '#/boutique/' );
        fetchStoreSearchedProducts( paramsArr[ 1 ].split( '?' )[ 0 ], userId );
  	};

    render() {
        const { storeSearchedProducts } = this.props;

        return (
			<div className="homepage-container">
                { storeSearchedProducts && storeSearchedProducts.type === 'product' && <ProductDetails /> }
                { storeSearchedProducts && [ 'categorie', 'tag' ].includes( storeSearchedProducts.type ) && <ProductsMozaic /> }
			</div>
        )
    }
}

const stateToProps = state => ({
    storeSearchedProducts: state.storeReducer.storeSearchedProducts,
});

const dispatchToProps = dispatch => ({
	fetchCurrentRoutingByUrl: url => dispatch(routingAction.fetchCurrentRoutingByUrl( url )),
    fetchStoreSearchedProducts: ( searchText, userId ) => dispatch(storeAction.fetchStoreSearchedProducts( searchText, userId )),
});

export default connect( stateToProps, dispatchToProps )( StoreDetails );