import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';
import Loader from 'react-loader-spinner';

import { ICONS } from "../../../constants";
import { userAction } from "../../../redux/actions";
import { updatePartnerInfoStep1 } from "../../../services/user";
import { handleLoader, t } from "../../../utils";

class PSponsorStep1 extends React.Component {
    constructor(props) {
        super(props);
        
        const { userPartnerInfoDB } = this.props;

        this.state = {
            lastName: ( userPartnerInfoDB && userPartnerInfoDB.Nom ) ? userPartnerInfoDB.Nom : '',
            firstName: ( userPartnerInfoDB && userPartnerInfoDB.Prenom ) ? userPartnerInfoDB.Prenom : '',
            adress1: ( userPartnerInfoDB && userPartnerInfoDB.Adresse1 ) ? userPartnerInfoDB.Adresse1 : '',
            adress2: ( userPartnerInfoDB && userPartnerInfoDB.Adresse2 ) ? userPartnerInfoDB.Adresse2 : '',
            city: ( userPartnerInfoDB && userPartnerInfoDB.Ville ) ? userPartnerInfoDB.Ville : '',
            region: ( userPartnerInfoDB && userPartnerInfoDB.Region ) ? userPartnerInfoDB.Region : '',
            postalCode: ( userPartnerInfoDB && userPartnerInfoDB.CodePostal ) ? userPartnerInfoDB.CodePostal : '',
            country: ( userPartnerInfoDB && userPartnerInfoDB.Pays ) ? userPartnerInfoDB.Pays : '',
            phoneNumber: ( userPartnerInfoDB && userPartnerInfoDB.Telephone ) ? userPartnerInfoDB.Telephone : '',
            errorMessage: '',
        };
    };

    checkErrors = () => {
        const { language } = this.props;

        if ( this.state.lastName === '' ) {
            this.setState ({ errorMessage: t( 'form:lastNameError', language ) });
            return false;
        }
        if ( this.state.firstName === '' ) {
            this.setState ({ errorMessage: t( 'form:firstNameError', language ) });
            return false;
        }
        if ( this.state.adress1 === '' ) {
            this.setState ({ errorMessage: t( 'form:addressError', language ) });
            return false;
        }
        if ( this.state.city === '' ) {
            this.setState ({ errorMessage: t( 'form:cityError', language ) });
            return false;
        }
        if ( this.state.postalCode === '' ) {
            this.setState ({ errorMessage: t( 'form:postalCodeError', language ) });
            return false;
        }
        if ( this.state.country === '' ) {
            this.setState ({ errorMessage: t( 'form:countryError', language ) });
            return false;
        }
        if ( this.state.phoneNumber === '' ) {
            this.setState ({ errorMessage: t( 'form:phoneError', language ) });
            return false;
        }
        this.setState ({ errorMessage: '' });
        return true;
    };

    handleChange = ( event ) => { 
        const value = event.target.value;
        const name = event.target.name;
        this.setState ( { [name]: value, successMessage: '' } );
    };

    handleSubmit = () => {
        const { fetchUserPartnerInfo, language, userInfoDB } = this.props;
        if ( this.checkErrors() ) {
            handleLoader( 'profile_loader', 'add' );
            const data = {
                NoCli: userInfoDB.NoCli,
                lastName: this.state.lastName,
                firstName: this.state.firstName,
                adress1: this.state.adress1,
                adress2: this.state.adress2,
                city: this.state.city,
                region: this.state.region,
                postalCode: this.state.postalCode,
                country: this.state.country,
                phoneNumber: this.state.phoneNumber,
            };
            updatePartnerInfoStep1( data )
                .then( res => {
                    if ( ! res.ret ) {
                        this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                    } else {
                        fetchUserPartnerInfo( userInfoDB.NoCli );
                        this.props.handlePageChange( 'right', 'sponsorStep2' );
                    }
                    handleLoader( 'profile_loader', 'remove' );
                })
                .catch( error => {
                    console.log( error );
                    this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                    handleLoader( 'profile_loader', 'remove' );
                });
        }
    };

    toggleTelHelpAddon = () => {
        const $profileInfoBubbleTel = document.getElementById( 'profile_info_bubble_tel' );
        if ( $profileInfoBubbleTel ) $profileInfoBubbleTel.classList.toggle( 'show' );
    };
    
    render() {
        const { language } = this.props;

        const $content =
            <>
            <div id='profile_container'>
                <div id='profile_loader'><Loader type='TailSpin' color='#fba00b' height={100} width={100} /></div>
                <div id='profile_nav'>
                    <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'menu' ) } }>{ t( 'profile:title', language ) }</span>
                    <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                    <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'sponsor' ) } }>{ t( 'profile:partner', language ) }</span>
                    <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                    <span>{ t( 'profile:personalInfo', language ) }</span>
                </div>
                <div className='profile_box_center'>
                    <div id='profile_header' className='profile_header_title'>
                        <h1>{ t( 'profile:personalInfo', language ) }</h1>
                    </div>
                    <div className='profile_edit_usual'>
                        <div className='profile_edit_usual_form'>
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='lastName'>{ t( 'form:lastName', language ) }</label>
                                <input
                                    type='text'
                                    name='lastName'
                                    value={ this.state.lastName }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                            </div> 
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='firstName'>{ t( 'form:firstName', language ) }</label>
                                <input
                                    type='text'
                                    name='firstName'
                                    value={ this.state.firstName }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                            </div> 
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='adress1'>{ t( 'form:address1', language ) }</label>
                                <input
                                    type='text'
                                    name='adress1'
                                    value={ this.state.adress1 }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                                <div className='profile_edit_usual_input_info'>{ t( 'form:address1Info', language ) }</div>    
                            </div> 
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='adress2'>{ t( 'form:address2', language ) }</label>
                                <input
                                    type='text'
                                    name='adress2'
                                    value={ this.state.adress2 }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                                <div className='profile_edit_usual_input_info'>{ t( 'form:address2Info', language ) }</div>    
                            </div> 
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='city'>{ t( 'form:city', language ) }</label>
                                <input
                                    type='text'
                                    name='city'
                                    value={ this.state.city }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                            </div> 
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='region'>{ t( 'form:region', language ) }</label>
                                <input
                                    type='text'
                                    name='region'
                                    value={ this.state.region }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                            </div> 
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='postalCode'>{ t( 'form:postalCode', language ) }</label>
                                <input
                                    type='text'
                                    name='postalCode'
                                    value={ this.state.postalCode }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                            </div> 
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='country'>{ t( 'form:country', language ) }</label>
                                <input
                                    type='text'
                                    name='country'
                                    value={ this.state.country }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                            </div> 
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='phoneNumber'>
                                    { t( 'form:phone', language ) }
                                    <span className='profile_info_bubble_box'>
                                        <img className='profile_icon small_icon hover_icon' src={ ICONS.help } alt='icone aide' onClick={ this.toggleTelHelpAddon }/>
                                        <span className='profile_info_bubble' id='profile_info_bubble_tel' onClick={ this.toggleTelHelpAddon }>
                                            { t( 'form:phoneInfo', language ) }
                                        </span>
                                    </span>
                                </label>
                                <input
                                    type='tel'
                                    name='phoneNumber'
                                    value={ this.state.phoneNumber }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                            </div> 
                        </div>
                        <div className='profile_edit_usual_error'>
                            { this.state.errorMessage }
                        </div>
                        <div className='profile_edit_usual_btn'>
                            <button className='collect_flag' id='edit_email_submit_btn' onClick={ this.handleSubmit }>
                                { t( 'shared:continue', language ) }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
    userInfoDB : state.userReducer.userInfoDB,
	userPartnerInfoDB: state.userReducer.userPartnerInfoDB,
});

const dispatchToProps = dispatch => ({
	fetchUserPartnerInfo: userId => dispatch( userAction.fetchUserPartnerInfo( userId )),
});

export default connect( stateToProps, dispatchToProps )( PSponsorStep1 );