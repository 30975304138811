import React from "react";
import { connect } from "react-redux";

import { t } from "../../utils";
 
class CountDownClassic extends React.Component {
    constructor(props) {
		super(props);

        const diff = this.props.onlineDate - Math.floor( Date.now() / 1000 );
        if ( diff > 0 ) {
            const byDay = 24 * 60 * 60;
            const byHour = 60 * 60;
            const byMinute = 60;

            const dayStg = Math.floor( diff / byDay ).toString().padStart( 2, '0' );
            const hourStg = Math.floor( ( diff % byDay  ) / byHour ).toString().padStart( 2, '0' );
            const minuteStg = Math.floor( ( diff % byHour  ) / byMinute ).toString().padStart( 2, '0' );
            const secondStg = ( diff % byMinute  ).toString().padStart( 2, '0' );

            this.state = {
                day1: parseInt(dayStg[ 0 ]),
                day2: parseInt(dayStg[ 1 ]),
                hour1: parseInt(hourStg[ 0 ]),
                hour2: parseInt(hourStg[ 1 ]),
                minute1: parseInt(minuteStg[ 0 ]),
                minute2: parseInt(minuteStg[ 1 ]),
                second1: parseInt(secondStg[ 0 ]),
                second2: parseInt(secondStg[ 1 ]),
            };
        } else {
            this.state = {
                day1: 0,
                day2: 0,
                hour1: 0,
                hour2: 0,
                minute1: 0,
                minute2: 0,
                second1: 0,
                second2: 0,
            };
        }
		this.animeClockInterval = setInterval( () => this.updateTime(), 1000);
	};

    componentWillUnmount() {
		clearInterval( this.animeClockInterval );
	};

    updateTime = () => {
        const { day1, day2, hour1, hour2, minute1, minute2, second1, second2 } = this.state;

        if ( second2 === 0 ) {
            if ( second1 === 0 ) {
                if ( minute2 === 0 ) {
                    if ( minute1 === 0 ) {
                        if ( hour2 === 0 ) {
                            if ( hour1 === 0 ) {
                                if ( day2 === 0 ) {
                                    if ( day1 === 0 ) {
                                        this.setState({ day1: 0, day2: 0, hour1: 0, hour2: 0, minute1: 0, minute2: 0, second1: 0, second2: 0 });
                                        clearInterval( this.animeClockInterval );
                                    } else {
                                        this.setState({ day1: day1 - 1, day2: 9, hour1: 2, hour2: 3, minute1: 5, minute2: 9, second1: 5, second2: 9 });
                                    }
                                } else {
                                    this.setState({ day2: day2 - 1, hour1: 2, hour2: 3, minute1: 5, minute2: 9, second1: 5, second2: 9 });
                                }
                            } else {
                                this.setState({ hour1: hour1 - 1, hour2: 9, minute1: 5, minute2: 9, second1: 5, second2: 9 });
                            }
                        } else {
                            this.setState({ hour2: hour2 - 1, minute1: 5, minute2: 9, second1: 5, second2: 9 });
                        }
                    } else {
                        this.setState({ minute1: minute1 - 1, minute2: 9, second1: 5, second2: 9 });
                    }
                } else {
                    this.setState({ minute2: minute2 - 1, second1: 5, second2: 9 });
                }
            } else {
                this.setState({ second1: second1 - 1, second2: 9 });
            }
        } else {
            this.setState({ second2: second2 - 1 });
        }
    };

	render() {
		const { day1, day2, hour1, hour2, minute1, minute2, second1, second2 } = this.state;
        const { language } = this.props;

		return (
            <div className="soon-clock">
                <div className="soon-box">
                    <div className="soon-number-box">
                        <div className="first">
                            <div className="number">{ day1 }</div>
                        </div>
                        <div className="second">
                            <div className="number">{ day2 }</div>
                        </div>
                    </div>
                    <div className="soon-label-box">{ t( 'clock:days', language ) }</div>
                </div>
                <div className="tick">:</div>
                <div className="soon-box">
                    <div className="soon-number-box">
                        <div className="first">
                            <div className="number">{ hour1 }</div>
                        </div>
                        <div className="second">
                            <div className="number">{ hour2 }</div>
                        </div>
                    </div>
                    <div className="soon-label-box">{ t( 'clock:hours', language ) }</div>
                </div>
                <div className="tick">:</div>
                <div className="soon-box">
                    <div className="soon-number-box">
                        <div className="first">
                            <div className="number">{ minute1 }</div>
                        </div>
                        <div className="second">
                            <div className="number">{ minute2 }</div>
                        </div>
                    </div>
                    <div className="soon-label-box">{ t( 'clock:minutes', language ) }</div>
                </div>
                <div className="tick">:</div>
                <div className="soon-box">
                    <div className="soon-number-box">
                        <div className="first">
                            <div className="number">{ second1 }</div>
                        </div>
                        <div className="second">
                            <div className="number">{ second2 }</div>
                        </div>
                    </div>
                    <div className="soon-label-box">{ t( 'clock:seconds', language ) }</div>
                </div>
            </div>
		);
	}
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
});

export default connect( stateToProps )( CountDownClassic );