import { MOVIES_REDUCER } from "../../constants";

const initialState = {
	catsList: [],
	completedMovies: [],
	favoriteMovies: [],
	forUserMovies: [],
	freeMovieHeader: [],
	momentMovies: [],
	mostViewedMovies: [],
	newForUserMovies: [],
	newMovies: [],
	pageMovies: [],
	pinnedFirstMovies: [],
	pinnedSecondMovies: [],
	platformConfig: {},
	preferencesFishingList: [],
	preferencesHuntingList: [],
	progressMovies: [],
	searchedIframeMovies: [],
	searchedMovies: [],
	searchedMoviesDone: false,
	searchProposals: [],
	seriesLocal: [],
	sliderMovies: [],
	soonAvailableHeader: [],
	soonAvailableHeaderDone: false,
	spotlightedMovies: [],
};

export default (state = initialState, action) => {
	const newState = Object.assign({}, state);

	switch (action.type) {
		case MOVIES_REDUCER.FETCH_CATS_LIST:
			newState.catsList = action.payload;
			return newState;
		case MOVIES_REDUCER.FETCH_FAVORITES_MOVIES:
			newState.favoriteMovies = action.payload;
			return newState;	
		case MOVIES_REDUCER.FETCH_FOR_USER_MOVIES:
			newState.forUserMovies = action.payload;
			return newState;	
		case MOVIES_REDUCER.FETCH_FREE_MOVIE_HEADER:
			newState.freeMovieHeader = action.payload;
			return newState;
		case MOVIES_REDUCER.FETCH_MOMENT_MOVIES:
			newState.momentMovies = action.payload;
			return newState;
		case MOVIES_REDUCER.FETCH_MOST_VIEWED_MOVIES:
			newState.mostViewedMovies = action.payload;
			return newState;
		case MOVIES_REDUCER.FETCH_NEW_FOR_USER_MOVIES:
			newState.newForUserMovies = action.payload;
			return newState;
		case MOVIES_REDUCER.FETCH_NEW_MOVIES:
			newState.newMovies = action.payload;
			return newState;
		case MOVIES_REDUCER.FETCH_PAGE_MOVIES:
			newState.pageMovies = action.payload;
			return newState;
		case MOVIES_REDUCER.FETCH_PINNED_FIRST_MOVIES:
			newState.pinnedFirstMovies = action.payload.pinnedFirst;
			return newState;
		case MOVIES_REDUCER.FETCH_PINNED_SECOND_MOVIES:
			newState.pinnedSecondMovies = action.payload.pinnedSecond;
			return newState;
		case MOVIES_REDUCER.FETCH_PINNED_MOVIES:
			newState.pinnedFirstMovies = action.payload.pinnedFirst;
			newState.pinnedSecondMovies = action.payload.pinnedSecond;
			return newState;
		case MOVIES_REDUCER.FETCH_PLATFORM_CONFIG:
			newState.platformConfig = action.payload;
			return newState;	
		case MOVIES_REDUCER.FETCH_PREFERENCES_LIST:
			newState.preferencesFishingList = action.payload.fishing;
			newState.preferencesHuntingList = action.payload.hunting;
			return newState;
		case MOVIES_REDUCER.FETCH_PROGRESS_MOVIES:
			newState.completedMovies = action.payload.completed;
			newState.progressMovies = action.payload.inProgress;
			return newState;
		case MOVIES_REDUCER.FETCH_SEARCHED_IFRAME_MOVIES:
			newState.searchedIframeMovies = action.payload;
			return newState;	
		case MOVIES_REDUCER.FETCH_SEARCHED_MOVIES:
			newState.searchedMovies = action.payload;
			newState.searchedMoviesDone = true;
			return newState;	
		case MOVIES_REDUCER.FETCH_SEARCH_PROPOSALS:
			newState.searchProposals = action.payload;
			return newState;	
		case MOVIES_REDUCER.FETCH_SERIES_LOCAL:
			newState.seriesLocal = action.payload
			return newState;	
		case MOVIES_REDUCER.FETCH_SLIDER_MOVIES:
			newState.sliderMovies = action.payload;
			return newState;
		case MOVIES_REDUCER.FETCH_SOON_AVAILABLE_HEADER:
			newState.soonAvailableHeader = action.payload;
			newState.soonAvailableHeaderDone = true;
			return newState;
		case MOVIES_REDUCER.FETCH_SPOTLIGHTED_MOVIES:
			newState.spotlightedMovies = action.payload;
			return newState;
		default:
			return state;
	}
};
