import React from "react";
import { connect } from "react-redux";

import { DATA_VLOG, DATA_VLOG_ALT, POPUP_REDUCER } from "../../constants";
import { popupAction } from '../../redux/actions';
import { updateUserPaymentStatus } from "../../services/user";
import { getUserInfo, isGuest, t } from "../../utils";

class Footer extends React.Component {
    goToChangePaymentPage = () => {
        const { changePaymentToken } = this.props;
        if ( changePaymentToken && changePaymentToken !== '' ) {
            updateUserPaymentStatus( `?token=${ changePaymentToken }&type=discarded` );
            window.location.href = "/modifier-paiement/?session_id=" + changePaymentToken;
        } 
	};

    render() {
        const { language, popupOpen, userDiscarded } = this.props;

		return (
            <>
                {
                    isGuest() &&
                    <>
                        <div id="advertisement_guest" className="collect_flag">
                            <h3>{ t( 'freemium:info', language ) }</h3>
                            <a className="btn btn-sm btn-secondary btnOffer" href='/offres/'>{ t( 'freemium:subscribe', language ) }</a>
                            <div
                                className="btn btn-sm btn-secondary btnLogin"
                                onClick={ () => {
                                    if ( getUserInfo().NoCli ) {
                                        localStorage.removeItem( DATA_VLOG );
                                        localStorage.removeItem( DATA_VLOG_ALT );
                                        window.location.reload();
                                    } else {
                                        popupOpen( POPUP_REDUCER.POPUP_LOGIN );
                                    }
                                }}>
                                { getUserInfo().NoCli ? t( 'freemium:disconnect', language ) : t( 'freemium:connect', language ) }
                            </div>
                        </div>
                        <div id="advertisement_guest_mobile" className="collect_flag">
                            <h3>{ t( 'freemium:info', language ) }</h3>
                            <div className="advertisement_guest_mobile_btns">
                                <a className="btn btn-sm btn-secondary btnOffer" href='/offres/'>{ t( 'freemium:subscribe', language ) }</a>
                                <div
                                    className="btn btn-sm btn-secondary btnLogin"
                                    onClick={ () => {
                                        if ( getUserInfo().NoCli ) {
                                            localStorage.removeItem( DATA_VLOG );
                                            localStorage.removeItem( DATA_VLOG_ALT );
                                            window.location.reload();
                                        } else {
                                            popupOpen( POPUP_REDUCER.POPUP_LOGIN );
                                        }
                                    }}>
                                    { getUserInfo().NoCli ? t( 'freemium:disconnect', language ) : t( 'freemium:connect', language ) }
                                </div>
                            </div>
                        </div>
                        {/* {
                            themeId !== null &&
                            <div id="advertisement_guest_bottom" className="collect_flag">
                                <h3>Et bien plus encore !</h3>
                                <div>Zone300 propose des centaines de films en illimité et sans publicité. A regarder partout, tout le temps et sans engagement.</div>
                                <a className="btn btn-sm btn-secondary btnOffer" href= '/offres/'>ABONNEZ-VOUS</a>
                            </div>
                        }    */}
                    </>
                }
                {
                    userDiscarded &&
                    <>
                        <div id="advertisement_discarded" className="collect_flag">
                            <div
                                className="btn btn-sm btn-secondary btnReactive"
                                onClick={this.goToChangePaymentPage}>
                                { t( 'freemium:reactivateAdds', language ) }
                            </div>
                        </div> 
                    </>
                }
                <footer className="footer-container">
                    <nav className="navbar navbar-light bg-faded">
                        <p className="text-muted lead" href="#">
                            <a className="navbar-brand text-danger font-weight-bold" href="/">
                                Zone300
                            </a>
                            &copy;2018-2022
                        </p>
                    </nav>
                </footer>
            </>
		);
	}
}

const stateToProps = state => ({
    changePaymentToken: state.userReducer.changePaymentToken,
    language: state.translatingReducer.language,
	themeId: state.routingReducer.themeId,
    userDiscarded: state.userReducer.userDiscarded,
});

const dispatchToProps = dispatch => ({
	popupOpen: popupName => dispatch(popupAction.popupOpen( popupName )),
});

export default connect( stateToProps, dispatchToProps )( Footer );