import React from 'react';
import { connect } from "react-redux";

import ListRows from "./ListRows";

import { routingAction, userAction } from "../../redux/actions";

class StoreHome extends React.Component {
    componentDidMount() {
        const { fetchCurrentRoutingByUrl, fetchUserGeoInfo } = this.props;
        fetchCurrentRoutingByUrl( 'boutique' );
        fetchUserGeoInfo();
  	};

    render() {
        return (
			<div className="homepage-container">
                <ListRows />
			</div>
        )
    }
}

const stateToProps = state => ({});

const dispatchToProps = dispatch => ({
	fetchCurrentRoutingByUrl: url => dispatch(routingAction.fetchCurrentRoutingByUrl( url )),
    fetchUserGeoInfo: () => dispatch(userAction.fetchUserGeoInfo()),
});

export default connect( stateToProps, dispatchToProps )( StoreHome );