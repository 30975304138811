import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

import Completed from '../../pages/Completed';
import Favorites from '../../pages/Favorites';
import Fishing from '../../pages/Fishing';
import Home from '../../pages/Home';
import Hunting from '../../pages/Hunting';
import IframeComponent from '../../pages/IframeComponent';
import Playlist from "../../pages/Playlist";
import Preferences from "../../pages/Preferences";
import Profile from "../../pages/Profile";
import ProfileManager from "../../pages/ProfileManager";
import Progress from '../../pages/Progress';
import Search from '../../pages/Search';
import StoreCart from '../../pages/StoreCart';
import StoreDetails from '../../pages/StoreDetails';
import StoreHome from '../../pages/StoreHome';
import Training from "../../pages/Training";
import TrainingDetails from "../../pages/TrainingDetails";

function getConfirmation( message, callback ) {
	const allowTransition = window.confirm( message );
	callback( allowTransition );
};

function fixParams() {
	const url = window.location.toString();

	const fixParamArr = url.split( '#/continu/' );
	if ( fixParamArr[ 1 ] && fixParamArr[ 1 ] !== '' && fixParamArr[ 1 ][ 0 ] === '&' ) {
		window.location.assign(  `/browse/?continu/#/continu/?${ fixParamArr[ 1 ].substring( 1 ) }` );
	}

	if ( url.includes( '#/search/' ) ) {
		const fixParam_ = url.split( '/browse/' )[ 1 ].split( '#/search/' );
		if ( fixParam_[ 0 ].split( '%2F&' )[ 1 ] ) {
			window.location.assign( `/browse/${ fixParam_[ 0 ].split( '%2F&' )[ 0 ] }/#/search/${ fixParam_[ 0 ].split( '%2F&' )[ 0 ].substring(8) }` );
		} else if ( fixParam_[ 0 ].split( '&fb' )[ 1 ] ) {
			window.location.assign( `/browse/${ fixParam_[ 0 ].split( '/&fb' )[ 0 ] }/#/search/${ fixParam_[ 0 ].split( '/&fb' )[ 0 ].substring(8) }` );
		}  
	} else if ( url.includes( '?search=' ) && url.split( '?search=' )[ 1 ].includes( '%2F' ) ) {
		const fixParam_ = url.split( '?search=' )[ 1 ].split( '%2F' )[ 0 ];
		if ( fixParam_ ) window.location.assign( `/browse/?search=${ fixParam_ }/#/search/${ fixParam_ }` );
	}
};

fixParams();

const Router = () => (
	<HashRouter getUserConfirmation={getConfirmation} forceRefresh={true} basename='/browse'>
    	<Switch>
      		<Route path="/" exact component={ Home } />
      		<Route path="/continu/" exact component={ Playlist } />
	    	<Route path="/chasse/" component={ Hunting } />
	    	<Route path="/peche/" component={ Fishing } />
	    	<Route path="/ma-liste/" component={ Favorites } />
	    	<Route path="/mes-films-en-cours/" component={ Progress } />
	    	<Route path="/mes-films-termines/" component={ Completed } />
      		<Route path="/search/:searchInput" exact component={ Search } />
	    	<Route path="/mon-compte/" component={ Profile } /> 
	    	<Route path="/mes-profils/" component={ ProfileManager } /> 
	    	<Route path="/preferences/" component={ Preferences } /> 
	    	<Route path="/formations/" exact component={ Training } /> 
	    	<Route path="/formations/:trainingName" component={ TrainingDetails } /> 
	    	<Route path="/boutique/" exact component={ StoreHome } /> 
	    	<Route path="/boutique/:productName" component={ StoreDetails } /> 
	    	<Route path="/mon-panier/" exact component={ StoreCart } />  
			<Route path="/iframe/:iframeLink" exact component={ IframeComponent } />
    	</Switch>
  	</HashRouter>
);

export default Router;