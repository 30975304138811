import React from "react";
import { connect } from "react-redux";

import MozaicRender from "../../components/tools/MozaicRender";

import { moviesAction } from "../../redux/actions";
import { getUserInfo, t } from "../../utils";

class HomeList extends React.Component {
	componentDidMount() {
		const { fetchProgressMovies, profileVersion } = this.props;
		const userId = getUserInfo().NoCli;
		fetchProgressMovies( 0, userId, profileVersion );
	};

	render() {
		const { completedMovies, language } = this.props;

		return (
			<div id='favorite_Homelist'>
				{
					completedMovies && completedMovies.length > 0 && completedMovies[ 0 ].movies && completedMovies[ 0 ].movies.length > 0 ?
					<MozaicRender movieListInfo = { completedMovies } />
					:
					<div className ='default-favorite'>
						<h3>{ t( 'completed:default', language ) }</h3>
					</div>
				}
			</div>
		);
	}
}

const stateToProps = state => ({
	completedMovies: state.moviesReducer.completedMovies,
	profileVersion: state.userReducer.profileVersion,
	language: state.translatingReducer.language,
});

const dispatchToProps = dispatch => ({
	fetchProgressMovies: ( themeId, userId, version ) => dispatch( moviesAction.fetchProgressMovies( themeId, userId, version )),
});

export default connect( stateToProps, dispatchToProps )( HomeList );
