import React from "react";
import { connect }  from "react-redux";

import ButtonClose from "../components/ButtonClose";
import HeaderLogo from "../components/HeaderLogo";

import { POPUP_REDUCER } from "../../../constants";
import { popupAction } from "../../../redux/actions";
import { forgottenPassword } from "../../../services/user";
import { checkEmail, handleLoader, t } from "../../../utils";
 
class PopupForgottenPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            errorMessage: '',
            successMessage: '',
        };
    };

    checkErrors = () => {
        const { language } = this.props;

        if ( this.state.email === '' ) {
            this.setState({ errorMessage: t( 'form:emailError', language ) });
            return false;
        } else if ( ! checkEmail( this.state.email ) ) {
            this.setState({ errorMessage:  t( 'form:emailValidError', language ) });
            return false;
        }
        this.setState({ errorMessage: '' });
        return true;
    };

    handleChange = event => {
        let value = event.target.value;
        const name = event.target.name;
        if ( name === 'email' ) value = value.toLowerCase().trim();
        this.setState({ [name]: value });
    };

    handleSubmit = () => {
        const { language } = this.props;

        if ( this.checkErrors() ) {
            handleLoader( 'ils_loader', 'add' );
            const dataUser = { email: this.state.email };
            forgottenPassword( dataUser )
                .then( res => {
                    if ( ! res.ret ) {
                        switch( res.error ) {
                            case 'user unknown':
                                this.setState({ errorMessage: t( 'form:userUnknown', language ) });
                                break;
                            default:
                                this.setState({ errorMessage: t( 'shared:genericError', language ) });       
                        }
                        handleLoader( 'ils_loader', 'remove' );
                        return;
                    } 

                    this.setState({ successMessage: t( 'forgottenPassword:success', language )});
                    handleLoader( 'ils_loader', 'remove' );
                })
                .catch( error => {
                    console.log( error );
                    this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                    handleLoader( 'ils_loader', 'remove' );
                });   
        }
    };

    render() {
        const { language, popupClose } = this.props;

        return (
            <>
                <HeaderLogo />
                <div className="ils_popup_title">{ t( 'forgottenPassword:title', language ) }</div>
                <div className="ils_popup_text">{ t( 'forgottenPassword:text1', language ) }</div>
                <div className="ils_popup_text">{ t( 'forgottenPassword:text2', language ) }</div>
                <div className="ils_popup_form">
                    <div className="ils_popup_input">
                        <input
                            type="email"
                            name="email"
                            value={ this.state.email }
                            onChange={ this.handleChange }
                            onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                            placeholder="Email" />
                    </div>

                    <div className="ils_popup_error">{ this.state.errorMessage }</div>
                    <div className="ils_popup_success">{ this.state.successMessage }</div>
                    {
                        this.state.successMessage === '' ?
                        <div className="ils_popup_valid_button" onClick={ this.handleSubmit }>
                            { t( 'forgottenPassword:confirm', language ) }
                        </div>
                        :
                        <div className="ils_popup_valid_button" onClick={ () => popupClose( POPUP_REDUCER.POPUP_FORGOTTEN_PASSWORD ) }>
                            { t( 'shared:backPlatform', language ) }
                        </div>
                    }
                </div>
                <ButtonClose popupName={ POPUP_REDUCER.POPUP_FORGOTTEN_PASSWORD } />
            </>
        );
    }
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
});

const dispatchToProps = dispatch => ({
    popupClose: popupName => dispatch(popupAction.popupClose( popupName )),
});

export default connect(stateToProps, dispatchToProps)(PopupForgottenPassword);
