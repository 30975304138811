import React from "react";
import { connect }  from "react-redux";

import { API_IMAGE, CURRENCY, DISPLAY, ICONS, IMAGES } from "../../constants";
import { t } from "../../utils";

class ListLinkItem extends React.Component {
	constructor(props) {
		super(props);

		this.state = { imageLoadNoCache: true, imageLoadError: true };
	};

	handleClick = e => {
		e.stopPropagation();
		const { link } = this.props;

		if ( link.link ) {
			window.location.href = link.link;
		} else if ( link.trailer && link.trailer.length > 0 ) {
			const { parent } = this.props;

			parent.setState({
				playerShow: true,
				playerHeight: "100%",
				playerWidth: "100%",
				videoJsOptions: {
					isTrailer: true,
					logBtnExit: parent.logBtnExit,
					movieKey: 0,
					movieList: [ link ], 
					playing: true,
					progressInfo: null,
				},
			});
	
			window.location.href = window.location.href + "#play/";
		}
	};

	renderPic = item => {
		const { style } = this.props;
		if ( item.index !== null ) {
			let srcImage = '';
			let srcErrorImage = '';
			
			if ( style === DISPLAY.POSTER ) {
				srcImage = item.posterFileName ? `${API_IMAGE}${ item.posterFileName }.jpg&w=500&h=667` : IMAGES.placeholderPoster;
				srcErrorImage = IMAGES.placeholderPoster;
			} else if ( style === DISPLAY.POSTER_LONG ) {
				srcImage = item.posterLongFileName ? `${API_IMAGE}${ item.posterLongFileName }.jpg&w=500&h=889` : IMAGES.placeholderPosterLong;
				srcErrorImage = IMAGES.placeholderPosterLong;
			} else if ( style === DISPLAY.SQUARE ) {
				srcImage = item.squareFileName ? `${API_IMAGE}${ item.squareFileName }.jpg&w=500&h=500` : IMAGES.placeholderSquare;
				srcErrorImage = IMAGES.placeholderSquare;
			} else if ( style === DISPLAY.SLIDER ) {
				return (
					<>
						<img
							className="tile-img d-none d-md-block"
							alt={item.alt}
							src={ `${API_IMAGE}${ item.carouselWebFileName }.jpg&w=1892`} 
							onError={e => {
								if ( this.state.imageLoadNoCache ) {
									this.setState({ imageLoadNoCache: false });
									e.target.src = `${ srcImage }&no-cache`;
								} else if ( this.state.imageLoadError ) {
									this.setState({ imageLoadError: false });
									e.target.src = srcErrorImage;
								}
							}} />
						<img
							className="tile-img sd-block d-sm-block d-md-none"
							alt={item.alt}
							src={ `${API_IMAGE}${ item.carouselMobileFileName }.jpg&w=1892`} 
							onError={e => {
								if ( this.state.imageLoadNoCache ) {
									this.setState({ imageLoadNoCache: false });
									e.target.src = `${ srcImage }&no-cache`;
								} else if ( this.state.imageLoadError ) {
									this.setState({ imageLoadError: false });
									e.target.src = srcErrorImage;
								}
							}} />
					</>
				);
			} else {
				srcImage = item.thumbnailFileName ? `${API_IMAGE}${ item.thumbnailFileName }.jpg&w=500&h=281&crop-to-fit` : IMAGES.placeholderThumbnail;
				srcErrorImage = IMAGES.placeholderThumbnail;
			}

			return (
				<img
					className="tile-img"
					alt={ item.alt }
					src={ srcImage } 
					onError={e => {
						if ( this.state.imageLoadNoCache ) {
							this.setState({ imageLoadNoCache: false });
							e.target.src = `${ srcImage }&no-cache`;
						} else if ( this.state.imageLoadError ) {
							this.setState({ imageLoadError: false });
							e.target.src = srcErrorImage;
						}
					}} />
			);
		}
		return (
			<img
				alt="img"
				className="tile-img"
				src={ IMAGES.placeholderThumbnail } />
		);
	};

	render() {
		const { catId, isMozaicPage, language, link, userGeoInfo } = this.props;
		const currency = userGeoInfo && userGeoInfo.currency ? userGeoInfo.currency : CURRENCY.EUR;

		return (
			<div className={ `${ isMozaicPage ? 'mozaic_figure_box' : '' } shine-container` } >
				<figure
					className='effect-thumbnail-movie collect_flag'
					id={ `cat-${ catId }-link-${ link.index }` }
					onClick={ this.handleClick } >
					{ this.renderPic( link ) }
                    <figcaption className={`figure-details ${ link.title ? 'with-overlay' : '' }`}>
                        {
                            link.badgeTop ?
                            <div className="figure-badgeTop">
                                {
                                    link.badgeTop.icon ?
                                    <img className="figure-icon" src={ ICONS[ link.badgeTop.icon ] } alt='icone' />
                                    :
                                    <></>
                                }
                                { link.badgeTop[ t( 'keys:text', language ) ] ? link.badgeTop[ t( 'keys:text', language ) ] : link.badgeTop.text }
                            </div>
                            :
                            <></>
                        }
                        { link.isFree ? <div className="figure-badgeFree">{ link.isFree.text }</div> : <></> }
						{
							link.titlePngFileName ?
							<div className="figure-img-title">
								<img 
									alt={ `logo ${ link.alt }` }
									src={ `${ API_IMAGE }${ link.titlePngFileName }.png&h=160&crop-to-fit` }
									onError={ e => e.target.style.display = 'none' } />  
							</div>
                            :
                            <></>
						}
						{
							link.title ?
							<div className="figure-title">
								{ link[ t( 'keys:title', language ) ] ? link[ t( 'keys:title', language ) ] : link.title }
							</div>
							:
							<></>
						}
						{
							link.badgeCenter ?
							<div className="figure-badgeCenter">
								{ link.badgeCenter[ t( 'keys:text', language ) ] ? link.badgeCenter[ t( 'keys:text', language ) ] : link.badgeCenter.text }
							</div>
							:
							<></>
						}
						{
							link.info ?
								<div className="figure-info">
									{ link[ t( 'keys:info', language ) ] ? link[ t( 'keys:info', language ) ] : link.info }
								</div>
							:
							<></>
						}
						{ link.prices ? <div className="figure-price">{ link.prices[ currency ].priceStg }</div> : <></> }
						{ link.pricesRent ?
							<div className="figure-price">
								{ link.pricesRent[ currency ].priceStg }
								<span className="figure-price-rent-info">{ link.pricesRent[ currency ].priceInfo }</span>
							</div>
							:
							<></>
						}
					</figcaption>
					{
						link.trailer && link.trailer.length > 0 &&
						<div className='movie_progress_play'>
							<i className="fa fa-play" aria-hidden="true"></i>
						</div>
					}
				</figure>
			</div>
		);
	};
}

const stateToProps = state => ({
	language: state.translatingReducer.language,
	userGeoInfo: state.userReducer.userGeoInfo,
});

export default connect( stateToProps )( ListLinkItem );