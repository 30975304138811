import React, { Component } from 'react'

export default class CheckBox extends Component {
    check = (id) => {
        const { toggle, handleOnChange } = this.props;
        if(toggle) return;
        handleOnChange(id);
    }

    render() {
        const { id, label, value, handleOnChange, toggle } = this.props;

        return (
            <>
            <div className={!toggle ? "checkBox": "checkBox disabled"}>
                <input type="checkbox" disabled={ toggle } onChange={() => handleOnChange( id )}  checked={ value } id={id} name="check" />
                <label htmlFor={ id }></label>
            </div>
            <div onClick={ () => this.check(id) }>{ label }</div>
            </>
        )
    }
}
