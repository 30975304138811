import { PLAYER_REDUCER } from "../../constants";

const initialState = {
	isPlayingStarted: false,
};

export default (state = initialState, action) => {
  	const newState = Object.assign({}, state);
	switch (action.type) {
		case PLAYER_REDUCER.FETCH_PLAYING_STATE:
            newState.isPlayingStarted = action.payload.isPlayingStarted;
            return newState;
		default:
			return state;
	}
};