export const translation_fr = {
    available: {
        last: 'Dernier jour pour regarder ce film',
        morePart1: 'Il vous reste ',
        morePart2: ' jours pour regarder ce film',
        one: 'Il vous reste 1 jour pour regarder ce film',
    },
    badge: {
        eachFriday: 'Chaque vendredi',
        eachWeek: 'Chaque semaine',
        new: 'Nouveauté',
        newEpisode: 'Nouveaux épisodes',
        newSeason: 'Nouvelle saison',
    },
    categories: {
        advice: 'Catégories suggérées :',
        search: 'Rechercher ma catégorie',
        title: 'Indiquer la catégorie concernée',
    },
    changePayment: {
        confirm: 'Changer mon mode de paiement',
        text1: 'Le dernier paiement de votre abonnement Zone300 a échoué.',
        text2: 'Changez votre mode de paiement pour continuer de profiter de tous nos reportages, films et séries en illimité.',
        title: 'Changez votre mode de paiement',
    },
    clock: {
        days: 'JOURS',
        hours: 'HEURES',
        minutes: 'MINUTES',
        seconds: 'SECONDES',
    },
    completed: {
        default: 'Vous n\'avez pas encore terminé de films',
    },
    distribution: {
        actor: 'Acteur',
        director: 'Réalisateur',
        producer: 'Producteur',
    },
    favorites: {
        default: 'Vous n\'avez pas encore ajouté de films à votre liste',
        info: 'Ajoutez des films à votre liste en appuyant sur "+ ma liste"',
    },
    forgottenPassword: {
        confirm: 'Envoyer',
        success: 'Nous venons de vous envoyer un email avec la marche à suivre.',
        text1: 'Renseignez votre adresse e-mail.',
        text2: 'Nous vous enverrons un lien pour réinitialser de votre mot de passe.',
        title: 'Mot de passe oublié ?',
    },
    form: {
        addComment: 'Ajouter un commentaire',
        address1: 'Adresse ligne 1',
        address1Info: 'Rue, voie, boite postale, nom de société',
        address2: 'Adresse ligne 2 (facultatif)',
        adress2Info: 'Appartement, immeuble, étage...',
        addressError: 'Veuillez entrer votre adresse',
        ageValid: 'Je confirme avoir plus de 15 ans',
        ageValidError: 'Vous devez confirmer avoir plus de 15 ans',
        birthday: 'Date de naissance',
        birthdayInfo: 'Nous utiliserons votre date de naissance pour de l\'envoi de cadeaux ou de futures promotions',
        birthdayError: 'Veuillez entrer votre date de naissance',
        checkboxError: 'Vous n\'avez pas coché un nombre suffisant de cases',
        city: 'Ville',
        cityError: 'Veuillez entrer votre ville',
        codeEmpty: 'Veuillez entrer votre code',
        codeError: 'Code incorrect',
        comment: 'Commentaire',
        comments: 'Commentaires',
        commentSuccess: 'Votre avis a bien été enregistré',
        country: 'Pays',
        countryError: 'Veuillez entrer votre pays',
        defaultSelect: '- Sélectionner -',
        editSuccess: 'Changement effectué avec succès',
        emailError: 'Veuillez entrer votre e-mail',
        emailExists: 'Email déjà utilisé',
        emailNew: 'Veuillez entrer votre nouvel e-mail',
        emailNewLabel: 'Nouvel e-mail',
        emailOld: 'Adresse e-mail actuelle :',
        emailValidError: 'Veuillez entrer un e-mail valide',
        firstName: 'Prénom',
        firstNameError: 'Veuillez entrer votre prénom',
        gender: 'Genre',
        genderError: 'Veuillez entrer votre genre',
        genderFemale: 'Femme',
        genderMale: 'Homme',
        isp: 'Opérateur Box Internet',
        ispError: 'Veuillez entrer votre opérateur Box Internet',
        lastName: 'Nom',
        lastNameError: 'Veuillez entrer votre nom',
        leaveReview: 'Laisser un avis',
        newsletter: 'Je ne souhaite pas recevoir la newsletter',
        optional: 'facultatif',
        password: 'Mot de passe',
        passwordConfirm: 'Confirmation mot de passe',
        passwordConfirmError: 'Les mots de passe ne correspondent pas',
        passwordConfirmNewEmpty: 'Veuillez retaper votre nouveau mot de passe',
        passwordEmpty: 'Veuillez entrer votre mot de passe',
        passwordError: 'Mot de passe incorrect',
        passwordNew: 'Nouveau mot de passe',
        passwordNewConfirm: 'Retaper votre nouveau mot de passe',
        passwordNewEmpty: 'Veuillez entrer votre nouveau mot de passe',
        passwordOld: 'Mot de passe actuel',
        passwordRequired: 'Veuillez renseigner votre mot de passe (obligatoire)',
        phone: 'Numéro de téléphone',
        phoneError: 'Veuillez entrer votre numéro de téléphone',
        phoneInfo: 'Nous ne vous enverrons pas de sms sans votre accord',
        phoneSmall: 'Téléphone',
        postalCode: 'Code postal',
        postalCodeError: 'Veuillez entrer votre code postal',
        productUnknown: 'Produit inconnu',
        pseudo: 'Pseudo',
        pseudoError: 'Veuillez entrer votre pseudo',
        rateStarErrorMovie: 'Veuillez noter le film',
        rateStarErrorProduct: 'Veuillez noter le produit',
        rateStarErrorSerie: 'Veuillez noter la série',
        reason: 'Sélectionnez au moins 1 raison',
        region: 'État / Région / Canton (si approprié)',
        requiredFields: '* champs obligatoires',
        saveUpdate: 'Enregistrer les modifications',
        tshirtSize: 'Taille de t-shirt',
        tshirtSizeError: 'Veuillez entrer votre taille de t-shirt',
        tokenExpired: 'Le délai est dépassé, veuillez rééssayer.',
        userExists: 'Utilisateur déjà existant',
        userUnknown: 'Utilisateur inconnu',
        yourOpinion: 'Votre avis sur',
        yourOpinionProduct: 'Votre avis sur le produit',
    },
    freemium: {
        adds: 'Publicité',
        connect: 'SE CONNECTER',
        disconnect: 'SE DÉCONNECTER',
        freeMonthMovie: 'Film gratuit du mois',
        freePlay: 'Regarder gratuitement',
        info: 'Accès illimité à tous les films',
        infoAdds: 'Abonnez-vous et profitez de Zone300 en illimité sans publicité.',
        reactivate: 'Réactiver',
        reactivateAdds: 'Réactiver mon compte',
        removeAdds: 'Supprimer les publicités',
        sub: 'S\'abonner',
        subscribe: 'ABONNEZ-VOUS',
        subscribeAdds: 'Supprimer les publicités et choisir mon programme',
    },
    keys: {
        description: 'description',
        details: 'details',
        freeBtn: 'freeBtn',
        info: 'info',
        linkBtn: 'linkBtn',
        name: 'name',
        playBtn: 'playBtn',
        preTitre: 'preTitre',
        priceBtn: 'priceBtn',
        priceRentBtn: 'priceRentBtn',
        priceInfo: 'priceInfo',
        synopsis_txt: 'synopsis_txt',
        text: 'text',
        text_1: 'text_1',
        text_2: 'text_2',
        title: 'title',
        titlePngFileName: 'titlePngFileName',
        titre: 'titre',
    },
    login: {
        confirm: 'Se connecter',
        forgottenPassword: 'Mot de passe oublié ?',
        text1: 'Première visite ?',
        text2: 'Créer un compte',
        title: 'Se connecter',
    },
    moviePage: {
        navDistribution: 'Distribution',
        navEpisodes: 'Épisodes',
        navExtras: 'Extras',
        navProducts: 'Produits',
        navRelated: 'Contenu associé',
    },
    nav: {
        address: 'Adresse',
        cart: 'Mon panier',
        confirmation: 'Confirmation',
        favorites: 'Ma liste',
        home: 'Mon Zone300',
        language: 'Langue',
        notifications: 'Notifications',
        payment: 'Paiement',
        playlist: 'En continu',
        search: 'Rechercher',
        store: 'Boutique',
        training: 'Formations',
    },
    partner: {
        cap: 'la casquette Zone300',
        capGift: '1 casquette Zone300 + goodies',
        copiedLink: 'lien copié',
        copyLink: 'Copier le lien',
        giftPreviousPart1: 'Avec ',
        giftPreviousPart2: ' abonnements tu as gagné ',
        giftNextPart1: 'Plus que ',
        giftNextPart2: ' abonnement',
        giftNextPart3: ' pour débloquer ',
        info1: 'Comment ça marche ?',
        info2: 'Remplissez les informations suivantes pour que nous puissions vous envoyer les lots pour chaque palier.',
        info3: '*Attention l\'abonnement doit être pris au cours d\'une session unique de navigation après avoir cliqué sur le lien tracké.',
        info4: 'Tout désabonnement d\'un partenaire fait perdre la totalité des abonnements récoltés et ne donne plus droit aux lots.',
        info5: 'Toute demande pour changement de lots par palier ne sera pas traitée.',
        intro1: 'Devenez partenaire',
        intro2: 'Je deviens partenaire',
        link: 'Mon lien unique',
        listGift: 'Voici la liste des lots à gagner selon les abonnements que vous générez :',
        moreGift: 'Et encore pleins d\'autre cadeaux à venir !',
        permit: 'votre permis de chasse',
        permitGift: 'Votre validation annuelle nationale au permis de chasse',
        shareLink: 'Partagez votre lien personnalisé avec vos amis et gagnez des cadeaux !',
        subscriptions: 'abonnements',
        succeeded: 'Mes parrainages réussis',
        succeededTotal: 'Total des parrainages réussis',
        successConfirm: 'Accéder au tableau de bord',
        successTitle: 'Validation',
        successText1: 'Félicitations !',
        successText2: 'Vous êtes maintenant partenaire de Zone300 et pouvez dès à présent partager votre lien unique avec vos amis.',
        sweat: 'le sweatshirt Zone300',
        sweatGift1: '1 sweatshirt Zone300',
        sweatGift2: '1 sweatshirt personnalisé à votre nom et le logo "Zone300 team"',
        tshirt: 'le t-shirt Zone300',
        tshirtGift: '1 t-shirt Zone300 en édition limitée + goodies',
    },
    payment: {
        bill: 'Facture',
        cardInfo: 'Informations de la carte',
        cardName: 'Nom du titulaire de la carte',
        codeConfirm: 'Appliquer',
        codeLabel: 'Code promo',
        codeLabelDiscount: 'Code promo : remise de',
        codeRemove: 'Retirer',
        codeSuccess: 'Le code a été appliqué',
        discount: 'Remise de',
        error: 'Échec du paiement',
        forSubZ3: 'pour les abonnés Zone300',
        info: 'Paiement sécurisé. Transactions bancaires cryptées.',
        nav1: '1 - Récapitulatif de la commande',
        nav2: '2 - Paiement',
        orderConfirm: 'Confirmer la commande',
        payConfirm: 'Payer',
        success: 'Paiement réalisé avec succès',
        successInfo: 'Une confirmation par e-mail vous a été envoyée.',
        successTitle: 'Merci pour votre achat',
        successTrainingConfirm: 'Démarrer la formation',
        total: 'Total',
        totalBeforeDiscount: 'Total avant remise',
        trackLink: 'Suivez votre colis ici',
        useCard: 'Utiliser ma carte',
        useOtherCard: 'Utiliser un autre moyen de paiement',
    },
    preferences: {
        error: 'Veuillez choisir au moins trois catégories',
        selection: 'Sélectionnez au moins trois catégories',
        title: 'Personnalisez votre Zone300',
    },
    profile: {
        aboutUs: 'À propos',
        account: 'Compte',
        billingHistory: 'Historique de facturation',
        cookies: 'Modalités relatives aux cookies',
        confidentiality: 'Déclaration de confidentialité',
        data: 'RGPD',
        date: 'Date',
        details: 'Détails du compte',
        disconnect: 'Se déconnecter',
        disconnectQuestion: 'Voulez-vous vous déconnecter ?',
        distributors: 'Nos distributeurs',
        editAvatar: 'Modifier l\'avatar',
        editEmail: 'Modifier l\'email',
        editFirstName: 'Modifier le prénom',
        editInfo: 'Modifier mes informations',
        editLastName: 'Modifier le nom',
        editPassword: 'Modifier le mot de passe',
        editPayment: 'Changer mon mode de paiement',
        editProfile: 'Modifier mon profil',
        editSubscription: 'Modifier mon abonnement',
        furtherInfo: 'Informations complémentaires',
        legal: 'Mentions légales',
        media: 'Médias',
        offers: 'Voir nos offres',
        order: 'Commande',
        orderId: 'Identifiant de commande',
        orders: 'Commandes',
        ordersCompleted: 'Commandes passées',
        ordersCompletedNone: 'Vous n\'avez pas de commandes passées',
        ordersInProgress: 'Commandes en cours',
        ordersInProgressNone: 'Vous n\'avez pas de commandes en cours',
        partner: 'Partenaire',
        partners: 'Nos partenaires',
        paymentFailed: 'Paiement échoué',
        personalInfo: 'Informations personnelles',
        personalProfile: 'Profil personnel',
        personalize: 'Personnaliser',
        preferences: 'Vos préférences',
        profile: 'Profil',
        questions: 'Des questions ? Contactez Diane',
        review: 'Avis',
        social: 'Suivez-nous sur les réseaux',
        subscription: 'Mon Abonnement',
        subscriptionSince: 'Abonné depuis le',
        subscriptionUpdated: 'Votre abonnement a été modifié',
        terms: 'CGU',
        title: 'Mon compte',
        unsubscribe: 'Me désabonner',
        whoAreWe: 'Qui sommes nous ?',
    },
    profileManager: {
        add: 'Ajouter un profil',
        change: 'Changer de profil',
        edit: 'Modifier le profil',
        remove: 'Supprimer le profil',
        removeImpossible: 'Impossible de supprimer le profil initial',
        removeInfo: 'Attention cette action est irréversible.',
        removeQuestion: 'Êtes vous sûr de vouloir supprimer ce profil ?',
        title: 'Mes profils',
    },
    progress: {
        default: 'Vous n\'avez pas de films à terminer pour l\'instant',
    },
    reactivate: {
        details: 'Votre prochain prélèvement aura lieu le ',
        confirm: 'Réactiver mon abonnement',
        infoPart1: 'Si vous réactivez votre abonnement ',
        infoPart2: ' maintenant vous continuerez de profiter de tous nos reportages, films et séries en illimité.',
        success: 'Votre abonnement a été réactivé',
        title: 'Nous sommes contents de vous voir revenir !',
    },
    reactivatePayment: {
        confirm: 'Réactiver mon compte',
        text1: 'Vous pouvez le réactiver dès maintenant pour profiter à nouveau de tous nos reportages, films et séries en illimité.',
        title: 'Votre compte Zone 300 est en pause',
    },
    registration: {
        confirm: 'Continuer',
        confirmInfo: 'En cliquant sur "Continuer" vous acceptez nos conditions générales d\'abonnement.',
        text1: 'Vous avez déjà un compte Zone300 ?',
        text2: 'Se connecter',
        title: 'Inscription',
        titleFreeMovie: 'Inscrivez-vous et profitez gratuitement de votre film',
        titlePlaylist: 'Inscrivez-vous et profitez de notre chaîne gratuite',
    },
    resetPassword: {
        confirm: 'Envoyer',
        text1: 'Saisissez votre nouveau mot de passe.',
        title: 'Mot de passe oublié ?',
        success: 'Votre mot de passe a été mis à jour.',
    },
    search: {
        confirm: 'Lancer la recherche',
        default: 'Vidéo non disponible pour le moment',
        placeholder: 'RECHERCHER...',
    },
    shared: {
        add: 'Ajouter',
        atDate: 'à',
        availableDate: 'Disponible le',
        back: 'Retour',
        backNavigation: 'Retour à la navigation',
        backPlatform: 'Retourner à la plateforme',
        bonus: 'Bonus',
        confirm: 'Valider',
        continue: 'Continuer',
        description: 'Description',
        details: 'Détails',
        dislike: 'Je n\'aime pas',
        edit: 'Modifier',
        episode: 'Épisode',
        episodeSmall: 'épisode',
        explore: 'Explorer',
        fishing: 'Pêche',
        genericError: 'Une erreur est survenue',
        hunting: 'Chasse',
        like: 'J\'aime',
        listAddTo: 'Ajouter à Ma Liste',
        listRemoveTo: 'Supprimer de Ma Liste',
        minutesLeft: ' minutes restantes',
        minuteLeft: ' minute restante',
        nextVideo: 'Prochaine vidéo dans ',
        no: 'Non',
        on: 'sur',
        onAir: 'Actuellement en diffusion',
        opinion: 'avis',
        or: 'OU',
        other: 'Autres',
        play: 'Lecture',
        relatedProduct: 'Vous aimerez sûrement',
        remove: 'Supprimer',
        rent: 'LOCATION',
        resume: 'Reprendre',
        save: 'Enregistrer',
        second: 'seconde',
        trailer: 'Bande-annonce',
        trailers: 'Bandes-annonces',
        yes: 'Oui',
    },
    store: {
        add : 'Ajouter au panier',
        addSuccess: 'Le produit a été ajouté à votre panier',
        billingAddress: 'Adresse de facturation',
        cartEmpty: 'Votre panier est vide',
        cartRemoveQuestion: 'Voulez vous supprimer ce produit de votre panier ?',
        color: 'Couleur',
        continue: 'Continuer mes achats',
        delivered: 'Livré',
        deliveryAddress: 'Adresse de livraison',
        deliveryProgress: 'En cours de livraison',
        deliveryStart: 'En préparation',
        errorBillingAddress: 'Veuillez entrer votre adresse de facturation',
        errorBillingCity: 'Veuillez entrer votre ville de facturation',
        errorBillingCountry: 'Veuillez entrer votre pays de facturation',
        errorBillingFirstName: 'Veuillez entrer votre prénom de facturation',
        errorBillingLastName: 'Veuillez entrer votre nom de facturation',
        errorBillingPostalCode: 'Veuillez entrer votre code postal de facturation',
        errorCountryUnavailable: 'La livraison n\'est pas possible dans votre pays.',
        errorColor: 'Veuillez indiquer la couleur',
        errorDeliveryAddress: 'Veuillez entrer votre adresse de livraison',
        errorDeliveryCity: 'Veuillez entrer votre ville de livraison',
        errorDeliveryCountry: 'Veuillez entrer votre pays de livraison',
        errorDeliveryFirstName: 'Veuillez entrer votre prénom de livraison',
        errorDeliveryLastName: 'Veuillez entrer votre nom de livraison',
        errorDeliveryPostalCode: 'Veuillez entrer votre code postal de livraison',
        errorQuantity: 'Veuillez indiquer le nombre de produits',
        errorSize: 'Veuillez indiquer la taille',
        expirationDate: 'Date d\'expiration',
        forSub: 'POUR LES ABONNÉS',
        outOfStock: 'En rupture de stock',
        paymentMethod: 'Moyen de paiement',
        quantity: 'Quantité',
        sameAddress: 'Identique à l\'adresse de livraison',
        successInfo1: 'Merci pour votre achat',
        successInfo2: 'Une confirmation par e-mail vous a été envoyée',
        successInfo3: 'Vous pouvez suivre votre commande dans le menu',
        size: 'Taille',
        valid: 'Valider mon panier',
    },
    subscription: {
        confirm: 'Confirmer la modification',
        current: 'FORFAIT EN COURS',
        month1Adj: 'mensuel',
        month1Stg: '1 mois',
        month1StgLong: 'Abonnement mensuel',
        month3Adj: 'trimestriel',
        month3Stg: '3 mois',
        month3StgLong: 'Abonnement 3 mois',
        month6Adj: 'semestriel',
        month6Stg: '6 mois',
        month6StgLong: 'Abonnement 6 mois',
        month12Adj: 'annuel',
        month12Stg: '12 mois',
        month12StgLong: 'Abonnement 12 mois',
        text1: 'Si vous changez votre abonnement maintenant vous serez prélevé de ',
        text2: 'Votre abonnement a bien été modifié, vous serez prélevé de ',
        the: 'le ',
    },
    time: {
        day: 'jour',
        days: 'jours',
        hour: 'heure',
        hours: 'heures',
        minute: 'minute',
        minutes: 'minutes',
        month: 'mois',
        months: 'mois',
        since: 'il y a',
        year: 'an',
        years: 'ans',
    },
    unsub: {
        cancel: 'Rester sur Zone300',
        confirm: 'Annuler mon abonnement',
        continue: 'Poursuivre la résiliation',
        step: 'Étape',
        step1Title: 'Êtes-vous sûr de vouloir partir ?',
        step1Text1: 'Saviez vous que Zone300 sort 4 nouveaux films exclusifs par semaine ?',
        step1Text2: 'Avez-vous regardé les dernières recommandations faites pour vous ?',
        step2Text1: 'Parce que votre avis est important. Merci de nous faire savoir la raison de votre départ, afin de nous améliorer (pour mieux nous retrouver).',
        step2Text2: 'Il s\'agit d\'un problème technique ? Contactez Diane au 09 72 16 43 58.',
        step3Title: 'Vous nous quittez ?',
        step3Text1: 'L\'annulation sera effective dès la fin de la période de facturation en cours',
        step3Text2: 'Vous pouvez réactiver votre abonnement depuis votre espace en cliquant sur "Réactiver mon abonnement".',
        step3Text3: 'Si vous souhaitez vous désabonner maintenant, renseignez votre mot de passe pour confirmer votre identité et cliquez sur "Annuler mon abonnement".',
    },
};