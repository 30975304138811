import React from "react";
import { connect }  from "react-redux";

import { CURRENCY } from "../../../../constants";
import { t } from "../../../../utils";

import PaymentDetailsItem from "./PaymentDetailsItem";
 
class PaymentDetailsBox extends React.Component {
    render() {
        const { handleChangeProduct, language, productPaymentInfo, selected, userGeoInfo, withSeparator } = this.props;
        const currency = userGeoInfo && userGeoInfo.currency ? userGeoInfo.currency : CURRENCY.EUR;

        return (
            <>
                { withSeparator && <div className="popup-payment-details-separator">{ t( 'shared:or', language ) }</div>}
                {
                    productPaymentInfo && productPaymentInfo.type === 'pack' &&
                    <div
                        className={ `popup-payment-details ${ selected ? 'selected' : 'selectable' }` }
                        onClick={ () => { if ( ! selected ) handleChangeProduct( productPaymentInfo ) } }>
                        <div className="popup-payment-details-radio-box">
                            <span className="popup-payment-details-radio-dot"></span>
                        </div>
                        <div className="popup-payment-details-content">
                            {
                                productPaymentInfo.items && productPaymentInfo.items.length > 0 ?
                                productPaymentInfo.items.map( ( item, index ) =>
                                    <PaymentDetailsItem item={ item } key={ index } />
                                )
                                :
                                <PaymentDetailsItem item={ productPaymentInfo } />
                            }
                        </div>
                        <div className="popup-payment-details-bandage">
                            {
                                productPaymentInfo.prices[ currency ].priceOverlineStg ?
                                <span className="popup-payment-details-bandage-priceOverlineStg">
                                    { productPaymentInfo.prices[ currency ].priceOverlineStg }
                                </span>
                                :
                                <></>
                            }
                            {
                                productPaymentInfo.prices[ currency ].priceStg ?
                                <span className="popup-payment-details-bandage-priceStg">
                                    { productPaymentInfo.prices[ currency ].priceStg }
                                </span>
                                :
                                <></>
                            }
                        </div>  
                    </div>
                }
                {
                    productPaymentInfo && productPaymentInfo.type === 'item' &&
                    <div
                        className={ `popup-payment-details ${ selected ? 'selected' : 'selectable' }` }
                        onClick={ () => { if ( ! selected ) handleChangeProduct( productPaymentInfo ) } }>
                        <div className="popup-payment-details-radio-box">
                            <span className="popup-payment-details-radio-dot"></span>
                        </div>
                        <div className="popup-payment-details-content">
                            <PaymentDetailsItem item={ productPaymentInfo } />
                        </div>
                    </div>
                }
            </>
        );
    }
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
    userGeoInfo: state.userReducer.userGeoInfo,
});

export default connect( stateToProps )( PaymentDetailsBox );