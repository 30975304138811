import React from "react";

import InfiniteCarousel from 'react-leaf-carousel';

import ListProductItem from "./ListProductItem";
import InfinteCarrouselReRender from "../tools/InfinteCarrouselReRender";

import { DISPLAY, IMAGES } from "../../constants";

class ListProducts extends React.Component {
	getSlidesNumbers = size => {
		if ( size === DISPLAY.XL ) {
			return {
				max: 5,
				breakpoints: [
					{ breakpoint: 768, settings: { arrows: false, slidesToShow: 1, slidesToScroll: 1 } },
					{ breakpoint: 1025, settings: { arrows: false, slidesToShow: 2, slidesToScroll: 2 } },
					{ breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 3 } },
					{ breakpoint: 3000, settings: { slidesToShow: 4, slidesToScroll: 4 } },
				]
			};
		} else if ( size === DISPLAY.XXL ) {
			return {
				max: 4,
				breakpoints: [
					{ breakpoint: 768, settings: { arrows: false, slidesToShow: 1, slidesToScroll: 1 } },
					{ breakpoint: 1025, settings: { arrows: false, slidesToShow: 2, slidesToScroll: 2 } },
					{ breakpoint: 1200, settings: { slidesToShow: 2, slidesToScroll: 2 } },
					{ breakpoint: 3000, settings: { slidesToShow: 3, slidesToScroll: 3 } },
				]
			};
		} else if ( size === DISPLAY.XS ) {
			return {
				max: 7,
				breakpoints: [
					{ breakpoint: 768, settings: { arrows: false, slidesToShow: 2, slidesToScroll: 2 } },
					{ breakpoint: 1025, settings: { arrows: false, slidesToShow: 4, slidesToScroll: 4 } },
					{ breakpoint: 1200, settings: { slidesToShow: 5, slidesToScroll: 5 } },
					{ breakpoint: 3000, settings: { slidesToShow: 6, slidesToScroll: 6 } },
				]
			};
		} else if ( size === DISPLAY.XXS ) {
			return {
				max: 8,
				breakpoints: [
					{ breakpoint: 768, settings: { arrows: false, slidesToShow: 3, slidesToScroll: 3 } },
					{ breakpoint: 1025, settings: { arrows: false, slidesToShow: 5, slidesToScroll: 5 } },
					{ breakpoint: 1200, settings: { slidesToShow: 6, slidesToScroll: 6 } },
					{ breakpoint: 3000, settings: { slidesToShow: 7, slidesToScroll: 7 } },
				]
			};
		} else if ( size === DISPLAY.SINGLETON ) {
			return {
				max: 1,
				breakpoints: [
					{ breakpoint: 768, settings: { arrows: false, slidesToShow: 1, slidesToScroll: 1 } },
					{ breakpoint: 1025, settings: { arrows: false, slidesToShow: 1, slidesToScroll: 1 } },
					{ breakpoint: 1200, settings: { slidesToShow: 1, slidesToScroll: 1 } },
					{ breakpoint: 3000, settings: { slidesToShow: 1, slidesToScroll: 1 } },
				]
			};
		}

		return {
			max: 6,
			breakpoints: [
				{ breakpoint: 768, settings: { arrows: false, slidesToShow: 2, slidesToScroll: 2 } },
				{ breakpoint: 1025, settings: { arrows: false, slidesToShow: 3, slidesToScroll: 3 } },
				{ breakpoint: 1200, settings: { slidesToShow: 4, slidesToScroll: 4 } },
				{ breakpoint: 3000, settings: { slidesToShow: 5, slidesToScroll: 5 } },
			]
		};
	};
		
	render() {
		const { currentDisplay, display, productListIndex } = this.props;
		const slideNumbers = this.getSlidesNumbers( display.sizeNew || display.size || '' );

		return (
			<div className="grid for-image">
				<InfinteCarrouselReRender condition={ display.rerender }>
					<InfiniteCarousel
						breakpoints={ slideNumbers.breakpoints } 
						dots={false}
						slidesSpacing={5}
						showSides={true}
						sidesOpacity={.5}
						sideSize={.1}
						slidesToScroll={ slideNumbers.max }
						slidesToShow={ slideNumbers.max  }
						animationDuration={600}
						lazyLoad={true}
						placeholderImageSrc={ IMAGES.placeholderThumbnail }>
						{
							currentDisplay && currentDisplay.length > 0 &&
							currentDisplay.map( (item, index) =>
								<div key={ index } >
									<ListProductItem 
										catId={ productListIndex } 
										product={ item } />
								</div>
							)
						}
					</InfiniteCarousel>
				</InfinteCarrouselReRender>
			</div>
		);
	};
};

export default ListProducts;