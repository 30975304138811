import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';

import MozaicRender from '../../../components/tools/MozaicRender';

import { updateUnsubStep } from "../../../services/user";
import { moviesAction } from "../../../redux/actions";
import { t } from "../../../utils";

class PUnsubStep1 extends React.Component {
    componentDidMount() {
        const { fetchNewForUserMovies, profileVersion, userInfoDB } = this.props;
        fetchNewForUserMovies( userInfoDB.NoCli, profileVersion );
    };

    unsubStep = event => {
        const { handlePageChange, userInfoDB } = this.props;
        const valueId = event.currentTarget.id;
        let data = { NoCli: userInfoDB.NoCli, data: '' };

        switch ( valueId ) {
            case 'sub_step1':
                data.data = 'new-step1 - sub';
                updateUnsubStep( data )
                    .then( res => { console.log( res ); } )
                    .catch( error => { console.log( error ); } );
                window.location.href="/browse/#/";
                break;
            case 'unsub_step1':
                data.data = 'new-step1 - unsub';
                updateUnsubStep( data )
                    .then( res => { console.log( res ); } )
                    .catch( error => { console.log( error ); } );
                handlePageChange( 'right', 'unsubStep2' );
                break;
            default:
                break;
        }
    };
    
    render() {
        const { language, newForUserMovies } = this.props;

        const $content =
        <>
            <div className="profile_unsubStep1_container">
                <div className="profile_intro_texte">
                    <div id='profile_nav2'>
                        <div className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'editSub' ) } }>{ t( 'shared:back', language ) }</div>
                        <div>{ t( 'unsub:step', language ) } 1/3</div>
                    </div>
                    <div >
                        <h1>{ t( 'unsub:step1Title', language ) }</h1>   
                        <p>{ t( 'unsub:step1Text1', language ) }</p> 
                        <p>{ t( 'unsub:step1Text2', language ) }</p>   
                    </div>
                </div>
            </div>
            <div id='result-container1'>
                {
                    newForUserMovies && newForUserMovies.length > 0 && newForUserMovies[ 0 ].movies && newForUserMovies[ 0 ].movies.length > 0 &&
					<MozaicRender movieListInfo = { newForUserMovies } />
                } 
            </div>
            <div className="profile_unsub_nav_btns">
                <div id='sub_step1' onClick={(event) => this.unsubStep(event) } className="profile_btn_return">
                    <span>{ t( 'unsub:cancel', language ) }</span>
                </div>
                <div id='unsub_step1' className="profile_btn_continuer" onClick={(event) => this.unsubStep(event) }>
                    <span>{ t( 'unsub:continue', language ) }</span>
                </div>
            </div>
        </>
       
        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
    newForUserMovies: state.moviesReducer.newForUserMovies,
    profileVersion: state.userReducer.profileVersion,
    userInfoDB: state.userReducer.userInfoDB,
});

const dispatchToProps = dispatch => ({
    fetchNewForUserMovies: ( userId, version ) => dispatch( moviesAction.fetchNewForUserMovies( userId, version )),
});

export default connect( stateToProps, dispatchToProps )( PUnsubStep1 );