import React from 'react';
import { connect } from "react-redux";

import SCRouter from "./SCRouter";

import { routingAction, storeAction, userAction } from "../../redux/actions";
import { getUserInfo } from "../../utils";

class StoreCart extends React.Component {
    componentDidMount() {
        if ( ! getUserInfo().NoCli ) { window.location.href = "/boutique/"; return; }
        const { fetchCurrentRoutingByUrl, fetchStoreSelectedProducts, fetchUserAddress, fetchUserStripeCard, storeCart } = this.props;
        fetchCurrentRoutingByUrl( 'mon-panier' );
        if ( storeCart && storeCart.length > 0 ) {
            let productIds = '';
            storeCart.forEach( el => {
                productIds += `|${ el.productId }|`;
            });
            fetchStoreSelectedProducts( productIds );
            fetchUserAddress( getUserInfo().NoCli );
            fetchUserStripeCard( getUserInfo().NoCli );
        }
  	};

    render() {
        return (
            <div className="homepage-container special-nav-menu">
                <SCRouter />
            </div>
        )
    }
}

const stateToProps = state => ({
    storeCart: state.storeReducer.storeCart,
});

const dispatchToProps = dispatch => ({
	fetchCurrentRoutingByUrl: url => dispatch(routingAction.fetchCurrentRoutingByUrl( url )),
    fetchStoreSelectedProducts: productIds => dispatch(storeAction.fetchStoreSelectedProducts( productIds )),
    fetchUserStripeCard: userId => dispatch(userAction.fetchUserStripeCard( userId )),
    fetchUserAddress: userId => dispatch(userAction.fetchUserAddress( userId )),
});

export default connect( stateToProps, dispatchToProps )( StoreCart );