import React from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";

import Slider from "../Slider";
import FreeMovieHeader from "./FreeMovieHeader";
import SoonAvailableHeader from "./SoonAvailableHeader";

import { moviesAction, trainingAction } from "../../redux/actions";
import { updateUserPaymentStatus } from "../../services/user";
import { getUserInfo, isGuest, isProgressTimeValid } from "../../utils";

import "react-responsive-carousel/lib/styles/carousel.min.css";

class Header extends React.Component {
	static contextTypes = { router : Proptypes.object };

	constructor(props,context) {
		super(props,context);

		this.logBtnExit = this.logBtnExit.bind(this);

		this.state = {
			playerShow: false,
			playerAutoplay: false,
			playerHeight: 0,
			playerWidth: 0,
			videoJsOptions: { autoplay: false, playing: false },
		};
	};

	componentDidMount() {
		this.backListener = this.context.router.history.listen( ( location, action, state ) => {
			if ( action === "POP" && this.state.playerShow && location.hash !== '#play/' ) this.logBtnExit();
		});

		if ( this.props.isTrainingPage ) {
			const { fetchSoonAvailableHeader, fetchTrainingSliderMovies, profileVersion } = this.props;
			const userId = getUserInfo().NoCli;
			fetchTrainingSliderMovies( 'training', userId );
			fetchSoonAvailableHeader( 'training', userId, profileVersion );
		}
	};

	componentDidUpdate( prevProps ) {
		if ( this.props.themeId !== prevProps.themeId ) {
			const { fetchFreeMovieHeader, fetchSoonAvailableHeader, fetchSliderMovies, profileVersion, themeId, userDiscarded } = this.props;
			const userId = getUserInfo().NoCli;
			fetchSliderMovies( themeId, userId, profileVersion );
			fetchSoonAvailableHeader( themeId, userId, profileVersion );
			if ( isGuest() || userDiscarded ) fetchFreeMovieHeader( themeId, userId, profileVersion );
		}
	};

	goToChangePaymentPage = () => {
        const { changePaymentToken } = this.props;
        if ( changePaymentToken && changePaymentToken !== '' ) {
            updateUserPaymentStatus( `?token=${ changePaymentToken }&type=discarded` );
            window.location.href = "/modifier-paiement/?session_id=" + changePaymentToken;
        } 
	};

	logBtnExit = () => {
		if( document.location.hash.includes( "#play/" ) ) this.context.router.history.goBack();
		this.setState( { playerShow: false, playerHeight: 0, playerWidth: 0, videoJsOptions: { autoplay: false, playing: false } } );
		if ( this.props.isGlobalPlayingPage ) {
			window.location.href = window.location.href.replace( "#play/", "" );
			window.location.reload();
		}
	};
		
	onClickFullscreen = movie => {
		const { isVodPage, sliderMovies, userDiscarded } = this.props;

		if ( ! isVodPage ) {
			if ( isGuest() ) {
				window.location.href = "/offres/";
				return;
			} else if ( userDiscarded ) {
				this.goToChangePaymentPage();
				return;
			}
		}

		const movieList = sliderMovies[ 0 ].movies;
		const progressInfo = isProgressTimeValid( movie.progressTime, movie.timeTotal ) ? { progressTime: movie.progressTime, timeTotal: movie.timeTotal } : null;
		const movieKey = movieList.indexOf( movieList.filter( el => el.transaction === movie.transaction )[ 0 ] );

		this.setState({
			playerShow: true,
			playerHeight: "100%",
			playerWidth: "100%",
			videoJsOptions: {
				logBtnExit: this.logBtnExit,
				movieKey: movieKey,
				movieList: movieList, 
				playing: true,
				progressInfo: progressInfo,
			},
		});

		window.location.href = window.location.href + "#play/";
	};

	render(){
		const { freeMovieHeader, isTrainingPage, sliderMovies, soonAvailableHeader, soonAvailableHeaderDone, trainingSliderMovies }  = this.props;

		return (
			<>
			{
				soonAvailableHeaderDone &&
				<>
					{
						soonAvailableHeader.length > 0 ?
						<SoonAvailableHeader />
						:
						<>
							{
								freeMovieHeader.length > 0 ?
								<FreeMovieHeader />
								:
								<Slider sliderMovies={ isTrainingPage ? trainingSliderMovies : sliderMovies } />
							}
						</>
					}
				</>
			}
			</>
		)
	};
};

const stateToProps = state => ({
	changePaymentToken: state.userReducer.changePaymentToken,
	freeMovieHeader: state.moviesReducer.freeMovieHeader,
	isGlobalPlayingPage: state.routingReducer.isGlobalPlayingPage,
	isVodPage: state.routingReducer.isVodPage,
	profileVersion: state.userReducer.profileVersion,
	sliderMovies: state.moviesReducer.sliderMovies,
	soonAvailableHeader: state.moviesReducer.soonAvailableHeader,
    soonAvailableHeaderDone: state.moviesReducer.soonAvailableHeaderDone,
	themeId: state.routingReducer.themeId,
	trainingSliderMovies: state.trainingReducer.trainingSliderMovies,
	userDiscarded: state.userReducer.userDiscarded,
});

const dispatchToProps = dispatch => ({
	fetchFreeMovieHeader: ( themeId, userId, version ) => dispatch(moviesAction.fetchFreeMovieHeader( themeId, userId, version )),
	fetchSliderMovies: ( themeId, userId, version ) => dispatch(moviesAction.fetchSliderMovies( themeId, userId, version )),
	fetchSoonAvailableHeader: ( themeId, userId, version ) => dispatch(moviesAction.fetchSoonAvailableHeader( themeId, userId, version )),
	fetchTrainingSliderMovies: ( themeId, userId ) => dispatch(trainingAction.fetchTrainingSliderMovies( themeId, userId )),
});

export default connect( stateToProps, dispatchToProps )( Header );