import React from "react";
import { connect } from "react-redux";

import CountDownCards from "../Clock/CountDownCards";
import CountDownClassic from "../Clock/CountDownClassic";

import { API_IMAGE, DISPLAY, ICONS } from "../../constants";
import { toggleFavorites, toggleThumbs, updateUserPaymentStatus } from "../../services/user";
import {
	convertToCsaKey, convertToDefKey, formatDateForAvailability,
	getMovieAdditionalInfo, getUserInfo, getStarWidth, isGuest, t
} from "../../utils";
import { isCanadian } from "../../utils/getLocalTimeZone";
 
class MoviePageHeader extends React.Component {
	constructor( props ) {
		super( props );

		this.favoriteList = [];
		this.thumbUpList = [];
		this.thumbDownList = [];
	}; 

	btnSrcIsInList = movieId => {
		const { language } = this.props;
        this.favoriteList = this.props.favorites;
		if ( this.favoriteList.includes( movieId ) ) {
            return {
                bubble: <div className="bubble bubble-bottom">{ t( 'shared:listRemoveTo', language ) }</div>,
                class: 'movie_icon selected',
                src: ICONS.checked
            };
        } 
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:listAddTo', language ) }</div>,
            class: 'movie_icon',
            src: ICONS.favorites
        };
    };

	btnSrcThumbUp = movieId => {
		const { language } = this.props;
		this.thumbUpList = this.props.thumbsUp;
		if ( this.thumbUpList.includes( movieId ) ) {
            return { 
                bubble: <></>, 
                class: 'movie_icon selected', 
                src: ICONS.thumbFilled 
            };
        }
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:like', language ) }</div>,
            class: 'movie_icon',
            src: ICONS.thumb
        };
    };

	btnSrcThumbDown = movieId => {
		const { language } = this.props;
		this.thumbDownList = this.props.thumbsDown;
        if ( this.thumbDownList.includes( movieId ) ) {
            return { 
                bubble: <></>, 
                class: 'movie_icon icon_revert selected', 
                src: ICONS.thumbFilled 
            };
        }
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:dislike', language ) }</div>,
            class: 'movie_icon icon_revert',
            src: ICONS.thumb
        };
    };

	checkEpisodeToStart = () => {
        const { seriesLocal, searchedMovies } = this.props;
        if ( ! searchedMovies[ 0 ].movies[ 0 ].episodes ) return;
        let episodes = searchedMovies[ 0 ].movies[ 0 ].episodes;
        let index = null;
        seriesLocal.forEach( ( series, i ) => { if ( series.transaction === searchedMovies[ 0 ].movies[ 0 ].transaction ) index = i } );
        if ( index !== null ) episodes = seriesLocal[index].episodes;
    
        return [ ...episodes ].sort((a, b)  => b.updatedAt - a.updatedAt)[0]; 
    };

	getPlayBtnText = () => {
        const { language, userDiscarded } = this.props;

        if ( isGuest() ) return t( 'shared:play', language );
        if ( userDiscarded ) return t( 'freemium:reactivate', language );
        return t( 'shared:play', language );
    };

	goToChangePaymentPage = () => {
        const { changePaymentToken } = this.props;
        if ( changePaymentToken && changePaymentToken !== '' ) {
            updateUserPaymentStatus( `?token=${ changePaymentToken }&type=discarded` );
            window.location.href = "/modifier-paiement/?session_id=" + changePaymentToken;
        } 
	};

	toggleInMyList = ( e, movieId ) => {
        e.stopPropagation();
        if ( isGuest() ) return;
        
		const { profileVersion } = this.props;
		const action = this.props.favorites.includes( movieId ) ? 'remove' : 'add';
        let newFavoriteList = this.favoriteList;
        newFavoriteList = ( action === 'remove' ) 
            ? newFavoriteList.splice( newFavoriteList.indexOf( movieId ), 1 )
            : newFavoriteList.push( movieId );	
        this.setState( { favoriteList: newFavoriteList } );

        toggleFavorites( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ movieId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
    };

	toggleThumbDown = ( e, movieId ) => {
        e.stopPropagation();
        if ( isGuest() ) return;

		const { profileVersion } = this.props;
        const action = this.props.thumbsDown.includes( movieId ) ? 'remove-down' : 'add-down';
        let newThumbDownList = this.thumbDownList;
        let newThumbUpList = this.thumbUpList;
        newThumbDownList = ( action === 'remove-down' ) 
            ? newThumbDownList.splice( newThumbDownList.indexOf( movieId ), 1 )
            : newThumbDownList.push( movieId );	
        if ( action === 'add-down' && newThumbUpList.includes( movieId ) ) newThumbUpList.splice( newThumbUpList.indexOf( movieId ), 1 );
        this.setState( { thumbDownList: newThumbDownList, thumbUpList: newThumbUpList } );

        toggleThumbs( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ movieId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
	};

	toggleThumbUp = ( e, movieId ) => {
        e.stopPropagation();
        if ( isGuest() ) return;

		const { profileVersion } = this.props;
        const action = this.props.thumbsUp.includes( movieId ) ? 'remove-up' : 'add-up';
        let newThumbDownList = this.thumbDownList;
        let newThumbUpList = this.thumbUpList;
        newThumbUpList = ( action === 'remove-up' ) 
            ? newThumbUpList.splice( newThumbUpList.indexOf( movieId ), 1 )
            : newThumbUpList.push( movieId );
        if ( action === 'add-up' && newThumbDownList.includes( movieId ) ) newThumbDownList.splice( newThumbDownList.indexOf( movieId ), 1 );    	
        this.setState( { thumbDownList: newThumbDownList, thumbUpList: newThumbUpList } );

        toggleThumbs( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ movieId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
    };

	render() {
		const { language, onClickFullscreen, onClickFullscreenAddOn, playBtnClick, searchedMovies, userDiscarded } = this.props;

		let movie = null;
		if ( searchedMovies && searchedMovies.length > 0 && searchedMovies[ 0 ].movies.length > 0 ) movie = searchedMovies[ 0 ].movies[ 0 ];

		return (
			<>
				{
					searchedMovies && searchedMovies.length > 0 &&
					<>
					<div className="soon-container">
						<div className="soon-image-overlay-outside"></div>
						<div className="soon-image">
							<img
								src={ `${API_IMAGE}${ movie.backgroundFileName || movie.thumbnailFileName }.jpg` }
								alt={ movie.imageAlt || movie.titre } />	
							<div className="soon-image-overlay"></div>	
						</div>
						<div className="soon-content">
							{
								movie[ t( 'keys:titlePngFileName', language ) ] ?
								<div className="soon-img-title">
									<img 
										alt={ `logo ${ movie.imageAlt || movie.titre }` }
										src={ `${ API_IMAGE }${ movie[ t( 'keys:titlePngFileName', language ) ] }.png&h=160&crop-to-fit` }
										onError={ e => e.target.style.display = 'none' } />
								</div>    
								:
								<div className="soon-title">
									{ movie[ t( 'keys:titre', language ) ] ? movie[ t( 'keys:titre', language ) ] : movie.titre }
								</div>
							}
							{
								searchedMovies[ 0 ].sheet_state === 'offline' ?
								<>
									{
										movie.onlineDate && movie.onlineDate > 0 ?
										<>
											{ movie.clockStyle === DISPLAY.CLOCK_CARDS && <CountDownCards onlineDate={ movie.onlineDate } /> }
											{ movie.clockStyle === DISPLAY.CLOCK_CLASSIC && <CountDownClassic onlineDate={ movie.onlineDate } /> }
											<div className="soon-date">{ formatDateForAvailability( movie.onlineDate, language ) }</div>
										</>
										:
										<></>
									}
									{
										movie.trailer && movie.trailer.length > 0 &&
										<div className='movie_btns'>
											<button
												className="btn-movie btn-trailer collect_flag"
												onClick={ () => onClickFullscreenAddOn( 'trailer' ) }
												id={ `playTrailerBtn--cat-header-movie-${ movie.transaction }` } >
												<i className="fa fa-play icnBtn" aria-hidden="true"></i>{ t( 'shared:trailer', language ) }
											</button>
										</div>
									}
								</>
								:
								<>
                                    <div className='movie_btns'>
											<button
												className="btn-movie collect_flag"
												onClick={ () => movie.type === "series" ? playBtnClick( this.checkEpisodeToStart(), 'episode' ) : onClickFullscreen() }
												id={ `playBtn--cat-header-movie-${ movie.transaction }` } >
												<i className="fa fa-play icnBtn" aria-hidden="true"></i>
												{ this.getPlayBtnText() }          
											</button>
											{
												movie.trailer && movie.trailer.length > 0 &&
												<button
													className="btn-movie btn-trailer collect_flag"
													onClick={ () => onClickFullscreenAddOn( 'trailer' ) }
													id={ `playTrailerBtn--cat-header-movie-${ movie.transaction }` } >
													<i className="fa fa-play icnBtn" aria-hidden="true"></i>{ t( 'shared:trailer', language ) }
												</button>
											}	
											{
												! isGuest() && ! userDiscarded && movie.bonus && movie.bonus.length > 0 &&
												<button
													className="btn-movie btn-bonus collect_flag"
													onClick={ () => onClickFullscreenAddOn( 'bonus' ) }
													id={ `playBonusBtn--cat-header-movie-${ movie.transaction }` } >
													<i className="fa fa-play icnBtn" aria-hidden="true"></i>{ t( 'shared:bonus', language ) }
												</button>
											}	
									</div>
									{
										movie.type === "series" &&
										<div className="episode-serie">
											{ this.checkEpisodeToStart()[ t( 'keys:titre', language ) ] ? this.checkEpisodeToStart()[ t( 'keys:titre', language ) ] : this.checkEpisodeToStart().titre }
										</div>
									}
									<div className='movie_btns'>
										<div className="icons_box">
											<div
												className={ `movie_btn_box collect_flag ${ isGuest() && 'disabled' }` }
												onClick={ e => this.toggleInMyList( e, movie.transaction ) }
												id={ `favoritesBtn--cat-header-movie-${ movie.transaction }` } >
												<div className='icon_box'>
													<img
														className={ this.btnSrcIsInList( movie.transaction ).class }
														src={ this.btnSrcIsInList( movie.transaction ).src }
														alt='icone favoris' />
													{ this.btnSrcIsInList( movie.transaction ).bubble }    
												</div>
											</div>
											<div
												className={ `movie_btn_box collect_flag ${ isGuest() && 'disabled' }` }
												onClick={ e => this.toggleThumbUp( e, movie.transaction ) }
												id={ `thumbUpBtn--cat-header-movie-${ movie.transaction }` } >
												<div className='icon_box'>
													<img
														className={ this.btnSrcThumbUp( movie.transaction ).class }
														src={ this.btnSrcThumbUp( movie.transaction ).src }
														alt='icone puce en haut' />
													{ this.btnSrcThumbUp( movie.transaction ).bubble }    
												</div>
											</div>
											<div
												className={ `movie_btn_box collect_flag ${ isGuest() && 'disabled' }` }
												onClick={ e => this.toggleThumbDown( e, movie.transaction ) }
												id={ `thumbDownBtn--cat-header-movie-${ movie.transaction }` } >
												<div className='icon_box'>
													<img
														className={ this.btnSrcThumbDown( movie.transaction ).class }
														src={ this.btnSrcThumbDown( movie.transaction ).src }
														alt='icone favoris' />
													{ this.btnSrcThumbDown( movie.transaction ).bubble }
												</div>
											</div>
										</div>
									</div>
								</>
							}
							{
								<div className="movie_additional_info">
									<span>{ getMovieAdditionalInfo( movie, language ) }</span>
									{
										movie.csa && movie.csa !== '' &&
										<img className="movie_icon"
											src={ ICONS[ convertToCsaKey( movie.csa ) ] }
											alt='icone csa' />
									}
									{
										movie.definition && movie.definition !== '' &&
										<img className="movie_icon"
											src={ ICONS[ convertToDefKey( movie.definition ) ] }
											alt='icone definition' />
									}
								</div>
							}
							<div className='movie_popup_categories'>
								{
									isCanadian() && movie.categories_Canada && movie.categories_Canada.length > 0 ?
									<>
									{
										movie.categories_related_Canada && movie.categories_related_Canada.length > 0 &&
										movie.categories_related_Canada.map( ( el, key ) => ( 
											<span
												key={ key }
												className={ `catSpan catSpan-web ${ el.url ? 'catSpanLinked' : '' }` }
												onClick={ () => { if ( el.url ) window.location.href=`/browse/?search=${ el.url }/#/search/${ el.url }` } } >
												{ el[ t( 'keys:name', language ) ] ? el[ t( 'keys:name', language ) ] : el.name  }
											</span>
										))
									}
									{
										movie.categories_Canada && movie.categories_Canada.length > 0 &&
										movie.categories_Canada.map( ( el, key ) => (
											<span
												key={ key }
												className={ `catSpan catSpan-mobile ${ el.url ? 'catSpanLinked' : '' }` }
												onClick={ () => { if ( el.url ) window.location.href=`/browse/?search=${ el.url }/#/search/${ el.url }` } } >
												{ el[ t( 'keys:name', language ) ] ? el[ t( 'keys:name', language ) ] : el.name  }
											</span>                
										))
									}
									</>
									:
									<>
									{
										movie.categories_related && movie.categories_related.length > 0 &&
										movie.categories_related.map( ( el, key ) => ( 
											<span
												key={ key }
												className={ `catSpan catSpan-web ${ el.url ? 'catSpanLinked' : '' }` }
												onClick={ () => { if ( el.url ) window.location.href=`/browse/?search=${ el.url }/#/search/${ el.url }` } } >
												{ el[ t( 'keys:name', language ) ] ? el[ t( 'keys:name', language ) ] : el.name  }
											</span>
										))
									}
									{
										movie.categories && movie.categories.length > 0 &&
										movie.categories.map( ( el, key ) => (
											<span
												key={ key }
												className={ `catSpan catSpan-mobile ${ el.url ? 'catSpanLinked' : '' }` }
												onClick={ () => { if ( el.url ) window.location.href=`/browse/?search=${ el.url }/#/search/${ el.url }` } } >
												{ el[ t( 'keys:name', language ) ] ? el[ t( 'keys:name', language ) ] : el.name  }
											</span>                
										))
									}
									</>
								}
							</div>
							{
								movie.synopsis_txt ?
								<div className="soon-description">
									{ movie[ t( 'keys:synopsis_txt', language ) ] ? movie[ t( 'keys:synopsis_txt', language ) ] : movie.synopsis_txt }
								</div>
								:
								<></>
							}
							{
								movie.rating ?
								<div className="soon-rate-star-box">
									<div className="soon-rate-average">
										{ movie.rating.average.toString().replace( '.', ',' ) }
									</div>
									<div className="soon-rate-star">
										<i className="far fa-star"></i>
										<span
											style={{ width: `${ getStarWidth( 1, movie.rating.average ) }px` }}>
											<i className="fas fa-star"></i>
										</span>
									</div>
									<div className="soon-rate-star">
										<i className="far fa-star"></i>
										<span
											style={{ width: `${ getStarWidth( 2, movie.rating.average ) }px` }}>
											<i className="fas fa-star"></i>
										</span>
									</div>
									<div className="soon-rate-star">
										<i className="far fa-star"></i>
										<span
											style={{ width: `${ getStarWidth( 3, movie.rating.average ) }px` }}>
											<i className="fas fa-star"></i>
										</span>
									</div>
									<div className="soon-rate-star">
										<i className="far fa-star"></i>
										<span
											style={{ width: `${ getStarWidth( 4, movie.rating.average ) }px` }}>
											<i className="fas fa-star"></i>
										</span>
									</div>
									<div className="soon-rate-star">
										<i className="far fa-star"></i>
										<span
											style={{ width: `${ getStarWidth( 5, movie.rating.average ) }px` }}>
											<i className="fas fa-star"></i>
										</span>
									</div>
									<div className="soon-rate-count">
										{ movie.rating.count } { t( 'shared:opinion', language ) }
									</div>
								</div>
								:
								<></>
							}
						</div>
						<div className="soon-happy-ending-revert"></div>
					</div>
					<div className="soon-happy-ending"></div>
					</>
				}
			</>
		);
	}
}

const stateToProps = state => ({
	changePaymentToken: state.userReducer.changePaymentToken,
	favorites: state.userReducer.favorites,
	language: state.translatingReducer.language,
	profileVersion: state.userReducer.profileVersion,
	searchedMovies: state.moviesReducer.searchedMovies,
	seriesLocal: state.moviesReducer.seriesLocal,
	thumbsUp: state.userReducer.thumbsUp,
	thumbsDown: state.userReducer.thumbsDown,
	userDiscarded: state.userReducer.userDiscarded,
});

export default connect( stateToProps )( MoviePageHeader );