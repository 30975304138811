import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';

import { API_IMAGE, ICONS, IMAGES } from "../../constants";
import { userAction } from "../../redux/actions";
import { t } from "../../utils";

class PMChange extends React.Component {
    getAvatarPath = avatar => {
        return avatar && avatar !== '' ? `${ API_IMAGE }${ avatar }.jpg` : IMAGES.avatarDefault;
    };

    handleProfileEdit = version => {
        const { fetchProfile, handlePageChange, userInfoDB } = this.props;

        if ( userInfoDB ) {
            if ( version === 0 ) {
                fetchProfile({
                    type: 'edit',
                    firstName: userInfoDB.Prenom,
                    avatar: userInfoDB.avatar,
                    avatarId: userInfoDB.avatarId,
                    version: version,
                });
            } else {
                if ( userInfoDB.profiles && userInfoDB.profiles.length > 0 ) {
                    for ( let i = 0; i < userInfoDB.profiles.length; i++ ) {
                        if ( version === userInfoDB.profiles[ i ].version ) {
                            fetchProfile({
                                type: 'edit',
                                firstName: userInfoDB.profiles[ i ].firstName,
                                avatar: userInfoDB.profiles[ i ].avatar,
                                avatarId: userInfoDB.profiles[ i ].avatarId,
                                version: version,
                            });
                            break;
                        }
                    }
                }
            }
            
            handlePageChange( 'right', 'edit' );
        }
    };

    render() {
        const { fetchProfile, handlePageChange, language, profileVersion, userInfoDB } = this.props;
        
        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_nav'>
                        <span>{ t( 'profileManager:title', language ) }</span>
                    </div>
                    <div className='profile_box_center'>
                        <div id='profile_header'  className='profile_header_title'>
                            <h1>{ t( 'profileManager:change', language ) }</h1>
                        </div>
                    </div>
                    {
                        userInfoDB && userInfoDB.NoCli &&
                        <div className='profile_edit_usual profileManager'>
                            <div className='profile_edit_usual_current'>
                                <div className='user-avatar-box'>
                                    <div className='avatar_box' onClick={ () => { fetchProfile({ noCli: userInfoDB.NoCli, type: 'select', version: 0 }) } }>
                                        <img
                                            className={ `user-avatar ${ profileVersion === 0 ? 'selected' : '' }` }
                                            src={ this.getAvatarPath( userInfoDB.avatar ) }
                                            alt='avatar' />
                                    </div> 
                                    <div className='user-avatar-first_name'>{ userInfoDB.Prenom }</div>
                                    <div className='profile_edit_usual_btn'>
                                        <button className='collect_flag' id='edit_email_submit_btn' onClick={ () => { this.handleProfileEdit( 0 ) } }>
                                            { t( 'shared:edit', language ) }
                                        </button>
                                    </div>
                                </div>
                                {
                                    userInfoDB.profiles && userInfoDB.profiles.length > 0 &&
                                    userInfoDB.profiles.map( ( el, index ) => (
                                        <div className='user-avatar-box' key={ index }>
                                            <div className='avatar_box' onClick={ () => { fetchProfile({ noCli: userInfoDB.NoCli, type: 'select', version: el.version }) } }>
                                                <img
                                                    className={ `user-avatar ${ profileVersion === el.version ? 'selected' : '' }` }
                                                    src={ this.getAvatarPath( el.avatar ) }
                                                    alt='avatar' />
                                            </div> 
                                            <div className='user-avatar-first_name'>{ el.firstName }</div>
                                            <div className='profile_edit_usual_btn'>
                                                <button className='collect_flag' id='edit_email_submit_btn' onClick={ () => { this.handleProfileEdit( el.version ) } }>
                                                    { t( 'shared:edit', language ) }
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    userInfoDB.profiles && userInfoDB.profileMax && userInfoDB.profileMax > userInfoDB.profiles.length + 1 &&
                                    <div className='user-avatar-box'>
                                        <div className='avatar_box avatar_box_add' onClick={ () => { handlePageChange( 'right', 'add' ) } }>
                                            <img src={ ICONS.favorites } alt='icone ajouter' />
                                        </div>
                                        <div className='user-avatar-first_name'>
                                            { t( 'shared:add', language ) }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
    profileVersion: state.userReducer.profileVersion,
	userInfoDB: state.userReducer.userInfoDB,
});

const dispatchToProps = dispatch => ({
	fetchProfile: data => dispatch(userAction.fetchProfile( data )),
});

export default connect( stateToProps, dispatchToProps )( PMChange );