import React from 'react';
import { connect } from "react-redux";

import HomeList from "./HomeList";

import { routingAction } from "../../redux/actions";

class Preferences extends React.Component {
    componentDidMount() {
        const { fetchCurrentRoutingByUrl } = this.props;
        fetchCurrentRoutingByUrl( 'preferences' );
  	};

    render() {
        return (
            <div className="homepage-container">
                <HomeList />
            </div>
        )
    }
}

const stateToProps = state => ({});

const dispatchToProps = dispatch => ({
	fetchCurrentRoutingByUrl: url => dispatch( routingAction.fetchCurrentRoutingByUrl( url )),
});

export default connect( stateToProps, dispatchToProps )( Preferences );