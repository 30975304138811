import React from 'react';

import PMRouter from "./PMRouter";

class ProfileManager extends React.Component {
    render() {
        return (
            <div className="homepage-container special-nav-menu">
                <PMRouter />
            </div>
        )
    }
}

export default ProfileManager;