import React from 'react';

import ListHeader from '../ListRows/ListHeader';
import ListProducts from '../ListRows/ListProducts';

import { API_IMAGE } from '../../constants';

export default class RowProductsRender extends React.Component {
    getClassName = display => {
        return `GBListItem store-row ${ display.color || '' } ${ display.effect || '' } ${ display.extraClass || '' }`;
    };

    getStyle = display => {
        return display.effect === 'row-image' && display.imagePath !== '' ?
            { backgroundImage: `url(${API_IMAGE}${ display.imagePath }.jpg&h=720&crop-to-fit&q=40)` }
            :
            {}
        ;
    };

    render() {
        const { productListInfo } = this.props;

        if ( productListInfo && productListInfo.length > 0 ) {
			productListInfo.forEach( (item, key) => {
				productListInfo[ key ].currentDisplay = [ ...item.products ];
				if ( item.addon && item.addon.length > 0 ) {
					item.addon.forEach( item_ => 
						productListInfo[ key ].currentDisplay.splice( item_.index, 0, item_ )
					)
				}
			})
		}

        return (
            <>
                {
                    productListInfo && productListInfo.length > 0 &&
                    productListInfo.map( ( item, index ) =>
                        <div className="list-item" key={ index }>
                            <div className={ this.getClassName( item.display ) } style={ this.getStyle( item.display ) }>
                                {
                                    item.display.linked ?
                                    <div className="goTo_box collect_flag" id={ `btn-arrow-${ item.display.id }` } onClick={ () => window.location.href = item.display.href }>
                                        <ListHeader display={ item.display } />
                                    </div>
                                    :
                                    <ListHeader display={ item.display } />
                                }
                                <ListProducts
                                    currentDisplay={ item.currentDisplay }
                                    display={ item.display }
                                    productList={ item.products }
                                    productListIndex={ item.display.id } />
                            </div>
                        </div>
                    )
                }
            </>
        )
    }
}