import React from "react";
import { connect } from "react-redux";

import { API_IMAGE } from "../../constants";
import { storeAction } from "../../redux/actions";

class ListCatsStore extends React.Component {
	constructor(props) {
		super(props);
		
		this.categoriesList = React.createRef();
		this.arrowLeft = React.createRef();
		this.arrowRight = React.createRef();
		this.animeScroll = null;
	};

	componentDidMount() {
		const { fetchStoreCatsList } = this.props;
		fetchStoreCatsList();
	};

	componentWillUnmount() { 
		clearInterval( this.animeScroll );
		window.removeEventListener( 'mouseup',() => { clearInterval( this.animeScroll ); } );
	};

	checkScroll = () => {
		if ( this.categoriesList.current.scrollLeft === 0 ) {
			this.arrowLeft.current.classList.add( 'disabled' );
		} else {
			this.arrowLeft.current.classList.remove( 'disabled' );
		}
		if ( this.categoriesList.current.scrollWidth - this.categoriesList.current.clientWidth === this.categoriesList.current.scrollLeft ) {
			this.arrowRight.current.classList.add( 'disabled' );
		} else {
			this.arrowRight.current.classList.remove( 'disabled' );
		}
	};

	scrollLeft = () => {
		this.animeScroll = setInterval( () => { this.categoriesList.current.scrollLeft -= 5; this.checkScroll(); }, 10);
		window.addEventListener( 'mouseup',() => { clearInterval( this.animeScroll ); } );
	};

	scrollRight = () => {
		this.animeScroll = setInterval( () => { this.categoriesList.current.scrollLeft += 5; this.checkScroll(); }, 10);
		window.addEventListener( 'mouseup',() => { clearInterval( this.animeScroll ); } );
	};

	render() {
		const { storeCatsList } = this.props;

		return (
			<>
				{
					storeCatsList && storeCatsList.length > 0 ?
					storeCatsList.map( item =>
						item.cats && item.cats.length > 0 &&
						<div className="categories-list-box store-list" key={ item.display.id }>
							<div className="categories-list-title">{ item.display.name }</div>
							<div className="arrow arrow-left" onMouseDown={ this.scrollLeft } ref={ this.arrowLeft }><i className="fas fa-chevron-left"></i></div>	
							<div className="arrow arrow-right" onMouseDown={ this.scrollRight } ref={ this.arrowRight }><i className="fas fa-chevron-right"></i></div>
							<div className="categories-list" ref={ this.categoriesList }>
								{
									item.cats.map( item_ =>
										<a
											className="category-box collect_flag"
											href={ `/browse/?boutique=${item_.url}/#/boutique/${item_.url}` }
											key={ item_.id }
											id={ `${ item.display.id }-store-category-circle-${item_.id}` }>
											<div className="category-item store-category">
												<img
													className="category-img"
													src={ `${API_IMAGE}${item_.imagePathCircle && item_.imagePathCircle !== '' ? item_.imagePathCircle + '.png&w=200&h=200&&q=40' : 'category-default.png&q=40' }` }
													alt={ item_.name }/>
												<div className="category-name">
													<div>{ item_.name }</div>
												</div>    
											</div>
										</a> 
									)
								}
							</div>
						</div>
					)
					:
					<></>
				}
			</>	
		);
	}
}

const stateToProps = state => ({
	storeCatsList: state.storeReducer.storeCatsList,
});

const dispatchToProps = dispatch => ({
	fetchStoreCatsList: () => dispatch(storeAction.fetchStoreCatsList()),
});

export default connect( stateToProps, dispatchToProps )( ListCatsStore );