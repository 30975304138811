import React from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";

import CountDownCards from "../Clock/CountDownCards";
import CountDownClassic from "../Clock/CountDownClassic";
import VideoPlayer from "../../components/Videoplayer";

import { API_IMAGE, DISPLAY, ICONS } from "../../constants";
import { toggleFavorites, toggleThumbs, updateUserPaymentStatus } from "../../services/user";
import { formatDateForAvailability, getUserInfo, isGuest, t } from "../../utils";
 
class SoonAvailableHeader extends React.Component {
	static contextTypes = { router : Proptypes.object };

	constructor( props, context ) {
		super( props, context );

		this.logBtnExit = this.logBtnExit.bind(this);

		this.state = {
			isTrailerMuted: true,
			playerMovieShow: false,
			playerMovieHeight: 0,
			playerMovieWidth: 0,
			playerTrailerShow: false,
			playerTrailerStarted: false,
			playerTrailerHeight: 0,
			playerTrailerWidth: 0,
			videoMovieJsOptions: { autoplay: false, playing: false },
			videoTrailerJsOptions: { autoplay: false, playing: false },
		};

		this.favoriteList = [];
		this.thumbUpList = [];
		this.thumbDownList = [];

		this.trailerTimeout = null;
	}; 

	componentDidMount() {
		this.backListener = this.context.router.history.listen( ( location, action, state ) => {
			if ( action === "POP" && this.state.playerMovieShow && location.hash !== '#play/' ) this.logBtnExit();
		});

		const { soonAvailableHeader } = this.props;
		if ( soonAvailableHeader && soonAvailableHeader.length > 0 && soonAvailableHeader[ 0 ].display.trailer && soonAvailableHeader[ 0 ].display.trailer.length > 0 ) {
			this.trailerTimeout = setTimeout( this.playTrailer, 2000);
		}
	};

	componentWillReceiveProps( newProps ) {
		if ( newProps.isPlayingStarted ) this.setState({ playerTrailerStarted: true });
		return null;
	};

	componentWillUnmount() {
		clearTimeout( this.trailerTimeout );
	};

	btnSrcIsInList = movieId => {
		const { language } = this.props;
        this.favoriteList = this.props.favorites;
		if ( this.favoriteList.includes( movieId ) ) {
            return {
                bubble: <div className="bubble bubble-bottom">{ t( 'shared:listRemoveTo', language ) }</div>,
                class: 'movie_icon selected',
                src: ICONS.checked
            };
        } 
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:listAddTo', language ) }</div>,
            class: 'movie_icon',
            src: ICONS.favorites
        };
    };

	btnSrcThumbUp = movieId => {
		const { language } = this.props;
		this.thumbUpList = this.props.thumbsUp;
		if ( this.thumbUpList.includes( movieId ) ) {
            return { 
                bubble: <></>, 
                class: 'movie_icon selected', 
                src: ICONS.thumbFilled 
            };
        }
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:like', language ) }</div>,
            class: 'movie_icon',
            src: ICONS.thumb
        };
    };

	btnSrcThumbDown = movieId => {
		const { language } = this.props;
		this.thumbDownList = this.props.thumbsDown;
        if ( this.thumbDownList.includes( movieId ) ) {
            return { 
                bubble: <></>, 
                class: 'movie_icon icon_revert selected', 
                src: ICONS.thumbFilled 
            };
        }
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:dislike', language ) }</div>,
            class: 'movie_icon icon_revert',
            src: ICONS.thumb
        };
    };

	getPlayBtnText = () => {
        const { language, userDiscarded } = this.props;

        if ( isGuest() ) return t( 'shared:play', language );
        if ( userDiscarded ) return t( 'freemium:reactivate', language );
        return t( 'shared:play', language );
    };

	goToChangePaymentPage = () => {
        const { changePaymentToken } = this.props;
        if ( changePaymentToken && changePaymentToken !== '' ) {
            updateUserPaymentStatus( `?token=${ changePaymentToken }&type=discarded` );
            window.location.href = "/modifier-paiement/?session_id=" + changePaymentToken;
        } 
	};

	logBtnExit = () => {
		if( document.location.hash.includes( "#play/" ) ) this.context.router.history.goBack();
		this.setState({ playerMovieShow: false, playerMovieHeight: 0, playerMovieWidth: 0, videoMovieJsOptions: { autoplay: false, playing: false } });
	};

	onClickFullscreen = () => {
		const { soonAvailableHeader, userDiscarded } = this.props;

		if ( isGuest() ) {
			window.location.href = "/offres/";
			return;
		} else if ( userDiscarded ) {
			this.goToChangePaymentPage();
			return;
		}
			
		this.setState({
			playerMovieShow: true,
			playerMovieHeight: "100%",
			playerMovieWidth: "100%",
			videoMovieJsOptions: {
				logBtnExit: this.logBtnExit,
				movieKey: 0,
				movieList: soonAvailableHeader[ 0 ].movies, 
				playing: true,
				progressInfo: null,
			},
		});

		window.location.href = window.location.href + "#play/";
	};

	playTrailer = () => {
		const { soonAvailableHeader, version } = this.props;
		const { isTrailerMuted } = this.state;

		const index = version % soonAvailableHeader[ 0 ].display.trailer.length;
		let movieListWithCurrentTrailer = soonAvailableHeader[ 0 ].display;
		movieListWithCurrentTrailer.trailer = [ soonAvailableHeader[ 0 ].display.trailer[ index ] ];

		this.setState({
			playerTrailerShow: true,
			playerTrailerHeight: "100%",
			playerTrailerWidth: "100%",
			videoTrailerJsOptions: {
				isTrailer: true,
				isTrailerHeader: true,
				movieKey: 0,
				movieList: [ movieListWithCurrentTrailer ], 
				playing: true,
				progressInfo: null,
				controls: false,
				muted: isTrailerMuted,
			},
		});
	};

	reloadTrailer = () => this.setState({ videoTrailerJsOptions: { playing: true, reload: true } });
	toggleSound = () => this.setState({ isMuted: ! this.state.isTrailerMuted, videoTrailerJsOptions: { muted: ! this.state.isTrailerMuted } });

	toggleInMyList = ( e, movieId ) => {
        e.stopPropagation();
        if ( isGuest() ) return;
        
		const { profileVersion } = this.props;
		const action = this.props.favorites.includes( movieId ) ? 'remove' : 'add';
        let newFavoriteList = this.favoriteList;
        newFavoriteList = ( action === 'remove' ) 
            ? newFavoriteList.splice( newFavoriteList.indexOf( movieId ), 1 )
            : newFavoriteList.push( movieId );	
        this.setState( { favoriteList: newFavoriteList } );

        toggleFavorites( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ movieId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
    };

	toggleThumbDown = ( e, movieId ) => {
        e.stopPropagation();
        if ( isGuest() ) return;

		const { profileVersion } = this.props;
        const action = this.props.thumbsDown.includes( movieId ) ? 'remove-down' : 'add-down';
        let newThumbDownList = this.thumbDownList;
        let newThumbUpList = this.thumbUpList;
        newThumbDownList = ( action === 'remove-down' ) 
            ? newThumbDownList.splice( newThumbDownList.indexOf( movieId ), 1 )
            : newThumbDownList.push( movieId );	
        if ( action === 'add-down' && newThumbUpList.includes( movieId ) ) newThumbUpList.splice( newThumbUpList.indexOf( movieId ), 1 );
        this.setState( { thumbDownList: newThumbDownList, thumbUpList: newThumbUpList } );

        toggleThumbs( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ movieId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
	};

	toggleThumbUp = ( e, movieId ) => {
        e.stopPropagation();
        if ( isGuest() ) return;

		const { profileVersion } = this.props;
        const action = this.props.thumbsUp.includes( movieId ) ? 'remove-up' : 'add-up';
        let newThumbDownList = this.thumbDownList;
        let newThumbUpList = this.thumbUpList;
        newThumbUpList = ( action === 'remove-up' ) 
            ? newThumbUpList.splice( newThumbUpList.indexOf( movieId ), 1 )
            : newThumbUpList.push( movieId );
        if ( action === 'add-up' && newThumbDownList.includes( movieId ) ) newThumbDownList.splice( newThumbDownList.indexOf( movieId ), 1 );    	
        this.setState( { thumbDownList: newThumbDownList, thumbUpList: newThumbUpList } );

        toggleThumbs( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ movieId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
    };

	render() {
		const { language, soonAvailableHeader, version } = this.props;
		const {
			playerMovieShow, playerMovieHeight, playerMovieWidth, videoMovieJsOptions,
			isTrailerMuted, playerTrailerShow, playerTrailerStarted, playerTrailerHeight, playerTrailerWidth, videoTrailerJsOptions,
		} = this.state;

		let description = {};
		let movie = null;
		if ( soonAvailableHeader && soonAvailableHeader.length > 0 ) {
			description = soonAvailableHeader[ 0 ].display;
			if ( soonAvailableHeader[ 0 ].movies.length > 0 ) movie = soonAvailableHeader[ 0 ].movies[ 0 ];
			if ( description.versionImages &&  description.versionImages.length > 0 ) {
				const index = version % description.versionImages.length;
				if ( description.versionImages[ index ].backgroundFileName ) description.backgroundFileName = description.versionImages[ index ].backgroundFileName;
			}
		}

		return (
			<>
				{
					soonAvailableHeader && soonAvailableHeader.length > 0 &&
					<>
					<div className={ `soon-container ${ playerTrailerShow && playerTrailerStarted ? 'with-trailer' : '' }` }>
						<div className="soon-image-overlay-outside"></div>
						<div className={ `soon-image ${ playerTrailerShow && playerTrailerStarted ? 'with-trailer' : '' }` }>
							<img
								src={ `${API_IMAGE}${ description.backgroundFileName || description.thumbnailFileName }.jpg` }
								alt={ description.alt } />	
							<div className="soon-image-overlay"></div>	
						</div>
						<div className="soon-content">
							{
								description[ t( 'keys:titlePngFileName', language ) ] ?
								<div className="soon-img-title">
									{
										description.titlePngUpFileName ?
										<div className={ `soon-img-title-up ${ description.titlePngUpStyle ? description.titlePngUpStyle : '' }` }>
											<img 
												alt={ `logo ${ description.alt }` }
												src={ `${ API_IMAGE }${ description.titlePngUpFileName }.png&h=160&crop-to-fit` }
												onError={ e => e.target.style.display = 'none' } />
										</div>    
										:
										<></>
									}
									<img 
										alt={ `logo ${ description.alt }` }
										src={ `${ API_IMAGE }${ description[ t( 'keys:titlePngFileName', language ) ] }.png&h=160&crop-to-fit` }
										onError={ e => e.target.style.display = 'none' } />
								</div>    
								:
								<div className="soon-title">
									{
										description.titlePngUpFileName ?
										<div className={ `soon-title-up ${ description.titlePngUpStyle ? description.titlePngUpStyle : '' }` }>
											<img 
												alt={ `logo ${ description.alt }` }
												src={ `${ API_IMAGE }${ description.titlePngUpFileName }.png&h=160&crop-to-fit` }
												onError={ e => e.target.style.display = 'none' } />
										</div>    
										:
										<></>
									}
									<h2>{ description[ t( 'keys:title', language ) ] ? description[ t( 'keys:title', language ) ] : description.title }</h2>
								</div>
							}
							{
								! movie ?
								<>
									{
										description.onlineDate && description.onlineDate > 0 ?
										<>
											{ description.clockStyle === DISPLAY.CLOCK_CARDS && <CountDownCards onlineDate={ description.onlineDate } /> }
											{ description.clockStyle === DISPLAY.CLOCK_CLASSIC && <CountDownClassic onlineDate={ description.onlineDate } /> }
											<div className="soon-date">{ formatDateForAvailability( description.onlineDate, language ) }</div>
										</>
										:
										<></>
									}
								</>
								:
								<>
                                    <div className='movie_btns'>
											<button
												className="btn-movie collect_flag"
												onClick={ () => this.onClickFullscreen() }
												id={ `playBtn--cat-header-movie-${ movie.transaction }` } >
												<i className="fa fa-play icnBtn" aria-hidden="true"></i>
												{ this.getPlayBtnText() }          
											</button>	
									</div>
									<div className='movie_btns'>
										<div className="icons_box">
											<div
												className={ `movie_btn_box collect_flag ${ isGuest() && 'disabled' }` }
												onClick={ e => this.toggleInMyList( e, movie.transaction ) }
												id={ `favoritesBtn--cat-header-movie-${ movie.transaction }` } >
												<div className='icon_box'>
													<img
														className={ this.btnSrcIsInList( movie.transaction ).class }
														src={ this.btnSrcIsInList( movie.transaction ).src }
														alt='icone favoris' />
													{ this.btnSrcIsInList( movie.transaction ).bubble }    
												</div>
											</div>
											<div
												className={ `movie_btn_box collect_flag ${ isGuest() && 'disabled' }` }
												onClick={ e => this.toggleThumbUp( e, movie.transaction ) }
												id={ `thumbUpBtn--cat-header-movie-${ movie.transaction }` } >
												<div className='icon_box'>
													<img
														className={ this.btnSrcThumbUp( movie.transaction ).class }
														src={ this.btnSrcThumbUp( movie.transaction ).src }
														alt='icone puce en haut' />
													{ this.btnSrcThumbUp( movie.transaction ).bubble }    
												</div>
											</div>
											<div
												className={ `movie_btn_box collect_flag ${ isGuest() && 'disabled' }` }
												onClick={ e => this.toggleThumbDown( e, movie.transaction ) }
												id={ `thumbDownBtn--cat-header-movie-${ movie.transaction }` } >
												<div className='icon_box'>
													<img
														className={ this.btnSrcThumbDown( movie.transaction ).class }
														src={ this.btnSrcThumbDown( movie.transaction ).src }
														alt='icone favoris' />
													{ this.btnSrcThumbDown( movie.transaction ).bubble }
												</div>
											</div>
										</div>
									</div>
								</>
							}
							{
								description.description ?
								<div className="soon-description">
									{
										description[ t( 'keys:description', language ) ] ?
										description[ t( 'keys:description', language ) ]
										:
										description.description
									}
								</div>
								:
								<></>
							}
						</div>
						<div className="soon-happy-ending-revert"></div>
						{
							description.trailer && description.trailer.length > 0 &&
							<div className="soon-flex-container">
								<div className="soon-container-relative">
									<div
										id="z3pOverlay"
										className="z3PlayOverlay"
										style={{display : playerTrailerShow ? 'block' : 'none', width: playerTrailerWidth, height: playerTrailerHeight }}>
										<VideoPlayer className="video-js" id="VideoAccueil" { ...videoTrailerJsOptions } />
										<div
											className="soon-container-sound"
											style={{display: playerTrailerShow && playerTrailerStarted ? 'block' : 'none'}}
											onClick={ this.toggleSound }>
											{ isTrailerMuted ? <i className="fa fa-volume-off"></i> : <i className="fa fa-volume-up" aria-hidden="true"></i>}
										</div>
										<div
											className="soon-container-reload"
											style={{display: playerTrailerShow && playerTrailerStarted ? 'block' : 'none'}}
											onClick={ this.reloadTrailer }>
											<i className="fa fa-undo" aria-hidden="true"></i>
										</div>
									</div>
								</div>
							</div>
						}	
					</div>
					<div className="soon-happy-ending"></div>
					<div id="z3pOverlay" className="z3PlayOverlay" style={{display : playerMovieShow ? 'block' : 'none', width: playerMovieWidth, height: playerMovieHeight }}>
						<VideoPlayer className="video-js" id="VideoAccueil" { ...videoMovieJsOptions } />
					</div>
					</>
				}
			</>
		);
	}
}

const stateToProps = state => ({
	changePaymentToken: state.userReducer.changePaymentToken,
	favorites: state.userReducer.favorites,
	isPlayingStarted: state.playerReducer.isPlayingStarted,
	language: state.translatingReducer.language,
	profileVersion: state.userReducer.profileVersion,
	soonAvailableHeader: state.moviesReducer.soonAvailableHeader,
	thumbsUp: state.userReducer.thumbsUp,
	thumbsDown: state.userReducer.thumbsDown,
	userDiscarded: state.userReducer.userDiscarded,
	version: state.routingReducer.version,
});

export default connect( stateToProps )( SoonAvailableHeader );