import { detectBrowser, detectEnv } from "../utils/index";

export const API_IMAGE = 'https://admin-vod.zone300.com/api/cimage/webroot/img.php?src=../../../DATA/TMP/';
export const API_DATA = 'https://vod-dev.zone300.com/api/_fo/v3';
export const URL_VIMEO = 'https://vimeo.com/';

const URLS_PROD = [ 'https://www.zone300.com', 'https://zone300.com' ];
const STRIPE_KEYS = {
	STRIPE_KEY_LIVE: 'pk_live_KKcuT3tsxt0vmMOUfcgB4I21009k0GS3iW',
	STRIPE_KEY_TEST: 'pk_test_dJoVgZ5EuB2tYy2oZ42AoGlp00nmBYi8Dq',
};
export const STRIPE_KEY = ( URLS_PROD.includes( window.location.origin ) ) ? STRIPE_KEYS.STRIPE_KEY_LIVE : STRIPE_KEYS.STRIPE_KEY_TEST;

export const DEVICE_VERSION = "platform_web_" + detectBrowser() + "_" + detectEnv() + "_v240320";

export const DATA_LABEL = 'zone300';
export const DATA_LANGUAGE = 'z3_language';
export const DATA_NOTIF = 'z3_notif';
export const DATA_CART = 'z3_cart';
export const DATA_VLOG = 'z3_vLog';
export const DATA_VLOG_ALT = 'z3_vAbo2';

export const COUNTRY_CODE = {
	CA: 'CA',
	FR: 'FR',
};

export const CURRENCY = {
	CAD: 'cad',
	EUR: 'eur',
};

export const DISPLAY = {
	CLOCK_CARDS: 'cards',
	CLOCK_CLASSIC: 'classic',
    EACH_FRIDAY: 'EachFriday',
    EACH_WEEK: 'EachWeek',
	LINK: 'link',
	MULTIPLE_IMAGES: 'multiple-images',
	NEW: 'New',
	NEW_EPISODE: 'NewEpisode',
	NEW_SEASON: 'NewSeason',
    POSTER: 'poster',
	POSTER_LONG: 'posterLong',
	SINGLETON: 'SINGLETON',
	SLIDER: 'slider',
	SQUARE: 'square',
	SHEET_ID: 'sheetId',
	SOON_OFFLINE: 'soonOffline',
    XL: 'XL',
    XS: 'XS',
    XXL: 'XXL',
	XXS: 'XXS',
};

export const ICONS = {
	arrow: require( "../assets/icon/ionic-md-arrow-back.svg" ),
	backArrow: require( "../assets/icon/arrow-back.svg" ),
	boutique: require( "../assets/icon/boutiqueicon.svg" ),
	card: require( "../assets/icon/icon-card.svg" ),
	chasse: require( "../assets/icon/deer-antlers.svg" ),
	check: require( "../assets/icon/checkWhite.svg" ),
	checked: require( "../assets/icon/check.svg" ),
	csa10: require( "../assets/icon/csa10.svg" ),
	csa12: require( "../assets/icon/csa12.svg" ),
	csa16: require( "../assets/icon/csa16.svg" ),
	csa18: require( "../assets/icon/csa18.svg" ),
	csaAll: require( "../assets/icon/csaAll.svg" ),
	def43: require( "../assets/icon/4-3.svg" ),
	def4K: require( "../assets/icon/4K.svg" ),
	defHD: require( "../assets/icon/high-definition.svg" ),
	download: require( "../assets/icon/download.svg" ),
	facebook: require( "../assets/icon/Icon-logo-facebook.svg" ),
	favorites: require( "../assets/icon/add.svg" ),
	gift: require( "../assets/icon/Icon-gift.svg" ),
	graduation: require( "../assets/icon/icon-graduation.svg" ),
	help: require( "../assets/icon/Icon-ios-help-circle.svg" ),
	home: require( "../assets/icon/home.svg" ),
	info: require( "../assets/icon/Icon-info.svg" ),
	instagram: require( "../assets/icon/Icon-instagram.svg" ),
	like: require( "../assets/icon/thumbs-up.svg" ),
	mail: require( "../assets/icon/Icon-mail.svg" ),
	menuMove: require( "../assets/icon/more_vert_black_24dp.svg" ),
	messenger: require( "../assets/icon/facebook-messenger-brands.svg" ),
	notificationsFill: require( "../assets/icon/notifications-fill.svg" ),
	notificationsOutline: require( "../assets/icon/notifications-outline.svg" ),
	peche: require( "../assets/icon/fish.svg" ),
	pen: require( "../assets/icon/Icon-pen.svg" ),
	phone: require( "../assets/icon/phone-alt.svg" ),
	search: require( "../assets/icon/ios-search.svg" ),
	settings: require( "../assets/icon/Icon-ios-settings.svg" ),
	share: require( "../assets/icon/md-share.svg" ),
	thumb: require( "../assets/icon/thumbs-up.svg" ),
    thumbFilled: require( "../assets/icon/thumbs-up-filled.svg" ), 
	thumbWhite: require( "../assets/icon/thumbs-up-white.svg" ),
	thumbWhiteFilled: require( "../assets/icon/thumbs-up-filled-white.svg" ),
	twitter: require( "../assets/icon/Icon-twitter.svg" ),         
	user: require( "../assets/icon/Icon-user-alt.svg" ),
	youtube: require( "../assets/icon/Icon-youtube.svg" ),
};

export const IMAGES = {
	avatarDefault: require( "../assets/img/user-icon-default.png" ),
	cbAcceptedImg: require( "../assets/img/cb-accepted.png" ),
	logoImg: require( "../assets/img/logo-24_p.png" ),
	placeholderPoster: require( "../assets/img/placeholder-poster.jpg" ),
	placeholderPosterLong: require( "../assets/img/placeholder-posterLong.jpg" ),
	placeholderSquare: require( "../assets/img/placeholder-square.jpg" ),
	placeholderThumbnail: require( "../assets/img/placeholder-thumbnail.jpg" ),
};

export const LANGUAGE = {
	ENGLISH: 'en',
	FRENCH: 'fr',
};

export const COMMENT_TYPE = {
	MOVIE: 'movie',
	PRODUCT: 'product',
	SERIES: 'series',
};

export const COMMENT_REDUCER = {
	FETCH_COMMENT_ITEM: 'FETCH_COMMENT_ITEM',
};

export const MOVIES_REDUCER = {
	API_ERROR: 'API_ERROR',
	FETCH_CATS_LIST: 'FETCH_CATS_LIST',
	FETCH_FAVORITES_MOVIES: 'FETCH_FAVORITES_MOVIES',
	FETCH_FOR_USER_MOVIES: 'FETCH_FOR_USER_MOVIES',
	FETCH_FREE_MOVIE_HEADER: 'FETCH_FREE_MOVIE_HEADER',
	FETCH_MOMENT_MOVIES: 'FETCH_MOMENT_MOVIES',
	FETCH_MOST_VIEWED_MOVIES: 'FETCH_MOST_VIEWED_MOVIES',
	FETCH_NEW_FOR_USER_MOVIES: 'FETCH_NEW_FOR_USER_MOVIES',
	FETCH_NEW_MOVIES: 'FETCH_NEW_MOVIES',
	FETCH_PAGE_MOVIES: 'FETCH_PAGE_MOVIES',
	FETCH_PINNED_FIRST_MOVIES: 'FETCH_PINNED_FIRST_MOVIES',
	FETCH_PINNED_SECOND_MOVIES: 'FETCH_PINNED_SECOND_MOVIES',
	FETCH_PINNED_MOVIES: 'FETCH_PINNED_MOVIES',
	FETCH_PLATFORM_CONFIG: 'FETCH_PLATFORM_CONFIG',
	FETCH_PREFERENCES_LIST: 'FETCH_PREFERENCES_LIST',
	FETCH_PROGRESS_MOVIES: 'FETCH_PROGRESS_MOVIES',
	FETCH_SEARCHED_IFRAME_MOVIES: 'FETCH_SEARCHED_IFRAME_MOVIES',
	FETCH_SEARCHED_MOVIES: 'FETCH_SEARCHED_MOVIES',
	FETCH_SEARCH_PROPOSALS: 'FETCH_SEARCH_PROPOSALS',
	FETCH_SERIES_LOCAL: 'FETCH_SERIES_LOCAL',
	FETCH_SLIDER_MOVIES: 'FETCH_SLIDER_MOVIES',
	FETCH_SOON_AVAILABLE_HEADER: 'FETCH_SOON_AVAILABLE_HEADER',
	FETCH_SPOTLIGHTED_MOVIES: 'FETCH_SPOTLIGHTED_MOVIES',
};

export const PAYMENT_REDUCER = {
	API_ERROR: 'API_ERROR',
	FETCH_CART_INFO: 'FETCH_CART_INFO',
	FETCH_CODE_ID: 'FETCH_CODE_ID',
	FETCH_PRODUCT_ID: 'FETCH_PRODUCT_ID',
	FETCH_PRODUCT_TYPE: 'FETCH_PRODUCT_TYPE',
	FETCH_PRODUCT_PAYMENT_INFO: 'FETCH_PRODUCT_PAYMENT_INFO',
	TYPE_PURCHASE: 'purchase',
	TYPE_RENT: 'rent',
};

export const PLAYLIST_REDUCER = {
	API_ERROR: 'API_ERROR',
	FETCH_PLAYLIST_MOVIE_INDEX: 'FETCH_PLAYLIST_MOVIE_INDEX',
	FETCH_PLAYLIST_MOVIES: 'FETCH_PLAYLIST_MOVIES',
};

export const PLAYER_REDUCER = {
	FETCH_PLAYING_STATE: 'FETCH_PLAYING_STATE',
};

export const POPUP_REDUCER = {
	CLOSE: 'CLOSE',
	OPEN: 'OPEN',
	OPTIONS: 'OPTIONS',
	POPUP_CHANGE_PAYMENT : 'POPUP_CHANGE_PAYMENT',
	POPUP_FORGOTTEN_PASSWORD : 'POPUP_FORGOTTEN_PASSWORD',
	POPUP_LOGIN : 'POPUP_LOGIN',
	POPUP_REGISTRATION : 'POPUP_REGISTRATION',
	POPUP_REGISTRATION_FREE_MOVIE: 'POPUP_REGISTRATION_FREE_MOVIE',
	POPUP_REGISTRATION_PLAYLIST: 'POPUP_REGISTRATION_PLAYLIST',
	POPUP_RESET_PASSWORD : 'POPUP_RESET_PASSWORD',
	POPUP_PAYMENT_CONFIRM : 'POPUP_PAYMENT_CONFIRM',
	POPUP_PAYMENT_INIT : 'POPUP_PAYMENT_INIT',
	POPUP_PAYMENT_SUCCESS : 'POPUP_PAYMENT_SUCCESS',
	POPUP_USER_DISCARDED : 'POPUP_USER_DISCARDED',
};

export const ROUTING_REDUCER = {
	FETCH_CURRENT_ROUTING: 'FETCH_CURRENT_ROUTING',
	FETCH_CURRENT_ROUTING_BY_URL: 'FETCH_CURRENT_ROUTING_BY_URL',
	FETCH_VERSION: 'FETCH_VERSION',
	HIDE_SELECTED: 'HIDE_SELECTED',
};

export const STORE_DEFAULT_CODE = 'DEFAULT_DISCOUNT';
export const STORE_COLISSIMO_URL = 'https://www.laposte.fr/outils/suivre-vos-envois';

export const STORE_PURCHASE_STATES = {
	DELIVERED: 'delivered',
	DELIVERYPROCESS: 'deliveryProcess',
	PAID: 'paid',
};

export const STORE_REDUCER = {
	API_ERROR: 'API_ERROR',
	ADD_PRODUCT_IN_CART: 'ADD_PRODUCT_IN_CART',
	CLEAR_CART: 'CLEAR_CART',
	FETCH_STORE_CATS_LIST: 'FETCH_CATS_LIST',
	FETCH_STORE_PRODUCTS: 'FETCH_STORE_PRODUCTS',
	FETCH_STORE_SELECTED_PRODUCTS: 'FETCH_STORE_SELECTED_PRODUCTS',
	FETCH_STORE_SEARCHED_PRODUCTS: 'FETCH_STORE_SEARCHED_PRODUCTS',
	FETCH_STORE_SLIDER_PRODUCTS: 'FETCH_STORE_SLIDER_PRODUCTS',
	REMOVE_PRODUCT_IN_CART: 'REMOVE_PRODUCT_IN_CART',
	UPDATE_CART: 'UPDATE_CART',
};

export const TRAINING_REDUCER = {
	API_ERROR: 'API_ERROR',
	FETCH_TRAINING_DETAILS_DESCRIPTION: 'FETCH_TRAINING_DETAILS_DESCRIPTION',
	FETCH_TRAINING_MOVIES: 'FETCH_TRAINING_MOVIES',
	FETCH_TRAINING_SLIDER_MOVIES: 'FETCH_TRAINING_SLIDER_MOVIES',
};

export const TRANSLATING_REDUCER = {
	FETCH_LANGUAGE: 'FETCH_LANGUAGE',
};

export const USER_REDUCER = {
	API_ERROR: 'API_ERROR',
	FETCH_AVATARS_LIST: 'FETCH_AVATARS_LIST',
	FETCH_CATEGORIES_LIST: 'FETCH_CATEGORIES_LIST',
	FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
	FETCH_PLANS_LIST: 'FETCH_PLANS_LIST',
	FETCH_PROFILE: 'FETCH_PROFILE',
	FETCH_USER_ADDRESS: 'FETCH_USER_ADDRESS',
	FETCH_USER_GEO_INFO: 'FETCH_USER_GEO_INFO',
	FETCH_USER_INFO: 'FETCH_USER_INFO',
	FETCH_USER_PARTNER_INFO: 'FETCH_USER_PARTNER_INFO',
	FETCH_USER_PURCHASES: 'FETCH_USER_PURCHASES',
	FETCH_USER_STRIPE_CARD: 'FETCH_USER_STRIPE_CARD',
	FETCH_USER_STRIPE_INFO: 'FETCH_USER_STRIPE_INFO',
};

export const VERSION_BROWSE = 'browse-version';
export const VERSION_PROFILE = 'profile-version';
export const VERSION_PROFILE_EDIT = 'profile-version-edit';
export const VERSION_PROFILE_NOCLI = 'profile-version-nocli';