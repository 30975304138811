import { PLAYLIST_REDUCER } from "../../constants";

const initialState = {
    advertisingMovies: [],
    playlistMovies: [],
    playlistMovieIndex: null,
    playlistPeriodId: null,
    playlistTimeProgress: null,
};

export default ( state = initialState, action ) => {
    const newState = Object.assign( {}, state );
    switch ( action.type ) {
        case PLAYLIST_REDUCER.FETCH_PLAYLIST_MOVIE_INDEX:
            newState.playlistMovieIndex = action.payload;
            return newState;
        case PLAYLIST_REDUCER.FETCH_PLAYLIST_MOVIES:
            newState.advertisingMovies = action.payload.advertising;
            newState.playlistMovies = action.payload.movies;
            newState.playlistMovieIndex = action.payload.userProgress.movieIndex ? parseInt( action.payload.userProgress.movieIndex ) : null;
            newState.playlistPeriodId = action.payload.userProgress.periodId ? parseInt( action.payload.userProgress.periodId ) : null;
            newState.playlistTimeProgress = action.payload.userProgress.time ? parseInt( action.payload.userProgress.time ) : null;
            return newState;
        default:
            return state;
    }
};
