import React from "react";

import PMAdd from "./PMAdd";
import PMAvatar from "./PMAvatar";
import PMChange from "./PMChange";
import PMEdit from "./PMEdit";

class PMRouter extends React.Component {
    constructor(props) {
        super(props);

		this.state = {
			direction: 'right',
			step: 'change',
		};
    };

	handlePageChange = ( direction, step ) => {
		window.scrollTo( 0, 0 );
		this.setState({ direction: direction, step: step });
	};
    
    render() {
		switch( this.state.step ) {
			case 'add': return <PMAdd handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'avatarAdd': return <PMAvatar type='add' handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'avatarEdit': return <PMAvatar type='edit' handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'change': return <PMChange handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'edit': return <PMEdit handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			default: return <></>;
		}
	};
}

export default PMRouter;