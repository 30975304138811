import React from "react";

import { API_IMAGE, IMAGES } from "../../constants";

class ProposalImage extends React.Component {
    constructor(props) {
		super(props);

		this.state = {
            imageLoadNoCache: true,
            imageLoadError: true,
		};
	}

    componentDidUpdate( prevProps ) {
		if ( this.props.item.title !== prevProps.item.title ) {
			this.setState({ imageLoadNoCache: true, imageLoadError: true });
		}
	};

    render() {
        const { item } = this.props;

        return (
            <img
                alt={ item.title }
                src={`${API_IMAGE}${ item.img + '.jpg' }&w=500&h=281&crop-to-fit`} 
                onError={e => {
                    if ( this.state.imageLoadNoCache ) {
                        this.setState({ imageLoadNoCache: false });
                        e.target.src =`${API_IMAGE}${ item.img + '.jpg' }&w=500&h=281&crop-to-fit&no-cache`;
                    } else if ( this.state.imageLoadError ) {
                        this.setState({ imageLoadError: false });
                        e.target.src = IMAGES.placeholderThumbnail;
                    }
                }} />
        );
    };
}

export default ProposalImage;