import React from "react";
import { connect }  from "react-redux";

import ButtonClose from "../components/ButtonClose";
import HeaderLogo from "../components/HeaderLogo";

import { DATA_VLOG, POPUP_REDUCER } from "../../../constants";
import { popupAction, userAction } from "../../../redux/actions";
import { authUser } from "../../../services/user";
import { checkEmail, getUrlParams, handleLoader, t } from "../../../utils";
 
class PopupLogin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            errorMessage: ''
        };
    };

    checkErrors = () => {
        const { language } = this.props;

        if ( this.state.email === '' ) {
            this.setState({ errorMessage: t( 'form:emailError', language ) });
            return false;
        } else if ( ! checkEmail( this.state.email ) ) {
            this.setState({ errorMessage: t( 'form:emailValidError', language ) });
            return false;
        } else if ( this.state.password === '' ) {
            this.setState({ errorMessage: t( 'form:passwordEmpty', language ) });
            return false;
        }
        this.setState({ errorMessage: '' });
        return true;
    };

    handleChange = event => {
        let value = event.target.value;
        const name = event.target.name;
        if ( name === 'email' ) value = value.toLowerCase().trim();
        this.setState({ [name]: value });
    };

    handleSubmit = () => {
        const { language } = this.props;

        if ( this.checkErrors() ) {
            handleLoader( 'ils_loader', 'add' );
            const dataUser = { email: this.state.email, password: this.state.password };
            authUser( dataUser )
                .then( res => {
                    if ( ! res.ret ) {
                        switch( res.error ) {
                            case 'authentication':
                                this.setState ({ errorMessage: t( 'form:passwordError', language ) });
                                break;
                            default:
                                this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                        }
                        handleLoader( 'ils_loader', 'remove' );
                        return;
                    } 

                    localStorage.setItem( DATA_VLOG, JSON.stringify( res.data ) );
                    const { fetchUserInfo, popupClose, popupOpen } = this.props;
                    if ( res.data.is_guest === '0' ) fetchUserInfo( res.data.NoCli );
                    handleLoader( 'ils_loader', 'remove' );

                    if ( getUrlParams().cart ) {
                        popupOpen( POPUP_REDUCER.POPUP_PAYMENT_CONFIRM );
                    } else {
                        popupClose( POPUP_REDUCER.POPUP_LOGIN );
                    }
                    window.location.reload();
                })
                .catch( error => {
                    console.log( error );
                    this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                    handleLoader( 'ils_loader', 'remove' );
                });   
        }
    };

    render() {
        const { language, popupOpen } = this.props;

        return (
            <>
                <HeaderLogo />
                <div className="ils_popup_title">{ t( 'login:title', language ) }</div>
                <div className="ils_popup_text">
                    <span>{ t( 'login:text1', language ) }</span>
                    <span className="ils_popup_link" onClick={ () => popupOpen( POPUP_REDUCER.POPUP_REGISTRATION ) }>
                        { t( 'login:text2', language ) }
                    </span>
                </div>
                <div className="ils_popup_form">
                    <div className="ils_popup_input">
                        <input
                            type="email"
                            name="email"
                            value={ this.state.email }
                            onChange={ this.handleChange }
                            placeholder="Email" />
                    </div>
                    <div className="ils_popup_input">
                        <input
                            type="password"
                            name="password"
                            value={ this.state.password }
                            onChange={ this.handleChange }
                            onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                            placeholder={ t( 'form:password', language ) } />
                    </div>

                    <div className="ils_popup_error">{ this.state.errorMessage }</div>
                    <div className="ils_popup_valid_button" onClick={ this.handleSubmit }>
                        { t( 'login:confirm', language ) }
                    </div>
                    <div className="ils_popup_link"onClick={ () => popupOpen( POPUP_REDUCER.POPUP_FORGOTTEN_PASSWORD ) }>
                        { t( 'login:forgottenPassword', language ) }
                    </div>
                </div>
                <ButtonClose popupName={ POPUP_REDUCER.POPUP_LOGIN } />
            </>
        );
    }
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
});

const dispatchToProps = dispatch => ({
    fetchUserInfo: userId => dispatch(userAction.fetchUserInfo( userId )),
    popupClose: popupName => dispatch(popupAction.popupClose( popupName )),
    popupOpen: popupName => dispatch(popupAction.popupOpen( popupName )),
});

export default connect(stateToProps, dispatchToProps)(PopupLogin);
