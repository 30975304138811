import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';

import Page from './components/Page';

import CollectData from './services/collectData';

import './style/index.scss';

class App extends React.Component {
	constructor( props ) {
		super( props );

		this.backgroundImg = require( "./assets/img/bg-vx.png" );
	};

	componentDidMount() {
		const collectData = new CollectData( 'web' );
		collectData.collect();
	};

	render() {
		return (
			<div>
				<div id="background">
					<img src={ this.backgroundImg } alt="fond Zone300"/>
				</div>
				<Provider store={store}>
						<Page />
				</Provider>
			</div>
		);
	};
}

export default App;
