import { DATA_LANGUAGE, TRANSLATING_REDUCER } from "../../constants";
import { getLanguage } from "../../utils";

const initialState = {
	language: getLanguage(),
};

export default (state = initialState, action) => {
	const newState = Object.assign({}, state);

	switch (action.type) {
		case TRANSLATING_REDUCER.FETCH_LANGUAGE:
			localStorage.setItem( DATA_LANGUAGE, action.payload );
			newState.language = action.payload;
			return newState;
		default:
			return state;
	}
};
