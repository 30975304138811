import React from "react";

import SCAddress from "./SCAddress";
import SCPayment from "./SCPayment";
import SCSuccess from "./SCSuccess";
import SCValidation from "./SCValidation";

class SCRouter extends React.Component {
    constructor(props) {
        super(props);

		this.state = {
			direction: 'right',
			step: 'validation',
		};
    };

	handlePageChange = ( direction, step ) => {
		window.scrollTo( 0, 0 );
		this.setState({ direction: direction, step: step });
	};
    
    render() {
		switch( this.state.step ) {
			case 'address': return <SCAddress handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'payment': return <SCPayment handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'success': return <SCSuccess handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'validation': return <SCValidation handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			default: return <></>;
		}
	};
}

export default SCRouter;