import React from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";

import ListProductItem from "../../components/ListRows/ListProductItem";

import { ICONS } from "../../constants";
import { t } from "../../utils";

class ProductsMozaic extends React.Component {
    static contextTypes = { router : Proptypes.object };

	render() {
		const { language, storeSearchedProducts } = this.props;

		return (
            <div className='product_mozaic_box'>
                {
                    storeSearchedProducts && storeSearchedProducts.display && storeSearchedProducts.display.title &&
                    <div className='list-title list-title-relative'>
                        <div className="product-go-back" onClick={ () => this.context.router.history.goBack() }>
                            <span className='icon_box'>
                                <img className="nav_icon" src={ ICONS.backArrow } alt='icone retour' />
                            </span>
                            { t( 'shared:back', language ) }
                        </div>
                        <h1 className='list-title-product mozaic'>{ storeSearchedProducts.display.title }</h1>
                    </div>
                }
                <div className='movie_mozaic product_mozaic'>
                    {
                        storeSearchedProducts && storeSearchedProducts.products && storeSearchedProducts.products.length > 0 &&
                        storeSearchedProducts.products.map( ( item, index ) =>
                            <div key={ index }>
                                <ListProductItem 
                                    catId={ index } 
                                    product={ item } />
                            </div>
                        )
                    }
                </div>
            </div>
		);
	}
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
	storeSearchedProducts: state.storeReducer.storeSearchedProducts,
});

export default connect( stateToProps )( ProductsMozaic );