import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';

import { ICONS } from "../../constants";
import { convertTypeAbo, t } from "../../utils";

class PSectionAccount extends React.Component {
    convertDateAdd = date_add => {
        const date = new Date( date_add * 1000 );
        if ( ! date ) return '';
        const year = date.getFullYear();
        const month = ( '0' + ( date.getMonth() + 1 ) ).slice( -2 );
        const day = ( '0' + date.getDate() ).slice( -2 );

        return day + '/' + month + '/' + year;
    };
    
    render() {
        const { cancelAt, language, stripeReceipts, userInfoDB } = this.props;
        
        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_nav'>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'menu' ) } }>{ t( 'profile:title', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span>{ t( 'profile:account', language ) }</span>
                    </div>
					<div id='profile_header' className='profile_header_title'>
						<div><img className='profile_icon title_icon' src={ ICONS.user } alt='icone profil'/></div>
						<h1>{ t( 'profile:account', language ) }</h1>
					</div>
					<div id='profile_body'>
						<div className='profile_menu_title'>
							<div>{ t( 'profile:details', language ) }</div>
						</div>
						<div className='collect_flag profile_menu_small profile_hover' id='profile_menu_edit_email' onClick={ () => { this.props.handlePageChange( 'right', 'editEmail' ) } }>
							<div className='profile_scale'>{ userInfoDB.email }</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</div>
						<div className='collect_flag profile_menu_small profile_hover' id='profile_menu_password' onClick={ () => { this.props.handlePageChange( 'right', 'editPassword' ) } }>
							<div className='profile_scale'>{ t( 'form:password', language ) } : ∗∗∗∗∗∗∗</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</div>
						<div className='profile_menu_title'>
							<div>{ t( 'profile:subscription', language ) }</div>
						</div>
						<div className='profile_menu_small'>
							<div>{ convertTypeAbo( userInfoDB.TypeAbo, language ).stgLong }</div>
						</div>
						<div className='profile_menu_small'>
							<div>{ t( 'profile:subscriptionSince', language ) } : { this.convertDateAdd( userInfoDB.date_add ) }</div>
						</div>
						{
							stripeReceipts && stripeReceipts.length > 0 &&
							<div className='collect_flag profile_menu_small profile_hover' id='profile_menu_billing' onClick={ () => { this.props.handlePageChange( 'right', 'listBilling' ) } }>
								<div className='profile_scale'>{ t( 'profile:billingHistory', language ) }</div>
								<div className='f-grow-1'></div>
								<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
							</div>
						}
						{
							cancelAt === 0 &&
							<div className='collect_flag profile_menu_small profile_hover' id='profile_menu_email' onClick={ () => { this.props.handlePageChange( 'right', 'editSub' ) } }>
								<div className='profile_scale'>{ t( 'profile:editSubscription', language ) }</div>
								<div className='f-grow-1'></div>
								<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
							</div>
						}
					</div>
				</div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
	cancelAt: state.userReducer.cancelAt,
	language: state.translatingReducer.language,
	stripeReceipts: state.userReducer.stripeReceipts,
	userInfoDB: state.userReducer.userInfoDB,
});

export default connect( stateToProps )( PSectionAccount );