import React from "react";
import { connect } from "react-redux";

import RowRender from "../../components/tools/RowRender";

import { trainingAction } from "../../redux/actions";
import { getUserInfo } from "../../utils";
 
class ListRows extends React.Component {
	componentDidMount() {
		const { fetchTrainingMovies } = this.props;
		const userId = getUserInfo().NoCli;
		fetchTrainingMovies( 0, userId );
	};

	render() {
		const { trainingMovies } = this.props;

		return (
			<div id='chasse_Homelist'>
				<RowRender movieListInfo = { trainingMovies } />
			</div>
		);
	}
}

const stateToProps = state => ({
	trainingMovies: state.trainingReducer.trainingMovies,
});

const dispatchToProps = dispatch => ({
	fetchTrainingMovies: ( themeId, userId ) => dispatch( trainingAction.fetchTrainingMovies( themeId, userId )),
});

export default connect( stateToProps, dispatchToProps )( ListRows );