import React from "react";
import { connect } from "react-redux";

import ListCatsStore from "../../components/ListCatsStore";
import RowProductsRender from "../../components/tools/RowProductsRender";
import Slider from "../../components/Slider";

import { storeAction } from "../../redux/actions";
import { getUserInfo } from "../../utils";
 
class ListRows extends React.Component {
	componentDidMount() {
		const { fetchStoreProducts, fetchStoreSliderProducts } = this.props;
		const userId = getUserInfo().NoCli;
		fetchStoreProducts( 0, userId );
		fetchStoreSliderProducts( 0, userId );
	};

	render() {
		const { storeProducts, storeSliderProducts } = this.props;

		return (
			<div id='home_Homelist' className='storeList'>
				<Slider sliderMovies={ storeSliderProducts } />
				<ListCatsStore />
				<RowProductsRender productListInfo = { storeProducts } />
			</div>
		);
	}
}

const stateToProps = state => ({
	storeProducts: state.storeReducer.storeProducts,
	storeSliderProducts: state.storeReducer.storeSliderProducts,
});

const dispatchToProps = dispatch => ({
	fetchStoreProducts: ( themeId, userId ) => dispatch( storeAction.fetchStoreProducts( themeId, userId )),
	fetchStoreSliderProducts: ( themeId, userId ) => dispatch( storeAction.fetchStoreSliderProducts( themeId, userId )),
});

export default connect( stateToProps, dispatchToProps )( ListRows );