import $ from "jquery";
import { API_DATA, DATA_LABEL, DATA_VLOG, DATA_VLOG_ALT } from "../../constants";
const API_URL = `${API_DATA}/collect_data/`;

if ( window.location.origin === 'https://www.zone300.com' ) console.log = () => {};

class CollectData {
    constructor( platform ) {
        this.dataLabel = DATA_LABEL;
        this.dataVLogLabel = DATA_VLOG;
        this.dataVAbo2Label = DATA_VLOG_ALT;

        this.data = {};
        this.dataVAbo2 = {};
        this.dataVLog = {};
        this.error = {};

        this.result = {
            platform: platform,
            user: {
                idClient: this.uniqId( '-' + this.dataLabel ),
                email: '',
                ip: '',
                agent: '',
                referer: '',
                status: 'visitor',
                visitsCount: 0,
                createdAt: Date.now(),
                updatedAt: Date.now(),
            },
            currentPage: { url: window.location.href, timestamp: Date.now() },
            previousPage: { url: 'none', timestamp: 0 },
            events: [],
            metadata: {},
        };

        this.initFromLocalStorage();
        this.handleVisitsCount();
    };

    addClickMetadata( htmlElementId ) {
        let metadataToAdd;
        switch( htmlElementId ) {
            // exemple
            case 'accept_cga_btn':
                metadataToAdd = { cgaAccepted: true };
                break;
            default:
                metadataToAdd = '';    
        }
        return metadataToAdd;
    };

    checkErrors() {
        if ( ! this.isEmpty( this.dataVLog ) ) {
            if ( this.dataVLog.is_guest === '0' && ! this.dataVLog.TypeAbo ) {
                this.error = {
                    idClient: this.dataVLog.NoCli,
                    isGuest: this.dataVLog.is_guest,
                    status: this.dataVLog.TypeAbo,
                    timestamp: Date.now(),
                };
                this.collectError();
            }
        }
    };

    checkParentsHasClass ( className, htmlElement ) {
        if ( htmlElement ) {
            do {
                if ( htmlElement.classList.contains( className ) ) {
                    return htmlElement;
                } else {
                    htmlElement = htmlElement.parentElement;
                    if ( ! htmlElement ) return null;
                }
            } while ( htmlElement.parentElement );
        }

        return null;
    };

    collect() {
        this.checkErrors();
        localStorage.setItem( this.dataLabel, JSON.stringify( this.result ) );
        this.collectData();
        document.addEventListener( 'click', this.collectClick.bind( this ) );
    };

    collectClick( event ) {
        const self = this;
        const htmlElementWithClass = this.checkParentsHasClass( 'collect_flag', event.target );

        if ( ! htmlElementWithClass ) {
            // console.log( 'no flag on this htmlElement' );   
        } else {
            if ( htmlElementWithClass.id ) {
                let metadata = {};
                if ( this.addClickMetadata( htmlElementWithClass.id ) ) metadata = this.addClickMetadata( htmlElementWithClass.id );

                const eventToCollect = {
                    type: 'click',
                    target: htmlElementWithClass.id,
                    timestamp: Date.now(),
                    metadata: metadata,
                };

                $.ajax( {
                    url: API_URL + 'collect_event.php',
                    method: 'POST',
                    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                    dataType : 'json',
                    data: eventToCollect,
                    success: function( res ) {
                        let localStore = JSON.parse( localStorage.getItem( self.dataLabel ) );
                        localStore.events.push( res.data );
                        localStorage.setItem( self.dataLabel, JSON.stringify( localStore ) );
                    },
                    error: function( error ) { console.log( error ); }
                });
            } else {
                // console.log( 'no id on this htmlElement' );
            }   
        }
    };

    collectData() {
        const self = this;

        $.ajax( {
            url: API_URL + 'collect_data.php',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            dataType : 'json',
            data: self.result,
            success: function( res ) {
                self.result.user.agent = res.data.user.agent;
                self.result.user.ip = res.data.user.ip;
                self.result.user.referer = res.data.user.referer;
                self.result.events = [];
                self.result.metadata = {};

                localStorage.setItem( self.dataLabel, JSON.stringify( self.result ) );
            },
            error: function( error ) { console.log( error ); }
        });
    };
    
    collectError() {
        $.ajax( {
            url: API_URL + 'collect_error.php',
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            dataType : 'json',
            data: this.error,
            success: function( res ) { /* console.log( res ); */ },
            error: function( error ) { console.log( error ); }
        });
    };

    handleVisitsCount() {
        if ( ! sessionStorage.getItem( this.dataLabel ) ) {
            sessionStorage.setItem( this.dataLabel, 'start' );
            this.result.user.visitsCount ++;
        }
    };

    initFromLocalStorage() {
        const localData = localStorage.getItem( this.dataLabel );
        const localVAbo2 = localStorage.getItem( this.dataVAbo2Label );
        const localVLog = localStorage.getItem( this.dataVLogLabel );

        if ( localData ) this.data = JSON.parse( localData ); 
        if ( localVAbo2 ) this.dataVAbo2 = JSON.parse( localVAbo2 );     
        if ( localVLog ) this.dataVLog = JSON.parse( localVLog );
        
        if ( ! this.isEmpty( this.data ) ) {
            this.result.user = this.data.user;
            this.result.previousPage = { url: this.data.currentPage.url, timestamp: this.data.currentPage.timestamp };
            this.result.events = this.data.events;
            this.result.metadata = this.data.metadata;
        }

        if ( ! this.isEmpty( this.dataVAbo2 ) ) {
            if ( this.dataVAbo2.NoCli ) this.result.user.idClient = this.dataVAbo2.NoCli;
            if ( this.dataVAbo2.TypeAbo ) this.result.user.status = 'subscriber - ' + this.dataVAbo2.TypeAbo;
            if ( this.dataVAbo2.username ) this.result.user.email = this.dataVAbo2.username;
        }

        if ( ! this.isEmpty( this.dataVLog ) ) {
            if ( this.dataVLog.NoCli ) this.result.user.idClient = this.dataVLog.NoCli;
            if ( this.dataVLog.TypeAbo ) this.result.user.status = 'subscriber - ' + this.dataVLog.TypeAbo;
            if ( this.dataVLog.is_guest === '1' ) this.result.user.status = 'free user';
            if ( ! this.dataVLog.email && this.dataVLog.username ) this.dataVLog.email = this.dataVLog.username;
            if ( this.dataVLog.email ) this.result.user.email = this.dataVLog.email;
        }
    };

    isEmpty( obj ) {
        return Object.keys( obj ).length === 0;
    };

    uniqId( string ) {
        return Date.now() + string;
    };
}

export default CollectData;