import { COMMENT_REDUCER } from "../../constants";

const initialState = {
	commentItem: '',
	commentType: '',
};

export default (state = initialState, action) => {
	const newState = Object.assign({}, state);

	switch (action.type) {
		case COMMENT_REDUCER.FETCH_COMMENT_ITEM:
			newState.commentItem = action.payload.item;
			newState.commentType = action.payload.type;
			return newState;	
		default:
			return state;
	}
};