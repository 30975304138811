import React from "react";
import { connect } from "react-redux";

import { API_IMAGE } from "../../constants";
import { t } from "../../utils";

class LinkSliderItem extends React.Component {

    handleClick = e => {
		e.stopPropagation();
		const { link } = this.props;

		if ( link.link ) {
			window.location.href = link.link;
		} else if ( link.trailer && link.trailer.length > 0 ) {
			const { parent } = this.props;

			parent.setState({
				playerShow: true,
				playerHeight: "100%",
				playerWidth: "100%",
				videoJsOptions: {
					isTrailer: true,
					logBtnExit: parent.logBtnExit,
					movieKey: 0,
					movieList: [ link ], 
					playing: true,
					progressInfo: null,
				},
			});
	
			window.location.href = window.location.href + "#play/";
		}
	};

	render() {
		const { language, link, themeId } = this.props;
        
		return (
            <div
                className={ `carousel-img collect_flag ${ link.linkBtn && link.linkBtnStopPropagation !== false ? 'with-btn' : '' }` }
                onClick={ this.handleClick }
                id={ `carousel-${ themeId }-link-${ link.index }` }>  
                <img
                    className="d-none d-md-block img-fluid"
                    src={ `${API_IMAGE}${ link.carouselWebFileName }.jpg&h=720&crop-to-fit&q=40` }
                    alt={ link.alt } />
                <img
                    className="sd-block d-sm-block d-md-none img-fluid"
                    src={ `${API_IMAGE}${ link.carouselMobileFileName }.jpg&h=720&crop-to-fit&q=40` }
                    alt={ link.alt } />
                <div className="carousel-info">
                    {
                        link.titlePngFileName ?
                        <div className="carousel-img-title">
                            <img 
                                alt={ `logo ${ link.alt }` }
                                src={ `${ API_IMAGE }${ link.titlePngFileName }.png&h=160&crop-to-fit` }
                                onError={ e => e.target.style.display = 'none' } />
                        </div>    
                        :
                        <></>
                    }  
                    { link.title ? <div className="carousel-title">{ link.title }</div> : <></> }
                    <div className="carousel-btn-addon-box">
                        {
                            link.linksAddon && link.linksAddon.length > 0 &&
                            link.linksAddon.map( ( item, index ) => 
                                <div
                                    key={ index }
                                    className={ `carousel-btn-addon ${ item.link ? 'collect_flag linked' : '' }` }
                                    onClick={ e => { if ( item.link ) { e.stopPropagation(); window.location.href = item.link; } } }
                                    id={ `linkAddonBtn--carousel-${ themeId }-link-${ link.index }-index-${ index }` }>      
                                    { item[ t( 'keys:linkBtn', language ) ] ? item[ t( 'keys:linkBtn', language ) ] : item.linkBtn }          
                                </div>
                            )
                        }
                    </div>
                    { link.linkBtn ? 
                        <div
                            className={ `carousel-btn collect_flag ${ link.linkBtnExtraClass ? link.linkBtnExtraClass : '' }` }
                            onClick={ e => { e.stopPropagation(); window.location.href = link.link; } }
                            id={ `linkBtn--carousel-${ themeId }-link-${ link.index }` } >
                            { link.linkBtnIcon === 'play' && <i className="fa fa-play icnBtn" aria-hidden="true"></i> }    
                            { link[ t( 'keys:linkBtn', language ) ] ? link[ t( 'keys:linkBtn', language ) ] : link.linkBtn }          
                        </div>
                        :
                        <></>
                    }
                </div> 
                {
                    ! link.link && link.trailer && link.trailer.length > 0 &&
                    <div className='movie_progress_play'>
                        <i className="fa fa-play" aria-hidden="true"></i>
                    </div>
                }   
            </div>
		);
	};
}
const stateToProps = state => ({
    language: state.translatingReducer.language,
	themeId: state.routingReducer.themeId,
});

export default connect( stateToProps )( LinkSliderItem );