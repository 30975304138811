import React from "react";
import { connect } from "react-redux";

import InfiniteCarousel from 'react-leaf-carousel';
import InfinteCarrouselReRender from "../tools/InfinteCarrouselReRender";
import RowRender from "../tools/RowRender";
import RowProductsRender from "../tools/RowProductsRender";

import { API_IMAGE, IMAGES } from "../../constants";
import { getProgressBarStyle, getRoles, getShortSynopsis, isProgressTimeValid, t } from "../../utils";
 
class MoviePageRows extends React.Component {
	constructor( props ) {
		super( props );

        this.state = {
            navDistribution: false,
            navEpisodes: false,
            navExtras: false,
            navProducts: false,
            navRelated: false,
        };
	};

    componentDidMount() {
		const { searchedMovies } = this.props;

        if ( searchedMovies && searchedMovies.length > 0 ) {
            const movie = searchedMovies[ 0 ].movies[ 0 ];
            if ( movie.type === 'series' ) {
                this.setState({ navEpisodes: true });
            } else if ( movie.trailer.length > 0 || movie.bonus.length > 0 ) {
                this.setState({ navExtras: true });
            } else {
                this.setState({ navRelated: true });
            }
        }
 	};

	getImageFile = ( movie, type ) => {
        const { version } = this.props;
        if ( movie.versionImages &&  movie.versionImages.length > 0 ) {
        	const index = version % movie.versionImages.length;
        	if ( movie.versionImages[ index ][ type ] ) return movie.versionImages[ index ][ type ];
        }
        return movie[ type ];
    };

    handleNavChange = menu => {
		this.setState( {
            navDistribution: menu === 'navDistribution',
            navEpisodes: menu === 'navEpisodes',
            navExtras: menu === 'navExtras',
            navProducts: menu === 'navProducts',
            navRelated: menu === 'navRelated',
        });
	};

    renderMovies = type => {
        const { language, playBtnClick, searchedMovies, seriesLocal } = this.props;
        const parentMovie = searchedMovies[ 0 ].movies[ 0 ];
        let movieList = [];

        if ( type === 'episode' ) {
            movieList = parentMovie.episodes;
            let index = null;
            seriesLocal.forEach( ( series, i ) => { if ( series.transaction === parentMovie.transaction ) index = i } );
            if( index !== null ) movieList = seriesLocal[ index ].episodes;
        } else if ( type === 'trailer' ) {
            movieList = parentMovie.trailer;
        } else if ( type === 'bonus' ) {
            movieList = parentMovie.bonus;
        } else {
            return;
        }

        if ( ! movieList ) return;

        return movieList.map( ( movie, i ) =>
            <div
                key={ i }
                onClick={ () => playBtnClick( movie, type ) }
                className="episode-box">
                <div style={{ position:'relative' }}>
                    <img
                        className='movie_episode_img'
                        src={ `${ API_IMAGE }${ this.getImageFile( movie, 'thumbnailFileName' ) || this.getImageFile( parentMovie, 'thumbnailFileName' ) }.jpg&w=500&h=281&crop-to-fit` }
                        alt={ movie.imageAlt ? movie.imageAlt : movie.titre } />
                    <div className='movie_progress_play'>
                        <i className="fa fa-play" aria-hidden="true"></i>
                    </div>    
                    {
                        isProgressTimeValid( movie.time, movie.timeTotal ) ?
                        <div className='movie_progress'>
                            <div className="progress_bar" style={ getProgressBarStyle( movie.time, movie.timeTotal ) }></div>
                        </div>
                        :
                        <div className='movie_progress_no'></div>
                    }
                </div>
                <div className="episodes-thumbnails">
                    {
                        type === 'episode' ?
                        <>
                            <div className="number-episode">{ t( 'shared:episode', language ) } { i + 1 }</div>
                            <div className="title-episode">
                                {
                                    movie[ t( 'keys:titre', language ) ] ?
                                    movie[ t( 'keys:titre', language ) ].replace( / *\([^)]*\) */g, '' )
                                    :
                                    movie.titre.replace( / *\([^)]*\) */g, '' )
                                }
                            </div>
                        </>
                        :
                        <div className="title-episode">
                            {
                                movie[ t( 'keys:titre', language ) ] ?
                                movie[ t( 'keys:titre', language ) ]
                                :
                                movie.titre
                            }
                        </div>
                    }
                    <div className="synopsis-episode">
						{
							movie[ t( 'keys:synopsis_txt', language ) ] ?
							getShortSynopsis( movie[ t( 'keys:synopsis_txt', language ) ] )
							:
							getShortSynopsis( movie.synopsis_txt )
						}
                    </div>
                </div>
            </div>
        )
    };

	render() {
		const { language, searchedMovies } = this.props;
        const { navDistribution, navEpisodes, navExtras, navProducts, navRelated } = this.state;
        let movie = null;
        let relatedMovies = null;
        let relatedProducts = null;
        if ( searchedMovies && searchedMovies.length > 0 ) {
            movie = searchedMovies[ 0 ].movies[ 0 ];
            if ( searchedMovies[ 0 ].relatedMovies && searchedMovies[ 0 ].relatedMovies.length > 0 ) relatedMovies = searchedMovies[ 0 ].relatedMovies;
            if ( searchedMovies[ 0 ].relatedProducts && searchedMovies[ 0 ].relatedProducts.length > 0 ) relatedProducts = searchedMovies[ 0 ].relatedProducts;
        }

		return (
			<>
                {
                    searchedMovies && searchedMovies.length > 0 &&
                    <>
                        <div className="movie_rows_nav">
                            {
                                movie.type === 'series' &&
                                <div
                                    className={ `movie_rows_nav_item ${ navEpisodes && 'selected' }` }
                                    onClick={ () => { this.handleNavChange( 'navEpisodes' ) } }>
                                    { t( 'moviePage:navEpisodes', language ) }
                                </div>
                            }
                            {
                                ( movie.trailer.length > 0 || movie.bonus.length > 0 ) &&
                                <div
                                    className={ `movie_rows_nav_item ${ navExtras && 'selected' }` }
                                    onClick={ () => { this.handleNavChange( 'navExtras' ) } }>
                                    { t( 'moviePage:navExtras', language ) }
                                </div>
                            }
                            <div
                                className={ `movie_rows_nav_item ${ navRelated && 'selected' }` }
                                onClick={ () => { this.handleNavChange( 'navRelated' ) } }>
                                { t( 'moviePage:navRelated', language ) }
                            </div>
                            <div
                                className={ `movie_rows_nav_item ${ navDistribution && 'selected' }` }
                                onClick={ () => { this.handleNavChange( 'navDistribution' ) } }>
                                { t( 'moviePage:navDistribution', language ) }
                            </div>
                            <div
                                className={ `movie_rows_nav_item ${ navProducts && 'selected' }` }
                                onClick={ () => { this.handleNavChange( 'navProducts' ) } }>
                                { t( 'moviePage:navProducts', language ) }
                            </div>
                        </div>
                        <div className="movie_rows_details">
                            {
                                navDistribution && movie.distribution && movie.distribution.length > 0 &&
                                <div className="categories-list-box distribution-list-box">
                                    <div className="categories-list">
                                        {
                                            movie.distribution && movie.distribution.length > 0 &&
                                            movie.distribution.map( ( el, i ) =>
                                                <a
                                                    className="category-box collect_flag"
                                                    href={ `/browse/?search=${ el.url }/#/search/${ el.url }` }
                                                    key={ `participant-${ i }` }
                                                    id={ `movie-page-distribution-circle-${el.url}` }>
                                                    <div className="category-item">
                                                        <img
                                                            className="category-img"
                                                            src={ `${ API_IMAGE }${ el.imagePath }.jpg` }
                                                            alt={ el.title }
                                                            onError= { (e) => { e.target.src = IMAGES.avatarDefault } }/>
                                                        <div className="category-name">
                                                            <div>{ el.title }</div>
                                                            <div className="distribution-role">{ getRoles( el.role, language ) }</div>
                                                        </div>    
                                                    </div>
                                                </a> 
                                            )
                                        }
                                    </div>
                                </div>
                            }
                            {
                                navEpisodes &&
                                <div className="episodes-container">
                                    <InfinteCarrouselReRender condition={ true }>
                                        <InfiniteCarousel
                                            breakpoints={[
                                                { breakpoint: 500, settings: { arrows: false, slidesToShow: 1, slidesToScroll: 1 } },
                                                { breakpoint: 1024, settings: { arrows: false, slidesToShow: 2, slidesToScroll: 2 } },
                                                { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                                                { breakpoint: 3000, settings: { slidesToShow: 4, slidesToScroll: 4 } }
                                            ]}
                                            dots={false}
                                            slidesSpacing={5}
                                            showSides={true}
                                            sidesOpacity={.5}
                                            sideSize={.1}
                                            slidesToScroll={6}
                                            slidesToShow={6}
                                            animationDuration={600}
                                            lazyLoad={true} >
                                            { this.renderMovies( 'episode' ) }
                                        </InfiniteCarousel>
                                    </InfinteCarrouselReRender>
                                </div>
                            }
                            {
                                navExtras &&
                                <>
                                    {
                                        movie.trailer && movie.trailer.length > 0 &&
                                        <>
                                            <div className="list-title">
                                                <h3>
                                                    { movie.trailer.length === 1 ? t( 'shared:trailer', language ) : t( 'shared:trailers', language ) }
                                                </h3>
                                            </div>
                                            <div className="episodes-container">
                                                <InfinteCarrouselReRender condition={ true }>
                                                    <InfiniteCarousel
                                                        breakpoints={[
                                                            { breakpoint: 500, settings: { arrows: false, slidesToShow: 1, slidesToScroll: 1 } },
                                                            { breakpoint: 1024, settings: { arrows: false, slidesToShow: 2, slidesToScroll: 2 } },
                                                            { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                                                            { breakpoint: 3000, settings: { slidesToShow: 4, slidesToScroll: 4 } }
                                                        ]}
                                                        dots={false}
                                                        slidesSpacing={5}
                                                        showSides={true}
                                                        sidesOpacity={.2}
                                                        sideSize={.1}
                                                        slidesToScroll={6}
                                                        slidesToShow={6}
                                                        animationDuration={600}
                                                        lazyLoad={true} >
                                                        { this.renderMovies( 'trailer' ) }
                                                    </InfiniteCarousel>
                                                </InfinteCarrouselReRender>
                                            </div>
                                        </>
                                    }
                                    {
                                        movie.bonus && movie.bonus.length > 0 &&
                                        <>
                                            <div className="list-title">
                                                <h3>{ t( 'shared:bonus', language ) }</h3>
                                            </div>
                                            <div className="episodes-container">
                                                <InfinteCarrouselReRender condition={ true }>
                                                    <InfiniteCarousel
                                                        breakpoints={[
                                                            { breakpoint: 500, settings: { arrows: false, slidesToShow: 1, slidesToScroll: 1 } },
                                                            { breakpoint: 1024, settings: { arrows: false, slidesToShow: 2, slidesToScroll: 2 } },
                                                            { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                                                            { breakpoint: 3000, settings: { slidesToShow: 4, slidesToScroll: 4 } }
                                                        ]}
                                                        dots={false}
                                                        slidesSpacing={5}
                                                        showSides={true}
                                                        sidesOpacity={.2}
                                                        sideSize={.1}
                                                        slidesToScroll={6}
                                                        slidesToShow={6}
                                                        animationDuration={600}
                                                        lazyLoad={true} >
                                                        { this.renderMovies( 'bonus' ) }
                                                    </InfiniteCarousel>
                                                </InfinteCarrouselReRender>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                            { navRelated && relatedMovies && <RowRender movieListInfo = { relatedMovies } /> }
                            { navProducts && relatedProducts && <RowProductsRender productListInfo = { relatedProducts } /> }
                        </div>
                    </>
                }
                
            </>
		);
	}
}

const stateToProps = state => ({
	language: state.translatingReducer.language,
    searchedMovies: state.moviesReducer.searchedMovies,
    seriesLocal: state.moviesReducer.seriesLocal,
    version: state.routingReducer.version,
});

export default connect( stateToProps )( MoviePageRows );