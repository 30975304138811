import { DATA_CART, STORE_REDUCER } from "../../constants";
import { getCartInfo } from "../../utils";

const initialState = {
	storeCart: getCartInfo().cart,
	storeCartLength: getCartInfo().cartLenght,
	storeCartProductsTotal: getCartInfo().cartProductsTotal,
	storeCatsList: [],
	storeProducts: [],
	storeSelectedProducts: [],
	storeSearchedProducts: [],
	storeSliderProducts: [],
};

export default (state = initialState, action) => {
	const newState = Object.assign({}, state);
	let localCart = [];
	let newCart = [];

	switch (action.type) {
		case STORE_REDUCER.ADD_PRODUCT_IN_CART:
			if ( localStorage.getItem( DATA_CART ) ) localCart = JSON.parse( localStorage.getItem( DATA_CART ) );
			localCart.push( action.payload );
			localStorage.setItem( DATA_CART, JSON.stringify( localCart ) );
			newCart = initialState.storeCart;
			newCart.push( action.payload );
			newState.storeCart = newCart;
			newState.storeCartLength = newCart.length;
			newState.storeCartProductsTotal = initialState.storeCartProductsTotal + action.payload.productQuantity;
			return newState;
		case STORE_REDUCER.CLEAR_CART:
			localStorage.removeItem( DATA_CART );
			newState.storeCart = [];
			newState.storeCartLength = 0;
			newState.storeCartProductsTotal = 0;
			return newState;
		case STORE_REDUCER.FETCH_STORE_CATS_LIST:
			newState.storeCatsList = action.payload;
			return newState;
		case STORE_REDUCER.FETCH_STORE_PRODUCTS:
			newState.storeProducts = action.payload;
			return newState;
		case STORE_REDUCER.FETCH_STORE_SELECTED_PRODUCTS:
			newState.storeSelectedProducts = action.payload;
			return newState;
		case STORE_REDUCER.FETCH_STORE_SEARCHED_PRODUCTS:
			newState.storeSearchedProducts = action.payload;
			return newState;	
		case STORE_REDUCER.FETCH_STORE_SLIDER_PRODUCTS:
			newState.storeSliderProducts = action.payload;
			return newState;
		case STORE_REDUCER.REMOVE_PRODUCT_IN_CART:
			newCart = initialState.storeCart;
			if ( newCart[ action.payload ] ) {
				const productQuantity = newCart[ action.payload ].productQuantity;
				newCart.splice( action.payload, 1 );
				localStorage.setItem( DATA_CART, JSON.stringify( newCart ) );
				newState.storeCart = newCart;
				newState.storeCartLength = newCart.length;
				newState.storeCartProductsTotal = initialState.storeCartProductsTotal - productQuantity;
			}
			return newState;
		case STORE_REDUCER.UPDATE_CART:
			newCart = action.payload;
			localStorage.setItem( DATA_CART, JSON.stringify( newCart ) );
			let newQuantity = 0;
			newCart.forEach( el => { if ( el.productQuantity ) newQuantity += el.productQuantity; } );
			newState.storeCart = newCart;
			newState.storeCartLength = newCart.length;
			newState.storeCartProductsTotal = newQuantity;
			return newState;
		default:
			return state;
	}
};