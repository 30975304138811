import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';
import Loader from 'react-loader-spinner';

import { API_IMAGE, ICONS, IMAGES } from "../../constants";
import { userAction } from "../../redux/actions";
import { removeProfile, updateProfile } from "../../services/user";
import { handleLoader, t } from "../../utils";

class PMEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
        };
    };

    checkErrors = () => {
        const { language, profileEditFirstName } = this.props;

        if ( profileEditFirstName === '' ) {
            this.setState ({ errorMessage: t( 'form:firstNameError', language ) });
            return false;
        }
        this.setState ( { errorMessage: '' } );
        return true;
    };

    closeConfirmation = () => {
        const $confirmPopup = document.getElementById( 'profile_confirmation' );
        if ( $confirmPopup ) $confirmPopup.classList.remove( 'show' );
    };

    openConfirmation = () => {
        window.scrollTo( 0, 0 );
        const $confirmPopup = document.getElementById( 'profile_confirmation' );
        if ( $confirmPopup ) $confirmPopup.classList.add( 'show' );
    };

    getAvatarPath = avatar => {
        return avatar && avatar !== '' ? `${ API_IMAGE }${ avatar }.jpg` : IMAGES.avatarDefault;
    };

    handleChange = ( event ) => { 
        const { fetchProfile } = this.props;
        fetchProfile({ type: 'edit', firstName: event.target.value });
    };

    handleRemove = () => {
        const { fetchUserInfo, handlePageChange, language, profileEditVersion, userInfoDB } = this.props;

        handleLoader( 'profile_loader', 'add' );
        const data = {
            NoCli: userInfoDB.NoCli,
            version: profileEditVersion,
        };
        removeProfile( data )
            .then( res => {
                if ( ! res.ret ) {
                    this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                } else {
                    fetchUserInfo( userInfoDB.NoCli );
                    handlePageChange( 'left', 'change' );
                }
                handleLoader( 'profile_loader', 'remove' );
            })
            .catch( () => {
                this.setState ( { errorMessage: t( 'shared:genericError', language ) } );
                handleLoader( 'profile_loader', 'remove' );
            });
    };

    handleSave = () => {
        const { fetchUserInfo, handlePageChange, language, profileEditAvatarId, profileEditFirstName, profileEditVersion, userInfoDB } = this.props;

        if ( this.checkErrors() ) {
            handleLoader( 'profile_loader', 'add' );
            const data = {
                avatarId: profileEditAvatarId,
                firstName: profileEditFirstName,
                NoCli: userInfoDB.NoCli,
                version: profileEditVersion,
            };
            updateProfile( data )
                .then( res => {
                    if ( ! res.ret ) {
                        this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                    } else {
                        fetchUserInfo( userInfoDB.NoCli );
                        handlePageChange( 'left', 'change' );
                    }
                    handleLoader( 'profile_loader', 'remove' );
                })
                .catch( () => {
                    this.setState ( { errorMessage: t( 'shared:genericError', language ) } );
                    handleLoader( 'profile_loader', 'remove' );
                });
        }
    };

    render() {
        const { handlePageChange, language, profileEditAvatar, profileEditFirstName, profileEditVersion, userInfoDB } = this.props;
        const { errorMessage, } = this.state;
        
        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_loader'><Loader type='TailSpin' color='#fba00b' height={100} width={100} /></div>
                    <div id='profile_confirmation' onClick={ this.closeConfirmation }>
                        <div id='profile_confirmation_box'>
                            <div>{ t( 'profileManager:removeInfo', language ) }</div>
                            <div>{ t( 'profileManager:removeQuestion', language ) }</div>
                            <div>
                                <button className='collect_flag' id='profile_logout_confirm' onClick={ this.handleRemove }>{ t( 'shared:yes', language ) }</button>
                                <button className='collect_flag' id='profile_logout_cancel' onClick={ this.closeConfirmation }>{ t( 'shared:no', language ) }</button>
                            </div>
                        </div>
                    </div>
                    <div id='profile_nav'>
                        <span className='profile_nav' onClick={ () => { handlePageChange( 'left', 'change' ) } }>{ t( 'profileManager:title', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span>{ t( 'profileManager:edit', language ) }</span>
                    </div>
                    <div className='profile_box_center'>
                        <div id='profile_header'  className='profile_header_title'>
                            <h1>{ t( 'profileManager:edit', language ) }</h1>
                        </div>
                    </div>
                    {
                        userInfoDB &&
                        <div className='profile_edit_usual profileManager'>
                            <div className='profile_edit_usual_current'>
                                <div className='user-avatar-box'>
                                    <div className='avatar_box' onClick={ () => { handlePageChange( 'right', 'avatarEdit' ) } }>
                                        <img className='user-avatar' src={ this.getAvatarPath( profileEditAvatar ) } alt='avatar' />
                                        <div className='addon_icon_box'>
                                            <img className='profile_icon addon_icon' src={ ICONS.pen  } alt='icone crayon'/>
                                        </div>
                                    </div> 
                                    <a className='profile_edit_usual_btn tall' href='/browse/?preferences/#/preferences'>
                                        <button className='collect_flag preferences_button' id='edit_firstName_submit_btn'>
                                            { t( 'profile:preferences', language ) }
                                        </button>
                                    </a>
                                    <div className='profile_edit_usual'>
                                        <div className='profile_edit_usual_form'>
                                            <div className='profile_edit_usual_input'>
                                                <label htmlFor='firstName'>{ t( 'form:firstName', language ) }</label>
                                                <input
                                                    type='text'
                                                    name='firstName'
                                                    value={ profileEditFirstName }
                                                    onChange={ event => this.handleChange( event ) }
                                                    />
                                            </div> 
                                        </div>
                                        <div className='profile_edit_usual_error'>
                                            { errorMessage }
                                        </div>
                                        <div className='profile_edit_usual_btn tall'>
                                            <button className='collect_flag' id='edit_firstName_submit_btn' onClick={ this.handleSave }>
                                                { t( 'shared:save', language ) }
                                            </button>
                                        </div>
                                        {
                                            profileEditVersion === 0 ?
                                            <div className='profile_remove_info'>
                                                { t( 'profileManager:removeImpossible', language ) }
                                            </div>
                                            :
                                            <div className='profile_edit_usual_btn tall'>
                                                <button className='collect_flag red' id='edit_firstName_submit_btn' onClick={ this.openConfirmation }>
                                                    { t( 'profileManager:remove', language ) }
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
    profileEditAvatar: state.userReducer.profileEditAvatar,
    profileEditAvatarId: state.userReducer.profileEditAvatarId,
    profileEditFirstName: state.userReducer.profileEditFirstName,
    profileEditVersion: state.userReducer.profileEditVersion,
	userInfoDB: state.userReducer.userInfoDB,
});

const dispatchToProps = dispatch => ({
	fetchProfile: data => dispatch(userAction.fetchProfile( data )),
	fetchUserInfo: userId => dispatch(userAction.fetchUserInfo( userId )),
});

export default connect( stateToProps, dispatchToProps )( PMEdit );