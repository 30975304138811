import React from 'react';
import { connect } from "react-redux";

import MoviePlaylist from '../../components/MovieDisplay/MoviePlaylist';

import { POPUP_REDUCER } from "../../constants";
import { popupAction, playlistAction, routingAction } from "../../redux/actions";
import { getUserInfo } from "../../utils";

class Playlist extends React.Component {
    componentDidMount() {
        const { fetchCurrentRoutingByUrl, fetchPlaylistMovies, popupOpen,  popupOptions, profileVersion } = this.props;
        const userId = getUserInfo().NoCli;
        if ( ! userId ) {
            popupOptions({ isRequired: true });
            popupOpen( POPUP_REDUCER.POPUP_REGISTRATION_PLAYLIST );
            return;
        }

        fetchCurrentRoutingByUrl( 'en-continu' );
        fetchPlaylistMovies( userId, profileVersion );
  	};

    render() {
        return (
            <div className="homepage-container">
                <MoviePlaylist />
            </div>
        )
    }
}

const stateToProps = state => ({
    profileVersion: state.userReducer.profileVersion,
});

const dispatchToProps = dispatch => ({
	fetchCurrentRoutingByUrl: url => dispatch( routingAction.fetchCurrentRoutingByUrl( url )),
    fetchPlaylistMovies: ( userId, version ) => dispatch( playlistAction.fetchPlaylistMovies( userId, version )),
    popupOpen: popupName => dispatch(popupAction.popupOpen( popupName )),
    popupOptions: options => dispatch(popupAction.popupOptions( options )),
});

export default connect( stateToProps, dispatchToProps )( Playlist );