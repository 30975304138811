import { STORE_REDUCER } from '../../constants';
import {
	getStoreCatsList,
	getStoreProducts,
	getStoreSelectedProducts,
	getStoreSearchedProducts,
	getStoreSliderProducts,
} from '../../services/store';

export default {
	addProductInCart: product => dispatch => {
		dispatch({
			type: STORE_REDUCER.ADD_PRODUCT_IN_CART,
			payload: product
		});
	},
	clearCart: () => dispatch => {
		dispatch({
			type: STORE_REDUCER.CLEAR_CART,
			payload: ''
		});
	},
	fetchStoreCatsList: () => async dispatch => {
		try {
			const response = await getStoreCatsList();
			const { data } = response;
			dispatch({
				type: STORE_REDUCER.FETCH_STORE_CATS_LIST,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: STORE_REDUCER.API_ERROR,
				payload: "error : store API"
			});
		}
	},
	fetchStoreProducts: ( themeId, userId ) => async dispatch => {
		try {
			const params = userId ? `?themeId=${ themeId }&NoCli=${ userId }` : `?themeId=${ themeId }`;
			const response = await getStoreProducts( params );
			const { data } = response;
			dispatch({
				type: STORE_REDUCER.FETCH_STORE_PRODUCTS,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: STORE_REDUCER.API_ERROR,
				payload: "error : store API"
			});
		}
	},
	fetchStoreSelectedProducts: productIds => async dispatch => {
		try {
			const params = `?productIds=${ productIds }`;
			const response = await getStoreSelectedProducts( params );
			const { data } = response;
			dispatch({
				type: STORE_REDUCER.FETCH_STORE_SELECTED_PRODUCTS,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: STORE_REDUCER.API_ERROR,
				payload: "error : store API"
			});
		}
	},
	fetchStoreSearchedProducts: ( searchText, userId ) => async dispatch => {
		try {
			const params = userId ? `?searchText=${ searchText }&NoCli=${ userId }` : `?searchText=${ searchText }`;
			const response = await getStoreSearchedProducts( params );
			const { data } = response;
			dispatch({
				type: STORE_REDUCER.FETCH_STORE_SEARCHED_PRODUCTS,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: STORE_REDUCER.API_ERROR,
				payload: "error : store API"
			});
		}
	},
	fetchStoreSliderProducts: ( themeId, userId ) => async dispatch => {
		try {
			const params = userId ? `?themeId=${ themeId }&NoCli=${ userId }` : `?themeId=${ themeId }`;
			const response = await getStoreSliderProducts( params );
			const { data } = response;
			dispatch({
				type: STORE_REDUCER.FETCH_STORE_SLIDER_PRODUCTS,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: STORE_REDUCER.API_ERROR,
				payload: "error : store API"
			});
		}
	},
	removeProductInCart: index => dispatch => {
		dispatch({
			type: STORE_REDUCER.REMOVE_PRODUCT_IN_CART,
			payload: index
		});
	},
	updateCart: cart => dispatch => {
		dispatch({
			type: STORE_REDUCER.UPDATE_CART,
			payload: cart
		});
	},
};