import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';

import { API_IMAGE, IMAGES } from "../../constants";
import { storeAction } from "../../redux/actions";
import { formatEuroPrice, t } from "../../utils";

class SCValidation extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            imageLoadNoCache: true,
            imageLoadError: true,
            indexProductToRemove: null,
            cart: [],
            cartLength: 0,
        };
	};

    componentDidMount() {
        const { storeCart, storeCartLength } = this.props;
        this.setState( {
            cart: storeCart,
            cartLength: storeCartLength,
        });
    };

    closeConfirmation = () => {
        const $confirmPopup = document.getElementById( 'profile_confirmation' );
        if ( $confirmPopup ) $confirmPopup.classList.remove( 'show' );
        this.setState( { indexProductToRemove: null } );
    };

    openConfirmation = index => {
        const $confirmPopup = document.getElementById( 'profile_confirmation' );
        if ( $confirmPopup ) $confirmPopup.classList.add( 'show' );
        this.setState( { indexProductToRemove: index } );
    };

    getPriceTotal = () => {
        const { cart } = this.state;
        let result = 0;
        cart.forEach( el => {
            const price = this.getProductInfo( el ).price;
            if ( price ) result += ( price * el.productQuantity );
        });
        return result;
    };

    getProductInfo = product => {
        const { storeSelectedProducts } = this.props;
        const productInfo = storeSelectedProducts.find( el => el.productId === product.productId );
        return productInfo ? productInfo : {};
    };

    handleChangeProductColor = ( event, index ) => {
        const { cart } = this.state;
        const product = this.getProductInfo( cart[ index ] );

        if ( product.colors && product.colors.length > 0 ) {
            const $selectProductColor = document.getElementById( `select_productColor_${ index }` );
            let $selectProductColorArr = [];
            product.colors.forEach( el => $selectProductColorArr.push( {
                id: `select_productColor_${ el }_${ index }`,
                htmlElement: document.getElementById( `select_productColor_${ el }_${ index }` )
            }));

            const valueId = event.target.id;
            if ( valueId === `select_productColor_${ index }` ) {
                $selectProductColorArr.forEach( el => {
                    if ( el.htmlElement ) el.htmlElement.classList.toggle( 'show' ); 
                });  
                if ( $selectProductColor ) $selectProductColor.classList.toggle( 'open' ); 
            } else {
                $selectProductColorArr.forEach( el => {
                    if ( el.htmlElement ) el.htmlElement.classList.remove( 'show' ); 
                });
                if ( $selectProductColor ) $selectProductColor.classList.remove( 'open' );
                const productColor = valueId.split( '_' )[ 2 ] ? valueId.split( '_' )[ 2 ] : '';
                if ( productColor ) {
                    if ( $selectProductColor ) $selectProductColor.innerHTML = productColor;
                    let newCart = cart;
                    newCart[ index ].productColor = productColor;
                    this.setState( { cart: newCart } );
                }
            }
        }
    };

    handleChangeProductQuantity = ( type, index ) => {
        const { cart } = this.state;
        let newProductQuantity = cart[ index ].productQuantity;
        if ( type === 'minus' ) {
            if ( cart[ index ].productQuantity > 1 ) newProductQuantity --;
        } else if ( type === 'plus' ) {
            newProductQuantity ++;
        } 
        let newCart = cart;
        newCart[ index ].productQuantity = newProductQuantity;
        this.setState( { cart: newCart } );
    };

    handleChangeProductSize = ( event, index ) => {
        const { cart } = this.state;
        const product = this.getProductInfo( cart[ index ] );

        if ( product.sizes && product.sizes.length > 0 ) {
            const $selectProductSize = document.getElementById( `select_productSize_${ index }` );
            let $selectProductSizeArr = [];
            product.sizes.forEach( el => $selectProductSizeArr.push( {
                id: `select_productSize_${ el }_${ index }`,
                htmlElement: document.getElementById( `select_productSize_${ el }_${ index }` )
            }));

            const valueId = event.target.id;
            if ( valueId === `select_productSize_${ index }` ) {
                $selectProductSizeArr.forEach( el => {
                    if ( el.htmlElement ) el.htmlElement.classList.toggle( 'show' ); 
                });  
                if ( $selectProductSize ) $selectProductSize.classList.toggle( 'open' ); 
            } else {
                $selectProductSizeArr.forEach( el => {
                    if ( el.htmlElement ) el.htmlElement.classList.remove( 'show' ); 
                });
                if ( $selectProductSize ) $selectProductSize.classList.remove( 'open' );
                const productSize = valueId.split( '_' )[ 2 ] ? valueId.split( '_' )[ 2 ] : '';
                if ( productSize ) {
                    if ( $selectProductSize ) $selectProductSize.innerHTML = productSize;
                    let newCart = cart;
                    newCart[ index ].productSize = productSize;
                    this.setState( { cart: newCart } );
                }
            }
        }
    };

    handleSubmit = () => {
        const { handlePageChange, updateCart } = this.props;
        updateCart( this.state.cart );
        handlePageChange( 'right', 'address' );
    };

    isWithDefaultDiscount = () => {
        const { userDiscarded, userInfoDB } = this.props;
        return userInfoDB && userInfoDB.default_discount && ! userDiscarded;
    };

    removeProduct = index => {
        const { removeProductInCart } = this.props;
        removeProductInCart( index );
    };

    renderPic = product => {
		if ( product.mainFileName ) {
			const srcImage = `${ API_IMAGE }${ product.mainFileName }.png&w=500&h=500&crop-to-fit`;
			const srcErrorImage = IMAGES.placeholderSquare;
			
			return (
                <img
                    className='tile-img'
                    alt={ product.name }
                    src={ srcImage } 
                    onError={e => {
                        if ( this.state.imageLoadNoCache ) {
                            this.setState({ imageLoadNoCache: false });
                            e.target.src = `${ srcImage }&no-cache`;
                        } else if ( this.state.imageLoadError ) {
                            this.setState({ imageLoadError: false });
                            e.target.src = srcErrorImage;
                        }
                    }} />
			);
		}

		return (
			<img
				alt="img"
				className="tile-img"
				src={ IMAGES.placeholderSquare } />
		);
	};

    render() {
        const { language, userInfoDB } = this.props;
        const { cart, cartLength, indexProductToRemove } = this.state;
        
        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_confirmation' onClick={ this.closeConfirmation }>
                        <div id='profile_confirmation_box'>
                            <div>{ t( 'store:cartRemoveQuestion', language ) }</div>
                            <div>
                                <button onClick={ () => this.removeProduct( indexProductToRemove ) }>{ t( 'shared:yes', language ) }</button>
                                <button onClick={ this.closeConfirmation }>{ t( 'shared:no', language ) }</button>
                            </div>
                        </div>
                    </div>
                    <div id='profile_nav'>
                        <span>{ t( 'nav:cart', language ) }</span>
                    </div>
                    <div id='profile_body'>
                        {
                            cartLength > 0 && cart.map( ( item, index ) => {
                                const product = this.getProductInfo( item );
                                return (
                                    <div className='profile_menu' key={ index }>
                                        <div className='profile_scale cart_product_details'>
                                            <div className='cart_product_image'>{ this.renderPic( product ) }</div>
                                            <div className='cart_product_title'>{ product.name }</div>
                                            <div className='cart_product_price'>
                                                {
                                                    item.productQuantity > 1 ?
                                                    <span>
                                                        { `${ formatEuroPrice( product.price ) } x ${ item.productQuantity } = ${ formatEuroPrice( product.price * item.productQuantity ) }` }
                                                    </span>
                                                    :
                                                    <span>{ formatEuroPrice( product.price ) }</span>
                                                }
                                            </div>
                                            <div className='list-title-product-cart'>
                                                {
                                                    product.colors && product.colors.length === 1 &&
                                                    <div className='list-title-product-custom-select'>
                                                        <label>{ t( 'store:color', language ) }</label>
                                                        <div className='list-title-product-select-selected disabled'>
                                                            { product.colors[ 0 ] }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    product.colors && product.colors.length > 1 &&
                                                    <div className='list-title-product-custom-select'>
                                                        <label>{ t( 'store:color', language ) }</label>
                                                        <div
                                                            className='list-title-product-select-selected'
                                                            id={ `select_productColor_${ index }` }
                                                            onClick={ event => this.handleChangeProductColor( event, index ) }>
                                                                { item.productColor ? item.productColor : t( 'form:defaultSelect', language ) }
                                                        </div>
                                                        <div className='list-title-product-select-option-box'>     
                                                            {
                                                                product.colors.map( ( item_, index_ ) =>
                                                                    <div
                                                                        key={ index_ }
                                                                        className={ `list-title-product-select-option ${ index_ === product.colors.length - 1 ? 'last' : '' }` }
                                                                        id={ `select_productColor_${ item_ }_${ index }` }
                                                                        onClick={ event => this.handleChangeProductColor( event, index ) }>
                                                                            { item_ }
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    product.sizes && product.sizes.length === 1 &&
                                                    <div className='list-title-product-custom-select'>
                                                        <label>{ t( 'store:size', language ) }</label>
                                                        <div className='list-title-product-select-selected disabled'>
                                                            { product.sizes[ 0 ] }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    product.sizes && product.sizes.length > 1 &&
                                                    <div className='list-title-product-custom-select'>
                                                        <label>{ t( 'store:size', language ) }</label>
                                                        <div
                                                            className='list-title-product-select-selected'
                                                            id={ `select_productSize_${ index }` }
                                                            onClick={ event => this.handleChangeProductSize( event, index ) }>
                                                                { item.productSize ? item.productSize : t( 'form:defaultSelect', language ) }
                                                        </div>
                                                        <div className='list-title-product-select-option-box'>
                                                            {
                                                                product.sizes.map( ( item_, index_ ) =>
                                                                    <div
                                                                        key={ index_ }
                                                                        className={ `list-title-product-select-option ${ index_ === product.sizes.length - 1 ? 'last' : '' }` }
                                                                        id={ `select_productSize_${ item_ }_${ index }` }
                                                                        onClick={ event => this.handleChangeProductSize( event, index ) }>
                                                                            { item_ }
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                <div className='list-title-product-quantity-box'>
                                                    <label>{ t( 'store:quantity', language ) }</label>
                                                    <div className='list-title-product-quantity-input'>
                                                        <div
                                                            className='list-title-product-quantity-input-minus'
                                                            onClick={ () => this.handleChangeProductQuantity( 'minus', index ) }>
                                                            -
                                                        </div>
                                                        <input
                                                            type='number'
                                                            min='1'
                                                            name='productQuantity'
                                                            value={ item.productQuantity }
                                                            onChange={ () => {} }
                                                            />
                                                        <div
                                                            className='list-title-product-quantity-input-plus'
                                                            onClick={ () => this.handleChangeProductQuantity( 'plus', index ) }>
                                                            +
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='f-grow-1'></div>
                                        <div className='cart_product_remove' onClick={ () => { this.openConfirmation( index ) } }>{ t( 'shared:remove', language ) }</div>
                                    </div>
                                );
                            })
                        }
                        <div className='profile_menu'>
                            {
                                cartLength > 0 && this.getPriceTotal() > 0 ?
                                <>
                                    <div className='profile_scale cart_product_price tall'>
                                        { t( 'payment:total', language ) } : 
                                        {
                                            this.isWithDefaultDiscount() ?
                                            <>
                                                <span className='cart_product_price_old'>{ formatEuroPrice( this.getPriceTotal() ) }</span>
                                                { formatEuroPrice( Math.floor( this.getPriceTotal() - ( ( this.getPriceTotal() * userInfoDB.default_discount ) / 100 ) ) ) }
                                            </>
                                            :
                                            <span className='cart_product_price_initial'>{ formatEuroPrice( this.getPriceTotal() ) }</span>
                                        }
                                    </div>
                                    {
                                        this.isWithDefaultDiscount() &&
                                        <>
                                            <div className='f-grow-1'></div>
                                            <div className='cart_product_defualt_discount_box'>
                                                - { userInfoDB.default_discount } % { t( 'store:forSub', language ) }
                                            </div>
                                        </>
                                    }
                                </>
                                :
                                <div className='profile_scale cart_product_title'>{ t( 'store:cartEmpty', language ) }</div>
                            }
                        </div>
                    </div>
                </div>
                {
                    cartLength > 0 && this.getPriceTotal() > 0 &&
                    <div className='cart_valid_btn_box'>
                        <div className='cart_valid_btn' onClick={ () => window.location.href = '/browse/?boutique/#/boutique/' }>{ t( 'store:continue', language ) }</div>
                        <div className='cart_valid_btn' onClick={ this.handleSubmit }>{ t( 'store:valid', language ) }</div>
                    </div>
                }
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
	storeCart: state.storeReducer.storeCart,
	storeCartLength: state.storeReducer.storeCartLength,
    storeSelectedProducts: state.storeReducer.storeSelectedProducts,
    userDiscarded: state.userReducer.userDiscarded,
	userInfoDB: state.userReducer.userInfoDB,
});

const dispatchToProps = dispatch => ({
    removeProductInCart: index => dispatch(storeAction.removeProductInCart( index )),
    updateCart: cart => dispatch(storeAction.updateCart( cart )),
});

export default connect( stateToProps, dispatchToProps )( SCValidation );