import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';

import { CURRENCY, ICONS } from "../../../constants";
import { logEvent } from "../../../services/tracking";
import { t } from "../../../utils";

class PAccountBilling extends React.Component {
    getPriceStg = item => {
        if ( ! item.amount || ! item.currency ) return '';
        return `${ ( item.amount / 100 ).toString().replace( '.', ',' ) } ${ item.currency === CURRENCY.EUR ? '€' : item.currency.toUpperCase() }`;
    };

    handleClickBilling = item => {
        const { userInfoDB } = this.props;
        if ( item.paid && item.url ) {
            logEvent({ NoCli: userInfoDB.NoCli, event: 'ask-billing', eventDetails: `billing for ${ item.date }` }); 
            window.open( item.url, "_blank");
        } 
    }

    render() {
        const { language, stripeReceipts } = this.props;
        
        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_nav'>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'menu' ) } }>{ t( 'profile:title', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'account' ) } }>{ t( 'profile:account', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span>{ t( 'profile:billingHistory', language ) }</span>
                    </div>
					<div id='profile_header' className='profile_header_title'>
						<h1>{ t( 'profile:billingHistory', language ) }</h1>
					</div>
					<div id='profile_body'>
						<div className='profile_menu_title'>
							<div>{ t( 'shared:details', language ) }</div>
						</div>
                        {
                            stripeReceipts && stripeReceipts.length > 0 &&
                            stripeReceipts.map( ( item, index ) =>
                                <div
                                    className={ `profile_menu_small ${ item.paid ? 'collect_flag profile_menu_small profile_hover' : '' }` }
                                    id={ `profile_menu_billing_${ index }` }
                                    key={ index }
                                    onClick={ () => this.handleClickBilling( item ) }>
                                    <div className='profile_scale'>
                                        <span className='profile_scale_billing_date'>{ item.date } : </span>
                                        <span>{ this.getPriceStg( item ) }</span>
                                    </div>
                                    <div className='f-grow-1'></div>
                                    {
                                        item.paid ?
                                        <div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
                                        :
                                        <div className='profile_scale_billing_error'>{ t( 'profile:paymentFailed', language ) }</div>
                                    }
                                </div>
                            )
                        }
					</div>
				</div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
	stripeReceipts: state.userReducer.stripeReceipts,
	userInfoDB: state.userReducer.userInfoDB,
});

export default connect( stateToProps )( PAccountBilling );