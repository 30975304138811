import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';

import { ICONS } from "../../constants";
import { t } from "../../utils";

class SCSuccess extends React.Component {
    render() {
        const { language } = this.props;

        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_nav'>
                            <span>{ t( 'nav:cart', language ) }</span>
                            <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                            <span>{ t( 'nav:confirmation', language ) }</span>
                    </div>
                    <div id='profile_body'>
                        <div className='profile_menu'>
                            <div className='profile_scale cart_product_title'>
                                <div>{ t( 'store:successInfo1', language ) }</div>
                                <div>{ t( 'store:successInfo2', language ) }</div>
                                <div>{ t( 'store:successInfo3', language ) } <a href='/browse/?mon-compte/#/mon-compte/'>{ t( 'profile:title', language ) }</a></div>
                            </div>
                        </div>
                    </div>
                    <div className='profile_edit_usual_btn'>
                        <button onClick={ () => window.location.href = '/browse/#/' }>
                            { t( 'shared:back', language ) }
                        </button>
                    </div>
                </div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
});

export default connect( stateToProps )( SCSuccess );