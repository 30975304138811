import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';
import Loader from 'react-loader-spinner';

import { API_IMAGE, COMMENT_TYPE, ICONS, IMAGES } from "../../constants";
import { createComment } from "../../services/comment";
import { getUserInfo, handleLoader, formatEuroPrice, t } from "../../utils";

class PSectionComment extends React.Component {
    constructor(props) {
		super(props);

        const { userPurchases } = this.props;

        this.state = {
            comment: '',
            errorMessage: '',
            isCommentDone: false,
            pseudo: userPurchases && userPurchases.pseudo ? userPurchases.pseudo : '',
            rateStarNumber: 0,
            successMessage: '',
        };
	};

    checkErrors = () => {
        const { language } = this.props;

        if ( this.state.rateStarNumber === 0 ) {
            this.setState ({ errorMessage: t( 'form:rateStarErrorProduct', language ) });
            return false;
        }
        if ( this.state.comment !== '' && this.state.pseudo === '' ) {
            this.setState ({ errorMessage:  t( 'form:pseudoError', language ) });
            return false;
        }

        this.setState ({ errorMessage: '' });
        return true;
    };

    handleChange = ( event ) => { 
        const value = event.target.value;
        const name = event.target.name;
        this.setState ( { [name]: value, successMessage: '' } );
    };

    handleStarChange = starNumber => { 
        if ( starNumber > 0 ) this.setState ( { rateStarNumber: starNumber } );
    };

    handleSubmit = () => {
        const { commentItem, commentType, language } = this.props;
        const { comment, pseudo, rateStarNumber } = this.state;

        if ( this.checkErrors() ) {
            handleLoader( 'profile_loader', 'add' );
            const data = {
                NoCli: getUserInfo().NoCli,
                comment: comment,
                itemId: commentItem.productId,
                pseudo: pseudo,
                rateStarNumber: rateStarNumber,
                type: commentType,
            };
            createComment( data )
                .then( res => {
                    if ( ! res.ret ) {
                        this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                    } else {
                        this.setState ({ isCommentDone: true, successMessage: t( 'form:commentSuccess', language ) });
                    }
                    handleLoader( 'profile_loader', 'remove' );
                })
                .catch( error => {
                    console.log( error );
                    this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                    handleLoader( 'profile_loader', 'remove' );
                });
        }
    };

    renderPic = product => {
		if ( product.mainFileName ) {
			const srcImage = `${ API_IMAGE }${ product.mainFileName }.png&w=500&h=500&crop-to-fit`;
			const srcErrorImage = IMAGES.placeholderSquare;
			
			return (
                <img
                    className='tile-img'
                    alt={ product.name }
                    src={ srcImage } 
                    onError={e => {
                        if ( this.state.imageLoadNoCache ) {
                            this.setState({ imageLoadNoCache: false });
                            e.target.src = `${ srcImage }&no-cache`;
                        } else if ( this.state.imageLoadError ) {
                            this.setState({ imageLoadError: false });
                            e.target.src = srcErrorImage;
                        }
                    }} />
			);
		}

		return (
			<img
				alt="img"
				className="tile-img"
				src={ IMAGES.placeholderSquare } />
		);
	};

    render() {
        const { commentItem, commentType, language } = this.props;
        const { comment, isCommentDone, pseudo, rateStarNumber } = this.state;
        
        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_loader' className='bottom-loader'><Loader type='TailSpin' color='#fba00b' height={100} width={100} /></div>
                    <div id='profile_nav'>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'menu' ) } }>{ t( 'profile:title', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'orders' ) } }>{ t( 'profile:orders', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'ordersCompleted' ) } }>{ t( 'profile:ordersCompleted', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span>{ t( 'profile:review', language ) }</span>
                    </div>
                    {
                        commentType === COMMENT_TYPE.PRODUCT && commentItem && commentItem.productId &&
                        <>
                            <div id='profile_body'>
                                <div className='profile_menu'>
                                    <div className='profile_scale cart_product_details'>
                                        <div className='cart_product_image'>{ this.renderPic( commentItem ) }</div>
                                        <div className='cart_product_title'>{ commentItem.name }</div>
                                        <div className='cart_product_price'>
                                            <span>{ formatEuroPrice( commentItem.amount ) }</span>
                                        </div>
                                        <div className='list-title-product-cart'>
                                            {
                                                commentItem.color &&
                                                <div className='list-title-product-custom-select'>
                                                    <label>{ t( 'store:color', language ) }</label>
                                                    <div className='list-title-product-select-selected disabled'>{ commentItem.color }</div>
                                                </div>
                                            }
                                            {
                                                commentItem.size &&
                                                <div className='list-title-product-custom-select'>
                                                    <label>{ t( 'store:size', language ) }</label>
                                                    <div className='list-title-product-select-selected disabled'>{ commentItem.size }</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='profile_edit_usual'>
                                <div className='profile_edit_usual_form'>
                                    <h2>{ t( 'form:yourOpinionProduct', language ) }</h2>
                                    <div className='comment_star_rating_box'>
                                        <div
                                            className='comment_star_box'
                                            onClick={ () => this.handleStarChange( 1 ) }>
                                            { rateStarNumber > 0 ? <i className="fas fa-star"></i> : <i className="far fa-star"></i> }
                                        </div>
                                        <div
                                            className='comment_star_box'
                                            onClick={ () => this.handleStarChange( 2 ) }>
                                            { rateStarNumber > 1 ? <i className="fas fa-star"></i> : <i className="far fa-star"></i> }
                                        </div>
                                        <div
                                            className='comment_star_box'
                                            onClick={ () => this.handleStarChange( 3 ) }>
                                            { rateStarNumber > 2 ? <i className="fas fa-star"></i> : <i className="far fa-star"></i> }
                                        </div>
                                        <div
                                            className='comment_star_box'
                                            onClick={ () => this.handleStarChange( 4 ) }>
                                            { rateStarNumber > 3 ? <i className="fas fa-star"></i> : <i className="far fa-star"></i> }
                                        </div>
                                        <div
                                            className='comment_star_box'
                                            onClick={ () => this.handleStarChange( 5 ) }>
                                            { rateStarNumber > 4 ? <i className="fas fa-star"></i> : <i className="far fa-star"></i> }
                                        </div>
                                    </div>
                                    <div className='profile_edit_usual_input'>
                                        <label htmlFor='pseudo'>{ t( 'form:pseudo', language ) }</label>
                                        <input
                                            type='text'
                                            name='pseudo'
                                            value={ pseudo }
                                            onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                            onChange={ event => this.handleChange( event ) }
                                            />
                                    </div>
                                    <div className='profile_edit_usual_textarea'>
                                        <label htmlFor="textAreaValue">{ t( 'form:comments', language ) }</label>
                                        <textarea
                                            name="comment"
                                            value={ comment }
                                            onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                            onChange={ event => this.handleChange( event ) }
                                            />
                                    </div>
                                    <div className="profile_edit_usual_message">
                                        <div className='profile_edit_usual_error'>{ this.state.errorMessage }</div>
                                        <div className='profile_edit_usual_success'>{ this.state.successMessage }</div>
                                    </div>
                                    <div className='profile_edit_usual_btn comment_box'>
                                        {
                                            isCommentDone ?
                                            <button onClick={ () => this.props.handlePageChange( 'left', 'ordersCompleted' ) }>{ t( 'shared:back', language ) }</button>
                                            :
                                            <button onClick={ this.handleSubmit }>{ t( 'shared:confirm', language ) }</button>
                                        }
                                    </div>
                                </div> 
                            </div>
                        </>
                    }
				</div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
	commentItem: state.commentReducer.commentItem,
	commentType: state.commentReducer.commentType,
    userPurchases: state.userReducer.userPurchases,
});

export default connect( stateToProps )( PSectionComment );