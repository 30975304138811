import { ROUTING_REDUCER } from "../../constants";

const initialState = {
	isGlobalPlayingPage: false,
	isVodPage: false,
	selected: null,
	themeId: null,
	version: 0,
};

export default (state = initialState, action) => {
  const newState = Object.assign({}, state);

	switch (action.type) {
		case ROUTING_REDUCER.FETCH_CURRENT_ROUTING:
			const theme = parseInt( action.payload )
			newState.themeId = theme;
			if ( theme === 0 ) {
				newState.selected = 'home';
			} else if ( theme === 2 ) {
				newState.selected = 'chasse';
			} else if ( theme === 3 ) {
				newState.selected = 'peche';
			}
			return newState;
		case ROUTING_REDUCER.FETCH_CURRENT_ROUTING_BY_URL:
			switch( action.payload ) {
				case 'boutique':
				case 'boutique-details':
					newState.selected = 'store';
					return newState;
				case 'en-continu':
					newState.selected = 'playlist';
					return newState;
				case 'formations':
				case 'formations-details':
					newState.isGlobalPlayingPage = true;
					newState.isVodPage = true;
					newState.selected = 'training';
					return newState;
				case 'ma-liste':
					newState.selected = 'favorites';
					return newState;
				case 'mes-films-en-cours':
					newState.selected = '';
					return newState;
				case 'mes-films-termines':
					newState.selected = '';
					return newState;
				case 'mon-compte':
					newState.selected = 'profile';
					return newState;
				case 'mon-panier':
					newState.selected = null;
					return newState;
				case 'preferences':
					newState.selected = 'preferences';
					return newState;
				case 'search':
					newState.selected = 'search';
					return newState;
				default:
					return state;
			}
		case ROUTING_REDUCER.FETCH_VERSION:
			newState.version = action.payload;
			return newState;
		case ROUTING_REDUCER.HIDE_SELECTED:
			newState.selected = state.selected + action.payload;
			return newState;
		default:
			return state;
	}
};
