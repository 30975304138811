import { ROUTING_REDUCER } from "../../constants";

export default {
    fetchCurrentRouting: themeId => dispatch => {
        dispatch( {
            type: ROUTING_REDUCER.FETCH_CURRENT_ROUTING,
            payload: themeId,
        });
    },
    fetchCurrentRoutingByUrl: url => dispatch => {
        dispatch( {
            type: ROUTING_REDUCER.FETCH_CURRENT_ROUTING_BY_URL,
            payload: url,
        });
    },
    fetchVersion: version => dispatch => {
        dispatch( {
            type: ROUTING_REDUCER.FETCH_VERSION,
            payload: version,
        });
    },
    hideSelected: () => dispatch => {
        dispatch( {
            type: ROUTING_REDUCER.HIDE_SELECTED,
            payload: '-hidden',
        });
    },
}; 