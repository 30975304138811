import React from "react";
import { connect } from "react-redux";

import RowRender from "../../components/tools/RowRender";

class ListRows extends React.Component {
	render() {
		const { trainingDetailsDescription } = this.props;

		return (
			<div id='chasse_Homelist'>
				<RowRender movieListInfo={ trainingDetailsDescription } globalPlay={ true } />
			</div>
		);
	}
}

const stateToProps = state => ({
	trainingDetailsDescription: state.trainingReducer.trainingDetailsDescription,
});

export default connect( stateToProps )( ListRows );