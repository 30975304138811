import React from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";

import InfiniteCarousel from 'react-leaf-carousel';

import ListItem from "./ListItem";
import ListLinkItem from "./ListLinkItem";
import MoviePopup from "../MovieDisplay/MoviePopup";
import VideoPlayer from "../Videoplayer";
import InfinteCarrouselReRender from "../tools/InfinteCarrouselReRender";

import { DISPLAY, ICONS, IMAGES } from "../../constants";
import { moviesAction } from "../../redux/actions";
import { updateUserPaymentStatus } from "../../services/user";
import { getUserInfo, isGuest, isProgressTimeValid } from "../../utils";

class ListMovies extends React.Component {
	static contextTypes = { router : Proptypes.object };

	constructor(props,context) {
		super(props, context);

		this.logBtnExit = this.logBtnExit.bind(this);

		this.state = {
			movie : null,
			playerShow: false,
			playerHeight: 0,
			playerWidth: 0,
			show: false,
			videoJsOptions: { autoplay: false, playing: false },
		};

		this.next = 'next';
		this.previous = 'previous';
		this.stretchTimeout = null;
	};

	componentDidMount() {
		this.backListener = this.context.router.history.listen( ( location, action, state ) => {
			if ( action === "POP" && this.state.playerShow && location.hash !== '#play/' ) this.logBtnExit();
		});

		this.stretchTimeout = setTimeout( this.stretchFirstElement, 0 ); 
	};

	componentWillUnmount() { 
		clearTimeout( this.stretchTimeout );
	};

	getSlidesNumbers = size => {
		if ( size === DISPLAY.XL ) {
			return {
				max: 5,
				breakpoints: [
					{ breakpoint: 768, settings: { arrows: false, slidesToShow: 1, slidesToScroll: 1 } },
					{ breakpoint: 1025, settings: { arrows: false, slidesToShow: 2, slidesToScroll: 2 } },
					{ breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 3 } },
					{ breakpoint: 3000, settings: { slidesToShow: 4, slidesToScroll: 4 } },
				]
			};
		} else if ( size === DISPLAY.XXL ) {
			return {
				max: 4,
				breakpoints: [
					{ breakpoint: 768, settings: { arrows: false, slidesToShow: 1, slidesToScroll: 1 } },
					{ breakpoint: 1025, settings: { arrows: false, slidesToShow: 2, slidesToScroll: 2 } },
					{ breakpoint: 1200, settings: { slidesToShow: 2, slidesToScroll: 2 } },
					{ breakpoint: 3000, settings: { slidesToShow: 3, slidesToScroll: 3 } },
				]
			};
		} else if ( size === DISPLAY.XS ) {
			return {
				max: 7,
				breakpoints: [
					{ breakpoint: 768, settings: { arrows: false, slidesToShow: 2, slidesToScroll: 2 } },
					{ breakpoint: 1025, settings: { arrows: false, slidesToShow: 4, slidesToScroll: 4 } },
					{ breakpoint: 1200, settings: { slidesToShow: 5, slidesToScroll: 5 } },
					{ breakpoint: 3000, settings: { slidesToShow: 6, slidesToScroll: 6 } },
				]
			};
		} else if ( size === DISPLAY.XXS ) {
			return {
				max: 8,
				breakpoints: [
					{ breakpoint: 768, settings: { arrows: false, slidesToShow: 3, slidesToScroll: 3 } },
					{ breakpoint: 1025, settings: { arrows: false, slidesToShow: 5, slidesToScroll: 5 } },
					{ breakpoint: 1200, settings: { slidesToShow: 6, slidesToScroll: 6 } },
					{ breakpoint: 3000, settings: { slidesToShow: 7, slidesToScroll: 7 } },
				]
			};
		} else if ( size === DISPLAY.SINGLETON ) {
			return {
				max: 1,
				breakpoints: [
					{ breakpoint: 768, settings: { arrows: false, slidesToShow: 1, slidesToScroll: 1 } },
					{ breakpoint: 1025, settings: { arrows: false, slidesToShow: 1, slidesToScroll: 1 } },
					{ breakpoint: 1200, settings: { slidesToShow: 1, slidesToScroll: 1 } },
					{ breakpoint: 3000, settings: { slidesToShow: 1, slidesToScroll: 1 } },
				]
			};
		}

		return {
			max: 6,
			breakpoints: [
				{ breakpoint: 768, settings: { arrows: false, slidesToShow: 2, slidesToScroll: 2 } },
				{ breakpoint: 1025, settings: { arrows: false, slidesToShow: 3, slidesToScroll: 3 } },
				{ breakpoint: 1200, settings: { slidesToShow: 4, slidesToScroll: 4 } },
				{ breakpoint: 3000, settings: { slidesToShow: 5, slidesToScroll: 5 } },
			]
		};
	};

	goToChangePaymentPage = () => {
        const { changePaymentToken } = this.props;
        if ( changePaymentToken && changePaymentToken !== '' ) {
            updateUserPaymentStatus( `?token=${ changePaymentToken }&type=discarded` );
            window.location.href = "/modifier-paiement/?session_id=" + changePaymentToken;
        } 
	};

	handleMoviesSwap = type => {
		const { movieList } = this.props;
		const movieKey = movieList.indexOf( movieList.filter(el => el.transaction === this.state.movie.transaction)[0] );
		let movie = {};
		switch( type ) {
			case this.next:
				if ( movieKey + 2 > movieList.length ) {
					movie = movieList[ 0 ];
				} else {
					movie = movieList[ movieKey + 1 ];
				}
				break;
			case this.previous:
				if ( movieKey < 1 ) {
					movie = movieList[ movieList.length - 1 ];
				} else {
					movie = movieList[ movieKey - 1 ];
				}
				break;
			default:
				break;
		}

		this.setState({ movie })
	};

	logBtnExit = () => {
		if( document.location.hash.includes( "#play/" ) ) this.context.router.history.goBack();
		this.setState( { playerShow: false, playerHeight: 0, playerWidth: 0, videoJsOptions: { autoplay: false, playing: false } } );
		if ( this.props.isGlobalPlayingPage ) {
			window.location.href = window.location.href.replace( "#play/", "" );
			window.location.reload();
		}
	};

	navClose = () => {
		const { fetchFavoritesMovies, fetchProgressMovies, profileVersion, themeId } = this.props;

		const userId = getUserInfo().NoCli;
		if ( userId ) {
			fetchFavoritesMovies( themeId, userId, profileVersion );
			fetchProgressMovies( themeId, userId, profileVersion );
		}
		this.setState( { show: false } );
	};
		
	navCloseIf = ( event, condition ) => {
		if ( event.target.classList.value !== condition ) return false;
		this.navClose();
	};

	onClickFullscreen = () => {
		const { movie } = this.state;
		const { isVodPage, movieList, userDiscarded } = this.props;

		if ( ! isVodPage ) {
			if ( isGuest() ) {
				window.location.href = "/offres/";
				return;
			} else if ( userDiscarded ) {
				this.goToChangePaymentPage();
				return;
			}
		}

		const progressInfo = isProgressTimeValid( movie.progressTime, movie.timeTotal ) ? { progressTime: movie.progressTime, timeTotal: movie.timeTotal } : null;
		const movieKey = movieList.indexOf( movieList.filter( el => el.transaction === movie.transaction )[ 0 ] );
			
		this.setState({
			playerShow: true,
			playerHeight: "100%",
			playerWidth: "100%",
			videoJsOptions: {
				logBtnExit: this.logBtnExit,
				movieKey: movieKey,
				movieList: movieList, 
				playing: true,
				progressInfo: progressInfo,
			},
		});

		window.location.href = window.location.href + "#play/";
	};

	onClickFullscreenAddOn = type => {
		const { movie } = this.state;
		const { movieList, userDiscarded } = this.props;

		if ( type !== 'trailer' ) {
			if ( isGuest() ) {
				window.location.href = "/offres/";
				return;
			} else if ( userDiscarded ) {
				this.goToChangePaymentPage();
				return;
			}
		}

		const progressInfo = isProgressTimeValid( movie.progressTime, movie.timeTotal ) ? { progressTime: movie.progressTime, timeTotal: movie.timeTotal } : null;
		const movieKey = movieList.indexOf( movieList.filter( el => el.transaction === movie.transaction )[ 0 ] );

		this.setState({
			playerShow: true,
			playerHeight: "100%",
			playerWidth: "100%",
			videoJsOptions: {
				isBonus: type === 'bonus',
				isTrailer: type === 'trailer',
				logBtnExit: this.logBtnExit,
				movieKey: movieKey,
				movieList: movieList, 
				playing: true,
				progressInfo: progressInfo,
			},
		});

		window.location.href = window.location.href + "#play/";
	};

	stretchFirstElement = () => {
		const { display } = this.props;

		let widthIncrement = 36;
		if ( display.style === DISPLAY.POSTER ) widthIncrement = 15;
		if ( display.style === DISPLAY.POSTER_LONG ) widthIncrement = 11;
		if ( display.style === DISPLAY.SQUARE ) widthIncrement = 20;

		if ( display.isFirstItemLarge ) {
			const $el = document.getElementById( `first-${ display.id }` );
			if ( $el && $el.parentElement && $el.parentElement.nextSibling ) {
				if ( $el.parentElement.offsetWidth && $el.parentElement.nextSibling.offsetWidth ) {
					if ( $el.parentElement.offsetWidth < $el.parentElement.nextSibling.offsetWidth * 1.8 )
						$el.parentElement.style.width = `${ $el.parentElement.offsetWidth * 2 + widthIncrement }px`;
				}
			}
		}
	};

	render() {
		const { addon, currentDisplay, display, isMovieInfoPage, isMozaicPage, movieList, movieListIndex } = this.props;
		const navMobile = document.getElementById( 'nav-container-mobile' );
		const navWeb = document.getElementById( 'nav-container-web' );

		if ( this.state.show && ! isMovieInfoPage ) {
			if ( navMobile ) navMobile.style.opacity = '0';
			if ( navWeb ) navWeb.style.opacity = '0';
		} else {
			if ( navMobile ) navMobile.style.opacity = '1';
			if ( navWeb ) navWeb.style.opacity = '1';
		}

		if ( this.state.show ) {
			document.documentElement.style.overflowY = 'hidden';
		} else {
			document.documentElement.style.overflowY = 'scroll';
		}

		let slideNumbers = this.getSlidesNumbers( display.sizeNew || display.size || '' );
		let moviesModified = [];

		if ( display.isFirstItemLarge ) {
			currentDisplay.forEach( ( el, index ) => {
				if ( index === 0 ) {
					moviesModified.push( [ el ] );
					moviesModified.push( [] );
				} else if ( index % 2 === 1 ) {
					if ( currentDisplay[ index + 1 ] ) {
						moviesModified.push( [ el, currentDisplay[ index + 1 ] ] );
					} else {
						moviesModified.push( [ el, currentDisplay[ 0 ] ] );
					}
				}
			});

			if ( [ DISPLAY.POSTER, DISPLAY.POSTER_LONG, DISPLAY.SQUARE ].includes( display.style ) ) {
				slideNumbers = {
					max: 9,
					breakpoints: [
						{ breakpoint: 768, settings: { arrows: false, slidesToShow: 4, slidesToScroll: 4 } },
						{ breakpoint: 1025, settings: { arrows: false, slidesToShow: 6, slidesToScroll: 6 } },
						{ breakpoint: 1200, settings: { slidesToShow: 7, slidesToScroll: 7 } },
						{ breakpoint: 3000, settings: { slidesToShow: 8, slidesToScroll: 8 } },
					]
				};
			} else {
				slideNumbers = {
					max: 8,
					breakpoints: [
						{ breakpoint: 768, settings: { arrows: false, slidesToShow: 3, slidesToScroll: 3 } },
						{ breakpoint: 1025, settings: { arrows: false, slidesToShow: 5, slidesToScroll: 5 } },
						{ breakpoint: 1200, settings: { slidesToShow: 6, slidesToScroll: 6 } },
						{ breakpoint: 3000, settings: { slidesToShow: 7, slidesToScroll: 7 } },
					]
				};
			}
		}

		return (
			<>
				{
					isMozaicPage ?
					<div className="movie_mozaic">
						{
							movieList && movieList.length > 0 &&
							movieList.map( movie => 
								<ListItem 
									catId={ movieListIndex } 
									key={ movie.transaction } 
									movie={ movie }
									parent={ this }
									isMovieInfoPage={ isMovieInfoPage }
									isMozaicPage={ isMozaicPage }
									style={ display.styleNew || display.style || '' } />
							)
						}
					</div>
					:
					<div className="grid for-image">
						<InfinteCarrouselReRender condition={ display.rerender }>
							<InfiniteCarousel
								breakpoints={ slideNumbers.breakpoints } 
								dots={false}
								slidesSpacing={5}
								showSides={true}
								sidesOpacity={.5}
								sideSize={.1}
								slidesToScroll={ slideNumbers.max }
								slidesToShow={ slideNumbers.max  }
								animationDuration={600}
								lazyLoad={true}
								placeholderImageSrc={ IMAGES.placeholderThumbnail }>
								{
									currentDisplay && currentDisplay.length > 0 &&
									(
										addon && addon.type === DISPLAY.MULTIPLE_IMAGES && addon.images && addon.images.length > 0 ?
										(
											addon.images.map( image => 
												<ListItem 
													catId={ movieListIndex } 
													key={ currentDisplay[ 0 ].transaction } 
													movie={ currentDisplay[ 0 ] }
													multipleImage={ image }
													parent={ this }
													isMovieInfoPage={ isMovieInfoPage }
													isMozaicPage={ isMozaicPage }
													style={ display.styleNew || display.style || '' } />
											)
										)
										:
										(
											moviesModified && moviesModified.length > 0 ?
											(
												moviesModified.map( (item, index) => {
													if ( item.length === 1 ) {
														return (
															<div key={ index } id={ `first-${ display.id }` }>
																{
																	item[ 0 ].type === DISPLAY.LINK ?
																	<ListLinkItem 
																		catId={ movieListIndex } 
																		link={ item[ 0 ] }
																		isMozaicPage={ isMozaicPage }
																		effect={ display.effect }
																		parent={ this }
																		style={ display.styleNew || display.style || '' } />
																	:
																	<ListItem 
																		catId={ movieListIndex } 
																		movie={ item[ 0 ] }
																		parent={ this }
																		isMovieInfoPage={ isMovieInfoPage }
																		isMozaicPage={ isMozaicPage }
																		effect={ display.effect }
																		style={ display.styleNew || display.style || '' } />
																}
															</div> 
														)
													} else if ( item.length === 2 ) {
														return (
															<div key={ index } >
																{
																	item[ 0 ].type === DISPLAY.LINK ?
																	<ListLinkItem 
																		catId={ movieListIndex } 
																		link={ item[ 0 ] }
																		isMozaicPage={ isMozaicPage }
																		effect={ display.effect }
																		parent={ this }
																		style={ display.styleNew || display.style || '' } />
																	:
																	<ListItem 
																		catId={ movieListIndex } 
																		movie={ item[ 0 ] }
																		parent={ this }
																		isMovieInfoPage={ isMovieInfoPage }
																		isMozaicPage={ isMozaicPage }
																		effect={ display.effect }
																		style={ display.styleNew || display.style || '' } />
																}
																{
																	item[ 1 ].type === DISPLAY.LINK ?
																	<ListLinkItem 
																		catId={ movieListIndex } 
																		link={ item[ 1 ] }
																		isMozaicPage={ isMozaicPage }
																		effect={ display.effect }
																		parent={ this }
																		style={ display.styleNew || display.style || '' } />
																	:
																	<ListItem 
																		catId={ movieListIndex } 
																		movie={ item[ 1 ] }
																		parent={ this }
																		isMovieInfoPage={ isMovieInfoPage }
																		isMozaicPage={ isMozaicPage }
																		effect={ display.effect }
																		style={ display.styleNew || display.style || '' } />
																}
															</div> 
														)
													}
													return <div key={ index }></div>;
												})
											)
											:
											(
												currentDisplay.map( (item, index) =>
													<div key={ index } >
														{
															item.type === DISPLAY.LINK ?
															<ListLinkItem 
																catId={ movieListIndex } 
																link={ item }
																isMozaicPage={ isMozaicPage }
																effect={ display.effect }
																parent={ this }
																style={ display.styleNew || display.style || '' } />
															:
															<ListItem 
																catId={ movieListIndex } 
																movie={ item }
																parent={ this }
																isMovieInfoPage={ isMovieInfoPage }
																isMozaicPage={ isMozaicPage }
																effect={ display.effect }
																style={ display.styleNew || display.style || '' } />
														}
													</div> 
												)
											)	
										)
									)
								}
							</InfiniteCarousel>
						</InfinteCarrouselReRender>
					</div>
				}
				<div className="grid">
					{
						this.state.show &&
						<>
							{
								isMovieInfoPage ?
								<div className="movie_popup_info movie_page">
									<MoviePopup
										movie={ this.state.movie }
										parent={ this } />
								</div>
								:
								<div className="movie_popup">
									<div className='movie_popup_mask' onClick={ e => this.navCloseIf( e, 'movie_popup_mask' ) }>
										<div className="movie_popup_info">
											<MoviePopup
												movie={ this.state.movie }
												parent={ this } />
											<div className='movie_popup_close'>
												<button className="close-button fa fa-window-close" onClick={ this.navClose }></button>
											</div>
											{
												( ! addon || addon.type !== DISPLAY.MULTIPLE_IMAGES ) &&
												<>
													<div className='movie_popup_left' onClick={ e => this.navCloseIf( e, 'movie_popup_left' ) }>
														<div className="movie_popup_previous" onClick={ () => this.handleMoviesSwap(this.previous) }>
															<div>
																<img className="movie_icon" src={ ICONS.backArrow } alt='icone precedent' />
															</div>
														</div>
													</div>	
													<div className='movie_popup_right' onClick={ e => this.navCloseIf( e, 'movie_popup_right' ) }>
														<div className="movie_popup_next" onClick={ () => this.handleMoviesSwap(this.next) }>
															<div>
																<img className="movie_icon" src={ ICONS.backArrow } alt='icone suivant' />
															</div>
														</div>
													</div>
												</>
											}	
										</div>
									</div>
								</div>		
							}
						</>
					}	
					<div id="z3pOverlay" className="z3PlayOverlay" style={{display : this.state.playerShow ? 'block' : 'none', width: this.state.playerWidth, height: this.state.playerHeight }}>
						<VideoPlayer className="video-js" id="VideoAccueil" { ...this.state.videoJsOptions } />
					</div>
				</div>
			</>
		);
	};
};

const stateToProps = state => ({
	changePaymentToken: state.userReducer.changePaymentToken,
	isGlobalPlayingPage: state.routingReducer.isGlobalPlayingPage,
	isVodPage: state.routingReducer.isVodPage,
	profileVersion: state.userReducer.profileVersion,
	themeId: state.routingReducer.themeId,
	userDiscarded: state.userReducer.userDiscarded,
});

const dispatchToProps = dispatch => ({
	fetchFavoritesMovies: ( themeId, userId, version ) => dispatch( moviesAction.fetchFavoritesMovies( themeId, userId, version )),
	fetchProgressMovies: ( themeId, userId, version ) => dispatch( moviesAction.fetchProgressMovies( themeId, userId, version )),
});

export default connect( stateToProps, dispatchToProps )( ListMovies );