import $ from "jquery";
import { API_DATA } from "../../constants";
const URL = `${API_DATA}/user/updateUnsubStep.php`;

export default ( queryVariable ) => {
    if ( queryVariable !== undefined ) {
        return $.ajax( {
            url: URL,
            method: 'POST',
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            dataType : 'json',
            data: queryVariable
        })
    }
};