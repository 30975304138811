import React from "react";
import { connect }  from "react-redux";

import ButtonClose from "../components/ButtonClose";
import HeaderLogo from "../components/HeaderLogo";

import { DATA_VLOG, POPUP_REDUCER } from "../../../constants";
import { popupAction } from "../../../redux/actions";
import { activeCampaignEvent, gtmEvent } from "../../../services/tracking";
import { createUser } from "../../../services/user";
import { checkEmail, handleLoader, t } from "../../../utils";
 
class PopupRegistration extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ageValid: false,
            email: '',
            newsletter: false,
            password: '',
            passwordCheck: '',
            phoneNumber: '',
            errorMessage: ''
        };
    };

    checkErrors = () => {
        const { language } = this.props;

        if ( this.state.email === '' ) {
            this.setState({ errorMessage: t( 'form:emailError', language ) });
            return false;
        } else if ( ! checkEmail( this.state.email ) ) {
            this.setState({ errorMessage: t( 'form:emailValidError', language ) });
            return false;
        } else if ( this.state.password === '' ) {
            this.setState({ errorMessage: t( 'form:passwordEmpty', language ) });
            return false;
        } else if ( this.state.password !== this.state.passwordCheck ) {
            this.setState({ errorMessage: t( 'form:passwordConfirmError', language ) });
            return false;
        } else if ( ! this.state.ageValid ) {
            this.setState({ errorMessage: t( 'form:ageValidError', language ) });
            return false;
        }
        this.setState({ errorMessage: '' });
        return true;
    };

    getTitle = () => {
        const { language, isFreeMovie, isPlaylist } = this.props;

        if ( isFreeMovie ) return t( 'registration:titleFreeMovie', language );
        if ( isPlaylist ) return t( 'registration:titlePlaylist', language );
        return t( 'registration:title', language );
    };

    handleChange = event => {
        let value = event.target.value;
        const name = event.target.name;
        if ( name === 'email' ) value = value.toLowerCase().trim();
        this.setState({ [name]: value });
    };

    handleSubmit = () => {
        const { language } = this.props;

        if ( this.checkErrors() ) {
            handleLoader( 'ils_loader', 'add' );

            if ( this.state.phoneNumber )
                activeCampaignEvent({ email: this.state.email, eventName: 'ajout telephone', eventDetails: 'browse', phone: this.state.phoneNumber });

            const dataUser = {
                email: this.state.email,
                optIn: this.state.newsletter ? '0' : '1',
                password: this.state.password,
                phoneNumber: this.state.phoneNumber,
            };

            createUser( dataUser )
                .then( res => {
                    if ( ! res.ret ) {
                        switch( res.error ) {
                            case 'already exists':
                                this.setState ({ errorMessage: t( 'form:userExists', language ) });
                                break;
                            default:
                                this.setState ({ errorMessage: t( 'shared:genericError', language ) });       
                        }
                        handleLoader( 'ils_loader', 'remove' );
                        return;
                    } 

                    localStorage.setItem( DATA_VLOG, JSON.stringify( res.data ) );
                    const { popupClose } = this.props;
                    handleLoader( 'ils_loader', 'remove' );
                    gtmEvent({ event: 'Inscription' });
                    activeCampaignEvent({ email: res.data.email, eventName: 'Inscription', eventDetails: 'browse' });
                    popupClose( POPUP_REDUCER.POPUP_REGISTRATION );
                    window.location.reload();
                })
                .catch( error => {
                    console.log( error );
                    this.setState ( { errorMessage: t( 'shared:genericError', language ) } );
                    handleLoader( 'ils_loader', 'remove' );
                });   
        }
    };

    render() {
        const { language, popupOpen } = this.props;

        return (
            <>
                <HeaderLogo />
                <div className="ils_popup_title">
                    { this.getTitle() }
                </div>
                <div className="ils_popup_text">
                    <span>{ t( 'registration:text1', language ) }</span>
                    <span className="ils_popup_link" onClick={ () => popupOpen( POPUP_REDUCER.POPUP_LOGIN ) }>
                        { t( 'registration:text2', language ) }
                    </span>
                </div>
                <div className="ils_popup_form">
                    <div className="ils_popup_input">
                        <input
                            type="email"
                            name="email"
                            value={ this.state.email }
                            onChange={ this.handleChange }
                            placeholder="Email *" />
                    </div>
                    <div className="ils_popup_input">
                        <input
                            type='tel'
                            name='phoneNumber'
                            value={ this.state.phoneNumber }
                            onChange={ this.handleChange }
                            placeholder={ t( 'form:phone', language ) } />
                    </div>
                    <div className="ils_popup_input">
                        <input
                            type="password"
                            name="password"
                            value={ this.state.password }
                            onChange={ this.handleChange }
                            onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                            placeholder={ `${t( 'form:password', language )} *` } />
                    </div>
                    <div className="ils_popup_input">
                        <input
                            type="password"
                            name="passwordCheck"
                            value={ this.state.passwordCheck }
                            onChange={ this.handleChange }
                            onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                            placeholder={ `${t( 'form:passwordConfirm', language )} *` } />
                    </div>
                    <div className="ils_popup_required_fields">{ t( 'form:requiredFields', language ) }</div>
                    <div className="ils_popup_checkbox_container">
                        <div className="ils_popup_checkbox">
                            <input
                                type='checkbox'
                                name='newsletter'
                                onChange={ () => {} }
                                checked={ this.state.newsletter } /> 
                            <span  onClick={ () => this.setState({ newsletter: ! this.state.newsletter }) } />
                        </div>
                        <div className="ils_popup_checkbox_label">
                        { t( 'form:newsletter', language ) }
                        </div>    
                    </div>
                    <div className="ils_popup_checkbox_container">
                        <div className="ils_popup_checkbox">
                            <input
                                type='checkbox'
                                name='ageValid'
                                onChange={ () => {} }
                                checked={ this.state.ageValid } /> 
                            <span  onClick={ () => this.setState({ ageValid: ! this.state.ageValid }) } />
                        </div>
                        <div className="ils_popup_checkbox_label">
                        { t( 'form:ageValid', language ) }
                        </div>    
                    </div>
                    <div className="ils_popup_error">{ this.state.errorMessage }</div>
                    <div className="ils_popup_valid_button" onClick={ this.handleSubmit }>
                        { t( 'registration:confirm', language ) }
                    </div>
                    <div className="ils_popup_link" onClick={ () => window.location.href='/mentions-legales/abonnement/' }>
                        { t( 'registration:confirmInfo', language ) }
                    </div>
                </div>
                <ButtonClose popupName={ POPUP_REDUCER.POPUP_REGISTRATION } />
            </>
        );
    }
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
});

const dispatchToProps = dispatch => ({
    popupClose: popupName => dispatch(popupAction.popupClose( popupName )),
    popupOpen: popupName => dispatch(popupAction.popupOpen( popupName )),
});

export default connect(stateToProps, dispatchToProps)(PopupRegistration);
