import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';

import { ICONS } from "../../../constants";
import { logEvent } from "../../../services/tracking";
import { t } from "../../../utils";

class PAccountSub extends React.Component {
    goToChangePaymentPage = () => {
        const { stripeSubId, userInfoDB } = this.props;
        if ( stripeSubId && stripeSubId !== '' ) {
            logEvent({ NoCli: userInfoDB.NoCli, event: 'change-cb', eventDetails: 'account menu' });
            window.location.href = `/modifier-paiement/?session=${ stripeSubId.substr( 4 ) }-${ userInfoDB.NoCli }`;
        } 
	};
    
    render() {
        const { language, stripeSubId } = this.props;

        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_nav'>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'menu' ) } }>{ t( 'profile:title', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'account' ) } }>{ t( 'profile:account', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span>{ t( 'profile:editSubscription', language ) }</span>
                    </div>
					<div id='profile_header' className='profile_header_title'>
						<h1>{ t( 'profile:editSubscription', language ) }</h1>
					</div>
                    <div id='profile_body'>
						<div className='profile_menu_title'>
							<div>{ t( 'profile:questions', language ) }</div>
						</div>
                        <a className='collect_flag profile_menu_small profile_hover' id='profile_menu_email_contact' href='mailto:contact@zone300.com'>
                            <div><img className='profile_icon' src={ ICONS.mail } alt='icone email'/></div>
                            <div className='profile_scale'>Email</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</a>
						<a className='collect_flag profile_menu_small profile_hover' id='profile_menu_tel' href='tel:+33972164358'>
                            <div><img className='profile_icon white' src={ ICONS.phone } alt='icone tel'/></div>
                            <div className='profile_scale'>09 72 16 43 58</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</a>
						<a className='collect_flag profile_menu_small profile_hover' id='profile_menu_messenger' href='https://www.facebook.com/zone300/'>
                            <div><img className='profile_icon white' src={ ICONS.messenger } alt='icone messenger'/></div>
                            <div className='profile_scale'>@zone300</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</a>
                        {
                            stripeSubId && stripeSubId !== '' &&
                            <div className='collect_flag profile_menu_small profile_hover' id='profile_menu_email' onClick={this.goToChangePaymentPage}>
                                <div className='profile_scale'>{ t( 'profile:editPayment', language ) }</div>
                                <div className='f-grow-1'></div>
                                <div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
                            </div>   
                        } 
                        {
                            stripeSubId && stripeSubId !== '' &&
                            <div className='collect_flag profile_menu_small profile_hover' id='profile_menu_email' onClick={ () => { this.props.handlePageChange( 'right', 'changeSub' ) } }>
                                <div className='profile_scale'>{ t( 'profile:editSubscription', language ) }</div>
                                <div className='f-grow-1'></div>
                                <div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
                            </div>   
                        } 
                        <div className='collect_flag profile_menu_small profile_hover' id='profile_menu_email' onClick={ () => { this.props.handlePageChange( 'right', 'unsubStep1' ) } }>
							<div className='profile_scale'>{ t( 'profile:unsubscribe', language ) }</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
                        </div>    
					</div>
				</div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
	stripeSubId: state.userReducer.stripeSubId,
	userInfoDB: state.userReducer.userInfoDB,
});

export default connect( stateToProps )( PAccountSub );