import React from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";

import InfiniteCarousel from 'react-leaf-carousel';

import InfinteCarrouselReRender from "../../components/tools/InfinteCarrouselReRender";
import RowProductsRender from "../../components/tools/RowProductsRender";

import { API_IMAGE, ICONS, IMAGES } from "../../constants";
import { storeAction } from "../../redux/actions";
import { getStarWidth, formatEuroPrice, formatTimeSince, t } from "../../utils";
 
class ProductDetails extends React.Component {
    static contextTypes = { router : Proptypes.object };

    constructor( props, context ) {
		super( props, context );
		this.state = {
            currentIndex: 0,
            errorMessage: '',
            imageLoadNoCache: true,
            imageLoadError: true,
            productColor: '',
            productQuantity: 1,
            productSize: '',
            showPopup: false,
            successMessage: '',
        };

        this.next = 'next';
		this.previous = 'previous';
	};

    componentDidMount() {
        const { storeSearchedProducts } = this.props;
        if ( storeSearchedProducts.product ) {
            const product = storeSearchedProducts.product[ 0 ];
            if ( product.colors && product.colors.length === 1 ) this.setState({ productColor: product.colors[ 0 ] });
            if ( product.sizes && product.sizes.length === 1 ) this.setState({ productColor: product.sizes[ 0 ] });
        }
    };

    checkErrors = () => {
        const { language, storeSearchedProducts } = this.props;
        let isColorRequired = false;
        let isSizeRequired = false;
        if ( storeSearchedProducts.product ) {
            const product = storeSearchedProducts.product[ 0 ];
            isColorRequired = product.colors && product.colors.length > 1;
            isSizeRequired = product.sizes && product.sizes.length > 1;
        }

        if ( this.state.productQuantity === ''  ) {
            this.setState ({ errorMessage: t( 'store:errorQuantity', language ), successMessage: '' });
            return false;
        }
        if ( isColorRequired && this.state.productColor === ''  ) {
            this.setState ({ errorMessage: t( 'store:errorColor', language ), successMessage: '' });
            return false;
        }
        if ( isSizeRequired && this.state.productSize === ''  ) {
            this.setState ({ errorMessage: t( 'store:errorSize', language ), successMessage: '' });
            return false;
        }
        this.setState ({ errorMessage: '', successMessage: '' });
        return true;
    };

    handleArrowClick = ( type, listLength ) => {
        const { currentIndex } = this.state;
        let newIndex = currentIndex;
		switch( type ) {
			case this.next:
				if ( currentIndex + 2 > listLength ) {
					newIndex = 0;
				} else {
					newIndex ++;
				}
				break;
			case this.previous:
				if ( currentIndex < 1 ) {
					newIndex = listLength - 1;
				} else {
					newIndex --;
				}
				break;
			default:
				break;
		}

		this.setState({ currentIndex: newIndex });
	};

    handleChangeProductColor = ( event ) => {
        const { storeSearchedProducts } = this.props;
        if ( storeSearchedProducts.product ) {
            const product = storeSearchedProducts.product[ 0 ];

            if ( product.colors && product.colors.length > 0 ) {
                const $selectProductColor = document.getElementById( 'select_productColor' );
                let $selectProductColorArr = [];
                product.colors.forEach( el => $selectProductColorArr.push({ id: `select_productColor_${ el }`, htmlElement: document.getElementById( `select_productColor_${ el }` ) }) );

                const valueId = event.target.id;
                if ( valueId === 'select_productColor' ) {
                    $selectProductColorArr.forEach( el => {
                        if ( el.htmlElement ) el.htmlElement.classList.toggle( 'show' ); 
                    });  
                    if ( $selectProductColor ) $selectProductColor.classList.toggle( 'open' ); 
                } else {
                    $selectProductColorArr.forEach( el => {
                        if ( el.htmlElement ) el.htmlElement.classList.remove( 'show' ); 
                    });
                    if ( $selectProductColor ) $selectProductColor.classList.remove( 'open' );
                    const productColor = valueId.split( '_' )[ 2 ] ? valueId.split( '_' )[ 2 ] : '';
                    if ( productColor ) {
                        if ( $selectProductColor ) $selectProductColor.innerHTML = productColor;
                        this.setState({ productColor: productColor }); 
                    }
                }
            }
        }
    };

    handleChangeProductSize = ( event ) => {
        const { storeSearchedProducts } = this.props;
        if ( storeSearchedProducts.product ) {
            const product = storeSearchedProducts.product[ 0 ];

            if ( product.sizes && product.sizes.length > 0 ) {
                const $selectProductSize = document.getElementById( 'select_productSize' );
                let $selectProductSizeArr = [];
                product.sizes.forEach( el => $selectProductSizeArr.push({ id: `select_productSize_${ el }`, htmlElement: document.getElementById( `select_productSize_${ el }` ) }) );

                const valueId = event.target.id;
                if ( valueId === 'select_productSize' ) {
                    $selectProductSizeArr.forEach( el => {
                        if ( el.htmlElement ) el.htmlElement.classList.toggle( 'show' ); 
                    });  
                    if ( $selectProductSize ) $selectProductSize.classList.toggle( 'open' ); 
                } else {
                    $selectProductSizeArr.forEach( el => {
                        if ( el.htmlElement ) el.htmlElement.classList.remove( 'show' ); 
                    });
                    if ( $selectProductSize ) $selectProductSize.classList.remove( 'open' );
                    const productSize = valueId.split( '_' )[ 2 ] ? valueId.split( '_' )[ 2 ] : '';
                    if ( productSize ) {
                        if ( $selectProductSize ) $selectProductSize.innerHTML = productSize;
                        this.setState({ productSize: productSize }); 
                    }
                }
            }
        }
    };

    handleSubmit = () => {
        const { addProductInCart, language, storeSearchedProducts } = this.props;
        if ( storeSearchedProducts.product ) {
            if ( this.checkErrors() ) {
                const product = storeSearchedProducts.product[ 0 ];
                const $selectProductColor = document.getElementById( 'select_productColor' );
                const $selectProductSize = document.getElementById( 'select_productSize' );

                if ( $selectProductColor ) $selectProductColor.innerHTML = product.colors && product.colors.length === 1 ? product.colors[ 0 ] : t( 'form:defaultSelect', language );
                if ( $selectProductSize ) $selectProductSize.innerHTML = product.sizes && product.sizes.length === 1 ? product.sizes[ 0 ] : t( 'form:defaultSelect', language );

                addProductInCart( {
                    price: product.price,
                    productColor: this.state.productColor,
                    productId: product.productId,
                    productQuantity: this.state.productQuantity,
                    productSize: this.state.productSize,
                });

                this.setState( {
                    errorMessage: '',
                    productColor: '',
                    productQuantity: 1,
                    productSize: '',
                    successMessage: t( 'store:addSuccess', language ),
                });
            }
        }
    };

    isWithDefaultDiscount = () => {
        const { userDiscarded, userInfoDB } = this.props;
        return userInfoDB && userInfoDB.default_discount && ! userDiscarded;
    };

    navClose = () => { this.setState( { showPopup: false } ); };
	navCloseIf = ( event, condition ) => {
		if ( event.target.classList.value !== condition ) return false;
		this.navClose();
	};

    renderPic = imageName => {
        const { storeSearchedProducts } = this.props;
        
		if ( imageName && storeSearchedProducts.product && storeSearchedProducts.product[ 0 ] ) {
            const product = storeSearchedProducts.product[ 0 ];
			const srcImage = `${ API_IMAGE }${ imageName }.png&w=500&h=500&crop-to-fit`;
			const srcErrorImage = IMAGES.placeholderSquare;
			
			return (
                <img
                    className='tile-img'
                    alt={ product.name }
                    src={ srcImage } 
                    onError={e => {
                        if ( this.state.imageLoadNoCache ) {
                            this.setState({ imageLoadNoCache: false });
                            e.target.src = `${ srcImage }&no-cache`;
                        } else if ( this.state.imageLoadError ) {
                            this.setState({ imageLoadError: false });
                            e.target.src = srcErrorImage;
                        }
                    }} />
			);
		}

		return (
			<img
				alt="img"
				className="tile-img"
				src={ IMAGES.placeholderSquare } />
		);
	};

	render() {
		const { language, storeSearchedProducts, userInfoDB } = this.props;
        const { currentIndex, showPopup } = this.state;
        
        const slideNumbers = {
            max: 5,
            breakpoints: [
                { breakpoint: 768, settings: { arrows: false, slidesToShow: 1, slidesToScroll: 1 } },
                { breakpoint: 1025, settings: { arrows: false, slidesToShow: 2, slidesToScroll: 2 } },
                { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                { breakpoint: 3000, settings: { slidesToShow: 4, slidesToScroll: 4 } },
            ]
        };

        let product = null;
        let productsRelated = null;
        let imagesList = [];
        if ( storeSearchedProducts.product ) {
            product = storeSearchedProducts.product[ 0 ];
            if ( product.mainFileName ) imagesList.push( product.mainFileName );
            if ( product.gallery1FileName ) imagesList.push( product.gallery1FileName );
            if ( product.gallery2FileName ) imagesList.push( product.gallery2FileName );
            if ( product.gallery3FileName ) imagesList.push( product.gallery3FileName );
            if ( product.gallery4FileName ) imagesList.push( product.gallery4FileName );
            if ( product.gallery5FileName ) imagesList.push( product.gallery5FileName );
            if ( product.gallery6FileName ) imagesList.push( product.gallery6FileName );
            if ( storeSearchedProducts.productsRelated ) {
                productsRelated = storeSearchedProducts.productsRelated;
                productsRelated.forEach( ( item, key ) => {
                    productsRelated[ key ].products = item.products.filter( el => el.productId !== product.productId );
                });
            }
        }
        
		return (
			<div id='home_Homelist' className='storeDetails'>
                {
                    product &&
                    <>
                    <div className='list-item'>
                        <div>
                            <div className='list-title list-title-relative'>
                                <div className="product-go-back" onClick={ () => this.context.router.history.goBack() }>
                                    <span className='icon_box'>
                                        <img className="nav_icon" src={ ICONS.backArrow } alt='icone retour' />
                                    </span>
                                    { t( 'shared:back', language ) }
                                </div>
                                <h1 className='list-title-product'>{ product.name }</h1>
                                {
                                    product.rating ?
                                    <div className="soon-rate-star-box">
                                        <div className="soon-rate-average">
                                            { product.rating.average.toString().replace( '.', ',' ) }
                                        </div>
                                        <div className="soon-rate-star">
                                            <i className="far fa-star"></i>
                                            <span
                                                style={{ width: `${ getStarWidth( 1, product.rating.average ) }px` }}>
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </div>
                                        <div className="soon-rate-star">
                                            <i className="far fa-star"></i>
                                            <span
                                                style={{ width: `${ getStarWidth( 2, product.rating.average ) }px` }}>
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </div>
                                        <div className="soon-rate-star">
                                            <i className="far fa-star"></i>
                                            <span
                                                style={{ width: `${ getStarWidth( 3, product.rating.average ) }px` }}>
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </div>
                                        <div className="soon-rate-star">
                                            <i className="far fa-star"></i>
                                            <span
                                                style={{ width: `${ getStarWidth( 4, product.rating.average ) }px` }}>
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </div>
                                        <div className="soon-rate-star">
                                            <i className="far fa-star"></i>
                                            <span
                                                style={{ width: `${ getStarWidth( 5, product.rating.average ) }px` }}>
                                                <i className="fas fa-star"></i>
                                            </span>
                                        </div>
                                        <div className="soon-rate-count">
                                            { product.rating.count } { t( 'shared:opinion', language ) }
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                                <div className='list-title-product-tags-box'>
                                    {
                                        product.tags && product.tags.length > 0 &&
                                        product.tags.map( ( item, index ) => 
                                            <div
                                                key={ index }
                                                className={ `list-title-product-tags ${ item.url ? 'productTagsLinked' : '' }` }
                                                onClick={ () => { if ( item.url ) window.location.href=`/browse/?boutique=${ item.url }/#/boutique/${ item.url }` } } >
                                                { item.label }
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    product.isOutOfStock === '1' &&
                                    <div className='list-title-product-outOfStock'>{ t( 'store:outOfStock', language ) }</div>
                                }
                                <div className='list-title-product-price'>
                                    { this.isWithDefaultDiscount() && <span className='list-title-product-price-initial'>{ formatEuroPrice( product.price ) }</span> }
                                    {
                                        this.isWithDefaultDiscount() ?
                                        formatEuroPrice( Math.floor( product.price - ( ( product.price * userInfoDB.default_discount ) / 100 ) ) )
                                        :
                                        formatEuroPrice( product.price )
                                    }
                                    {
                                        this.isWithDefaultDiscount() &&
                                        <span className='list-title-product-price-discount'>
                                            - { userInfoDB.default_discount } % { t( 'store:forSub', language ) }
                                        </span>
                                    }
                                </div>
                                {
                                    product.isOutOfStock === '0' &&
                                    <div className='list-title-product-cart'>
                                        {
                                            product.colors && product.colors.length === 1 &&
                                            <div className='list-title-product-custom-select'>
                                                <label>{ t( 'store:color', language ) }</label>
                                                <div className='list-title-product-select-selected disabled'>
                                                    { product.colors[ 0 ] }
                                                </div>
                                            </div>
                                        }
                                        {
                                            product.colors && product.colors.length > 1 &&
                                            <div className='list-title-product-custom-select'>
                                                <label>{ t( 'store:color', language ) }</label>
                                                <div
                                                    className='list-title-product-select-selected'
                                                    id='select_productColor' onClick={ event => this.handleChangeProductColor( event ) }>
                                                        { t( 'form:defaultSelect', language ) }
                                                    </div>
                                                <div className='list-title-product-select-option-box'>     
                                                    {
                                                        product.colors.map( ( item, index ) =>
                                                            <div
                                                                key={ index }
                                                                className={ `list-title-product-select-option ${ index === product.colors.length - 1 ? 'last' : '' }` }
                                                                id={ `select_productColor_${ item }` }
                                                                onClick={ event => this.handleChangeProductColor( event ) }>
                                                                    { item }
                                                                </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            product.sizes && product.sizes.length === 1 &&
                                            <div className='list-title-product-custom-select'>
                                                <label>{ t( 'store:size', language ) }</label>
                                                <div className='list-title-product-select-selected disabled'>
                                                    { product.sizes[ 0 ] }
                                                </div>
                                            </div>
                                        }
                                        {
                                            product.sizes && product.sizes.length > 1 &&
                                            <div className='list-title-product-custom-select'>
                                                <label>{ t( 'store:size', language ) }</label>
                                                <div
                                                    className='list-title-product-select-selected'
                                                    id='select_productSize'
                                                    onClick={ event => this.handleChangeProductSize( event ) }>
                                                        { t( 'form:defaultSelect', language ) }
                                                    </div>
                                                <div className='list-title-product-select-option-box'>    
                                                    {
                                                        product.sizes.map( ( item, index ) =>
                                                            <div
                                                                key={ index }
                                                                className={ `list-title-product-select-option ${ index === product.sizes.length - 1 ? 'last' : '' }` }
                                                                id={ `select_productSize_${ item }` }
                                                                onClick={ event => this.handleChangeProductSize( event ) }>
                                                                    { item }
                                                                </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className='list-title-product-quantity-box'>
                                            <label>{ t( 'store:quantity', language ) }</label>
                                            <div className='list-title-product-quantity-input'>
                                                <div
                                                    className='list-title-product-quantity-input-minus'
                                                    onClick={ () => { if ( this.state.productQuantity > 1 ) this.setState( { productQuantity: this.state.productQuantity - 1 } ) } }>
                                                    -
                                                </div>
                                                <input
                                                    type='number'
                                                    min='1'
                                                    name='productQuantity'
                                                    value={ this.state.productQuantity }
                                                    onChange={ () => {} }
                                                    />
                                                <div
                                                    className='list-title-product-quantity-input-plus'
                                                    onClick={ () => this.setState( { productQuantity: this.state.productQuantity + 1 } ) }>
                                                    +
                                                </div>
                                            </div>
                                        </div>
                                        <div className='list-title-product-btn'>
                                            <button onClick={ this.handleSubmit }>{ t( 'store:add', language ) }</button>
                                        </div>
                                    </div>
                                }
                                <div className='list-title-product-cart-message'>
                                    <div className='list-title-product-cart-message-error'>{ this.state.errorMessage }</div>
                                    <div className='list-title-product-cart-message-success'>{ this.state.successMessage }</div>
                                </div>
                            </div>
                            <div className="grid for-image">
                                <InfinteCarrouselReRender>
                                    <InfiniteCarousel
                                        breakpoints={ slideNumbers.breakpoints } 
                                        dots={false}
                                        slidesSpacing={5}
                                        showSides={true}
                                        sidesOpacity={.5}
                                        sideSize={.1}
                                        slidesToScroll={ slideNumbers.max }
                                        slidesToShow={ slideNumbers.max  }
                                        animationDuration={600}
                                        lazyLoad={true}
                                        placeholderImageSrc={ IMAGES.placeholderThumbnail }>
                                        {
                                            imagesList && imagesList.length > 0 &&
                                            imagesList.map( (item, index) =>
                                                <div key={ index } onClick={ () => this.setState( { currentIndex: index, showPopup: true } ) }>
                                                    <div className='shine-container'>
                                                        <figure className='effect-thumbnail-movie collect_flag'>
                                                            { this.renderPic( item ) }
                                                        </figure>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </InfiniteCarousel>
                                </InfinteCarrouselReRender>
                            </div>
                        </div>
                    </div>
                    {
                        product.tags && product.tags.filter( el => el.title && el.description ).length > 0 &&
                        product.tags.filter( el => el.title && el.description ).map( ( item, index ) => 
                            <div key={ index }>
                                { item.title && <div className='description-title-product'>{ `${ item.title } :` }</div> }
                                { item.description && <div className='description-product'>{ item.description }</div> }                                
                            </div>
                        )
                    }
                    <div className='description-title-product'>{ t( 'shared:description', language ) } :</div>
                    <div
                        dangerouslySetInnerHTML={{ __html: product.description.replace( '\n\n', '<br /><br />' ).replace( '\n', '<br />' ) }}
                        className='description-product' />
                    </>
                }
                { productsRelated && <RowProductsRender productListInfo = { productsRelated } /> }
                <div className="grid">
                    {
                        showPopup && product && imagesList && imagesList.length > 0 &&
                        <div className="movie_popup">
                            <div className='movie_popup_mask' onClick={ e => this.navCloseIf( e, 'movie_popup_mask' ) }>
                                <div className="movie_popup_info">
                                    <div className="movie_popup_content">
                                        <div className='movie_popup_img_box'>
                                            <img
                                                className='product_popup_img'
                                                src={ `${ API_IMAGE }${ imagesList[ currentIndex ] }.png&h=720&crop-to-fit` }
                                                alt={ product.name } />
                                        </div>
                                    </div>
                                    <div className='movie_popup_close'>
                                        <button className="close-button fa fa-window-close" onClick={ this.navClose }></button>
                                    </div>
                                    <div className='movie_popup_left' onClick={ e => this.navCloseIf( e, 'movie_popup_left' ) }>
                                        <div className="movie_popup_previous" onClick={ () => this.handleArrowClick( this.previous, imagesList.length ) }>
                                            <div>
                                                <img className="movie_icon" src={ ICONS.backArrow } alt='icone precedent' />
                                            </div>
                                        </div>
                                    </div>	
                                    <div className='movie_popup_right' onClick={ e => this.navCloseIf( e, 'movie_popup_right' ) }>
                                        <div className="movie_popup_next" onClick={ () => this.handleArrowClick( this.next, imagesList.length ) }>
                                            <div>
                                                <img className="movie_icon" src={ ICONS.backArrow } alt='icone suivant' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {
                    product.comments && product.comments.length > 0 &&
                    <div id='profile_container' className='movie_comment_page'>
                        <div className='profile_edit_usual'>
                            <div className='profile_edit_usual_form'>
                                <div className='comment-box-title'>{ t( 'form:comments', language ) }</div>
                                <div>
                                    {
                                        product.comments.map( ( el, key ) => (
                                            <div className='comment-box' key={ key }>
                                                <div className='comment-user-box'>
                                                    <div className='comment-avatar-box'>
                                                        <img
                                                            className='comment-avatar'
                                                            src={ el.avatar && el.avatar !== '' ? `${ API_IMAGE }${ el.avatar }.jpg` : IMAGES.avatarDefault }
                                                            alt='avatar'/>
                                                    </div>
                                                    <div className='comment-pseudo'>{ el.pseudo }</div>
                                                    <div className='comment-date'>{ formatTimeSince( el.date, language ) }</div>
                                                </div>
                                                <div>{ el.comment }</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
			</div>
		);
	}
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
    storeSearchedProducts: state.storeReducer.storeSearchedProducts,
    userDiscarded: state.userReducer.userDiscarded,
	userInfoDB: state.userReducer.userInfoDB,
});

const dispatchToProps = dispatch => ({
    addProductInCart: product => dispatch(storeAction.addProductInCart( product )),
});

export default connect( stateToProps, dispatchToProps )( ProductDetails );