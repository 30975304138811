import React from "react";
import { connect } from "react-redux";

import { API_IMAGE, DATA_NOTIF, ICONS, IMAGES, LANGUAGE, POPUP_REDUCER } from "../../../constants";
import { translatingAction, popupAction, userAction } from '../../../redux/actions';
import { getUserInfo, isGuest, t } from "../../../utils";
import { isCanadian } from '../../../utils/getLocalTimeZone';

class NavWeb extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            hasNotifToShow: false,
            imageLoadNoCache: true,
            imageLoadError: true,
            showNotif: false,
            showProfileOptions: false,
        };
        
        this.lastNotifId = localStorage.getItem( DATA_NOTIF );
    };

    getAvatarPath = () => {
        const { fetchProfile, profileVersion, profileVersionNoCli, userInfoDB } = this.props;

        if ( userInfoDB && userInfoDB.NoCli ) {
            if ( profileVersionNoCli !== userInfoDB.NoCli ) {
                fetchProfile({ noCli: userInfoDB.NoCli, type: 'select', version: 0 });
                if ( userInfoDB.avatar && userInfoDB.avatar !== '' ) return `${ API_IMAGE }${ userInfoDB.avatar }.jpg`;
            } else {
                if ( profileVersion === 0 && userInfoDB.avatar && userInfoDB.avatar !== '' ) return `${ API_IMAGE }${ userInfoDB.avatar }.jpg`;
                if ( userInfoDB.profiles && userInfoDB.profiles.length > 0 ) {
                    for ( let i = 0; i < userInfoDB.profiles.length; i++ ) {
                        if ( profileVersion === userInfoDB.profiles[ i ].version ) return `${ API_IMAGE }${ userInfoDB.profiles[ i ].avatar }.jpg`;
                    }

                    fetchProfile({ noCli: userInfoDB.NoCli, type: 'select', version: 0 });
                    if ( userInfoDB.avatar && userInfoDB.avatar !== '' ) return `${ API_IMAGE }${ userInfoDB.avatar }.jpg`;
                }
            }
        }

        return IMAGES.avatarDefault;
    };

    componentDidUpdate( prevProps ) {
        const { language, notifications } = this.props;

		if ( notifications !== prevProps.notifications && notifications.length > 0 ) {
            if ( ! this.lastNotifId || this.lastNotifId < notifications[ 0 ].id ) this.setState({ hasNotifToShow: true });
		}

        const $selectLanguage = document.getElementById( 'select_language' );
        if ( language && $selectLanguage ) {
            switch ( language ) {
                case LANGUAGE.FRENCH:
                    $selectLanguage.innerHTML = 'Français';
                    break;
                case LANGUAGE.ENGLISH:
                    $selectLanguage.innerHTML = 'English';
                    break;  
                default:
                    break;
            } 
        }
	};

    handleChangeLanguage = ( event ) => {
        const { fetchLanguage } = this.props;

        const $selectLanguage = document.getElementById( 'select_language' );
        const $selectLanguageArr = [
            { id: 'select_language_french', htmlElement: document.getElementById( 'select_language_french' ) },
            { id: 'select_language_english', htmlElement: document.getElementById( 'select_language_english' ) },
        ];

        const valueId = event.target.id;
        switch ( valueId ) {
            case 'select_language':
                $selectLanguageArr.forEach( el => {
                    if ( el.htmlElement ) el.htmlElement.classList.toggle( 'show' ); 
                });  
                if ( $selectLanguage ) $selectLanguage.classList.toggle( 'open' );  
                break;
            case 'select_language_french':
            case 'select_language_english':
                $selectLanguageArr.forEach( el => {
                    if ( el.htmlElement ) el.htmlElement.classList.remove( 'show' ); 
                });
                if ( $selectLanguage ) $selectLanguage.classList.remove( 'open' );
                const languageSelected = valueId.split( '_' )[ 2 ] ? valueId.split( '_' )[ 2 ] : '';
                if ( languageSelected ) {
                    switch ( languageSelected ) {
                        case 'french':
                            $selectLanguage.innerHTML = 'Français';
                            fetchLanguage( LANGUAGE.FRENCH );
                            break;
                        case 'english':
                            $selectLanguage.innerHTML = 'English';
                            fetchLanguage( LANGUAGE.ENGLISH );
                            break; 
                        default:
                            break;
                    } 
                }  
                break;
            default:
                break;
        }
    };

    closeNotif = () => this.setState({ hasNotifToShow: false, showNotif: false });
    openNotif = () => {
        const { notifications } = this.props;
        localStorage.setItem( DATA_NOTIF, notifications[ 0 ].id );
        this.setState({ hasNotifToShow: false, showNotif: true });
    };

    render() {
        const { language, notifications, popupOpen, selected, storeCartLength, storeCartProductsTotal, userInfoDB } = this.props;
        const { hasNotifToShow, imageLoadError, imageLoadNoCache, showNotif, showProfileOptions } = this.state;

		return (
		    <>
            <div id='nav-container-web'>
                <a
                    className={ `collect_flag nav_item ${ selected === 'home' ? 'selected' : '' }` }
                    id='nav_home-web'
                    href='/browse/#/'>
                    <span className='icon_box'>
                        <img className="nav_icon" src={ ICONS.home } alt='icone accueil' />
                    </span>
                    <span className='nav_item_text'>{ t( 'nav:home', language ) }</span>
                </a>
                <a
                    className={ `collect_flag nav_item ${ selected === 'playlist' ? 'selected' : '' }` }
                    id='nav_playlist-web'
                    href='/browse/?continu/#/continu/'>
                    <span className='icon_box'>
                        <span className='dot live'></span>
                    </span>
					<span className='nav_item_text'>{ t( 'nav:playlist', language ) }</span>
                </a>
                <a
                    className={ `collect_flag nav_item ${ selected === 'chasse' ? 'selected' : '' }` }
                    id='nav_chasse-web'
                    href='/browse/?chasse/#/chasse/'>
                    <span className='icon_box'>
                        <img className="nav_icon" src={ ICONS.chasse } alt='icone chasse' />
                    </span>
                    <span className='nav_item_text'>{ t( 'shared:hunting', language ) }</span>
                </a>
                <a
                    className={ `collect_flag nav_item ${ selected === 'peche' ? 'selected' : '' }` }
                    id='nav_peche-web'
                    href='/browse/?peche/#/peche/'>
                    <span className='icon_box'>
                        <img className="nav_icon" src={ ICONS.peche } alt='icone peche' />
                    </span>
                    <span className='nav_item_text'>{ t( 'shared:fishing', language ) }</span>
                </a>
                <a
                    className={ `collect_flag nav_item ${ selected === 'search' ? 'selected' : '' }` }
                    id='nav_search-web'
                    href='/browse/?search=new_search/#/search/new_search'>
                    <span className='icon_box'>
                        <img className="nav_icon" src={ ICONS.search } alt='icone recherche' />
                    </span>
                    <span className='nav_item_text'>{ t( 'nav:search', language ) }</span>
                </a>
                <a
                    className={ `collect_flag nav_item ${ selected === 'favorites' ? 'selected' : '' }  ${ isGuest() ? 'disabled' : '' }` }
                    id='nav_favorites-web'
                    href={  `${ isGuest() ? '/offres/' : '/browse/?ma-liste/#/ma-liste/' }` }>
                    <span className='icon_box'>
                        <img className="nav_icon" src={ ICONS.favorites } alt='icone favoris' />
                    </span>
                    <span className='nav_item_text'>{ t( 'nav:favorites', language ) }</span>
                </a>
                <a
                    className={ `collect_flag nav_item ${ selected === 'training' ? 'selected' : '' }` }
                    id='nav_training-web'
                    href='/browse/?formations/#/formations/'>
                    <span className='icon_box'>
                        <img className="nav_icon" src={ ICONS.graduation } alt='icone formations' />
                    </span>
                    <span className='nav_item_text'>{ t( 'nav:training', language ) }</span>
                </a>
                {
                    ! isCanadian() &&
                    <a
                        className={ `collect_flag nav_item ${ selected === 'store' ? 'selected' : '' }` }
                        id='nav_boutique-web'
                        href='/browse/?boutique/#/boutique/'>
                        <span className='icon_box'>
                            <img className="nav_icon" src={ ICONS.boutique } alt='icone boutique' />
                        </span>
                        <span className='nav_item_text'>{ t( 'nav:store', language ) }</span>
                    </a>
                }
            </div>
            <div id='nav-container-web-up'>
                <a id='nav_logo-web' href='/browse/#/'>
                    <span className='nav_item_text'>
                        <img className="nav_logo" src={ IMAGES.logoImg } alt='logo zone300' />
                    </span>
                </a>
                <div>
                    {
                        storeCartLength > 0 &&
                        <div
                            className={ `collect_flag nav_item ${ selected === 'cart' ? 'selected' : '' }` }
                            id='nav_cart-web'
                            onClick={ () => {
                                if ( ! getUserInfo().NoCli ) {
                                    popupOpen( POPUP_REDUCER.POPUP_LOGIN );
                                } else {
                                    window.location.href = '/browse/?mon-panier/#/mon-panier/';
                                }
                            }}>
                            <i className='fa fa-shopping-cart' aria-hidden='true'></i>
                            <span className='dot-cart'>{ storeCartProductsTotal }</span>
                        </div>
                    }
                    <div
                        className='collect_flag nav_item active'
                        id='nav_notif-web'>
                        {
                            hasNotifToShow ?
                            <span className='icon_box' onClick={ this.openNotif }>
                                <img className="nav_icon" src={ ICONS.notificationsFill } alt='icone notifications' />
                                <span className='dot notif'></span>
                            </span>
                            :
                            <span className='icon_box' onClick={ this.openNotif }>
                                <img className="nav_icon" src={ ICONS.notificationsOutline } alt='icone pas de notifications' />
                            </span>
                        }    
                        {
                            showNotif &&
                            <div className='notif-context-menu'>
                                <div className='notif-context-menu-title'>{ t( 'nav:notifications', language ) }</div>
                                <div className='notif-context-menu-close'>
                                    <button className='fa fa-window-close' onClick={ this.closeNotif }></button>
                                </div>
                                {
                                    notifications.map( item => 
                                        <div
                                            key={ item.id }
                                            className={ `notif-context-menu-item ${ item.link ? 'linked' : '' }` }
                                            onClick={ () => { if ( item.link ) window.location.href=item.link; } } >
                                            <img
                                                className="tile-img-small"
                                                alt={ item.alt }
                                                src={`${API_IMAGE}${ item.imageFileName + '.jpg' }&w=500&h=281&crop-to-fit`} 
                                                onError={e => {
                                                    if ( imageLoadNoCache ) {
                                                        this.setState({ imageLoadNoCache: false });
                                                        e.target.src =`${API_IMAGE}${ item.imageFileName + '.jpg' }&w=500&h=281&crop-to-fit&no-cache`;
                                                    } else if ( imageLoadError ) {
                                                        this.setState({ imageLoadError: false });
                                                        e.target.src = IMAGES.placeholderThumbnail;
                                                    }
                                                }} />
                                            <div className='notif-context-menu-info'>
                                                <div>
                                                    {
                                                        item[ t( 'keys:text_1', language ) ] ?
                                                        item[ t( 'keys:text_1', language ) ]
                                                        :
                                                        item.text_1
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        item[ t( 'keys:text_2', language ) ] ?
                                                        item[ t( 'keys:text_2', language ) ]
                                                        :
                                                        item.text_2
                                                    }
                                                </div>
                                            </div>
                                        </div> 
                                    )
                                }
                            </div>
                        }
                    </div>
                    {/*
                    <div className='language_custom_select'>
                        <div className='language_select_selected' id='select_language' onClick={ event => this.handleChangeLanguage( event ) }>{ t( 'nav:language', language ) }</div>
                        <div className='language_select_option' id='select_language_french' onClick={ event => this.handleChangeLanguage( event ) }>Français</div>
                        <div className='language_select_option' id='select_language_english' onClick={ event => this.handleChangeLanguage( event ) }>English</div>
                    </div>
                    */}
                    <div
                        className={ `collect_flag nav_item active ${ selected === 'profile' ? 'selected' : '' }` }
                        id='nav_profile-web'
                        onClick={ () => {
                            if ( ! getUserInfo().NoCli ) {
                                popupOpen( POPUP_REDUCER.POPUP_LOGIN );
                            } else if ( userInfoDB && userInfoDB.profileMax && userInfoDB.profileMax > 1 ) {
                                this.setState({ showProfileOptions: ! showProfileOptions });
                            } else {
                                window.location.href = '/browse/?mon-compte/#/mon-compte/';
                            }
                        }}>
                        <span className='icon_box'>
                            <img className='user-avatar' src={ this.getAvatarPath() } alt='avatar' />
                        </span>
                        {
                            showProfileOptions &&
                            <div className='profile-context-menu'>
                                <div
                                    className='profile-context-menu-item'
                                    onClick={ e => { e.stopPropagation(); window.location.href = '/browse/?mes-profils/#/mes-profils/'; }}>
                                    { t( 'profileManager:title', language ) }
                                </div>
                                <div
                                    className='profile-context-menu-item'
                                    onClick={ e => { e.stopPropagation(); window.location.href = '/browse/?mon-compte/#/mon-compte/'; }}>
                                    { t( 'profile:title', language ) }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            </>
		);
	}
}

const stateToProps = state => ({
    notifications: state.userReducer.notifications,
	selected: state.routingReducer.selected,
    language: state.translatingReducer.language,
    profileVersion: state.userReducer.profileVersion,
    profileVersionNoCli: state.userReducer.profileVersionNoCli,
    storeCartLength: state.storeReducer.storeCartLength,
    storeCartProductsTotal: state.storeReducer.storeCartProductsTotal,
    userInfoDB: state.userReducer.userInfoDB,
});

const dispatchToProps = dispatch => ({
    fetchLanguage: language => dispatch(translatingAction.fetchLanguage( language )),
    fetchProfile: data => dispatch(userAction.fetchProfile( data )),
	popupOpen: popupName => dispatch(popupAction.popupOpen( popupName )),
});

export default connect( stateToProps, dispatchToProps )( NavWeb );