import React from "react";
import { connect } from "react-redux";

import MozaicRender from "../../components/tools/MozaicRender";

import { moviesAction } from "../../redux/actions";
import { getUserInfo, t } from "../../utils";

class HomeList extends React.Component {
	componentDidMount() {
		const { fetchProgressMovies, profileVersion } = this.props;
		const userId = getUserInfo().NoCli;
		fetchProgressMovies( 0, userId, profileVersion );
	};

	render() {
		const { language, progressMovies } = this.props;

		return (
			<div id='favorite_Homelist'>
				{
					progressMovies && progressMovies.length > 0 && progressMovies[ 0 ].movies && progressMovies[ 0 ].movies.length > 0 ?
					<MozaicRender movieListInfo = { progressMovies } />
					:
					<div className ='default-favorite'>
						<h3>{ t( 'progress:default', language ) }</h3>
					</div>
				}
			</div>
		);
	}
}

const stateToProps = state => ({
	profileVersion: state.userReducer.profileVersion,
	progressMovies: state.moviesReducer.progressMovies,
	language: state.translatingReducer.language,
});

const dispatchToProps = dispatch => ({
	fetchProgressMovies: ( themeId, userId, version ) => dispatch( moviesAction.fetchProgressMovies( themeId, userId, version )),
});

export default connect( stateToProps, dispatchToProps )( HomeList );
