import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';
import Loader from 'react-loader-spinner';

import { activeCampaignEvent, logEvent, gtmEvent } from "../../../services/tracking";
import { checkPassword, suspendUserSub, updateUnsubStep } from "../../../services/user";
import { formatDateForUser, handleLoader, t } from "../../../utils";

class PUnsubStep3 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            errorMessage: ''
        }
    };

    checkErrors = () => {
        const { language } = this.props;

        if ( this.state.password === '' ) {
            this.setState ({ errorMessage: t( 'form:passwordEmpty', language ) });
            return false;
        }
        this.setState ({ errorMessage: '' });
        return true;
    };
    
    handleChange = event => this.setState({password: event.target.value});

    handleUnSub = event => {
        const { language, userInfoDB } = this.props;
        const valueId = event.currentTarget.id;
        let data = { NoCli: userInfoDB.NoCli, data: '' };

        switch ( valueId ) {
            case 'sub_step3':
                data.data = 'new-step3 - sub';
                updateUnsubStep( data )
                    .then( res => { console.log( res ); } )
                    .catch( error => { console.log( error ); } );
                window.location.href="/browse/#/";
                break;
            case 'unsub_step3':
                if ( this.checkErrors() ) {
                    handleLoader( 'profile_loader', 'add' );
                    const dataUser = { NoCli: userInfoDB.NoCli, password: this.state.password };
                    checkPassword( dataUser )
                        .then( res => {
                            if ( ! res.ret ) {
                                switch( res.error ) {
                                    case 'authentication':
                                        logEvent({ NoCli: userInfoDB.NoCli, event: 'error-unsub-step3', eventDetails: 'authentication-check' }); 
                                        this.setState ({ errorMessage: t( 'form:passwordError', language ) });
                                        break;
                                    default:
                                        logEvent({ NoCli: userInfoDB.NoCli, event: 'error-unsub-step3', eventDetails: 'default-check' }); 
                                        this.setState ({ errorMessage: t( 'shared:genericError', language ) });       
                                }
                                handleLoader( 'profile_loader', 'remove' );
                                return;
                            }

                            suspendUserSub( dataUser )
                                .then( res_ => {
                                    if ( ! res_.ret ) {
                                        switch( res_.data ) {
                                            case 'authentication':
                                                logEvent({ NoCli: userInfoDB.NoCli, event: 'error-unsub-step3', eventDetails: 'authentication-suspend' })
                                                this.setState ({ errorMessage: t( 'form:passwordError', language ) });
                                                break;
                                            default:
                                                logEvent({ NoCli: userInfoDB.NoCli, event: 'error-unsub-step3', eventDetails: 'default-suspend' });
                                                this.setState ({ errorMessage: t( 'shared:genericError', language ) });       
                                        }
                                        handleLoader( 'profile_loader', 'remove' );
                                        return;
                                    }

                                    let acData = {
                                        email: userInfoDB.email,
                                        eventDetails: 'funnel browse',
                                        eventName: 'desabonnement en attente'
                                    };

                                    if ( res_.cancel_at ) {
                                        const cancelAt = new Date( res_.cancel_at * 1000 );
                                        const year = cancelAt.getFullYear();
                                        const month = ( cancelAt.getMonth() + 1 ).toString().padStart(2, '0');
                                        const day = cancelAt.getDate().toString().padStart(2, '0');
                                        acData[ 'date désabonnement' ] = `${year}-${month}-${day}`;
                                    }

                                    data.data = 'new-step3 - unsub';
                                    updateUnsubStep( data )
                                        .then( res => { console.log( res ); } )
                                        .catch( error => { console.log( error ); } );
                                    
                                    gtmEvent({ event: 'desabonnement en attente' });
                                    activeCampaignEvent( acData );
                                    window.location.href = "/confirmation-desinscription/";
                                } )
                                .catch( error => {
                                    logEvent({ NoCli: userInfoDB.NoCli, event: 'error-unsub-step3', eventDetails: 'catch-suspend-v220607' });
                                    // this.setState ( { errorMessage: t( 'shared:genericError', language ) } );
                                    // handleLoader( 'profile_loader', 'remove' );
                                    
                                    let acData = {
                                        email: userInfoDB.email,
                                        eventDetails: 'funnel browse',
                                        eventName: 'desabonnement en attente'
                                    };

                                    let errorToLog = 'no error sent';
                                    if ( error ) {
                                        const errorStg = JSON.stringify( error );
                                        if ( errorStg ) errorToLog.substring( 0, 200 );
                                    }
                                    
                                    data.data = 'new-step3 - unsub';
                                    updateUnsubStep( data )
                                        .then( res => { console.log( res ); } )
                                        .catch( error_ => { console.log( error_ ); } );
                                    
                                    logEvent({ NoCli: userInfoDB.NoCli, event: 'error-unsub-step3', eventDetails: errorToLog });
                                    gtmEvent({ event: 'desabonnement en attente' });
                                    activeCampaignEvent( acData );
                                    window.location.href = "/confirmation-desinscription/";
                                });
                        })
                        .catch( error => {
                            console.log( error );
                            logEvent({ NoCli: userInfoDB.NoCli, event: 'error-unsub-step3', eventDetails: 'catch-check' });
                            this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                            handleLoader( 'profile_loader', 'remove' );
                        });
                }
                break;
            default:
                break;

        }
    };

    render() {
        const { handlePageChange, language, userInfoDB } = this.props;
        const { password, errorMessage } = this.state;

        const $content = 
            <>
                <div id="profile_container">
                    <div id='profile_loader'><Loader type='TailSpin' color='#fba00b' height={100} width={100} /></div>
                    <div className="profile-unsub-form">
                        <div id='profile_nav2'>
                            <div className='profile_nav' onClick={ () => handlePageChange( 'left', 'unsubStep2' ) }>{ t( 'shared:back', language ) }</div>
                            <div>{ t( 'unsub:step', language ) } 3/3</div>
                        </div>
                        <div>
                            <h1 className='profile_unsub_title'>{ t( 'unsub:step3Title', language ) }</h1>
                        </div>
                        <ul className='profile_unsub_list'>
                            <li>
                                { t( 'unsub:step3Text1', language ) }{
                                    userInfoDB.date_next_payment &&
                                    <>, { t( 'subscription:the', language ) } { formatDateForUser( userInfoDB.date_next_payment, language ) }</>
                                }
                                .
                            </li>
                            <li>{ t( 'unsub:step3Text2', language ) }</li>
                            <li>{ t( 'unsub:step3Text3', language ) }</li>
                        </ul>
                        <div className="unsub_form_confirmation">
                            <label htmlFor="password-confirmation">{ t( 'form:passwordRequired', language ) }</label>
                            <input className="" type="password" value={ password } onChange={ this.handleChange }  id="password-confirmation" />
                            { errorMessage && <div className="mt-3 profile_edit_usual_error">{ errorMessage }</div> }
                        </div>
                    </div>
                </div>
                <div className="profile_unsub_nav_btns">
                    <div id="sub_step3" className="profile_btn_return" onClick={ event => this.handleUnSub(event) }>
                        <span>{ t( 'unsub:cancel', language ) }</span>
                    </div>
                    <div id="unsub_step3" className="profile_btn_unsub" onClick={ event => this.handleUnSub(event) } >
                        <span>{ t( 'unsub:confirm', language ) }</span>
                    </div>
                </div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
	userInfoDB: state.userReducer.userInfoDB,
});

export default connect( stateToProps )( PUnsubStep3 );