import React from "react";
import { connect } from "react-redux";

import RowRender from "../../components/tools/RowRender";

import { moviesAction } from "../../redux/actions";
import { getUserInfo } from "../../utils";

class ListRows extends React.Component {
	componentDidUpdate( prevProps ) {
		if ( this.props.themeId !== prevProps.themeId ) {
			const {
				fetchFavoritesMovies, 
				// fetchForUserMovies, 
				// fetchMomentMovies,
				fetchMostViewedMovies,
				fetchNewMovies,
				fetchPageMovies,
				fetchPinnedMovies,
				fetchProgressMovies,
				fetchSpotlightedMovies,
				profileVersion,
				themeId
			} = this.props;

			const userId = getUserInfo().NoCli;
			if ( userId ) {
				fetchFavoritesMovies( themeId, userId, profileVersion );
				// fetchForUserMovies( themeId, userId, profileVersion );	
				fetchProgressMovies( themeId, userId, profileVersion );
			}
			// fetchMomentMovies( themeId, userId, profileVersion );
			fetchMostViewedMovies( themeId, userId, profileVersion );
			fetchNewMovies( themeId, userId, profileVersion );
			fetchPageMovies( themeId, userId, profileVersion );
			fetchPinnedMovies( themeId, userId, profileVersion, 'first' );
			fetchPinnedMovies( themeId, userId, profileVersion, 'second' );
			fetchSpotlightedMovies( themeId, userId, profileVersion );
		}
	};

	render() {
		const {
			completedMovies,
			favoriteMovies,
			// forUserMovies,
			// momentMovies,
			mostViewedMovies,
			newMovies,
			pageMovies,
			pinnedFirstMovies,
			pinnedSecondMovies,
			progressMovies,
			spotlightedMovies
		} = this.props;
		
		return (
			<div id='home_Homelist'>
				<RowRender movieListInfo = { spotlightedMovies } />
				<RowRender movieListInfo = { newMovies } />
				<RowRender movieListInfo = { progressMovies } />
				<RowRender movieListInfo = { mostViewedMovies } />
				<RowRender movieListInfo = { pinnedFirstMovies } />
				<RowRender movieListInfo = { favoriteMovies } />
				<RowRender movieListInfo = { pinnedSecondMovies } />
				{/* <RowRender movieListInfo = { momentMovies } /> */}
				{/* <RowRender movieListInfo = { forUserMovies } /> */}
				<RowRender movieListInfo = { pageMovies } />
				<RowRender movieListInfo = { completedMovies } />
			</div>
		);
	}
}

const stateToProps = state => ({
	completedMovies: state.moviesReducer.completedMovies,
	favoriteMovies: state.moviesReducer.favoriteMovies,
	forUserMovies: state.moviesReducer.forUserMovies,
	momentMovies: state.moviesReducer.momentMovies,
	mostViewedMovies: state.moviesReducer.mostViewedMovies,
	newMovies: state.moviesReducer.newMovies,
	pageMovies: state.moviesReducer.pageMovies,
	pinnedFirstMovies: state.moviesReducer.pinnedFirstMovies,
	pinnedSecondMovies: state.moviesReducer.pinnedSecondMovies,
	profileVersion: state.userReducer.profileVersion,
	progressMovies: state.moviesReducer.progressMovies,
	spotlightedMovies: state.moviesReducer.spotlightedMovies,
	themeId: state.routingReducer.themeId,
});

const dispatchToProps = dispatch => ({
	fetchFavoritesMovies: ( themeId, userId, version ) => dispatch( moviesAction.fetchFavoritesMovies( themeId, userId, version )),
	fetchForUserMovies: ( themeId, userId, version ) => dispatch( moviesAction.fetchForUserMovies( themeId, userId, version )),
	fetchMomentMovies: ( themeId, userId, version ) => dispatch( moviesAction.fetchMomentMovies( themeId, userId, version )),
	fetchMostViewedMovies: ( themeId, userId, version ) => dispatch( moviesAction.fetchMostViewedMovies( themeId, userId, version )),
	fetchNewMovies: ( themeId, userId, version ) => dispatch( moviesAction.fetchNewMovies( themeId, userId, version )),
	fetchPageMovies: ( themeId, userId, version ) => dispatch( moviesAction.fetchPageMovies( themeId, userId, version )),
	fetchPinnedMovies: ( themeId, userId, version, type ) => dispatch( moviesAction.fetchPinnedMovies( themeId, userId, version, type )),
	fetchProgressMovies: ( themeId, userId, version ) => dispatch( moviesAction.fetchProgressMovies( themeId, userId, version )),
	fetchSpotlightedMovies: ( themeId, userId, version ) => dispatch(moviesAction.fetchSpotlightedMovies( themeId, userId, version )),
});

export default connect( stateToProps, dispatchToProps )( ListRows );
