import React from "react";
import { connect } from "react-redux";

import { API_IMAGE, DISPLAY, IMAGES } from "../../constants";
import { updateUserPaymentStatus } from "../../services/user";
import { formatDateOnly, getProgressBarStyle, getShortSynopsis, isGuest, isProgressTimeValid, t } from "../../utils";

class ListItem extends React.Component {
	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
		this.playBtnClick = this.playBtnClick.bind(this);

		this.state = { imageLoadNoCache: true, imageLoadError: true };
	};

	componentDidMount() {
		if( this.props.isMovieInfoPage ) this.handleClick();
	};

	goToChangePaymentPage = () => {
        const { changePaymentToken } = this.props;
        if ( changePaymentToken && changePaymentToken !== '' ) {
            updateUserPaymentStatus( `?token=${ changePaymentToken }&type=discarded` );
            window.location.href = "/modifier-paiement/?session_id=" + changePaymentToken;
        } 
	};

	handleClick = () => {
		const { parent, movie } = this.props;
		parent.setState( { movie: movie, rerender: false, show: true } );
	};

	playBtnClick = e => {
		e.stopPropagation();
		const { isVodPage, movie, parent, userDiscarded } = this.props;
		
		if ( ! isVodPage ) {
			if ( isGuest() ) {
				window.location.href = "/offres/";
				return;
			} else if ( userDiscarded ) {
				this.goToChangePaymentPage();
				return;
			}
		}
		
		const movieList = parent.props.movieList;
		const progressInfo = isProgressTimeValid( movie.progressTime, movie.timeTotal ) ? { progressTime: movie.progressTime, timeTotal: movie.timeTotal } : null;
		const movieKey = movieList.indexOf( movieList.filter( el => el.transaction === movie.transaction )[ 0 ] );
			
		parent.setState({
			playerShow: true,
			playerHeight: "100%",
			playerWidth: "100%",
			videoJsOptions: {
				logBtnExit: parent.logBtnExit,
				movieKey: movieKey,
				movieList: movieList, 
				playing: true,
				progressInfo: progressInfo,
			},
		});

		window.location.href = window.location.href + "#play/";
	};

	renderBadges = typeBadges => {
		const { language } = this.props;

		return (
            <>
                {
                    typeBadges && typeBadges.length > 0 &&
                    typeBadges.map( ( item, index ) =>
                        <div key={ index }>
                            { item === DISPLAY.NEW && <div className="badge-new">{ t( 'badge:new', language ) }</div> }
							{ item === DISPLAY.NEW_EPISODE && <div className="badge-new">{ t( 'badge:newEpisode', language ) }</div> }
							{ item === DISPLAY.NEW_SEASON && <div className="badge-new">{ t( 'badge:newSeason', language ) }</div> }
							{ item === DISPLAY.EACH_WEEK && <div className="badge-recurrence">{ t( 'badge:eachWeek', language ) }</div> }
							{ item === DISPLAY.EACH_FRIDAY && <div className="badge-recurrence">{ t( 'badge:eachFriday', language ) }</div> }
							{
								item.split( '||' )[ 0 ] === DISPLAY.SOON_OFFLINE && item.split( '||' )[ 1 ] &&
								<div className="badge-soon-offline">
									<i class="fa fa-hourglass-half" aria-hidden="true"></i>
									{ formatDateOnly( parseInt( item.split( '||' )[ 1 ] ), language ) }
								</div>
							}
                        </div>
                    )
                }
            </>
        )
	};

	renderImage = () => {
		const { catId, effect, isMovieInfoPage, language, movie, multipleImage, style, version } = this.props;

		const imageAlt = movie.imageAlt ? movie.imageAlt : movie.titre;
		let posterFileName = movie.posterFileName;
		let posterLongFileName = movie.posterLongFileName;
		let squareFileName = movie.squareFileName;
		let thumbnailFileName = movie.thumbnailFileName;
		let srcImage = '';
		let srcError = false;
		let srcErrorImage = '';

		if ( movie.versionImages &&  movie.versionImages.length > 0 ) {
			const index = version % movie.versionImages.length;
			if ( movie.versionImages[ index ].posterFileName ) posterFileName = movie.versionImages[ index ].posterFileName;
			if ( movie.versionImages[ index ].posterLongFileName ) posterLongFileName = movie.versionImages[ index ].posterLongFileName;
			if ( movie.versionImages[ index ].squareFileName ) squareFileName = movie.versionImages[ index ].squareFileName;
			if ( movie.versionImages[ index ].thumbnailFileName ) thumbnailFileName = movie.versionImages[ index ].thumbnailFileName;
		}
		
		if ( style === DISPLAY.POSTER ) {
			if ( ! posterFileName ) {
				srcImage = IMAGES.placeholderPoster;
				srcError = true;
			} else {
				srcImage = `${API_IMAGE}${ multipleImage ? multipleImage + '.png' : posterFileName + '.jpg' }&w=500&h=667`;
			}
			srcErrorImage = IMAGES.placeholderPoster;
		} else if ( style === DISPLAY.POSTER_LONG ) {
			if ( ! posterLongFileName ) {
				srcImage = IMAGES.placeholderPosterLong;
				srcError = true;
			} else {
				srcImage = `${API_IMAGE}${ multipleImage ? multipleImage + '.png' : posterLongFileName + '.jpg' }&w=500&h=889`;
			}
			srcErrorImage = IMAGES.placeholderPosterLong;
		} else if ( style === DISPLAY.SQUARE ) {
			if ( ! squareFileName ) {
				srcImage = IMAGES.placeholderSquare;
				srcError = true;
			} else {
				srcImage = `${API_IMAGE}${ multipleImage ? multipleImage + '.png' :  squareFileName + '.jpg' }&w=500&h=500`;
			}
			srcErrorImage = IMAGES.placeholderSquare;
		} else {
			if ( ! thumbnailFileName ) {
				srcImage = IMAGES.placeholderThumbnail;
				srcError = true;
			} else {
				srcImage = `${API_IMAGE}${ multipleImage ? multipleImage + '.png' : thumbnailFileName + '.jpg' }&w=500&h=281&crop-to-fit`;
			}
			srcErrorImage = IMAGES.placeholderThumbnail;
		}

		return (
			<figure
				className={ `effect-thumbnail-movie collect_flag ${ isMovieInfoPage ? 'hide' : '' }` }
				id={ `cat-${ catId }-movie-${ movie.transaction }` }
				onClick={ () => { if ( ! movie.vod_block ) this.handleClick() } }>
				<div className={ `${ movie.extraClass && movie.extraClass.image ? movie.extraClass.image : '' }` }></div>
				<img
					className="tile-img"
					alt={ imageAlt }
					src={ srcImage } 
					onError={ e => {
						if ( this.state.imageLoadNoCache ) {
							this.setState({ imageLoadNoCache: false });
							e.target.src = `${ srcImage }&no-cache`;
						} else if ( this.state.imageLoadError ) {
							this.setState({ imageLoadError: false });
							e.target.src = srcErrorImage;
						}
					}} />	
				<figcaption>
					<div className="containerBadges">
						{ this.renderBadges( movie.typeBadges ) }
					</div>
					{
						( movie.aff_titre === '1' || srcError || ! this.state.imageLoadError ) &&
						this.renderTitle( movie[ t( 'keys:titre', language ) ] ? movie[ t( 'keys:titre', language ) ] : movie.titre )
					}
				</figcaption>
				{
					isProgressTimeValid( movie.progressTime, movie.timeTotal ) &&
					<div className='movie_progress_play' onClick={ () => { if ( ! movie.vod_block ) this.playBtnClick() } }>
						<i className="fa fa-play" aria-hidden="true"></i>
					</div>
				}
				{
					movie.vod_block &&
					<div className="blockingMask">
						<i className="fas fa-lock" aria-hidden="true"></i>
					</div>
				}
				{
					isProgressTimeValid( movie.progressTime, movie.timeTotal ) && effect === 'with-description' &&
					<div className='movie_progress in-figure'>
						<div className="progress_bar" style={ getProgressBarStyle( movie.progressTime, movie.timeTotal ) }></div>
					</div>
				}
			</figure>
		);
	};

	renderTitle = title => {
    	if (title.length < 35) { return <h1 className="search-tile-title long-title">{ title }</h1>; }
    	return <h1 className="search-tile-title longer-title">{ title }</h1>;
  	};

	render() {
		const { effect, isMozaicPage, language, movie } = this.props;

		return (
			<div className={ `${ isMozaicPage ? 'mozaic_figure_box' : '' } shine-container` }>
				{	
					movie && movie.transaction &&
					<>
						{ this.renderImage() }
						{
							isProgressTimeValid( movie.progressTime, movie.timeTotal ) && effect !== 'with-description' &&
							<div className='movie_progress'>
								<div className="progress_bar" style={ getProgressBarStyle( movie.progressTime, movie.timeTotal ) }></div>
							</div>
						}
						{
							effect === 'with-description' &&
							<div className='with-description-info'>
								<div className="with-description-title">
									{
										movie.preTitre &&
										<span className="with-description-pretitle">
											{ movie[ t( 'keys:preTitre', language ) ] ? movie[ t( 'keys:preTitre', language ) ] : movie.preTitre }
										</span>
									}
									{ movie[ t( 'keys:titre', language ) ] ? movie[ t( 'keys:titre', language ) ] : movie.titre }
								</div>
								<div className="with-description-synopsis">
									{
										movie[ t( 'keys:synopsis_txt', language ) ] ?
										getShortSynopsis( movie[ t( 'keys:synopsis_txt', language ) ] )
										:
										getShortSynopsis( movie.synopsis_txt )
									}
								</div>
							</div>
						}
					</>
				}
			</div>
		);
	};
}

const stateToProps = state => ({
	changePaymentToken: state.userReducer.changePaymentToken,
	isVodPage: state.routingReducer.isVodPage,
	language: state.translatingReducer.language,
	userDiscarded: state.userReducer.userDiscarded,
	version: state.routingReducer.version,
});

export default connect( stateToProps )( ListItem );