import { USER_REDUCER } from "../../constants";
import { 
	getAvatarsList, 
	getCategoriesList, 
	getNotifications, 
	getPlansList, 
	getUserAddress,
	// getUserGeoInfo,
	getUserInfo,
	getUserPartnerInfo,
	getUserPurchases,
	getUserStripeCard,
	getUserStripeInfo,
} from '../../services/user';
import { authFailed } from "../../utils";
import { getLocalTimeZone } from "../../utils/getLocalTimeZone";

export default {
	fetchAvatarsList: () => async dispatch => {
		try {
			const response = await getAvatarsList( "" );
			const { data } = response;
			dispatch({
				type: USER_REDUCER.FETCH_AVATARS_LIST,
				payload: data
			});
		} catch (err) {
			dispatch({
				type: USER_REDUCER.API_ERROR,
				payload: "error : user API"
			});
		}
	},
	fetchCategoriesList: () => async dispatch => {
		try {
			const response = await getCategoriesList( "" );
			const { data } = response;
			dispatch({
				type: USER_REDUCER.FETCH_CATEGORIES_LIST,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: USER_REDUCER.API_ERROR,
				payload: "error : user API"
			});
		}
	},
	fetchNotifications: () => async dispatch => {
		try {
			const response = await getNotifications( "" );
			const { data } = response;
			dispatch({
				type: USER_REDUCER.FETCH_NOTIFICATIONS,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: USER_REDUCER.API_ERROR,
				payload: "error : user API"
			});
		}
	},
	fetchPlansList: () => async dispatch => {
		try {
			const response = await getPlansList( "" );
			const { data } = response;
			dispatch({
				type: USER_REDUCER.FETCH_PLANS_LIST,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: USER_REDUCER.API_ERROR,
				payload: "error : user API"
			});
		}
	},
	fetchProfile: data => dispatch => {
        dispatch( {
            type: USER_REDUCER.FETCH_PROFILE,
            payload: data,
        });
    },
	fetchUserAddress: userId => async dispatch => {
		try {
			const params = `?NoCli=${ userId }`;
			const response = await getUserAddress( params );
			const { data } = response;
			dispatch({
				type: USER_REDUCER.FETCH_USER_ADDRESS,
				payload: data
			});
		} catch (err) {
			dispatch({
				type: USER_REDUCER.API_ERROR,
				payload: "error : user API"
			});
		}
	},
	fetchUserGeoInfo: () => async dispatch => {
		try {
			// replace API call by checking local time zone
			// const response = await getUserGeoInfo( "" );
			// const { data } = response;
			const userGeoInfo = getLocalTimeZone();
			dispatch({
				type: USER_REDUCER.FETCH_USER_GEO_INFO,
				// payload: data.data
				payload: userGeoInfo
			});
		} catch (err) {
			dispatch({
				type: USER_REDUCER.API_ERROR,
				payload: "error : user API"
			});
		}
	},
	fetchUserInfo: userId => async dispatch => {
		try {
			const params = `?NoCli=${ userId }`;
			const response = await getUserInfo( params );
			const { data } = response;
			if ( ! data.ret && data.data === "authentication" ) authFailed();
			dispatch({
				type: USER_REDUCER.FETCH_USER_INFO,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: USER_REDUCER.API_ERROR,
				payload: "error : user API"
			});
		}
	},
    fetchUserPartnerInfo: userId => async dispatch => {
		try {
			const params = `?NoCli=${ userId }`;
			const response = await getUserPartnerInfo( params );
			const { data } = response;
			dispatch({
				type: USER_REDUCER.FETCH_USER_PARTNER_INFO,
				payload: data
			});
		} catch (err) {
			dispatch({
				type: USER_REDUCER.API_ERROR,
				payload: "error : user API"
			});
		}
	},
    fetchUserPurchases: userId => async dispatch => {
		try {
			const params = `?NoCli=${ userId }`;
			const response = await getUserPurchases( params );
			const { data } = response;
			dispatch({
				type: USER_REDUCER.FETCH_USER_PURCHASES,
				payload: data
			});
		} catch (err) {
			dispatch({
				type: USER_REDUCER.API_ERROR,
				payload: "error : user API"
			});
		}
	},
    fetchUserStripeCard: userId => async dispatch => {
		try {
			const params = `?NoCli=${ userId }`;
			const response = await getUserStripeCard( params );
			const { data } = response;
			if ( ! data.ret ) return;
			dispatch({
				type: USER_REDUCER.FETCH_USER_STRIPE_CARD,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: USER_REDUCER.API_ERROR,
				payload: "error : user API"
			});
		}
	},
    fetchUserStripeInfo: userId => async dispatch => {
		try {
			const params = `?NoCli=${ userId }`;
			const response = await getUserStripeInfo( params );
			const { data } = response;
			if ( ! data.ret ) return;
			dispatch({
				type: USER_REDUCER.FETCH_USER_STRIPE_INFO,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: USER_REDUCER.API_ERROR,
				payload: "error : user API"
			});
		}
	},
};