import React from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import ListHeader from '../ListRows/ListHeader';
import LinkSliderItem from './LinkSliderItem';
import VideoPlayer from '../Videoplayer';

import { API_IMAGE, DISPLAY } from '../../constants';
import { isGuest, isProgressTimeValid } from '../../utils';

class Slider extends React.Component {
	static contextTypes = { router : Proptypes.object };

	constructor(props,context) {
		super(props,context);

		this.logBtnExit = this.logBtnExit.bind(this);

		this.state = {
			playerShow: false,
			playerAutoplay: false,
			playerHeight: 0,
			playerWidth: 0,
			videoJsOptions: { autoplay: false, playing: false },
		};
	};

	componentDidMount() {
		this.backListener = this.context.router.history.listen( ( location, action, state ) => {
			if ( action === "POP" && this.state.playerShow && location.hash !== '#play/' ) this.logBtnExit();
		});
	};

	getImageFile = ( movie, type ) => {
		const { version } = this.props;
		if ( movie.versionImages &&  movie.versionImages.length > 0 ) {
			const index = version % movie.versionImages.length;
			if ( movie.versionImages[ index ][ type ] ) return movie.versionImages[ index ][ type ];
		}
		return movie[ type ];
	};

	logBtnExit = () => {
		if( document.location.hash.includes( "#play/" ) ) this.context.router.history.goBack();
		this.setState( { playerShow: false, playerHeight: 0, playerWidth: 0, videoJsOptions: { autoplay: false, playing: false } } );
		if ( this.props.isGlobalPlayingPage ) {
			window.location.href = window.location.href.replace( "#play/", "" );
			window.location.reload();
		}
	};
		
	onClickFullscreen = movie => {
		const { isVodPage, sliderMovies, userDiscarded } = this.props;

		if ( ! isVodPage ) {
			if ( isGuest() ) {
				window.location.href = "/offres/";
				return;
			} else if ( userDiscarded ) {
				this.goToChangePaymentPage();
				return;
			}
		}

		const movieList = sliderMovies[ 0 ].movies;
		const progressInfo = isProgressTimeValid( movie.progressTime, movie.timeTotal ) ? { progressTime: movie.progressTime, timeTotal: movie.timeTotal } : null;
		const movieKey = movieList.indexOf( movieList.filter( el => el.transaction === movie.transaction )[ 0 ] );

		this.setState({
			playerShow: true,
			playerHeight: "100%",
			playerWidth: "100%",
			videoJsOptions: {
				logBtnExit: this.logBtnExit,
				movieKey: movieKey,
				movieList: movieList, 
				playing: true,
				progressInfo: progressInfo,
			},
		});

		window.location.href = window.location.href + "#play/";
	};

	render(){
		const { isInRows, sliderMovies }  = this.props;

		if ( sliderMovies && sliderMovies.length > 0 ) {
			sliderMovies.forEach( (item, key) => {
				sliderMovies[ key ].currentDisplay = [ ...item.movies ];
				if ( item.addon && item.addon.length > 0 ) {
					item.addon.forEach( item_ => {
						sliderMovies[ key ].currentDisplay.splice( item_.index, 0, item_ );
					})
				}
			})
		}

		return (
			<>
                {
                    sliderMovies && sliderMovies.length > 0 &&
                    sliderMovies.map( item =>
						<div key={ item.display.id }>
							{
								item.display.linked ?
								<div className="goTo_box collect_flag" id={ `btn-arrow-${ item.display.id }` } onClick={ () => window.location.href = item.display.href }>
									<ListHeader display={ item.display } />
								</div>
								:
								<ListHeader display={ item.display } isInRows={ isInRows } />
							}
							<div className={ `GBSlider ${ sliderMovies[ 0 ].currentDisplay.length === 1 ? 'no-dots' : 'with-dots' } ${ isInRows ? 'in-rows' : '' }` }>
								<Carousel
									showThumbs={false}
									showStatus={false}
									infiniteLoop={true}
									autoPlay={true}
									stopOnHover={true}
									dynamicHeight={true}
									interval={5000}
									transitionTime={750}>
										{
											item.currentDisplay && item.currentDisplay.length > 0 &&
											item.currentDisplay.map( (item_, index) =>
												<div key={ index }>
													{
														item_.type === DISPLAY.LINK ?
														<LinkSliderItem link={ item_ } parent={ this } />
														:
														<div
															className="carousel-img collect_flag"
															onClick={ this.onClickFullscreen.bind( this, item_ ) }
															id={ `playBtn--${ item.display.id }-movie-${ item_.transaction }` }>
															<img
																className="d-none d-md-block img-fluid"
																src={ `${ API_IMAGE }${ this.getImageFile( item_, 'carouselWebFileName' ) }.jpg&h=720&crop-to-fit&q=40` }
																alt={ item_.imageAlt ? item_.imageAlt : item_.titre } />
															<img
																className="sd-block d-sm-block d-md-none img-fluid"
																src={ `${API_IMAGE}${ this.getImageFile( item_, 'carouselMobileFileName' ) }.jpg&h=720&crop-to-fit&q=40` }
																alt={ item_.imageAlt ? item_.imageAlt : item_.titre } />
														</div>
													}
												</div>
											)
										}
								</Carousel>				
							</div>
						</div>
                    )
                }
                <div id="z3pOverlay" className="z3PlayOverlay" style={{display : this.state.playerShow ? 'block' : 'none', width: this.state.playerWidth, height: this.state.playerHeight }}>
                    <VideoPlayer className="video-js" id="VideoSlider" { ...this.state.videoJsOptions } />
                </div>
            </>

		)
	};
};

const stateToProps = state => ({
	isGlobalPlayingPage: state.routingReducer.isGlobalPlayingPage,
	isVodPage: state.routingReducer.isVodPage,
	userDiscarded: state.userReducer.userDiscarded,
	version: state.routingReducer.version,
});

export default connect( stateToProps )( Slider );
