import React from 'react';
import { connect } from "react-redux";

import Header from '../../components/Header';
import ListRows from "./ListRows";

import { routingAction, userAction } from "../../redux/actions";

class Training extends React.Component {
    componentDidMount() {
        const { fetchCurrentRoutingByUrl, fetchUserGeoInfo } = this.props;
        fetchCurrentRoutingByUrl( 'formations' );
        fetchUserGeoInfo();
  	};

    render() {
        return (
			<div className="homepage-container">
				<Header isTrainingPage={ true } />
                <ListRows />
			</div>
        )
    }
}

const stateToProps = state => ({});

const dispatchToProps = dispatch => ({
	fetchCurrentRoutingByUrl: url => dispatch(routingAction.fetchCurrentRoutingByUrl( url )),
    fetchUserGeoInfo: () => dispatch(userAction.fetchUserGeoInfo()),
});

export default connect( stateToProps, dispatchToProps )( Training );

