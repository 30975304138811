import React from "react";
import { connect } from "react-redux";

import Fade from "react-reveal/Fade";
import Loader from "react-loader-spinner";

import { PopupPaymentConfirm, PopupPaymentInit, PopupPaymentSuccess } from "./PopupPayment";
import { PopupForgottenPassword, PopupLogin, PopupRegistration, PopupResetPassword } from "./PopupUser";
import { PopupChangePayment, PopupUserDiscarded } from "./PopupWarning";

import { POPUP_REDUCER } from "../../constants";
import { popupAction } from "../../redux/actions";


class PopupHandler extends React.Component {
    onClickMask = e => {
        if ( e.target.classList.contains( 'ils_popup_mask' ) ) this.props.popupClose( this.props.popupName );
    };

    render() {
        const { popupName, popupOpen } = this.props;

        return (
            <>
            {
                popupName && popupOpen &&
                <div className="ils_popup">
                    <div id="ils_loader"><Loader type="TailSpin" color="#fba00b" height={100} width={100} /></div>
                    <div className="ils_popup_mask" onClick={ e => this.onClickMask( e ) }>
                        <div className="ils_popup_info">
                            <Fade bottom duration={800} >
                                <div className="ils_popup_content">
                                    { popupOpen && popupName === POPUP_REDUCER.POPUP_CHANGE_PAYMENT && <PopupChangePayment /> }
                                    { popupOpen && popupName === POPUP_REDUCER.POPUP_FORGOTTEN_PASSWORD && <PopupForgottenPassword /> }
                                    { popupOpen && popupName === POPUP_REDUCER.POPUP_LOGIN && <PopupLogin /> }
                                    { popupOpen && popupName === POPUP_REDUCER.POPUP_REGISTRATION && <PopupRegistration /> }
                                    { popupOpen && popupName === POPUP_REDUCER.POPUP_REGISTRATION_FREE_MOVIE && <PopupRegistration isFreeMovie={ true } /> }
                                    { popupOpen && popupName === POPUP_REDUCER.POPUP_REGISTRATION_PLAYLIST && <PopupRegistration isPlaylist={ true } /> }
                                    { popupOpen && popupName === POPUP_REDUCER.POPUP_RESET_PASSWORD && <PopupResetPassword /> }
                                    { popupOpen && popupName === POPUP_REDUCER.POPUP_PAYMENT_CONFIRM && <PopupPaymentConfirm /> }
                                    { popupOpen && popupName === POPUP_REDUCER.POPUP_PAYMENT_INIT && <PopupPaymentInit /> }
                                    { popupOpen && popupName === POPUP_REDUCER.POPUP_PAYMENT_SUCCESS && <PopupPaymentSuccess /> }
                                    { popupOpen && popupName === POPUP_REDUCER.POPUP_USER_DISCARDED && <PopupUserDiscarded /> }
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            }
            </>
        );
    };    
};

const stateToProps = state => ( {
    popupName: state.popupReducer.popupName,
    popupOpen: state.popupReducer.popupOpen,
});

const dispatchToProps = dispatch => ({
    popupClose: popupName => dispatch( popupAction.popupClose( popupName ) ),
});

export default connect ( stateToProps, dispatchToProps )( PopupHandler );
