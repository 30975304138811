import React from "react";
import { connect } from "react-redux";

import VideoPlayer from "../Videoplayer";

import { API_IMAGE, ICONS, IMAGES } from "../../constants";
import { toggleFavorites, toggleThumbs } from "../../services/user";
import { checkiOS, convertToCsaKey, convertToDefKey, getMovieAdditionalInfo, getRoles, getUserInfo, isGuest, t } from "../../utils";
import { isCanadian } from "../../utils/getLocalTimeZone";
 
class MoviePlaylist extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            isFullScreen: false,
			isMuted: true,
			playerShow: false,
			playerStarted: false,
			playerHeight: 0,
			playerWidth: 0,
			videoJsOptions: { autoplay: false, playing: false },
		};

        this.favoriteList = [];
        this.thumbUpList = [];
        this.thumbDownList = [];
    };

    componentDidUpdate( prevProps ) {
        const { playlistMovies, playlistPeriodId } = this.props;
		if ( playlistMovies && playlistMovies !== prevProps.playlistMovies && playlistPeriodId ) this.playPlaylist();
	};

    componentWillReceiveProps( newProps ) {
		if ( newProps.isPlayingStarted ) this.setState({ playerStarted: true });
		return null;
	};

    btnSrcIsInList = movieId => {
        const { language } = this.props;
        this.favoriteList = this.props.favorites;
		if ( this.favoriteList.includes( movieId ) ) {
            return {
                bubble: <div className="bubble bubble-bottom">{ t( 'shared:listRemoveTo', language ) }</div>,
                class: 'movie_icon selected',
                src: ICONS.checked
            };
        } 
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:listAddTo', language ) }</div>,
            class: 'movie_icon',
            src: ICONS.favorites
        };
    };

	btnSrcThumbUp = movieId => {
        const { language } = this.props;
		this.thumbUpList = this.props.thumbsUp;
		if ( this.thumbUpList.includes( movieId ) ) {
            return { 
                bubble: <></>, 
                class: 'movie_icon selected', 
                src: ICONS.thumbFilled 
            };
        }
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:like', language ) }</div>,
            class: 'movie_icon',
            src: ICONS.thumb
        };
    };

	btnSrcThumbDown = movieId => {
        const { language } = this.props;
		this.thumbDownList = this.props.thumbsDown;
        if ( this.thumbDownList.includes( movieId ) ) {
            return { 
                bubble: <></>, 
                class: 'movie_icon icon_revert selected', 
                src: ICONS.thumbFilled 
            };
        }
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:dislike', language ) }</div>,
            class: 'movie_icon icon_revert',
            src: ICONS.thumb
        };
    };

    participantToShow = movie => {
        if( ! movie.distribution ) return;
        const { language } = this.props;
        
        return movie.distribution.map( ( el, i ) => 
            <div key={ `participant-${ i }` }
                onClick={ () => { if( el.url ) window.location.href = `/browse/?search=${ el.url }/#/search/${ el.url }` } } 
                className={ `distribution-box ${ el.url && "distribution-interraction" }` }>
                <img
                    className='movie_distribution_img'
                    src={ `${ API_IMAGE }${ el.imagePath }.jpg` }
                    alt={ el.title }
                    onError= { (e) => { e.target.src = IMAGES.avatarDefault } } />
                <div className="distribution-thumbnails">{ el.title } </div>
                <div className="distribution-thumbnails">{ getRoles( el.role, language ) }</div>
            </div>
        )
    };

    playPlaylist = () => {
		const { advertisingMovies, playlistMovies, playlistMovieIndex } = this.props;
		const { isMuted } = this.state;

		this.setState({
			playerShow: true,
			playerHeight: "100%",
			playerWidth: "100%",
			videoJsOptions: {
				advertisingKey: 0,
				advertisingList: advertisingMovies, 
                isPlaylist: true,
				movieKey: playlistMovieIndex ? playlistMovieIndex : 0,
				movieList: playlistMovies, 
				playing: true,
				progressInfo: null,
				controls: false,
				muted: isMuted,
			},
		});
	};

    toggleInMyList = ( e, movieId ) => {
        e.stopPropagation();
        if ( isGuest() ) return;
        
        const { profileVersion } = this.props;
		const action = this.props.favorites.includes( movieId ) ? 'remove' : 'add';
        let newFavoriteList = this.favoriteList;
        newFavoriteList = ( action === 'remove' ) 
            ? newFavoriteList.splice( newFavoriteList.indexOf( movieId ), 1 )
            : newFavoriteList.push( movieId );	
        this.setState( { favoriteList: newFavoriteList } );

        toggleFavorites( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ movieId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
    };

    toggleThumbDown = ( e, movieId ) => {
        e.stopPropagation();
        if ( isGuest() ) return;

        const { profileVersion } = this.props;
        const action = this.props.thumbsDown.includes( movieId ) ? 'remove-down' : 'add-down';
        let newThumbDownList = this.thumbDownList;
        let newThumbUpList = this.thumbUpList;
        newThumbDownList = ( action === 'remove-down' ) 
            ? newThumbDownList.splice( newThumbDownList.indexOf( movieId ), 1 )
            : newThumbDownList.push( movieId );	
        if ( action === 'add-down' && newThumbUpList.includes( movieId ) ) newThumbUpList.splice( newThumbUpList.indexOf( movieId ), 1 );
        this.setState( { thumbDownList: newThumbDownList, thumbUpList: newThumbUpList } );

        toggleThumbs( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ movieId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
	};

	toggleThumbUp = ( e, movieId ) => {
        e.stopPropagation();
        if ( isGuest() ) return;

        const { profileVersion } = this.props;
        const action = this.props.thumbsUp.includes( movieId ) ? 'remove-up' : 'add-up';
        let newThumbDownList = this.thumbDownList;
        let newThumbUpList = this.thumbUpList;
        newThumbUpList = ( action === 'remove-up' ) 
            ? newThumbUpList.splice( newThumbUpList.indexOf( movieId ), 1 )
            : newThumbUpList.push( movieId );
        if ( action === 'add-up' && newThumbDownList.includes( movieId ) ) newThumbDownList.splice( newThumbDownList.indexOf( movieId ), 1 );    	
        this.setState( { thumbDownList: newThumbDownList, thumbUpList: newThumbUpList } );

        toggleThumbs( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ movieId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
    };

    toggleFullScreen = () => {
        if ( checkiOS() ) return;

        const player = document.getElementById( 'z3pOverlay' );
        const isInFullScreen =
            ( document.fullscreenElement && document.fullscreenElement !== null ) ||
            ( document.webkitFullscreenElement && document.webkitFullscreenElement !== null ) ||
            ( document.mozFullScreenElement && document.mozFullScreenElement !== null ) ||
            ( document.msFullscreenElement && document.msFullscreenElement !== null );

        if ( isInFullScreen ) {
            if ( document.exitFullscreen ) {
                document.exitFullscreen();
            } else if ( document.webkitExitFullscreen ) {
                document.webkitExitFullscreen();
            } else if ( document.mozCancelFullScreen ) {
                document.mozCancelFullScreen();
            } else if ( document.msExitFullscreen ) {
                document.msExitFullscreen();
            }
        } else {
            if ( player.requestFullScreen ) {
                player.requestFullScreen();
            } else if ( player.mozRequestFullScreen ) {     
                player.mozRequestFullScreen();
            } else if ( player.webkitRequestFullScreen ) {
                player.webkitRequestFullScreen();
            } else if ( player.webkitEnterFullScreen) {
                player.webkitEnterFullScreen();
            }
        }

        this.setState({ isFullScreen: ! isInFullScreen });
	};

    toggleSound = () => this.setState({ isMuted: ! this.state.isMuted, videoJsOptions: { muted: ! this.state.isMuted } });

    render() {
        const { language, playlistMovies, playlistMovieIndex } = this.props;
        const { isMuted, playerShow, playerStarted, playerHeight, playerWidth, videoJsOptions } = this.state;

        let movie;
        const movieIndex = playlistMovieIndex ? playlistMovieIndex : 0;
        if ( playlistMovies && playlistMovies.length > 0 ) movie = playlistMovies[ movieIndex ];

        return (
            <>
            <div className="video-playlist-container">
                {
                    playlistMovies && playlistMovies.length > 0 &&
                    <div className="video-playlist-flex-container">
                        <div className="video-playlist-container-relative">
                            <div
                                id="z3pOverlay"
                                className="z3PlayOverlay"
                                style={{display : playerShow ? 'block' : 'none', width: playerWidth, height: playerHeight }}>
                                <VideoPlayer className="video-js" id="VideoPlaylist" { ...videoJsOptions } />
                                <div
                                    className="video-playlist-container-sound"
                                    style={{display: playerShow && playerStarted ? 'block' : 'none'}}
                                    onClick={ this.toggleSound }>
                                    { isMuted ? <i className="fa fa-volume-off"></i> : <i className="fa fa-volume-up" aria-hidden="true"></i>}
                                </div>
                                {
                                    ! checkiOS() &&
                                    <div
                                        className="video-playlist-container-full-screen"
                                        style={{display: playerShow && playerStarted ? 'block' : 'none'}}
                                        onClick={ this.toggleFullScreen }>
                                        <i className='fas fa-expand fullscreen_icon'></i>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }	
            </div>
            <div className="video-playlist-happy-ending"></div>
            <>
                {
                    movie && movie.titre &&
                    <div className="video-playlist-content">
                        <div className="video-playlist-content-header">
                            {
                                movie[ t( 'keys:titlePngFileName', language ) ] ?
                                <div className="video-playlist-img-title">
                                    <img 
                                        alt={ movie.titre }
                                        src={ `${ API_IMAGE }${ movie[ t( 'keys:titlePngFileName', language ) ] }.png&h=160&crop-to-fit` }
                                        onError={ e => e.target.style.display = 'none' } />
                                </div>    
                                :
                                <div className="video-playlist-title">
                                    { movie[ t( 'keys:titre', language ) ] ? movie[ t( 'keys:titre', language ) ] : movie.titre }
                                </div> 
                            }
                            <div className='movie_btns'>
                                <div className="icons_box">
                                    <div
                                        className={ `movie_btn_box collect_flag ${ isGuest() && 'disabled' }` }
                                        onClick={ e => this.toggleInMyList( e, movie.transaction ) }
                                        id={ `favoritesBtn--cat-playlist-movie-${ movie.transaction }` } >
                                        <div className='icon_box'>
                                            <img
                                                className={ this.btnSrcIsInList( movie.transaction ).class }
                                                src={ this.btnSrcIsInList( movie.transaction ).src }
                                                alt='icone favoris' />
                                            { this.btnSrcIsInList( movie.transaction ).bubble }    
                                        </div>
                                    </div>
                                    <div
                                        className={ `movie_btn_box collect_flag ${ isGuest() && 'disabled' }` }
                                        onClick={ e => this.toggleThumbUp( e, movie.transaction ) }
                                        id={ `thumbUpBtn--cat-playlist-movie-${ movie.transaction }` } >
                                        <div className='icon_box'>
                                            <img
                                                className={ this.btnSrcThumbUp( movie.transaction ).class }
                                                src={ this.btnSrcThumbUp( movie.transaction ).src }
                                                alt='icone puce en haut' />
                                            { this.btnSrcThumbUp( movie.transaction ).bubble }    
                                        </div>
                                    </div>
                                    <div
                                        className={ `movie_btn_box collect_flag ${ isGuest() && 'disabled' }` }
                                        onClick={ e => this.toggleThumbDown( e, movie.transaction ) }
                                        id={ `thumbDownBtn--cat-playlist-movie-${ movie.transaction }` } >
                                        <div className='icon_box'>
                                            <img
                                                className={ this.btnSrcThumbDown( movie.transaction ).class }
                                                src={ this.btnSrcThumbDown( movie.transaction ).src }
                                                alt='icone favoris' />
                                            { this.btnSrcThumbDown( movie.transaction ).bubble }
                                        </div>
                                    </div>
                                    <a
                                        className={ `movie_btn_box collect_flag` }
                                        href={ `/browse/?search=${ movie.url ? movie.url : movie.transaction }/#/search/${ movie.url ? movie.url : movie.transaction }` }
                                        id={ `infoBtn--cat-playlist-movie-${ movie.transaction }` } >
                                        <div className='icon_box'>
                                            <img
                                                className="movie_icon"
                                                src={ ICONS.info }
                                                alt='icone info' />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="movie_additional_info">
                            <span>{ getMovieAdditionalInfo( movie, language ) }</span>
                            {
                                movie.csa && movie.csa !== '' &&
                                <img className="movie_icon"
                                    src={ ICONS[ convertToCsaKey( movie.csa ) ] }
                                    alt='icone csa' />
                            }
                            {
                                movie.definition && movie.definition !== '' &&
                                <img className="movie_icon"
                                    src={ ICONS[ convertToDefKey( movie.definition ) ] }
                                    alt='icone definition' />
                            }
                        </div>
                        <div className='movie_popup_categories'>
                            {
                                isCanadian() && movie.categories_Canada && movie.categories_Canada.length > 0 ?
                                <>
                                {
                                    movie.categories_related_Canada && movie.categories_related_Canada.length > 0 &&
                                    movie.categories_related_Canada.map( ( el, key ) => ( 
                                        <span
                                            key={ key }
                                            className={ `catSpan catSpan-web ${ el.url ? 'catSpanLinked' : '' }` }
                                            onClick={ () => { if ( el.url ) window.location.href=`/browse/?search=${ el.url }/#/search/${ el.url }` } } >
                                            { el[ t( 'keys:name', language ) ] ? el[ t( 'keys:name', language ) ] : el.name  }
                                        </span>
                                    ))
                                }
                                {
                                    movie.categories_Canada && movie.categories_Canada.length > 0 &&
                                    movie.categories_Canada.map( ( el, key ) => (
                                        <span
                                            key={ key }
                                            className={ `catSpan catSpan-mobile ${ el.url ? 'catSpanLinked' : '' }` }
                                            onClick={ () => { if ( el.url ) window.location.href=`/browse/?search=${ el.url }/#/search/${ el.url }` } } >
                                            { el[ t( 'keys:name', language ) ] ? el[ t( 'keys:name', language ) ] : el.name  }
                                        </span>                
                                    ))
                                }
                                </>
                                :
                                <>
                                {
                                    movie.categories_related && movie.categories_related.length > 0 &&
                                    movie.categories_related.map( ( el, key ) => ( 
                                        <span
                                            key={ key }
                                            className={ `catSpan catSpan-web ${ el.url ? 'catSpanLinked' : '' }` }
                                            onClick={ () => { if ( el.url ) window.location.href=`/browse/?search=${ el.url }/#/search/${ el.url }` } } >
                                            { el[ t( 'keys:name', language ) ] ? el[ t( 'keys:name', language ) ] : el.name  }
                                        </span>
                                    ))
                                }
                                {
                                    movie.categories && movie.categories.length > 0 &&
                                    movie.categories.map( ( el, key ) => (
                                        <span
                                            key={ key }
                                            className={ `catSpan catSpan-mobile ${ el.url ? 'catSpanLinked' : '' }` }
                                            onClick={ () => { if ( el.url ) window.location.href=`/browse/?search=${ el.url }/#/search/${ el.url }` } } >
                                            { el[ t( 'keys:name', language ) ] ? el[ t( 'keys:name', language ) ] : el.name  }
                                        </span>                
                                    ))
                                }
                                </>
                            }
                        </div>
                        <div className="movie_popup_synopsis">
                            { movie[ t( 'keys:synopsis_txt', language ) ] ? movie[ t( 'keys:synopsis_txt', language ) ] : movie.synopsis_txt  }
                        </div>
                        {
                            movie.distribution && movie.distribution.length > 0 &&
                            <div className="categories-list-box distribution-list-box">
                                <div className="categories-list">
                                    {
                                        movie.distribution && movie.distribution.length > 0 &&
                                        movie.distribution.map( ( el, i ) =>
                                            <a
                                                className="category-box collect_flag"
                                                href={ `/browse/?search=${ el.url }/#/search/${ el.url }` }
                                                key={ `participant-${ i }` }
                                                id={ `movie-page-distribution-circle-${el.url}` }>
                                                <div className="category-item">
                                                    <img
                                                        className="category-img"
                                                        src={ `${ API_IMAGE }${ el.imagePath }.jpg` }
                                                        alt={ el.title }
                                                        onError= { (e) => { e.target.src = IMAGES.avatarDefault } }/>
                                                    <div className="category-name">
                                                        <div>{ el.title }</div>
                                                        <div className="distribution-role">{ getRoles( el.role, language ) }</div>
                                                    </div>    
                                                </div>
                                            </a> 
                                        )
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }  
            </>  
            </>             
        );
    }
}

const stateToProps = state => ({
    advertisingMovies: state.playlistReducer.advertisingMovies,
    favorites: state.userReducer.favorites,
    isPlayingStarted: state.playerReducer.isPlayingStarted,
    language: state.translatingReducer.language,
    playlistMovies: state.playlistReducer.playlistMovies,
    playlistMovieIndex: state.playlistReducer.playlistMovieIndex,
    playlistPeriodId: state.playlistReducer.playlistPeriodId,
    profileVersion: state.userReducer.profileVersion,
    thumbsUp: state.userReducer.thumbsUp,
    thumbsDown: state.userReducer.thumbsDown,
});

export default connect( stateToProps )( MoviePlaylist );