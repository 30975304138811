import { USER_REDUCER, VERSION_PROFILE, VERSION_PROFILE_EDIT, VERSION_PROFILE_NOCLI } from "../../constants";
import { getProfileVersion, getProfileVersionEdit, getProfileVersionNoCli } from "../../utils";

const initialState = {
    authorizedDeliveryCountry: [],
    avatarsList: {},
    categoriesList: {},
    cancelAt: 0,
    changePaymentShowPopup: false,
    changePaymentToken: '',
    discardedUserShowPopup: false,
    favorites: [],
    plansList: {},
    profileAddAvatar: '',
    profileAddAvatarId: 0,
    profileAddFirstName: '',
    profileEditAvatar: '',
    profileEditAvatarId: 0,
    profileEditFirstName: '',
    profileEditVersion: getProfileVersionEdit(),
    profileVersion: getProfileVersion(),
    profileVersionNoCli: getProfileVersionNoCli(),
    notifications: [],
    stripeCard: {},
    stripeReceipts: [],
    stripePlan: {},
    stripeSubId: '',
    thumbsDown: [],
    thumbsUp: [],
    userAddress: {},
    userDiscarded: false,
    userGeoInfo: {},
    userInfoDB: {},
    userPartnerInfoDB: {},
    userPreferences: [],
    userPreferencesDone: false,
    userPurchases: [],
    userThemes: [],
};

export default ( state = initialState, action ) => {
    const newState = Object.assign( {}, state );
    switch ( action.type ) {  
        case USER_REDUCER.FETCH_AVATARS_LIST:
            newState.avatarsList = action.payload.avatars;
            return newState;
        case USER_REDUCER.FETCH_CATEGORIES_LIST:
            newState.categoriesList = action.payload;
            return newState;
        case USER_REDUCER.FETCH_NOTIFICATIONS:
            newState.notifications = action.payload;
            return newState;
        case USER_REDUCER.FETCH_PLANS_LIST:
            newState.plansList = action.payload;
            return newState;
        case USER_REDUCER.FETCH_PROFILE:
            const data = action.payload;
            if ( data.type === 'add' ) {
                if ( data.avatar ) newState.profileAddAvatar = data.avatar;
                if ( data.avatarId ) newState.profileAddAvatarId = data.avatarId;
                if ( data.firstName || data.firstName === '' ) newState.profileAddFirstName = data.firstName;
            } else if ( data.type === 'edit' ) {
                if ( data.avatar ) newState.profileEditAvatar = data.avatar;
                if ( data.avatarId ) newState.profileEditAvatarId = data.avatarId;
                if ( data.firstName || data.firstName === ''  ) newState.profileEditFirstName = data.firstName;
                if ( data.version || data.version === 0 ) {
                    sessionStorage.setItem( VERSION_PROFILE_EDIT, data.version );
                    newState.profileEditVersion = data.version;
                }
            } else if ( data.type === 'select' ) {
                if ( data.noCli && ( data.version || data.version === 0 ) ) {
                    localStorage.setItem( VERSION_PROFILE, data.version );
                    localStorage.setItem( VERSION_PROFILE_NOCLI, data.noCli );
                    newState.profileVersion = data.version;
                    newState.profileVersionNoCli = data.noCli;
                } 
            }
            return newState;
        case USER_REDUCER.FETCH_USER_ADDRESS:
            newState.authorizedDeliveryCountry = action.payload.authorizedDeliveryCountry;
            newState.userAddress = action.payload.user;
            return newState;
        case USER_REDUCER.FETCH_USER_GEO_INFO:
            newState.userGeoInfo = action.payload;
            return newState;
        case USER_REDUCER.FETCH_USER_INFO:
            if ( initialState.profileVersion === 0 ) {
                newState.favorites = action.payload.preferences.favorites;
                newState.thumbsDown = action.payload.preferences.thumbDown;
                newState.thumbsUp = action.payload.preferences.thumbUp;
                newState.userPreferences = action.payload.preferences.preferences;   
                newState.userThemes = action.payload.preferences.themes;
            } else {
                if ( action.payload.user && action.payload.user.profiles && action.payload.user.profiles.length > 0 ) {
                    for ( let i = 0; i < action.payload.user.profiles.length; i++ ) {
                        if ( initialState.profileVersion === action.payload.user.profiles[ i ].version ) {
                            newState.favorites = action.payload.user.profiles[ i ].preferences.favorites;
                            newState.thumbsDown = action.payload.user.profiles[ i ].preferences.thumbDown;
                            newState.thumbsUp = action.payload.user.profiles[ i ].preferences.thumbUp;
                            newState.userPreferences = action.payload.user.profiles[ i ].preferences.preferences;   
                            newState.userThemes = action.payload.user.profiles[ i ].preferences.themes;
                            break;
                        }
                    }
                }
            }

            newState.userPreferencesDone = true;
            newState.cancelAt = parseInt( action.payload.status.cancel_at );
            newState.changePaymentShowPopup = action.payload.status.popup_show_change_cb;
            newState.changePaymentToken = action.payload.status.token;
            newState.discardedUserShowPopup = action.payload.status.discarded_popup_show_change_cb;
            newState.userDiscarded = action.payload.status.discarded;
            newState.userInfoDB = action.payload.user;
            return newState;
        case USER_REDUCER.FETCH_USER_PARTNER_INFO:
            newState.userPartnerInfoDB = action.payload.user;
            return newState;
        case USER_REDUCER.FETCH_USER_PURCHASES:
            newState.userPurchases = action.payload.purchases;
            return newState;
        case USER_REDUCER.FETCH_USER_STRIPE_CARD:
            newState.stripeCard = action.payload.card;
            return newState;
        case USER_REDUCER.FETCH_USER_STRIPE_INFO:
            newState.stripeReceipts = action.payload.receipts;
            newState.stripePlan = action.payload.plan;
            newState.stripeSubId = action.payload.sub_id;
            return newState;
        default:
            return state;
    }
};