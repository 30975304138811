import React from 'react';

import ListHeader from '../ListRows/ListHeader';
import ListMovies from '../ListRows/ListMovies';
import Slider from '../Slider';

import { API_IMAGE } from '../../constants';

export default class RowRender extends React.Component {
    getClassName = display => {
        return `GBListItem ${ display.color || '' } ${ display.effect || '' } ${ display.extraClass || '' }`;
    };

    getStyle = display => {
        return display.effect === 'row-image' && display.imagePath !== '' ?
            { backgroundImage: `url(${API_IMAGE}${ display.imagePath }.jpg&h=720&crop-to-fit&q=40)` }
            :
            {}
        ;
    };

    render() {
        const { globalPlay, isMovieInfoPage, movieListInfo } = this.props;

		if ( movieListInfo && movieListInfo.length > 0 ) {
			movieListInfo.forEach( (item, key) => {
				movieListInfo[ key ].currentDisplay = [ ...item.movies ];
				if ( item.addon && item.addon.length > 0 ) {
					item.addon.forEach( item_ => 
						movieListInfo[ key ].currentDisplay.splice( item_.index, 0, item_ )
					)
				}
			})
		}

        let globalMovieList = [];
        if ( globalPlay ) movieListInfo.forEach( item => item.movies.forEach( el => globalMovieList.push( el ) ));

        return (
            <>
                {
                    movieListInfo && movieListInfo.length > 0 &&
                    movieListInfo.filter( el => el.currentDisplay && el.currentDisplay.length > 0 && ! el.hidden ).map( ( item, index ) =>
                        <div className="list-item" key={ index }>
                            {
                                item.display && item.display.style === 'slider' ?
                                <Slider isInRows={ true } sliderMovies = { [ item ] } />
                                :
                                <div className={ this.getClassName( item.display ) } style={ this.getStyle( item.display ) }>
                                    {
                                        item.display.linked ?
                                        <div className="goTo_box collect_flag" id={ `btn-arrow-${ item.display.id }` } onClick={ () => window.location.href = item.display.href }>
                                            <ListHeader display={ item.display } />
                                        </div>
                                        :
                                        <ListHeader display={ item.display } />
                                    }
                                    <ListMovies
                                        addon={ item.addon }
                                        display={ item.display }
                                        isMovieInfoPage={ isMovieInfoPage }
                                        currentDisplay={ item.currentDisplay }
                                        movieList={ globalMovieList.length > 0 ? globalMovieList : item.movies }
                                        movieListIndex={ item.display.id } />
                                </div>
                            }   
                        </div>
                    )
                }
            </>
        )
    }
}