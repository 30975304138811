import React from 'react';
import { connect } from "react-redux";

import ListRows from "./ListRows";
import MainDescription from "./MainDescription";

import { trainingAction, routingAction, userAction } from "../../redux/actions";
import { getUserInfo } from "../../utils";

class TrainingDetails extends React.Component {
    componentDidMount() {
        const fixParamArr = window.location.toString().split( '/browse/' )[ 1 ].split( '#/formations/' );
		if ( fixParamArr && fixParamArr[ 0 ] === '' ) {
			const fixParam = fixParamArr[ 1 ].split( '?' )[ 0 ];
			window.location.assign( "/browse/?formations=" + fixParam + "/#/formations/" + fixParam );
			return;
		} 

        const { fetchCurrentRoutingByUrl, fetchTrainingDetailsDescription, fetchUserGeoInfo } = this.props;
        const userId = getUserInfo().NoCli;
        fetchCurrentRoutingByUrl( 'formations-details' );
        fetchUserGeoInfo();

        const paramsArr = window.location.toString().split( '/browse/' )[ 1 ].split( '#/formations/' );
		fetchTrainingDetailsDescription( paramsArr[ 1 ].split( '?' )[ 0 ], userId );
  	};

    render() {
        return (
			<div className="homepage-container training-container">
                <MainDescription />
                <ListRows />
			</div>
        )
    }
}

const stateToProps = state => ({});

const dispatchToProps = dispatch => ({
	fetchCurrentRoutingByUrl: url => dispatch(routingAction.fetchCurrentRoutingByUrl( url )),
    fetchTrainingDetailsDescription: ( trainingName, userId ) => dispatch(trainingAction.fetchTrainingDetailsDescription( trainingName, userId )),
    fetchUserGeoInfo: () => dispatch(userAction.fetchUserGeoInfo()),
});

export default connect( stateToProps, dispatchToProps )( TrainingDetails );