import React from "react";

import PMenu from "./PMenu";
import PSectionAboutus from "./PSectionAboutus";
import PSectionAccount from "./PSectionAccount";
import PSectionProfile from "./PSectionProfile";
import PProfileAvatar from "./PSectionProfile/PProfileAvatar";
import PSectionSponsor from "./PSectionSponsor";
import PSponsorStep1 from "./PSectionSponsor/PSponsorStep1";
import PSponsorStep2 from "./PSectionSponsor/PSponsorStep2";
import PSponsorStep3 from "./PSectionSponsor/PSponsorStep3";
import PAccountBilling from "./PSectionAccount/PAccountBilling";
import PAccountEmail from "./PSectionAccount/PAccountEmail";
import PAccountFirstName from "./PSectionAccount/PAccountFirstName";
import PAccountLastName from "./PSectionAccount/PAccountLastName";
import PAccountPassword from "./PSectionAccount/PAccountPassword";
import PAccountSub from "./PSectionAccount/PAccountSub";
import PAccountSubChange from "./PSectionAccount/PAccountSubChange";
import PUnsubStep1 from "./PSectionUnsub/PUnsubStep1";
import PUnsubStep2 from "./PSectionUnsub/PUnsubStep2";
import PUnsubStep3 from "./PSectionUnsub/PUnsubStep3";
import PReactivate from "./PSectionUnsub/PReactivate";
import PSectionOrders from "./PSectionOrders";
import POrdersDetails from "./PSectionOrders/POrdersDetails";
import PSectionComment from "./PSectionComment";

class PRouter extends React.Component {
    constructor(props) {
        super(props);

		this.state = {
			direction: 'right',
			step: 'menu',
		};
    };

	handlePageChange = ( direction, step ) => {
		window.scrollTo( 0, 0 );
		this.setState({ direction: direction, step: step });
	};
    
    render() {
		switch( this.state.step ) {
			case 'menu': return <PMenu handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'aboutus': return <PSectionAboutus handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'account': return <PSectionAccount handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'listBilling': return <PAccountBilling handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'editEmail': return <PAccountEmail handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'editFirstName': return <PAccountFirstName handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'editLastName': return <PAccountLastName handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'editPassword': return <PAccountPassword handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'editSub': return <PAccountSub handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'changeSub': return <PAccountSubChange handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'profile': return <PSectionProfile handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'editAvatar': return <PProfileAvatar handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'sponsor': return <PSectionSponsor handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'sponsorStep1': return <PSponsorStep1 handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'sponsorStep2': return <PSponsorStep2 handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'sponsorStep3': return <PSponsorStep3 handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'unsubStep1': return <PUnsubStep1 handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'unsubStep2': return <PUnsubStep2 handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'unsubStep3': return <PUnsubStep3 handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'reactivate': return <PReactivate handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'orders': return <PSectionOrders handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'ordersCompleted': return <POrdersDetails type='completed' handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'ordersInProgress': return <POrdersDetails type='inProgress' handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			case 'comment': return <PSectionComment handlePageChange={ this.handlePageChange } direction={ this.state.direction }/>;
			default: return <></>;
		}
	};
}

export default PRouter;    