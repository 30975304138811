import { PAYMENT_REDUCER } from '../../constants';
import {
	getCartInfo,
	getProductPaymentInfo,
} from '../../services/payment';

export default {
	fetchCartInfo: token => async dispatch => {
		try {
			const params = `?token=${ token }`;
			const response = await getCartInfo( params );
			const { data } = response;

			if ( data.ret ) {
				dispatch({
					type: PAYMENT_REDUCER.FETCH_CART_INFO,
					payload: data.data
				});
			}
		} catch (err) {
			dispatch({
				type: PAYMENT_REDUCER.API_ERROR,
				payload: "error : payment API"
			});
		}
	},
    fetchCodeId: codeId => dispatch => {
        dispatch( {
            type: PAYMENT_REDUCER.FETCH_CODE_ID,
            payload: codeId
        });
    },
    fetchProductId: productId => dispatch => {
        dispatch( {
            type: PAYMENT_REDUCER.FETCH_PRODUCT_ID,
            payload: productId
        });
    },
    fetchProductType: productType => dispatch => {
        dispatch( {
            type: PAYMENT_REDUCER.FETCH_PRODUCT_TYPE,
            payload: productType
        });
    },
    fetchProductPaymentInfo: ( productId, userId, codeId ) => async dispatch => {
		try {
			const params = codeId ? `?productId=${ productId }&NoCli=${ userId }&codeId=${ codeId }` : `?productId=${ productId }&NoCli=${ userId }`;
			const response = await getProductPaymentInfo( params );
			const { data } = response;

			if ( data.ret ) {
				dispatch({
					type: PAYMENT_REDUCER.FETCH_PRODUCT_PAYMENT_INFO,
					payload: data.data
				});
			}
		} catch (err) {
			dispatch({
				type: PAYMENT_REDUCER.API_ERROR,
				payload: "error : payment API"
			});
		}
	},
};