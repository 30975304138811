import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';

import { ICONS } from "../../constants";
import { t } from "../../utils";

class PSectionOrders extends React.Component {

    render() {
        const { language, userPurchases } = this.props;

        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_nav'>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'menu' ) } }>{ t( 'profile:title', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span>{ t( 'profile:orders', language ) }</span>
                    </div>
                    <div id='profile_body'>
                        {
                            userPurchases &&
                            <>
                                <div
                                    className={ `collect_flag profile_menu ${ userPurchases.inProgress.length > 0 ? 'profile_hover' : '' }` }
                                    id='profile_menu_orders_inProgress'
                                    onClick={ () => { if ( userPurchases.inProgress.length > 0 ) this.props.handlePageChange( 'right', 'ordersInProgress' ) } }>
                                    <div className='profile_scale'>{ t( 'profile:ordersInProgress', language ) } ({ userPurchases.inProgress.length })</div>
                                    {
                                        userPurchases.inProgress.length > 0 &&
                                        <>
                                            <div className='f-grow-1'></div>
                                            <div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
                                        </>
                                    }
                                </div>
                                <div
                                    className={ `collect_flag profile_menu ${ userPurchases.completed.length > 0 ? 'profile_hover' : '' }` }
                                    id='profile_menu_orders_completed'
                                    onClick={ () => { if ( userPurchases.completed.length > 0 ) this.props.handlePageChange( 'right', 'ordersCompleted' ) } }>
                                    <div className='profile_scale'>{ t( 'profile:ordersCompleted', language ) } ({ userPurchases.completed.length })</div>
                                    {
                                        userPurchases.completed.length > 0 &&
                                        <>
                                            <div className='f-grow-1'></div>
                                            <div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
    userPurchases: state.userReducer.userPurchases,
});

export default connect( stateToProps )( PSectionOrders );