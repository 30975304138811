import React from "react";
import { connect }  from "react-redux";

import ButtonClose from "../components/ButtonClose";
import HeaderLogo from "../components/HeaderLogo";

import { POPUP_REDUCER } from "../../../constants";
import { updateUserPaymentStatus } from "../../../services/user";
import { t } from "../../../utils";
 
class PopupUserDiscarded extends React.Component {
    goToChangePaymentPage = () => {
        const { changePaymentToken } = this.props;
        if ( changePaymentToken && changePaymentToken !== '' ) {
            updateUserPaymentStatus( `?token=${ changePaymentToken }&type=discarded` );
            window.location.href = "/modifier-paiement/?session_id=" + changePaymentToken;
        } 
	};

    render() {
        const { language } = this.props;

        return (
            <>
                <HeaderLogo />
                <div className="ils_popup_title">{ t( 'reactivatePayment:title', language ) }</div>
                <div className="ils_popup_text">{ t( 'reactivatePayment:text1', language ) }</div>
                <div className="ils_popup_form">
                    <div className="ils_popup_valid_button" onClick={ this.goToChangePaymentPage }>{ t( 'reactivatePayment:confirm', language ) }</div>
                </div>
                <ButtonClose popupName={ POPUP_REDUCER.POPUP_USER_DISCARDED } />
            </>
        );
    }
}

const stateToProps = state => ({
	changePaymentToken: state.userReducer.changePaymentToken,
    language: state.translatingReducer.language,
});

export default connect( stateToProps )(PopupUserDiscarded);
