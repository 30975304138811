import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';
import Loader from 'react-loader-spinner';

import { ICONS } from "../../constants";
import { userAction } from "../../redux/actions";
import { logEvent } from "../../services/tracking";
import { updateUserAddress } from "../../services/user";
import { getUserInfo, handleLoader, t } from "../../utils";

class SCAddress extends React.Component {
    constructor(props) {
		super(props);

		const { userAddress } = this.props;

        this.state = {
            billingLastName: ( userAddress && userAddress.billingLastName ) ? userAddress.billingLastName : '',
            billingFirstName: ( userAddress && userAddress.billingFirstName ) ? userAddress.billingFirstName : '',
            billingAddress1: ( userAddress && userAddress.billingAddress1 ) ? userAddress.billingAddress1 : '',
            billingAddress2: ( userAddress && userAddress.billingAddress2 ) ? userAddress.billingAddress2 : '',
            billingCity: ( userAddress && userAddress.billingCity ) ? userAddress.billingCity : '',
            billingPostalCode: ( userAddress && userAddress.billingPostalCode ) ? userAddress.billingPostalCode : '',
            billingCountry: ( userAddress && userAddress.billingCountry ) ? userAddress.billingCountry : '',
            deliveryLastName: ( userAddress && userAddress.deliveryLastName ) ? userAddress.deliveryLastName : '',
            deliveryFirstName: ( userAddress && userAddress.deliveryFirstName ) ? userAddress.deliveryFirstName : '',
            deliveryAddress1: ( userAddress && userAddress.deliveryAddress1 ) ? userAddress.deliveryAddress1 : '',
            deliveryAddress2: ( userAddress && userAddress.deliveryAddress2 ) ? userAddress.deliveryAddress2 : '',
            deliveryPostalCode: ( userAddress && userAddress.deliveryPostalCode ) ? userAddress.deliveryPostalCode : '',
            deliveryCity: ( userAddress && userAddress.deliveryCity ) ? userAddress.deliveryCity : '',
            deliveryCountry: ( userAddress && userAddress.deliveryCountry ) ? userAddress.deliveryCountry : '',
            isBillingSameAsDelivery: false,
            phoneNumber: ( userAddress && userAddress.phoneNumber ) ? userAddress.phoneNumber : '',
            errorMessage: '',
        };
	};

    checkErrors = () => {
        const { authorizedDeliveryCountry, language } = this.props;

        if ( this.state.deliveryLastName === '' ) {
            this.setState ({ errorMessage: t( 'store:errorDeliveryLastName', language ) });
            return false;
        }
        if ( this.state.deliveryFirstName === '' ) {
            this.setState ({ errorMessage: t( 'store:errorDeliveryFirstName', language ) });
            return false;
        }
        if ( this.state.deliveryAddress1 === '' ) {
            this.setState ({ errorMessage: t( 'store:errorDeliveryAddress', language ) });
            return false;
        }
        if ( this.state.deliveryPostalCode === '' ) {
            this.setState ({ errorMessage: t( 'store:errorDeliveryPostalCode', language ) });
            return false;
        }
        if ( this.state.deliveryCity === '' ) {
            this.setState ({ errorMessage: t( 'store:errorDeliveryCity', language ) });
            return false;
        }
        if ( this.state.deliveryCountry === '' ) {
            this.setState ({ errorMessage: t( 'store:errorDeliveryCountry', language ) });
            return false;
        }
        if ( this.state.billingLastName === '' ) {
            this.setState ({ errorMessage: t( 'store:errorBillingLastName', language ) });
            return false;
        }
        if ( this.state.billingFirstName === '' ) {
            this.setState ({ errorMessage: t( 'store:errorBillingFirstName', language ) });
            return false;
        }
        if ( this.state.billingAddress1 === '' ) {
            this.setState ({ errorMessage: t( 'store:errorBillingAddress', language ) });
            return false;
        }
        if ( this.state.billingPostalCode === '' ) {
            this.setState ({ errorMessage: t( 'store:errorBillingPostalCode', language ) });
            return false;
        }
        if ( this.state.billingCity === '' ) {
            this.setState ({ errorMessage: t( 'store:errorBillingCity', language ) });
            return false;
        }
        if ( this.state.billingCountry === '' ) {
            this.setState ({ errorMessage: t( 'store:errorBillingCountry', language ) });
            return false;
        }
        if ( this.state.phoneNumber === '' ) {
            this.setState ({ errorMessage: t( 'form:phoneError', language ) });
            return false;
        }
        if ( ! authorizedDeliveryCountry.includes( this.state.deliveryCountry.toLowerCase() ) ) {
            logEvent({ NoCli: getUserInfo().NoCli, event: 'store-unauthorized-country', eventDetails: `unauthorized delivery country : ${ this.state.deliveryCountry }` });
            this.setState ({ errorMessage: t( 'store:errorCountryUnavailable', language ) });
            return false;
        }

        this.setState ({ errorMessage: '' });
        return true;
    };

    handleChange = ( event ) => { 
        const value = event.target.value;
        const name = event.target.name;
        this.setState ( { [name]: value, successMessage: '' } );
    };

    handleChangeCheckbox = () => {
        const { isBillingSameAsDelivery } = this.state;

        if ( ! isBillingSameAsDelivery ) {
            const {
                deliveryLastName,
                deliveryFirstName,
                deliveryAddress1,
                deliveryAddress2,
                deliveryCity,
                deliveryPostalCode,
                deliveryCountry,
            } = this.state;

            this.setState( {
                billingLastName: deliveryLastName,
                billingFirstName: deliveryFirstName,
                billingAddress1: deliveryAddress1,
                billingAddress2: deliveryAddress2,
                billingCity: deliveryCity,
                billingPostalCode: deliveryPostalCode,
                billingCountry: deliveryCountry,
                isBillingSameAsDelivery: ! isBillingSameAsDelivery,
            });
        } else {
            this.setState( {
                billingLastName: '',
                billingFirstName: '',
                billingAddress1: '',
                billingAddress2: '',
                billingCity: '',
                billingPostalCode: '',
                billingCountry: '',
                isBillingSameAsDelivery: ! isBillingSameAsDelivery,
            });
        }

        
    };

    handleSubmit = () => {
        const { fetchUserAddress, language } = this.props;
        if ( this.checkErrors() ) {
            handleLoader( 'profile_loader', 'add' );
            const data = {
                NoCli: getUserInfo().NoCli,
                billingLastName: this.state.billingLastName,
                billingFirstName: this.state.billingFirstName,
                billingAddress1: this.state.billingAddress1,
                billingAddress2: this.state.billingAddress2,
                billingPostalCode: this.state.billingPostalCode,
                billingCity: this.state.billingCity,
                billingCountry: this.state.billingCountry,
                deliveryLastName: this.state.deliveryLastName,
                deliveryFirstName: this.state.deliveryFirstName,
                deliveryAddress1: this.state.deliveryAddress1,
                deliveryAddress2: this.state.deliveryAddress2,
                deliveryPostalCode: this.state.deliveryPostalCode,
                deliveryCity: this.state.deliveryCity,
                deliveryCountry: this.state.deliveryCountry,
                phoneNumber: this.state.phoneNumber,
            };
            updateUserAddress( data )
                .then( res => {
                    if ( ! res.ret ) {
                        this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                    } else {
                        fetchUserAddress( getUserInfo().NoCli );
                        this.props.handlePageChange( 'right', 'payment' );
                    }
                    handleLoader( 'profile_loader', 'remove' );
                })
                .catch( error => {
                    console.log( error );
                    this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                    handleLoader( 'profile_loader', 'remove' );
                });
        }
    };

    render() {
        const { language, handlePageChange } = this.props;

        const $content =
            <div id='profile_container'>
                <div id='profile_loader'><Loader type='TailSpin' color='#fba00b' height={100} width={100} /></div>
                <div id='profile_nav'>
                        <span className='profile_nav' onClick={ () => { handlePageChange( 'left', 'validation' ) } }>{ t( 'nav:cart', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span>{ t( 'nav:address', language ) }</span>
                </div>
                <div className='profile_box_center'>
                    <div className='profile_header_title'>
                        <h1>{ t( 'profile:personalInfo', language ) }</h1>
                    </div>
                    <div className='profile_edit_usual'>
                        <div className='profile_edit_usual_form'>
                            <div className='address_title'>
                                <h2>{ t( 'store:deliveryAddress', language ) }</h2>
                            </div>
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='deliveryLastName'>{ t( 'form:lastName', language ) }</label>
                                <input
                                    type='text'
                                    name='deliveryLastName'
                                    value={ this.state.deliveryLastName }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                            </div> 
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='deliveryFirstName'>{ t( 'form:firstName', language ) }</label>
                                <input
                                    type='text'
                                    name='deliveryFirstName'
                                    value={ this.state.deliveryFirstName }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                            </div> 
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='deliveryAddress1'>{ t( 'form:address1', language ) }</label>
                                <input
                                    type='text'
                                    name='deliveryAddress1'
                                    value={ this.state.deliveryAddress1 }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                                <div className='profile_edit_usual_input_info'>{ t( 'form:address1Info', language ) }</div>    
                            </div> 
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='deliveryAddress2'>{ t( 'form:address2', language ) }</label>
                                <input
                                    type='text'
                                    name='deliveryAddress2'
                                    value={ this.state.deliveryAddress2 }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                            </div> 
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='deliveryPostalCode'>{ t( 'form:postalCode', language ) }</label>
                                <input
                                    type='text'
                                    name='deliveryPostalCode'
                                    value={ this.state.deliveryPostalCode }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                            </div> 
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='deliveryCity'>{ t( 'form:city', language ) }</label>
                                <input
                                    type='text'
                                    name='deliveryCity'
                                    value={ this.state.deliveryCity }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                            </div> 
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='deliveryCountry'>{ t( 'form:country', language ) }</label>
                                <input
                                    type='text'
                                    name='deliveryCountry'
                                    value={ this.state.deliveryCountry }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                            </div> 
                            <div className='profile_edit_usual_input'>
                                <label htmlFor='phoneNumber'>{ t( 'form:phone', language ) }</label>
                                <input
                                    type='tel'
                                    name='phoneNumber'
                                    value={ this.state.phoneNumber }
                                    onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                    onChange={ event => this.handleChange( event ) }
                                    />
                            </div>
                            <div className='address_title'>
                                <h2>{ t( 'store:billingAddress', language ) }</h2>
                            </div>
                            <div className="address_checkbox_container">
                                <div className="address_checkbox">
                                    <input
                                        type='checkbox'
                                        name='ageValid'
                                        onChange={ () => {} }
                                        checked={ this.state.isBillingSameAsDelivery } /> 
                                    <span  onClick={ this.handleChangeCheckbox } />
                                </div>
                                <div className="address_checkbox_label">
                                    { t( 'store:sameAddress', language ) }
                                </div>    
                            </div>
                            <div className={ this.state.isBillingSameAsDelivery ? 'inputs_disabled' : '' }>
                                <div className='profile_edit_usual_input'>
                                    <label htmlFor='billingLastName'>{ t( 'form:lastName', language ) }</label>
                                    <input
                                        type='text'
                                        name='billingLastName'
                                        value={ this.state.billingLastName }
                                        onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                        onChange={ event => this.handleChange( event ) }
                                        />
                                </div> 
                                <div className='profile_edit_usual_input'>
                                    <label htmlFor='billingFirstName'>{ t( 'form:firstName', language ) }</label>
                                    <input
                                        type='text'
                                        name='billingFirstName'
                                        value={ this.state.billingFirstName }
                                        onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                        onChange={ event => this.handleChange( event ) }
                                        />
                                </div> 
                                <div className='profile_edit_usual_input'>
                                    <label htmlFor='billingAddress1'>{ t( 'form:address1', language ) }</label>
                                    <input
                                        type='text'
                                        name='billingAddress1'
                                        value={ this.state.billingAddress1 }
                                        onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                        onChange={ event => this.handleChange( event ) }
                                        />
                                    <div className='profile_edit_usual_input_info'>{ t( 'form:address1Info', language ) }</div>    
                                </div> 
                                <div className='profile_edit_usual_input'>
                                    <label htmlFor='billingAddress2'>{ t( 'form:address2', language ) }</label>
                                    <input
                                        type='text'
                                        name='billingAddress2'
                                        value={ this.state.billingAddress2 }
                                        onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                        onChange={ event => this.handleChange( event ) }
                                        />
                                </div> 
                                <div className='profile_edit_usual_input'>
                                    <label htmlFor='billingPostalCode'>{ t( 'form:postalCode', language ) }</label>
                                    <input
                                        type='text'
                                        name='billingPostalCode'
                                        value={ this.state.billingPostalCode }
                                        onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                        onChange={ event => this.handleChange( event ) }
                                        />
                                </div> 
                                <div className='profile_edit_usual_input'>
                                    <label htmlFor='billingCity'>{ t( 'form:city', language ) }</label>
                                    <input
                                        type='text'
                                        name='billingCity'
                                        value={ this.state.billingCity }
                                        onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                        onChange={ event => this.handleChange( event ) }
                                        />
                                </div> 
                                <div className='profile_edit_usual_input'>
                                    <label htmlFor='billingCountry'>{ t( 'form:country', language ) }</label>
                                    <input
                                        type='text'
                                        name='billingCountry'
                                        value={ this.state.billingCountry }
                                        onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                        onChange={ event => this.handleChange( event ) }
                                        />
                                </div>
                            </div>  
                        </div>
                        <div className='profile_edit_usual_error'>
                            { this.state.errorMessage }
                        </div>
                        <div className='profile_edit_usual_btn'>
                            <button className='collect_flag' id='edit_email_submit_btn' onClick={ this.handleSubmit }>
                                { t( 'shared:continue', language ) }
                            </button>
                        </div>
                    </div>
                </div>
            </div>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    authorizedDeliveryCountry: state.userReducer.authorizedDeliveryCountry,
    language: state.translatingReducer.language,
    userAddress: state.userReducer.userAddress,
});

const dispatchToProps = dispatch => ({
    fetchUserAddress: userId => dispatch( userAction.fetchUserAddress( userId )),
});

export default connect( stateToProps, dispatchToProps )( SCAddress );