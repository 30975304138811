import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';
import Loader from 'react-loader-spinner';

import { ICONS } from "../../../constants";
import { userAction } from "../../../redux/actions";
import { updateFirstName } from "../../../services/user";
import { handleLoader, t } from "../../../utils";

class PAccountFirstName extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            errorMessage: '',
            password: '',
            successMessage : '',
        };
    };

    checkErrors = () => {
        const { language } = this.props;

        if ( this.state.firstName === '' ) {
            this.setState ({ errorMessage: t( 'form:firstNameError', language ) });
            return false;
        }
        if ( this.state.password === '' ) {
            this.setState ({ errorMessage: t( 'form:passwordEmpty', language ) });
            return false;
        }
        this.setState ( { errorMessage: '' } );
        return true;
    };

    handleChange = ( event ) => { 
        const value = event.target.value;
        const name = event.target.name;
        this.setState ( { [name]: value, successMessage: '' } );
    };

    handleSubmit = () => {
        const { fetchUserInfo, language, userInfoDB } = this.props;
        if ( this.checkErrors() ) {
            handleLoader( 'profile_loader', 'add' );
            const data = {
                firstName: this.state.firstName,
                NoCli: userInfoDB.NoCli,
                password: this.state.password
            };
            updateFirstName( data )
                .then( res => {
                    if ( ! res.ret ) {
                        switch( res.error ) {
                            case 'authentication':
                                this.setState ({ errorMessage: t( 'form:passwordError', language ) });
                                break;
                            default:
                                this.setState ({ errorMessage: t( 'shared:genericError', language ) });       
                        }
                    } else {
                        fetchUserInfo( userInfoDB.NoCli );
                        this.setState( {
                            firstName: '',
                            errorMessage: '',
                            password: '',
                            successMessage : t( 'form:editSuccess', language ),
                        });
                    }
                    handleLoader( 'profile_loader', 'remove' );
                })
                .catch( error => {
                    console.log( error );
                    this.setState ( { errorMessage: t( 'shared:genericError', language ) } );
                    handleLoader( 'profile_loader', 'remove' );
                });
        }
    };
    
    render() {
        const { language, userInfoDB } = this.props;

        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_loader'><Loader type='TailSpin' color='#fba00b' height={100} width={100} /></div>
                    <div id='profile_nav'>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'menu' ) } }>{ t( 'profile:title', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'profile' ) } }>{ t( 'profile:profile', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span>{ t( 'profile:editFirstName', language ) }</span>
                    </div>
                    <div className='profile_box_center'>
                        <div id='profile_header' className='profile_header_title'>
                            <h1>{ t( 'profile:editFirstName', language ) }</h1>
                        </div>
                        <div className='profile_edit_usual_success'>
                            { this.state.successMessage }
                        </div>
                        <div className='profile_edit_usual'>
                            <div className='profile_edit_usual_current'>
                                <div>{ t( 'form:firstName', language ) } :</div>
                                <div>{ userInfoDB.Prenom }</div>
                            </div>
                            <div className='profile_edit_usual_form'>
                                <div className='profile_edit_usual_input'>
                                    <label htmlFor='firstName'>{ t( 'form:firstName', language ) }</label>
                                    <input
                                        type='text'
                                        name='firstName'
                                        value={ this.state.firstName }
                                        onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                        onChange={ event => this.handleChange( event ) }
                                        />
                                </div> 
                                <div className='profile_edit_usual_input'>
                                    <label htmlFor='password'>{ t( 'form:password', language ) }</label>
                                    <input
                                        type='password'
                                        name='password'
                                        value={ this.state.password }
                                        onKeyDown={ event => { if (event.keyCode === 13) this.handleSubmit(); }}
                                        onChange={ event => this.handleChange( event ) }
                                        />
                                </div> 
                            </div>
                            <div className='profile_edit_usual_error'>
                                { this.state.errorMessage }
                            </div>
                            <div className='profile_edit_usual_btn'>
                                <button className='collect_flag' id='edit_firstName_submit_btn' onClick={ this.handleSubmit }>
                                    { t( 'form:saveUpdate', language ) }
                                </button>
                            </div>
                        </div>
                    </div>
				</div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
	userInfoDB: state.userReducer.userInfoDB,
});

const dispatchToProps = dispatch => ({
	fetchUserInfo: userId => dispatch(userAction.fetchUserInfo( userId )),
});

export default connect( stateToProps, dispatchToProps )( PAccountFirstName );