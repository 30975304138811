import React from 'react';

export default class InfinteCarrouselReRender extends React.Component {
    renderNumber = 1;

    render() {
        const { children, condition = false } = this.props;
        let carrouselToDisplay1 = <></>;        
        let carrouselToDisplay2 = children;  

        this.renderNumber += 1;
        if( this.renderNumber % 2 === 0 && condition ) {
            carrouselToDisplay1 = children;        
            carrouselToDisplay2 = <></>;
        }
        
        return (
            <>
                { carrouselToDisplay1 }
                { carrouselToDisplay2 }
            </>
        )
    }
}
