import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';
import md5 from 'md5';

import { ICONS } from "../../constants";
import { userAction } from '../../redux/actions';
import { t } from "../../utils";

class PSectionSponsor extends React.Component {
    constructor(props) {
        super(props);

        const { language, userInfoDB } = this.props;
        let linkId = '';
        if ( userInfoDB.NoCli ) linkId = md5( userInfoDB.NoCli );

        this.state = {
            copyBtnText: t( 'partner:copyLink', language ),
            link: 'https://www.zone300.com/?utm_campaign='+linkId+'&utm_source=affiliation&utm_medium=link',
        };
    };

    componentDidMount() {
        const { fetchUserPartnerInfo, userInfoDB } = this.props;
        fetchUserPartnerInfo( userInfoDB.NoCli );
  	};

    copyLink = () => {
        const { language } = this.props;
        const $link = document.getElementById( 'profile_link' );
        if ( $link ) {
            $link.select();
            document.execCommand( 'copy' );
            this.setState({ copyBtnText: t( 'partner:copiedLink', language ) });
        } 
    };
    
    render() {
        const { language, userPartnerInfoDB } = this.props;

        let $content = <></>;
        if ( userPartnerInfoDB && userPartnerInfoDB.step1Completed === 1 && userPartnerInfoDB.step2Completed === 1 ) {
            const giftsArr = [
                [ t( 'partner:tshirt', language ), 5 ],
                [ t( 'partner:cap', language ), 10 ],
                [ t( 'partner:sweat', language ), 50 ],
                [ t( 'partner:sweat', language ), 100 ],
                [ t( 'partner:permit', language ), 200 ],
            ];
            const subCount = userPartnerInfoDB.compteurAbonnement;

            let progressDeg = 0;
            let nextGiftIndex = 0;

            for ( let i = 0; i < giftsArr.length; i++ ) {
                if ( subCount < giftsArr[ i ][ 1 ] ) {
                    progressDeg = Math.floor( ( subCount * 360 ) / giftsArr[ i ][ 1 ]  );
                    nextGiftIndex = i;
                    break;  
                }
            }

            const progressMessage = `${ subCount } ${ t( 'shared:on', language ) } ${ giftsArr[ nextGiftIndex ][ 1 ] }`;
            let previousGiftMessage = '';
            if ( nextGiftIndex > 0 ) previousGiftMessage = `${ t( 'partner:giftPreviousPart1', language ) }${giftsArr[ nextGiftIndex - 1 ][ 1 ]}${ t( 'partner:giftPreviousPart2', language ) }${ giftsArr[ nextGiftIndex - 1 ][ 0 ] }`;

            const leftSub = giftsArr[ nextGiftIndex ][ 1 ] - subCount;
            const plural = leftSub > 1 ? 's' : '';
            const nextGiftMessage = `${ t( 'partner:giftNextPart1', language ) }${ leftSub }${ t( 'partner:giftNextPart2', language ) }${ plural }${ t( 'partner:giftNextPart3', language ) }${ giftsArr[ nextGiftIndex ][ 0 ] }`;

            let degLeft = 0;
            let degRight = 0;
            if ( progressDeg > 180 ) {
                degRight = 180;
                degLeft = progressDeg - 180;
            } else {
                degRight = progressDeg;
                degLeft = 0;
            }

            const keyFramesLeft = `
                @keyframes loading-l {
                    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
                    100% { -webkit-transform: rotate(${ degLeft }deg); transform: rotate(${ degLeft }deg); }
                }
            `;

            const keyFramesRight = `
                @keyframes loading-r {
                    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
                    100% { -webkit-transform: rotate(${ degRight }deg); transform: rotate(${ degRight }deg); }
                }
            `;

            const styleElement = document.createElement('style');
            let styleSheet = null;
            document.head.appendChild(styleElement);
            styleSheet = styleElement.sheet;
            styleSheet.insertRule( keyFramesLeft, styleSheet.cssRules.length );
            styleSheet.insertRule( keyFramesRight, styleSheet.cssRules.length );

            $content =
                <>
                    <div id='profile_container'>
                        <div id='profile_nav'>
                            <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'menu' ) } }>{ t( 'profile:title', language ) }</span>
                            <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                            <span>{ t( 'profile:partner', language ) }</span>
                        </div>
                        <div id='profile_header' className='profile_header_title'>
                            <div><img className='profile_icon title_icon' src={ ICONS.gift } alt='icone cadeau'/></div>
                            <h1>{ t( 'profile:partner', language ) }</h1>
                        </div>
                        <div className='profile_card full'>
                            <div className='profile_card_title'>{ t( 'partner:link', language ) }</div>
                            <div className='profile_card_small text-center'>{ t( 'partner:shareLink', language ) }</div>
                            <div className='profile_card_button'>
                                <input className='profile_link_full' id='profile_link' spellCheck="false" value={ this.state.link } readOnly />
                                <button className='collect_flag' id='profile_link_dashboard' onClick={ this.copyLink }>
                                    { this.state.copyBtnText }
                                </button>
                            </div>
                            <div className='profile_card_button'>
                                <button className='gray' onClick={ () => { this.props.handlePageChange( 'right', 'sponsorStep1' ) } }>
                                    { t( 'profile:editInfo', language ) }
                                </button>
                            </div>
                        </div>
                        <div className='profile_card_box'>
                            <div className='profile_card half'>
                                <div className='profile_card_title'>{ t( 'partner:succeeded', language ) }</div>
                                <div className='profile_card_small'>{ t( 'partner:succeededTotal', language ) } : { subCount }</div>
                                <div className='profile_card_small'>{ previousGiftMessage }</div>
                                <div className="progress orange">
                                    <span className="progress-left">
                                        <span className="progress-bar"></span>
                                    </span>
                                    <span className="progress-right">
                                        <span className="progress-bar" ></span>
                                    </span>
                                    <div className="progress-value">{ progressMessage }</div>
                                </div>
                                <div className='profile_card_small text-center special-margin'>{ nextGiftMessage }</div>     
                            </div>
                            <div className='profile_card half'>
                                <div className='profile_card_title'>{ t( 'partner:info1', language ) }</div>
                                <div className='profile_card_small'>{ t( 'partner:info2', language ) }</div>
                                <div className='profile_card_small'>{ t( 'partner:info3', language ) }</div>
                                <div className='profile_card_small'>{ t( 'partner:info4', language ) }</div>
                                <div className='profile_card_small'>{ t( 'partner:info5', language ) }</div>
                            </div>
                        </div>
                        <div className='profile_card full'>
                            <div className='profile_card_small'>{ t( 'partner:listGift', language ) }</div>
                            <div className='profile_card_list first'>
                                <div>5 { t( 'partner:info1', language ) }</div>
                                <div className='f-grow-1'></div>
                                <div>{ t( 'partner:tshirtGift', language ) }</div>
                            </div>
                            <div className='profile_card_list'>
                                <div>10 { t( 'partner:subscriptions', language ) }</div>
                                <div className='f-grow-1'></div>
                                <div>{ t( 'partner:capGift', language ) }</div>
                            </div>
                            <div className='profile_card_list'>
                                <div>50 { t( 'partner:subscriptions', language ) }</div>
                                <div className='f-grow-1'></div>
                                <div>{ t( 'partner:sweatGift1', language ) }</div>
                            </div>
                            <div className='profile_card_list'>
                                <div>100 { t( 'partner:subscriptions', language ) }</div>
                                <div className='f-grow-1'></div>
                                <div>{ t( 'partner:sweatGift2', language ) }</div>
                            </div>
                            <div className='profile_card_list last'>
                                <div>200 { t( 'partner:subscriptions', language ) }</div>
                                <div className='f-grow-1'></div>
                                <div>{ t( 'partner:permitGift', language ) }</div>
                            </div>
                            <div className='profile_card_tall text-right'>{ t( 'partner:moreGift', language ) }</div>
                        </div>
                    </div>
                </>;
        } else {
            $content =
                <>
                    <div id='profile_container'>
                        <div id='profile_nav'>
                            <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'menu' ) } }>{ t( 'profile:title', language ) }</span>
                            <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                            <span>{ t( 'profile:partner', language ) }</span>
                        </div>
                        <div id='profile_header' className='profile_header_title'>
                            <div><img className='profile_icon title_icon' src={ ICONS.gift } alt='icone cadeau'/></div>
                            <h1>{ t( 'profile:partner', language ) }</h1>
                        </div>
                        <div className='profile_card_box'>
                            <div className='profile_card half'>
                                <div className='profile_card_title'>{ t( 'partner:intro1', language ) }</div>
                                <div className='profile_card_tall text-center'>{ t( 'partner:info1', language ) }</div>
                                <div className='profile_card_small text-center'>{ t( 'partner:shareLink', language ) }</div>
                                <div className='profile_card_button'>
                                    <button onClick={ () => { this.props.handlePageChange( 'right', 'sponsorStep1' ) } }>
                                        { t( 'partner:intro2', language ) }
                                    </button>
                                </div>
                            </div>
                            <div className='profile_card half'>
                                <div className='profile_card_title'>{ t( 'partner:info1', language ) }</div>
                                <div className='profile_card_small'>{ t( 'partner:info2', language ) }</div>
                                <div className='profile_card_small'>{ t( 'partner:info3', language ) }</div>
                                <div className='profile_card_small'>{ t( 'partner:info4', language ) }</div>
                                <div className='profile_card_small'>{ t( 'partner:info5', language ) }</div>
                            </div>
                        </div>
                        <div className='profile_card full'>
                            <div className='profile_card_small'>{ t( 'partner:listGift', language ) }</div>
                            <div className='profile_card_list first'>
                                <div>5 { t( 'partner:subscriptions', language ) }</div>
                                <div className='f-grow-1'></div>
                                <div>{ t( 'partner:tshirtGift', language ) }</div>
                            </div>
                            <div className='profile_card_list'>
                                <div>10 { t( 'partner:subscriptions', language ) }</div>
                                <div className='f-grow-1'></div>
                                <div>{ t( 'partner:capGift', language ) }</div>
                            </div>
                            <div className='profile_card_list'>
                                <div>50 { t( 'partner:subscriptions', language ) }</div>
                                <div className='f-grow-1'></div>
                                <div>{ t( 'partner:sweatGift1', language ) }</div>
                            </div>
                            <div className='profile_card_list'>
                                <div>100 { t( 'partner:subscriptions', language ) }</div>
                                <div className='f-grow-1'></div>
                                <div>{ t( 'partner:sweatGift2', language ) }</div>
                            </div>
                            <div className='profile_card_list last'>
                                <div>200 { t( 'partner:subscriptions', language ) }</div>
                                <div className='f-grow-1'></div>
                                <div>{ t( 'partner:permitGift', language ) }</div>
                            </div>
                            <div className='profile_card_tall text-right'>{ t( 'partner:moreGift', language ) }</div>
                        </div>
                    </div>
                </>;
        }

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
	userInfoDB: state.userReducer.userInfoDB,
	userPartnerInfoDB: state.userReducer.userPartnerInfoDB,
});

const dispatchToProps = dispatch => ({
	fetchUserPartnerInfo: userId => dispatch( userAction.fetchUserPartnerInfo( userId )),
});

export default connect( stateToProps, dispatchToProps )( PSectionSponsor );