import React from "react";
import { connect } from "react-redux";

import { API_IMAGE, ICONS } from "../../constants";
import { t } from "../../utils";

class ListHeader extends React.Component {
    renderTitle = () => {
		const { display, language } = this.props;
        
        return (
            <>
                {
                    display.preIcon === 'dot-live' && 
                    <span className='icon_box'>
                        <span className='dot live'></span>
                    </span>
                }
                {
                    display.prePngFileName ?
                    <span className="list-title-prepng">
                        <img 
                            alt={ `logo ${ display.name }` }
                            src={ `${ API_IMAGE }${ display.prePngFileName }.png&h=30&crop-to-fit` }
                            onError={ e => e.target.style.display = 'none' } />
                    </span>
                    :
                    <>{ display.preName && <span className="list-title-prename">{ display.preName }</span> }</>
                }
                {
                    display.pngFileName ?
                    <span className="list-title-png">
                        <img 
                            alt={ display.name }
                            src={ `${ API_IMAGE }${ display.pngFileName }.png&h=30&crop-to-fit` }
                            onError={ e => e.target.style.display = 'none' } />
                    </span>
                    :
                    <>{ display[ t( 'keys:name', language ) ] ? display[ t( 'keys:name', language ) ] : display.name }</>
                }
            </>
        );
	};

    render() {
        const { display, isInRows, isMozaicPage, language } = this.props;

        return (
            <div className={ `list-title ${ isInRows ? 'in-rows' : '' }` }>
                <h3>
                    { this.renderTitle() }
                    {
                        ! isMozaicPage && display.linked &&
                        <>
                            <span className="list-infoText-box">
                                <span className="list-infoText">{ t( 'shared:explore', language ) }</span>
                            </span>
                            <img className="list-infoIcon" src={ ICONS.backArrow } alt='icone suivant'/>
                        </>
                    }
                </h3>
            </div>
        ); 
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
});

export default connect( stateToProps )( ListHeader );