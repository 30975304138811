import React from "react";
import { connect }  from "react-redux";

import ButtonClose from "../components/ButtonClose";
import HeaderLogo from "../components/HeaderLogo";

import { API_IMAGE, ICONS, POPUP_REDUCER } from "../../../constants";
import { popupAction } from "../../../redux/actions";
import { t } from "../../../utils";

class PopupPaymentSuccess extends React.Component {
    render() {
        const { language, popupClose, productPaymentInfo } = this.props;

        return (
            <>
                <HeaderLogo />
                <div className="popup-payment-success-box">
                    <div className="popup-payment-success-image">
                        <img src={ ICONS.check } alt='icone succes'/>
                    </div>
                    <div className="popup-payment-success-title">
                        { t( 'payment:successTitle', language ) }
                        
                    </div>
                    <div className="popup-payment-success-thumbnail">
                        <img 
                            alt={ productPaymentInfo.alt }
                            src={ `${ API_IMAGE }${ productPaymentInfo.thumbnailFileName }.jpg&h=720&crop-to-fit` }
                            onError={ e => e.target.style.display = 'none' } />
                    </div>
                    <div className="popup-payment-success-text">
                        { t( 'payment:successInfo', language ) }
                    </div>
                    <div className="popup-payment-valid-box">
                        <div className="popup-payment-valid" onClick={ () => popupClose( POPUP_REDUCER.POPUP_PAYMENT_SUCCESS ) }>
                            { t( 'payment:successTrainingConfirm', language ) }
                        </div>
                        <div className="popup-payment-cancel" onClick={ () => window.location.href='/browse/#/' }>
                            { t( 'shared:backPlatform', language ) }
                        </div>
                    </div>
                    <ButtonClose popupName={ POPUP_REDUCER.POPUP_PAYMENT_SUCCESS } />
                </div>
            </>
        );
    }
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
    productPaymentInfo: state.paymentReducer.productPaymentInfo,
});

const dispatchToProps = dispatch => ({
    popupClose: popupName => dispatch(popupAction.popupClose( popupName )),
});

export default connect(stateToProps, dispatchToProps)(PopupPaymentSuccess);
