import { combineReducers } from "redux";

import commentReducer from "./comment.reducer";
import moviesReducer from "./movies.reducer";
import playlistReducer from "./playlist.reducer";
import playerReducer from "./player.reducer";
import paymentReducer from "./payment.reducer";
import popupReducer from "./popup.reducer";
import routingReducer from "./routing.reducer";
import storeReducer from "./store.reducer";
import trainingReducer from "./training.reducer";
import translatingReducer from "./translating.reducer";
import userReducer from "./user.reducer";

const rootReducer = combineReducers({
	commentReducer,
	
	moviesReducer,
	paymentReducer,
	playlistReducer,
	playerReducer,
	popupReducer,
	routingReducer,
	storeReducer,
	trainingReducer,
	translatingReducer,
	userReducer,
});

export default rootReducer;