import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';

import { API_IMAGE, ICONS, IMAGES } from "../../constants";
import { t } from "../../utils";

class PSectionProfile extends React.Component {
    render() {
        const { language, userInfoDB } = this.props;
        
        const avatar = ( userInfoDB && userInfoDB.avatar && userInfoDB.avatar !== '' ) ? `${ API_IMAGE }${ userInfoDB.avatar }.jpg` : IMAGES.avatarDefault;
        
        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_nav'>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'menu' ) } }>{ t( 'profile:title', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span>{ t( 'profile:editProfile', language ) }</span>
                    </div>
                    <div className='profile_box_center'>
                        <div id='profile_header'  className='profile_header_title'>
                            <h1>{ t( 'profile:editProfile', language ) }</h1>
                        </div>
                    </div>
                    <div className='profile_edit_usual'>
                        <div className='profile_edit_usual_current'>
                            <div className='user-avatar-box'>
                                <div className='avatar_box'>
                                    <img className='user-avatar' src={ avatar } alt='avatar' />
                                    <div className='addon_icon_box' onClick={ () => { this.props.handlePageChange( 'right', 'editAvatar' ) } }>
                                        <img className='profile_icon addon_icon' src={ ICONS.pen  } alt='icone crayon'/>
                                    </div>
                                </div> 
                                <span className='user-avatar-first_name'>{ userInfoDB.Prenom }</span>
                            </div>
                        </div>
                    </div>
                    <div id='profile_body'>
                        <div className='profile_menu_title'>
                            <div>{ t( 'profile:personalProfile', language ) }</div>
                        </div>
                        <div className='collect_flag profile_menu_small profile_hover' id='profile_menu_last_name' onClick={ () => { this.props.handlePageChange( 'right', 'editLastName' ) } }>
							<div className='profile_scale'>{ t( 'form:lastName', language ) } : { userInfoDB.Nom }</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</div>
						<div className='collect_flag profile_menu_small profile_hover' id='profile_menu_edit_first_name' onClick={ () => { this.props.handlePageChange( 'right', 'editFirstName' ) } }>
							<div className='profile_scale'>{ t( 'form:firstName', language ) } : { userInfoDB.Prenom }</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</div>
                        <div className='profile_menu_title'>
                            <div>{ t( 'profile:preferences', language ) }</div>
                        </div>
                        <a className='collect_flag profile_menu_small profile_hover' id='profile_menu_facebook' href='/browse/?preferences/#/preferences'>
                            <div className='profile_scale'>{ t( 'profile:personalize', language ) }</div>
                            <div className='f-grow-1'></div>
                            <div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
                        </a>
                    </div>
                    <div className='profile_edit_usual'>
                        <div className='profile_edit_usual_btn'>
                            <button className='collect_flag' id='edit_email_submit_btn' onClick={ () => { this.props.handlePageChange( 'left', 'menu' ) } }>
                                { t( 'shared:save', language ) }
                            </button>
                        </div>
                    </div>
				</div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
	userInfoDB: state.userReducer.userInfoDB,
});

export default connect( stateToProps )( PSectionProfile );