import React, { useState } from "react";

import { useStripe } from "@stripe/react-stripe-js";

import { ICONS } from "../../constants";
import { stripeCreateStoreIntent, stripeTreatStoreSuccess } from "../../services/store";
import { activeCampaignEvent, gtmEvent } from "../../services/tracking";
import { handleLoader, t } from "../../utils";

const SCStripeForm = ( props ) => {
    const [succeeded, setSucceeded] = useState(null);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const stripe = useStripe();
    const language = props.language;

    const paymentSubmit = async ( event ) => {
        setProcessing( true );
        handleLoader( 'profile_loader', 'add' );

        let data = { currency: props.currency, NoCli: props.userId, cart: props.cartStg, isWithDefaultDiscount: props.isWithDefaultDiscount };
        if ( props.codeId ) data.codeId = props.codeId;

        stripeCreateStoreIntent( data )
            .then( res => {
                if ( ! res.ret ) {
                    switch( res.data ) {
                        case 'user unknown':
                            setError( t( 'form:userUnknown', language ) );
                            break;
                        case 'product unknown':
                            setError( t( 'form:productUnknown', language ) );
                            break;
                        default:
                            setError( t( 'shared:genericError', language ) );     
                    }
                    setProcessing( false );
                    handleLoader( 'profile_loader', 'remove' );
                    return;
                }

                if ( res.data.purchaseId ) data.purchaseId = res.data.purchaseId;

                stripe.confirmCardPayment( res.data.clientSecret, { payment_method: props.stripeCard.source } )
                    .then( res2 => {
                        if ( res2.error ) {
                            setError( `${ t( 'payment:error', language ) } : ${ res2.error.message }` );
                            setProcessing( false );
                            handleLoader( 'profile_loader', 'remove' );
                        } else {
                            if ( res2.paymentIntent ) {
                                if ( res2.paymentIntent.id ) data.paymentId = res2.paymentIntent.id;
                                if ( res2.paymentIntent.amount ) data.amount = res2.paymentIntent.amount;
                                if ( res2.paymentIntent.currency ) data.currency = res2.paymentIntent.currency;
                            }
                            
                            stripeTreatStoreSuccess( data )
                                .then( res3 => {
                                    if ( ! res3.ret ) {
                                        setError( t( 'shared:genericError', language ) );     
                                        setProcessing( false );
                                        handleLoader( 'profile_loader', 'remove' );
                                        return;
                                    }

                                    setError( null );
                                    setProcessing( false );
                                    handleLoader( 'profile_loader', 'remove' );
                                    setSucceeded( t( 'payment:success', language ) );
                                    props.clearCart();
                                    if ( res3.data.GTMEvent ) gtmEvent( res3.data.GTMEvent );
                                    if ( res3.data.email && res3.data.ACEventName ) activeCampaignEvent({ email: res3.data.email, eventName: res3.data.ACEventName, eventDetails: 'browse' });
                                    props.handlePageChange( 'right', 'success' );
                                })
                        }
                    });
            })
            .catch( error => {
                console.log( error );
                setError( t( 'shared:genericError', language ) );
                setProcessing( false );
                handleLoader( 'profile_loader', 'remove' );
            });
    };

    return (
        <div className='profile_menu menu_card'>
            <div className='menu_card_title'>{ t( 'store:paymentMethod', language ) }</div>
            <div className='menu_card_details'>
                <span><img className='profile_icon' src={ ICONS.card } alt='icone suivant'/></span>
                { props.stripeCard.brand } ****-{ props.stripeCard.last4 }
            </div>
            <div className='menu_card_details'>{ t( 'store:expirationDate', language ) } : { props.stripeCard.exp_month.toString().padStart( 2, '0' ) }/{ props.stripeCard.exp_year }</div>
            <div className="local_stripe_container">
                <div className="local_stripe_form">
                    <div className="local_stripe_results">
                        <div className="local_stripe_error">{ error }</div>
                        <div className="local_stripe_success">{ succeeded }</div>
                    </div>
                    <button
                        className="local_stripe_button"
                        onClick={ paymentSubmit }
                        disabled={ props.showStripeForm || succeeded || processing }>
                        { `${ t( 'payment:payConfirm', language ) } ${ props.priceStgCurrent }` } 
                    </button>
                    <button className="local_stripe_button gray" onClick={ props.toggleShowStripeForm }>
                        { ! props.showStripeForm ? t( 'payment:useOtherCard', language ) : t( 'payment:useCard', language ) }
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SCStripeForm;