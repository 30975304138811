import { PAYMENT_REDUCER } from "../../constants";

const initialState = {
	codeId: '',
	productId: '',
	productType: '',
	productPaymentInfo: {},
};

export default (state = initialState, action) => {
  	const newState = Object.assign({}, state);
	switch (action.type) {
		case PAYMENT_REDUCER.FETCH_CART_INFO:
            newState.codeId = action.payload.codeId;
            newState.productId = action.payload.productId;
            return newState;
		case PAYMENT_REDUCER.FETCH_CODE_ID:	
            newState.codeId = action.payload;
            return newState;
		case PAYMENT_REDUCER.FETCH_PRODUCT_ID:
            newState.productId = action.payload;
            return newState;
		case PAYMENT_REDUCER.FETCH_PRODUCT_TYPE:	
            newState.productType = action.payload;
            return newState;
		case PAYMENT_REDUCER.FETCH_PRODUCT_PAYMENT_INFO:	
            newState.productPaymentInfo = action.payload;
            return newState;
		default:
			return state;
	}
};