import React from "react";
import { connect }  from "react-redux";

import ButtonClose from "../components/ButtonClose";
import HeaderLogo from "../components/HeaderLogo";
import PaymentDetailsBox from "./components/PaymentDetailsBox";
import PaymentRecapBox from "./components/PaymentRecapBox";

import { CURRENCY, PAYMENT_REDUCER, POPUP_REDUCER } from "../../../constants";
import { paymentAction, popupAction } from "../../../redux/actions";
import { checkCode, createCart } from "../../../services/payment";
import { getUserInfo, handleLoader, t } from "../../../utils";
 
class PopupPaymentInit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: '',
            discountAmountStg: '',
            isCodeValid: false,
            priceStgCurrent: '',
            priceStgOld: '',
            productSelectedInfo: {},
            errorMessage: '',
            successMessage: '',
        };
    };

    componentDidMount() {
        const { fetchProductPaymentInfo, productId } = this.props;
        if ( productId && getUserInfo().NoCli ) fetchProductPaymentInfo( productId, getUserInfo().NoCli, null );
  	};

    componentWillReceiveProps( newProps ) {
        const { productPaymentInfo, productType, userGeoInfo } = newProps;
        const currency = userGeoInfo && userGeoInfo.currency ? userGeoInfo.currency : CURRENCY.EUR;
        if ( productPaymentInfo !== undefined ) this.setState({
            priceStgCurrent: productType === PAYMENT_REDUCER.TYPE_RENT ? productPaymentInfo.pricesRent[ currency ].priceStg : productPaymentInfo.prices[ currency ].priceStg,
            productSelectedInfo: productPaymentInfo
        });
        return null;
    };

    checkErrors = () => {
        const { language } = this.props;

        if ( this.state.code === '' ) {
            this.setState({ errorMessage: t( 'form:codeEmpty', language ) });
            return false;
        }
        this.setState({ errorMessage: '' });
        return true;
    };

    codeRemove = () => this.setState({
        code: '',
        discountAmountStg: '',
        isCodeValid: false,
        priceStgCurrent: this.state.priceStgOld,
        priceStgOld: '',
        errorMessage: '',
        successMessage: '',
    });

    codeSubmit = () => {
        if ( this.checkErrors() ) {
            handleLoader( 'ils_loader', 'add' );
            const { language, userGeoInfo, productType } = this.props;
            const { code, productSelectedInfo } = this.state;
            const dataCode = { code: code, NoCli: getUserInfo().NoCli, productId: productSelectedInfo.id };
            checkCode( dataCode )
                .then( res => {
                    if ( ! res.ret ) {
                        switch( res.data ) {
                            case 'code unknown':
                                this.setState ({ errorMessage: t( 'form:codeError', language ) });
                                break;
                            default:
                                this.setState ({ errorMessage: t( 'shared:genericError', language ) });       
                        }
                        handleLoader( 'ils_loader', 'remove' );
                        return;
                    } 

                    const currency = userGeoInfo && userGeoInfo.currency ? userGeoInfo.currency : CURRENCY.EUR;
                    this.setState({
                        discountAmountStg: res.data.amountStg,
                        isCodeValid: true,
                        priceStgCurrent: productType === PAYMENT_REDUCER.TYPE_RENT ? res.data.pricesRent[ currency ].priceStg : res.data.prices[ currency ].priceStg,
                        priceStgOld: this.state.priceStgCurrent,
                        errorMessage: '',
                        successMessage: t( 'payment:codeSuccess', language ),
                    });

                    handleLoader( 'ils_loader', 'remove' );
                })
                .catch( error => {
                    console.log( error );
                    this.setState ( { errorMessage: t( 'shared:genericError', language ) } );
                    handleLoader( 'ils_loader', 'remove' );
                });   
        }
    };

    handleChange = event => {
        let value = event.target.value;
        const name = event.target.name;
        if ( name === 'email' ) value = value.toLowerCase().trim();
        this.setState({ [name]: value });
    };

    handleChangeProduct = productInfo => {
        const { productType, userGeoInfo } = this.props;
        const currency = userGeoInfo && userGeoInfo.currency ? userGeoInfo.currency : CURRENCY.EUR;
        this.setState({
            code: '',
            discountAmountStg: '',
            isCodeValid: false,
            priceStgCurrent: productType === PAYMENT_REDUCER.TYPE_RENT ? productInfo.pricesRent[ currency ].priceStg : productInfo.prices[ currency ].priceStg,
            priceStgOld: '',
            productSelectedInfo: productInfo,
            errorMessage: '',
            successMessage: '',
        });
    };

    handleSubmit = () => {
        const { fetchCodeId, fetchProductId, popupOpen } = this.props;
        const { code, isCodeValid, productSelectedInfo } = this.state;

        fetchProductId( productSelectedInfo.id );
        if ( isCodeValid ) fetchCodeId( code );
        createCart({ NoCli: getUserInfo().NoCli, productId: productSelectedInfo.id, code: code });
        popupOpen( POPUP_REDUCER.POPUP_PAYMENT_CONFIRM );
    };

    render() {
        const { language, productPaymentInfo, productType, userGeoInfo } = this.props;
        const { discountAmountStg, isCodeValid, priceStgCurrent, priceStgOld, productSelectedInfo } = this.state;
        const currency = userGeoInfo && userGeoInfo.currency ? userGeoInfo.currency : CURRENCY.EUR;

        return (
            <>
                <HeaderLogo />
                <div className="popup-payment-progress-nav">
                    <div className="popup-payment-progress-nav-item selected">{ t( 'payment:nav1', language ) }</div>
                    <div className="popup-payment-progress-nav-item">{ t( 'payment:nav2', language ) }</div>
                    <div className="popup-payment-progress-nav-item">&nbsp;</div>
                </div>
                <PaymentDetailsBox
                    handleChangeProduct={ this.handleChangeProduct }
                    productPaymentInfo={ productPaymentInfo }
                    selected={ productPaymentInfo.id === productSelectedInfo.id } />
                {
                    productType === PAYMENT_REDUCER.TYPE_PURCHASE && productPaymentInfo && productPaymentInfo.proposals && productPaymentInfo.proposals.length > 0 &&
                    productPaymentInfo.proposals.map( ( item, index ) => 
                        <PaymentDetailsBox
                            handleChangeProduct={ this.handleChangeProduct }
                            productPaymentInfo={ item }
                            selected={ item.id === productSelectedInfo.id }
                            withSeparator={ true }
                            key={ index } />
                    )
                }
                <div className="popup-payment-recap">
                    <PaymentRecapBox
                        currency={ currency }
                        discountAmountStg={ discountAmountStg }
                        isCodeValid={ isCodeValid }
                        priceStgCurrent={ priceStgCurrent }
                        priceStgOld={ priceStgOld }
                        productInfo={ productSelectedInfo } />
                    <div className="popup-payment-code-box">
                        <div className="popup-payment-code-label">{ t( 'payment:codeLabel', language ) }</div>
                        <div className="popup-payment-code-form">
                            <input
                                type="text"
                                name="code"
                                value={ this.state.code }
                                onChange={ this.handleChange }
                                onKeyDown={ event => { if (event.keyCode === 13) this.codeSubmit(); }}
                                disabled={ isCodeValid }
                                placeholder={ t( 'payment:codeLabel', language ) } />
                            {
                                ! isCodeValid ?
                                <div className="popup-payment-code-form-valid" onClick={ this.codeSubmit }>{ t( 'payment:codeConfirm', language ) }</div>
                                : 
                                <div className="popup-payment-code-form-remove" onClick={ this.codeRemove }>{ t( 'payment:codeRemove', language ) }</div>
                            }
                        </div>
                        <div className="popup-payment-code-form-results">
                            <div className="popup-payment-code-form-error">{ this.state.errorMessage }</div>
                            <div className="popup-payment-code-form-success">{ this.state.successMessage }</div>
                        </div>
                    </div>
                    {
                        productSelectedInfo && productSelectedInfo.id &&
                        <div className="popup-payment-valid-box">
                            <div className="popup-payment-valid" onClick={ this.handleSubmit }>
                                { t( 'payment:orderConfirm', language ) }
                            </div>
                        </div>
                    }
                </div>
                <ButtonClose popupName={ POPUP_REDUCER.POPUP_PAYMENT_INIT } />
            </>
        );
    }
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
    productId: state.paymentReducer.productId,
    productPaymentInfo: state.paymentReducer.productPaymentInfo,
    productType: state.paymentReducer.productType,
    userGeoInfo: state.userReducer.userGeoInfo,
});

const dispatchToProps = dispatch => ({
    fetchCodeId: codeId => dispatch(paymentAction.fetchCodeId( codeId )),
    fetchProductId: productId => dispatch(paymentAction.fetchProductId( productId )),
    fetchProductPaymentInfo: ( productId, userId, codeId ) => dispatch(paymentAction.fetchProductPaymentInfo( productId, userId, codeId )),
    popupOpen: popupName => dispatch(popupAction.popupOpen( popupName )),
})

export default connect(stateToProps, dispatchToProps)(PopupPaymentInit);
