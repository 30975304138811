import { POPUP_REDUCER } from '../../constants';

export default {
    popupOpen: popupName => async dispatch => {
        dispatch( {
            type: POPUP_REDUCER.OPEN,
            payload: { open: true, name: popupName }
        } );
    },
    popupClose: popupName => async dispatch => {
        dispatch( {
            type: POPUP_REDUCER.CLOSE,
            payload: { open: false, name: popupName }
        } );
    },
    popupOptions: options => async dispatch => {
        dispatch( {
            type: POPUP_REDUCER.OPTIONS,
            payload: options
        } );
    },
};