import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';
import Loader from 'react-loader-spinner';

import { activeCampaignEvent, logEvent, gtmEvent } from "../../../services/tracking";
import { changeUserSub } from "../../../services/user";
import { formatDateForUser, handleLoader, t } from "../../../utils";

class PAccountSubChange extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            planFocus: this.props.stripePlan.month_count,
            planSelected: 0,
            errorMessage: '',
            successMessage: '',
        };
    };

    handleChangeSub = () => {
        const { language, plansList, userInfoDB } = this.props;
        const { planSelected } = this.state;
        this.setState ( { errorMessage: '', successMessage: '' } );
        handleLoader( 'profile_loader', 'add' );
        const dataUser = { NoCli: userInfoDB.NoCli, newSub: plansList[ planSelected ].stripe_id };
        changeUserSub( dataUser )
            .then( res => {
                if ( ! res.ret ) {
                    this.setState ({ errorMessage: t( 'shared:genericError', language ) });
                    handleLoader( 'profile_loader', 'remove' );
                    return;
                }

                handleLoader( 'profile_loader', 'remove' );
                this.setState ({ successMessage: t( 'form:subscriptionUpdated', language ) });

                let acData = {
                    email: userInfoDB.email,
                    eventDetails: 'account browse',
                    eventName: `abonnement modifie ${ plansList[ planSelected ].plan.details }`,
                };

                logEvent({ NoCli: userInfoDB.NoCli, event: 'edit sub', eventDetails: `new sub ${ plansList[ planSelected ].stripe_id }` });
                gtmEvent({ event: 'abonnement modifie' });
                activeCampaignEvent( acData );
            } )
            .catch( error => { console.log( error ); } );  
    };
    
    handleSelectSub = planId => {
        const { stripePlan } = this.props;
        if ( stripePlan.month_count && planId !== stripePlan.month_count ) this.setState({ planFocus: planId, planSelected: planId });
    };

    render() {
        const { language, plansList, stripePlan } = this.props;
        const { planFocus, planSelected, errorMessage, successMessage } = this.state;

        const $content =
            <>
                <div id='profile_container'>
                    <div className='profile_nav_return' onClick={ () => { this.props.handlePageChange( 'left', 'account' ) } }>{ t( 'shared:back', language ) }</div>
                    <div id='profile_loader'><Loader type='TailSpin' color='#fba00b' height={100} width={100} /></div>
					<div id='profile_header' className='profile_header_title'>
						<h1>{ t( 'profile:editSubsciption', language ) }</h1>
					</div>
                    {
                        successMessage === '' &&
                        <div id='profile_body'>
                            <div
                                className={ `collect_flag profile_menu profile_hover profile_sub ${ planFocus === 1 ? 'selected' : '' } ${ stripePlan.month_count === 1 ? 'current_offer' : '' }` }
                                id='profile_change_sub_1_month'
                                onClick={ () => this.handleSelectSub( 1 ) }>
                                <div className='profile_scale sub_title'>
                                    <div className='sub_current'>{ t( 'subscription:current', language ) }</div>
                                    <div className='sub_label'>{ stripePlan.month_count === 1 ? stripePlan.label : ( plansList[ 1 ] ? plansList[ 1 ].plan.label : '' ) }</div>
                                </div>
                                <div className='profile_scale'>{ stripePlan.month_count === 1 ? stripePlan.details : ( plansList[ 1 ] ? plansList[ 1 ].plan.details : '' ) }</div>
                                <div className='f-grow-1'></div>
                                <div>{ stripePlan.month_count === 1 ? stripePlan.price_details : ( plansList[ 1 ] ? plansList[ 1 ].plan.price_details : '' ) }</div>
                            </div>
                            <div
                                className={ `collect_flag profile_menu profile_hover profile_sub ${ planFocus === 3 ? 'selected' : '' } ${ stripePlan.month_count === 3 ? 'current_offer' : '' }` }
                                id='profile_change_sub_3_month'
                                onClick={ () => this.handleSelectSub( 3 ) }>
                                <div className='profile_scale sub_title'>
                                    <div className='sub_current'>{ t( 'subscription:current', language ) }</div>
                                    <div className='sub_label'>{ stripePlan.month_count === 3 ? stripePlan.label : ( plansList[ 3 ] ? plansList[ 3 ].plan.label : '' ) }</div>
                                </div>
                                <div className='profile_scale'>{ stripePlan.month_count === 3 ? stripePlan.details : ( plansList[ 3 ] ? plansList[ 3 ].plan.details : '' ) }</div>
                                <div className='f-grow-1'></div>
                                <div>{ stripePlan.month_count === 3 ? stripePlan.price_details : ( plansList[ 3 ] ? plansList[ 3 ].plan.price_details : '' ) }</div>
                            </div>
                            <div
                                className={ `collect_flag profile_menu profile_hover profile_sub ${ planFocus === 12 ? 'selected' : '' } ${ stripePlan.month_count === 12 ? 'current_offer' : '' }` }
                                id='profile_change_sub_12_month'
                                onClick={ () => this.handleSelectSub( 12 ) }>
                                <div className='profile_scale sub_title'>
                                    <div className='sub_current'>{ t( 'subscription:current', language ) }</div>
                                    <div className='sub_label'>{ stripePlan.month_count === 12 ? stripePlan.label : ( plansList[ 12 ] ? plansList[ 12 ].plan.label : '' ) }</div>
                                </div>
                                <div className='profile_scale'>{ stripePlan.month_count === 12 ? stripePlan.details : ( plansList[ 12 ] ? plansList[ 12 ].plan.details : '' ) }</div>
                                <div className='f-grow-1'></div>
                                <div>{ stripePlan.month_count === 12 ? stripePlan.price_details : ( plansList[ 12 ] ? plansList[ 12 ].plan.price_details : '' ) }</div>
                            </div>
                        </div>
                    }
                    {
                        planSelected > 0 && plansList[ planSelected ] &&
                        <div className="profile-unsub-form">
                            <ul className='profile_unsub_list'>
                                {
                                    successMessage === '' ?
                                    <li>
                                        { t( 'subscription:text1', language ) }{ plansList[ planSelected ].plan.price_stg } {
                                            stripePlan.period_end > 0 && 
                                            <>{ t( 'subscription:the', language ) }{ formatDateForUser( stripePlan.period_end, language ) }</>
                                        }.
                                    </li>
                                    :
                                    <li>
                                        { t( 'subscription:text2', language ) }{ plansList[ planSelected ].plan.price_stg } {
                                            stripePlan.period_end > 0 && 
                                            <>{ t( 'subscription:the', language ) }{ formatDateForUser( stripePlan.period_end, language ) }</>
                                        }.
                                    </li>
                                }
                            </ul>
                            <div className="unsub_form_confirmation">
                                { errorMessage && <div className="mt-3 profile_edit_usual_error">{ errorMessage }</div> }
                            </div>
                        </div>
                    }
				</div>
                <div className="profile_unsub_nav_btns">
                    {
                        planSelected > 0 && plansList[ planSelected ] && successMessage === '' &&
                        <div id="change_sub" className="profile_btn_return" onClick={this.handleChangeSub}>
                            <span>{ t( 'subscription:confirm', language ) }</span>
                        </div>
                    } 
                    {
                        successMessage !== '' &&
                        <div id="change_sub" className="profile_btn_return" onClick={ () => window.location.href = '/browse/#/' }>
                            <span>{ t( 'shared:backPlatform', language ) }</span>
                        </div>
                    } 
                </div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
	plansList: state.userReducer.plansList,
	stripePlan: state.userReducer.stripePlan,
	stripeSubId: state.userReducer.stripeSubId,
	userInfoDB: state.userReducer.userInfoDB,
});

export default connect( stateToProps )( PAccountSubChange );