import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';

import { API_IMAGE, DATA_VLOG, DATA_VLOG_ALT, ICONS, IMAGES } from "../../constants";
import { isGuest, t } from "../../utils";

class PMenu extends React.Component {
    closeConfirmation = () => {
        const $confirmPopup = document.getElementById( 'profile_confirmation' );
        if ( $confirmPopup ) $confirmPopup.classList.remove( 'show' );
    };

    openConfirmation = () => {
        window.scrollTo( 0, 0 );
        const $confirmPopup = document.getElementById( 'profile_confirmation' );
        if ( $confirmPopup ) $confirmPopup.classList.add( 'show' );
    };

	logout = e => {
        e.stopPropagation();
        localStorage.removeItem( DATA_VLOG );
        localStorage.removeItem( DATA_VLOG_ALT );
        window.location.href = '/browse/#/';
	};
    
    render() {
        const { cancelAt, language, userInfoDB, userPurchases } = this.props;

        const avatar = ( userInfoDB && userInfoDB.avatar && userInfoDB.avatar !== '' ) ? `${ API_IMAGE }${ userInfoDB.avatar }.jpg` : IMAGES.avatarDefault;
        
        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_confirmation' onClick={ this.closeConfirmation }>
                        <div id='profile_confirmation_box'>
                            <div>{ t( 'profile:disconnectQuestion', language ) }</div>
                            <div>
                                <button className='collect_flag' id='profile_logout_confirm' onClick={ this.logout }>{ t( 'shared:yes', language ) }</button>
                                <button className='gray collect_flag' id='profile_logout_cancel' onClick={ this.closeConfirmation }>{ t( 'shared:no', language ) }</button>
                            </div>
                        </div>
                    </div>
                    <div id='profile_nav'>
                        <span>{ t( 'profile:title', language ) }</span>
                    </div>
                    {
                        isGuest() ?
                        <>
                            <div id='profile_header'>
                                <div className='user-avatar-box'>
                                    <img
                                        className='user-avatar'
                                        src={ avatar }
                                        alt='avatar' />
                                    <div className={ `profile_unsub_nav_btns ${ cancelAt > 0 ? 'with_btn-return' : 'without_btn-return' }` }>
                                        { userPurchases && userPurchases.email && <h1>{ userPurchases.email }</h1> }
                                    </div>
                                </div>
                                <div className='f-grow-1'></div>
                            </div>
                            <div id='profile_body'>
                                <div className='collect_flag profile_menu profile_hover' id='profile_menu_user_details' onClick={ () => window.location.href = '/offres/' }>
                                    <div className='profile_scale'>{ t( 'profile:offers', language ) }</div>
                                    <div className='f-grow-1'></div>
                                    <div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
                                </div>
                                <div className='collect_flag profile_menu profile_hover' id='profile_menu_orders' onClick={ () => { this.props.handlePageChange( 'right', 'orders' ) } }>
                                    <div><img className='profile_icon store_icon' src={ ICONS.boutique } alt='icone profil'/></div>
                                    <div className='profile_scale'>{ t( 'profile:orders', language ) }</div>
                                    <div className='f-grow-1'></div>
                                    <div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
                                </div>
                                <div className='collect_flag profile_menu profile_hover' id='profile_menu_about_us' onClick={ () => { this.props.handlePageChange( 'right', 'aboutus' ) } }>
                                    <div><img className='profile_icon' src={ ICONS.info } alt='icone info'/></div>
                                    <div className='profile_scale'>{ t( 'profile:aboutUs', language ) }</div>
                                    <div className='f-grow-1'></div>
                                    <div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div id='profile_header'>
                                <div className='user-avatar-box'>
                                    <img
                                        className='user-avatar'
                                        src={ avatar }
                                        alt='avatar' />
                                    <div className={ `profile_unsub_nav_btns ${ cancelAt > 0 ? 'with_btn-return' : 'without_btn-return' }` }>
                                        <h1>{ userInfoDB.Prenom }</h1>
                                        {
                                            cancelAt > 0 && 
                                            <div id="profile_menu_reactivate" className="profile_btn_return" onClick={ () => { this.props.handlePageChange( 'right', 'reactivate' ) } }>
                                                <span>{ t( 'reactivate:confirm', language ) }</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='f-grow-1'></div>
                                <div className='collect_flag' id='profile_menu_details' onClick={ () => { this.props.handlePageChange( 'right', 'profile' ) } }>
                                    <div>
                                        <img className='profile_icon' src={ ICONS.pen } alt='icone crayon' />
                                        <span className='profile_scale'>{ t( 'profile:editProfile', language ) }</span>
                                    </div>
                                </div>
                            </div>
                            <div id='profile_body'>
                                <div className='collect_flag profile_menu profile_hover' id='profile_menu_user_details' onClick={ () => { this.props.handlePageChange( 'right', 'account' ) } }>
                                    <div><img className='profile_icon' src={ ICONS.user } alt='icone profil'/></div>
                                    <div className='profile_scale'>{ t( 'profile:account', language ) }</div>
                                    <div className='f-grow-1'></div>
                                    <div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
                                </div>
                                <div className='collect_flag profile_menu profile_hover' id='profile_menu_orders' onClick={ () => { this.props.handlePageChange( 'right', 'orders' ) } }>
                                    <div><img className='profile_icon store_icon' src={ ICONS.boutique } alt='icone profil'/></div>
                                    <div className='profile_scale'>{ t( 'profile:orders', language ) }</div>
                                    <div className='f-grow-1'></div>
                                    <div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
                                </div>
                                <div className='collect_flag profile_menu profile_hover' id='profile_menu_sponsor' onClick={ () => { this.props.handlePageChange( 'right', 'sponsor' ) } }>
                                    <div><img className='profile_icon' src={ ICONS.gift } alt='icone cadeau'/></div>
                                    <div className='profile_scale'>{ t( 'profile:partner', language ) }</div>
                                    <div className='f-grow-1'></div>
                                    <div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
                                </div>
                                <div className='collect_flag profile_menu profile_hover' id='profile_menu_about_us' onClick={ () => { this.props.handlePageChange( 'right', 'aboutus' ) } }>
                                    <div><img className='profile_icon' src={ ICONS.info } alt='icone info'/></div>
                                    <div className='profile_scale'>{ t( 'profile:aboutUs', language ) }</div>
                                    <div className='f-grow-1'></div>
                                    <div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
                                </div>
                            </div>
                        </>
                    }
                    <div id='profile_footer'>
                        <div className='collect_flag' id='profile_logout' onClick={ this.openConfirmation }>{ t( 'profile:disconnect', language ) }</div>
                    </div>
                </div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
	cancelAt: state.userReducer.cancelAt,
    language: state.translatingReducer.language,
	userInfoDB: state.userReducer.userInfoDB,
    userPurchases: state.userReducer.userPurchases,
});

export default connect( stateToProps )( PMenu );