import { POPUP_REDUCER } from "../../constants";
import { removeUrlParams } from "../../utils";

const initialState = {
	popupOpen: false,
	popupName: '',
	popupRequired: false,
};

export default (state = initialState, action) => {
  	const newState = Object.assign({}, state);
	switch (action.type) {
		case POPUP_REDUCER.CLOSE:
			removeUrlParams();
			if ( action.payload.name === POPUP_REDUCER.POPUP_PAYMENT_SUCCESS ) window.location.reload();
			newState.popupName = action.payload.name;
			newState.popupOpen = action.payload.open;
			if ( newState.popupRequired ) { newState.popupRequired = false; window.location.href="/browse/#/"; }
			return newState; 
		case POPUP_REDUCER.OPEN:
			newState.popupName = action.payload.name;
			newState.popupOpen = action.payload.open;
			return newState;
		case POPUP_REDUCER.OPTIONS:
			newState.popupRequired = action.payload.isRequired ? action.payload.isRequired : false;
			return newState;
		default:
			return state;
	}
};
