import { TRAINING_REDUCER } from '../../constants';
import {
	getTrainingDetailsDescription,
	getTrainingMovies,
	getTrainingSliderMovies,
} from '../../services/training';

export default {
	fetchTrainingDetailsDescription: ( trainingName, userId ) => async dispatch => {
		try {
			const params = userId ? `?trainingName=${ trainingName }&NoCli=${ userId }` : `?trainingName=${ trainingName }`;
			const response = await getTrainingDetailsDescription( params );
			const { data } = response;
			dispatch({
				type: TRAINING_REDUCER.FETCH_TRAINING_DETAILS_DESCRIPTION,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: TRAINING_REDUCER.API_ERROR,
				payload: "error : training API"
			});
		}
	},
	fetchTrainingMovies: ( themeId, userId ) => async dispatch => {
		try {
			const params = userId ? `?themeId=${ themeId }&NoCli=${ userId }` : `?themeId=${ themeId }`;
			const response = await getTrainingMovies( params );
			const { data } = response;
			dispatch({
				type: TRAINING_REDUCER.FETCH_TRAINING_MOVIES,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: TRAINING_REDUCER.API_ERROR,
				payload: "error : training API"
			});
		}
	},
	fetchTrainingSliderMovies: ( themeId, userId ) => async dispatch => {
		try {
			const params = userId ? `?themeId=${ themeId }&NoCli=${ userId }` : `?themeId=${ themeId }`;
			const response = await getTrainingSliderMovies( params );
			const { data } = response;
			dispatch({
				type: TRAINING_REDUCER.FETCH_TRAINING_SLIDER_MOVIES,
				payload: data.data
			});
		} catch (err) {
			dispatch({
				type: TRAINING_REDUCER.API_ERROR,
				payload: "error : training API"
			});
		}
	},
};