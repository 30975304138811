import React from "react";
import Proptypes from "prop-types";
import { connect } from "react-redux";

import VideoPlayer from "../../components/Videoplayer";

import { API_IMAGE, CURRENCY, ICONS, PAYMENT_REDUCER, POPUP_REDUCER } from "../../constants";
import { paymentAction, popupAction } from '../../redux/actions';
import { toggleFavorites, toggleThumbs } from "../../services/user";
import { getUserInfo, isGuest, isProgressTimeValid, t } from "../../utils";
 
class MainDescription extends React.Component {
	static contextTypes = { router : Proptypes.object };

	constructor( props, context ) {
		super( props, context );

		this.logBtnExit = this.logBtnExit.bind(this);

		this.state = {
			playerShow: false,
			playerHeight: 0,
			playerWidth: 0,
			show: false,
			videoJsOptions: { autoplay: false, playing: false },
		};

        this.favoriteList = [];
        this.thumbUpList = [];
        this.thumbDownList = [];
	}; 

	componentDidMount() {
		this.backListener = this.context.router.history.listen( ( location, action, state ) => {
			if ( action === "POP" && this.state.playerShow && location.hash !== '#play/' ) this.logBtnExit();
		});
	};

	btnSrcIsInList = () => {
		const { language, trainingDetailsDescription } = this.props;
		const trainingId = trainingDetailsDescription[ 0 ].display.id;
        this.favoriteList = this.props.favorites;
		if ( this.favoriteList.includes( trainingId ) ) {
            return {
                bubble: <div className="bubble bubble-bottom">{ t( 'shared:listRemoveTo', language ) }</div>,
                class: 'movie_icon selected',
                src: ICONS.checked
            };
        } 
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:listAddTo', language ) }</div>,
            class: 'movie_icon',
            src: ICONS.favorites
        };
    };

	btnSrcThumbUp = () => {
		const { language, trainingDetailsDescription } = this.props;
		const trainingId = trainingDetailsDescription[ 0 ].display.id;
		this.thumbUpList = this.props.thumbsUp;
		if ( this.thumbUpList.includes( trainingId ) ) {
            return { 
                bubble: <></>, 
                class: 'movie_icon selected', 
                src: ICONS.thumbFilled 
            };
        }
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:like', language ) }</div>,
            class: 'movie_icon',
            src: ICONS.thumb
        };
    };

	btnSrcThumbDown = () => {
		const { language, trainingDetailsDescription } = this.props;
		const trainingId = trainingDetailsDescription[ 0 ].display.id;
		this.thumbDownList = this.props.thumbsDown;
        if ( this.thumbDownList.includes( trainingId ) ) {
            return { 
                bubble: <></>, 
                class: 'movie_icon icon_revert selected', 
                src: ICONS.thumbFilled 
            };
        }
		return {
            bubble: <div className="bubble bubble-bottom">{ t( 'shared:dislike', language ) }</div>,
            class: 'movie_icon icon_revert',
            src: ICONS.thumb
        };
    };

	initPayment = type => {
		const { fetchProductId, fetchProductType, popupOpen, trainingDetailsDescription } = this.props;

		if ( getUserInfo().NoCli ) {
			if ( trainingDetailsDescription && trainingDetailsDescription.length > 0 && trainingDetailsDescription[ 0 ].display ) {
				fetchProductId( trainingDetailsDescription[ 0 ].display.id );
				fetchProductType( type );
				popupOpen( POPUP_REDUCER.POPUP_PAYMENT_INIT );
			}
		} else {
			popupOpen( POPUP_REDUCER.POPUP_REGISTRATION );
		}
	};

	logBtnExit = () => {
		if( document.location.hash.includes( "#play/" ) ) this.context.router.history.goBack();
		this.setState( { playerShow: false, playerHeight: 0, playerWidth: 0, videoJsOptions: { autoplay: false, playing: false } } );
		window.location.href = window.location.href.replace( "#play/", "" );
		window.location.reload();
	};

	onClickFullscreen = () => {
		const { trainingDetailsDescription } = this.props;

		let globalMovieList = [];
		trainingDetailsDescription.forEach( item => item.movies.forEach( el => globalMovieList.push( el ) ));
		const globalMovieKey = trainingDetailsDescription[ 0 ].display.lastMovieWatched ? globalMovieList.findIndex( item => item.transaction === trainingDetailsDescription[ 0 ].display.lastMovieWatched ) : 0;
		const movie = globalMovieList[ globalMovieKey ];
		const progressInfo = isProgressTimeValid( movie.progressTime, movie.timeTotal ) ? { progressTime: movie.progressTime, timeTotal: movie.timeTotal } : null;		

		this.setState({
			playerShow: true,
			playerHeight: "100%",
			playerWidth: "100%",
			videoJsOptions: {
				isTrailer: false,
				logBtnExit: this.logBtnExit,
				movieKey: globalMovieKey,
				movieList: globalMovieList, 
				playing: true,
				progressInfo: progressInfo,
			},
		});

		window.location.href = window.location.href + "#play/";
	};

	onClickFullscreenTrailer = () => {
		const { trainingDetailsDescription } = this.props;

		this.setState({
			playerShow: true,
			playerHeight: "100%",
			playerWidth: "100%",
			videoJsOptions: {
				isTrailer: true,
				logBtnExit: this.logBtnExit,
				movieKey: 0,
				movieList: [ trainingDetailsDescription[ 0 ].display ], 
				playing: true,
				progressInfo: null,
			},
		});

		window.location.href = window.location.href + "#play/";
	};

	toggleInMyList = () => {
        if ( isGuest() ) return;

		const { profileVersion, trainingDetailsDescription } = this.props;
		const trainingId = trainingDetailsDescription[ 0 ].display.id;
		const action = this.props.favorites.includes( trainingId ) ? 'remove' : 'add';
        let newFavoriteList = this.favoriteList;
        newFavoriteList = ( action === 'remove' ) 
            ? newFavoriteList.splice( newFavoriteList.indexOf( trainingId ), 1 )
            : newFavoriteList.push( trainingId );	
        this.setState( { favoriteList: newFavoriteList } );

        toggleFavorites( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ trainingId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
    };

    toggleThumbDown = () => {
        if ( isGuest() ) return;

		const { profileVersion, trainingDetailsDescription } = this.props;
		const trainingId = trainingDetailsDescription[ 0 ].display.id;
        const action = this.props.thumbsDown.includes( trainingId ) ? 'remove-down' : 'add-down';
        let newThumbDownList = this.thumbDownList;
        let newThumbUpList = this.thumbUpList;
        newThumbDownList = ( action === 'remove-down' ) 
            ? newThumbDownList.splice( newThumbDownList.indexOf( trainingId ), 1 )
            : newThumbDownList.push( trainingId );	
        if ( action === 'add-down' && newThumbUpList.includes( trainingId ) ) newThumbUpList.splice( newThumbUpList.indexOf( trainingId ), 1 );
        this.setState( { thumbDownList: newThumbDownList, thumbUpList: newThumbUpList } );

        toggleThumbs( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ trainingId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
	};

	toggleThumbUp = () => {
        if ( isGuest() ) return;

		const { profileVersion, trainingDetailsDescription } = this.props;
		const trainingId = trainingDetailsDescription[ 0 ].display.id;
        const action = this.props.thumbsUp.includes( trainingId ) ? 'remove-up' : 'add-up';
        let newThumbDownList = this.thumbDownList;
        let newThumbUpList = this.thumbUpList;
        newThumbUpList = ( action === 'remove-up' ) 
            ? newThumbUpList.splice( newThumbUpList.indexOf( trainingId ), 1 )
            : newThumbUpList.push( trainingId );
        if ( action === 'add-up' && newThumbDownList.includes( trainingId ) ) newThumbDownList.splice( newThumbDownList.indexOf( trainingId ), 1 );    	
        this.setState( { thumbDownList: newThumbDownList, thumbUpList: newThumbUpList } );

        toggleThumbs( `?action=${ action }&NoCli=${ getUserInfo().NoCli }&version=${ profileVersion }&movieId=${ trainingId }` )
            .then( function( res ) { console.log( res ); } )
            .catch( function( error ) { console.log( error ); } );
    };

	render() {
		const { language, trainingDetailsDescription, userGeoInfo } = this.props;
		const currency = userGeoInfo && userGeoInfo.currency ? userGeoInfo.currency : CURRENCY.EUR;

		let description = {};
		if ( trainingDetailsDescription && trainingDetailsDescription.length > 0 ) description = trainingDetailsDescription[ 0 ].display;

		let additionalInfoArr = [];
        if ( description.shootingDate &&  description.shootingDate !== '' ) additionalInfoArr.push( description.shootingDate );
        if ( description.shootingCountry &&  description.shootingCountry !== '' ) additionalInfoArr.push( description.shootingCountry );

		return (
			<>
				{
					trainingDetailsDescription && trainingDetailsDescription.length > 0 &&
					<>
					<div className="main-des-container">
						<div className="main-des-go-back" onClick={ () => this.context.router.history.goBack() }>
							<span className='icon_box'>
								<img className="nav_icon" src={ ICONS.backArrow } alt='icone retour' />
							</span>
							{ t( 'shared:back', language ) }
						</div>
						<div className="main-des-image">
							<div className="main-des-image-overlay"></div>
							<img
								src={ `${API_IMAGE}${ description.thumbnailFileName }.jpg&h=720&crop-to-fit&q=40` }
								alt={ description.alt } />	
						</div>
						<div className="main-des-content">
							{
								description.titlePngFileName ?
								<div className="main-des-img-title">
									<img 
										alt={ `logo ${ description.alt }` }
										src={ `${ API_IMAGE }${ description.titlePngFileName }.png&h=160&crop-to-fit` }
										onError={ e => e.target.style.display = 'none' } />
								</div>    
								:
								<></>
							}  
							{
								description.title ?
								<div className="main-des-title">
									{ description[ t( 'keys:title', language ) ] ? description[ t( 'keys:title', language ) ] : description.title }
								</div>
								:
								<></>
							}
							<div className="main-des-btn-addon-box">
								{
									description.linksAddon && description.linksAddon.length > 0 &&
									description.linksAddon.map( ( item, index ) => 
										<div
											key={ index }
											className={ `main-des-btn-addon ${ item.link ? 'collect_flag linked' : '' }` }
											onClick={ () => { if ( item.link ) window.location.href = item.link; } }
											id={ `linkAddonBtn--main-des-${ description.id }-link-${ description.index }-index-${ index }` }>
											{ item[ t( 'keys:linkBtn', language ) ] ? item[ t( 'keys:linkBtn', language ) ] : item.linkBtn }          
										</div>
									)
								}
							</div>
							{
								description.details ?
								<div className="main-des-details">
									{ description[ t( 'keys:details', language ) ] ? description[ t( 'keys:details', language ) ] : description.details }
								</div>
								:
								<></>
							}
							<div className="main-des-price">
								<div>
									{ description.prices && description.prices[ currency ].priceOverlineStg ? <span className="main-des-priceOverlineStg">{ description.prices[ currency ].priceOverlineStg }</span> : <></> }
									{ description.prices && description.prices[ currency ].priceStg ? <span className="main-des-priceStg">{ description.prices[ currency ].priceStg }</span> : <></> }
								</div>
								<div>
									{ description.pricesRent && description.pricesRent[ currency ].priceOverlineStg ? <span className="main-des-priceOverlineStg">{ description.pricesRent[ currency ].priceOverlineStg }</span> : <></> }
									{
										description.pricesRent && description.pricesRent[ currency ].priceStg ?
										<>
											<span className="main-des-priceStg">{ description.pricesRent[ currency ].priceStg }</span>
											<span className="main-des-price-rent-info">
												{
													description.pricesRent[ currency ][ t( 'keys:priceInfo' ) ] ?
													description.pricesRent[ currency ][ t( 'keys:priceInfo' ) ]
													:
													description.pricesRent[ currency ].priceInfo
												}
											</span>
										</>
										:
										<></>
									}
								</div>
								<div>
									{
										description.freeBtn &&
										<div className="main-des-free">
											{
												description[ t( 'keys:freeBtn', language ) ] ?
												description[ t( 'keys:freeBtn', language ) ]
												:
												description.freeBtn
											}
										</div>
									}
								</div>
							</div>
							<div className='movie_btns'>
								{
									description.priceBtn ?
									<button
										className="btn-movie collect_flag"
										onClick={ () => this.initPayment( PAYMENT_REDUCER.TYPE_PURCHASE ) }
										id={ `initPayment--main-des-${ description.id }` }>
										{
											description[ t( 'keys:priceBtn', language ) ] ?
											description[ t( 'keys:priceBtn', language ) ]
											:
											description.priceBtn
										}          
									</button>
									:
									<></>
								}
								{
									description.priceRentBtn ?
									<button
										className="btn-movie collect_flag"
										onClick={ () => this.initPayment( PAYMENT_REDUCER.TYPE_RENT ) }
										id={ `initPaymentRent--main-des-${ description.id }` }>
										{
											description[ t( 'keys:priceRentBtn', language ) ] ?
											description[ t( 'keys:priceRentBtn', language ) ]
											:
											description.priceRentBtn
										}       
									</button>
									:
									<></>
								}
								{
									! description.priceBtn && description.playBtn ?
									<button
										className="btn-movie collect_flag"
										onClick={ this.onClickFullscreen }
										id={ `playBtn--main-des-${ description.id }` }>
										{
											description[ t( 'keys:playBtn', language ) ] ?
											description[ t( 'keys:playBtn', language ) ]
											:
											description.playBtn
										}
									</button>
									:
									<></>
								}
								{
									description.trailer && description.trailer.length > 0 && ! this.state.playerShow &&
									<button
										className="btn-movie btn-trailer collect_flag"
										onClick={ this.onClickFullscreenTrailer }
										id={ `playTrailerBtn--main-des-${ description.id }` } >
										{ t( 'shared:trailer', language ) }
									</button>
								}
								<div className="icons_box">
									<div
										className={ `movie_btn_box collect_flag ${ isGuest() && 'disabled' }` }
										onClick={ this.toggleInMyList }
										id={ `favoritesBtn----main-des-${ description.id }` } >
										<div className='icon_box'>
											<img
												className={ this.btnSrcIsInList().class }
												src={ this.btnSrcIsInList().src }
												alt='icone favoris' />
											{ this.btnSrcIsInList().bubble }    
										</div>
									</div>
									<div
										className={ `movie_btn_box collect_flag ${ isGuest() && 'disabled' }` }
										onClick={ this.toggleThumbUp }
										id={ `thumbUpBtn----main-des-${ description.id }` } >
										<div className='icon_box'>
											<img
												className={ this.btnSrcThumbUp().class }
												src={ this.btnSrcThumbUp().src }
												alt='icone puce en haut' />
											{ this.btnSrcThumbUp().bubble }    
										</div>
									</div>
									<div
										className={ `movie_btn_box collect_flag ${ isGuest() && 'disabled' }` }
										onClick={ this.toggleThumbDown }
										id={ `thumbDownBtn----main-des-${ description.id }` } >
										<div className='icon_box'>
											<img
												className={ this.btnSrcThumbDown().class }
												src={ this.btnSrcThumbDown().src }
												alt='icone favoris' />
											{ this.btnSrcThumbDown().bubble }
										</div>
									</div>
								</div>
                    		</div>
							{
								description.description ?
								<div className="main-des-description">
									{
										description[ t( 'keys:description', language ) ] ?
										description[ t( 'keys:description', language ) ]
										:
										description.description
									}
								</div>
								:
								<></>
							}
							<div className="main-des-additional-info">
								<span>{ additionalInfoArr.join( ' - ' ) }</span>
							</div>				
						</div>
						<div className="main-des-happy-ending-revert"></div>
					</div>
					<div className="main-des-happy-ending"></div>
					{
						description.trailer && description.trailer.length > 0 &&
						<div id="z3pOverlay" className="z3PlayOverlay" style={{display : this.state.playerShow ? 'block' : 'none', width: this.state.playerWidth, height: this.state.playerHeight }}>
							<VideoPlayer className="video-js" id="VideoAccueil" { ...this.state.videoJsOptions } />
						</div>
					}	
					</>
				}
			</>
		);
	}
}

const stateToProps = state => ({
	favorites: state.userReducer.favorites,
	language: state.translatingReducer.language,
	profileVersion: state.userReducer.profileVersion,
    thumbsUp: state.userReducer.thumbsUp,
    thumbsDown: state.userReducer.thumbsDown,
	trainingDetailsDescription: state.trainingReducer.trainingDetailsDescription,
	userGeoInfo: state.userReducer.userGeoInfo,
});

const dispatchToProps = dispatch => ({
	fetchProductId: productId => dispatch(paymentAction.fetchProductId( productId )),
	fetchProductType: productType => dispatch(paymentAction.fetchProductType( productType )),
	popupOpen: popupName => dispatch(popupAction.popupOpen( popupName )),
});

export default connect( stateToProps, dispatchToProps )( MainDescription );