import React from 'react';
import { connect } from 'react-redux';

import Footer from '../Footer';
import NavMobile from '../Nav/NavMobile';
import NavWeb from '../Nav/NavWeb';
import PopupHandler from '../Popup';
import Router from '../Router';

import { POPUP_REDUCER, VERSION_BROWSE } from '../../constants';
import { moviesAction, paymentAction, popupAction, routingAction, userAction } from '../../redux/actions';
import { getUrlParams, getUserInfo, isGuest } from '../../utils';

class Page extends React.Component {
	constructor(props) {
		super(props);
		this.versionStoringTimeout = null;
	};

	componentDidMount() {
		const {
			changePaymentShowPopup,
			discardedUserShowPopup,
			fetchCartInfo,
			fetchNotifications,
			fetchPlatformConfig,
			fetchUserInfo,
			fetchVersion,
			popupOpen, 
		} = this.props;
		const userId = getUserInfo().NoCli;
        if ( userId && ! isGuest() ) fetchUserInfo( userId );
		if ( getUrlParams().cart ) fetchCartInfo( getUrlParams().cart );
		fetchNotifications();
		fetchPlatformConfig();

		const versionStorage = localStorage.getItem( VERSION_BROWSE );
		const versionSession = sessionStorage.getItem( VERSION_BROWSE );
		let versionInt = 0;
		if ( ! versionSession ) {
			sessionStorage.setItem( VERSION_BROWSE, 'done' );
			if ( versionStorage ) versionInt = parseInt( versionStorage ) + 1;
			this.versionStoringTimeout = setTimeout( () => localStorage.setItem( VERSION_BROWSE, versionInt ), 2000);
		} else {
			if ( versionStorage ) {
				versionInt = parseInt( versionStorage );
			} else {
				this.versionStoringTimeout = setTimeout( () => localStorage.setItem( VERSION_BROWSE, versionInt ), 2000);
			} 
		}
		fetchVersion( versionInt );

		if ( getUrlParams().initpass ) {
			popupOpen( POPUP_REDUCER.POPUP_RESET_PASSWORD );
		} else if ( getUrlParams().cart ) {
			popupOpen( userId ? POPUP_REDUCER.POPUP_PAYMENT_CONFIRM : POPUP_REDUCER.POPUP_LOGIN );
		} else if ( discardedUserShowPopup ) {
			popupOpen( POPUP_REDUCER.POPUP_USER_DISCARDED );
		} else if ( changePaymentShowPopup ) {
			popupOpen( POPUP_REDUCER.POPUP_CHANGE_PAYMENT );
		}
	};

	componentWillUnmount() {
		clearTimeout( this.versionStoringTimeout );
	};

	render(){
		const { userPreferences, userPreferencesDone } = this.props;
		if ( userPreferencesDone && userPreferences.length < 3 ) window.location.href = "/browse/?preferences/#/preferences";

		const urlEnd = window.location.href.split( '/#/' );
		if ( urlEnd.length > 1 ) {
			const urlArray = urlEnd[ 1 ].split( 'iframe' );
			if ( urlArray.length > 1 ) {
				return (
					<div>
						<Router />
					</div>
				)
			}
		}

		return (
			<div className="homepage-container">
				<PopupHandler />
                <NavMobile />
                <NavWeb />
                <Router />
                <Footer />
            </div>
		)
	};
}

const stateToProps = state => ({
	changePaymentShowPopup: state.userReducer.changePaymentShowPopup,
	discardedUserShowPopup: state.userReducer.discardedUserShowPopup,
	userPreferences: state.userReducer.userPreferences,
	userPreferencesDone: state.userReducer.userPreferencesDone,
});

const dispatchToProps = dispatch => ({
	fetchCartInfo: token => dispatch(paymentAction.fetchCartInfo( token )),
	fetchNotifications: () => dispatch(userAction.fetchNotifications()),
	fetchPlatformConfig: () => dispatch(moviesAction.fetchPlatformConfig()),
	fetchUserInfo: userId => dispatch(userAction.fetchUserInfo( userId )),
	fetchVersion: version => dispatch(routingAction.fetchVersion( version )),
	popupOpen: popupName => dispatch(popupAction.popupOpen( popupName )),
});

export default connect( stateToProps, dispatchToProps )( Page );
