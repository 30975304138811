import React from "react";
import { connect } from "react-redux";

import $ from 'jquery';
import { t } from "../../utils";
 
class CountDownCards extends React.Component {
    constructor(props) {
		super(props);

        const diff = this.props.onlineDate - Math.floor( Date.now() / 1000 );
        if ( diff > 0 ) {
            const byDay = 24 * 60 * 60;
            const byHour = 60 * 60;
            const byMinute = 60;

            const dayStg = Math.floor( diff / byDay ).toString().padStart( 2, '0' );
            const hourStg = Math.floor( ( diff % byDay  ) / byHour ).toString().padStart( 2, '0' );
            const minuteStg = Math.floor( ( diff % byHour  ) / byMinute ).toString().padStart( 2, '0' );
            const secondStg = ( diff % byMinute  ).toString().padStart( 2, '0' );

            this.clock = {
                day1: parseInt(dayStg[ 0 ]),
                day2: parseInt(dayStg[ 1 ]),
                hour1: parseInt(hourStg[ 0 ]),
                hour2: parseInt(hourStg[ 1 ]),
                minute1: parseInt(minuteStg[ 0 ]),
                minute2: parseInt(minuteStg[ 1 ]),
                second1: parseInt(secondStg[ 0 ]),
                second2: parseInt(secondStg[ 1 ]),
            };
        } else {
            this.clock = {
                day1: 0,
                day2: 0,
                hour1: 0,
                hour2: 0,
                minute1: 0,
                minute2: 0,
                second1: 0,
                second2: 0,
            };
        }

        this.animeDay1 = null;
        this.animeDay2 = null;
        this.animeHour1 = null;
        this.animeHour2 = null;
        this.animeMinute1 = null;
        this.animeMinute2 = null;
        this.animeSecond1 = null;
        this.animeSecond2 = setInterval( () => this.second2Play(), 1000);
	};

    componentWillUnmount() {
		clearInterval( this.animeDay1 );
		clearInterval( this.animeDay2 );
		clearInterval( this.animeHour1 );
		clearInterval( this.animeHour2 );
		clearInterval( this.animeMinute1 );
		clearInterval( this.animeMinute2 );
		clearInterval( this.animeSecond1 );
		clearInterval( this.animeSecond2 );
	};

    initClock = () => {
        $(".clock-container-second").removeClass("play");
        $("ul.second2Play li").eq( this.clock.second2 === 9 ? 0 : this.clock.second2 + 1 )
            .addClass("before")
            .removeClass("active");
        $("ul.second2Play li").eq( this.clock.second2 )
            .addClass("active")
            .closest(".clock-container-second")
            .addClass("play");
        $("ul.second1Play li").eq( this.clock.second1 === 5 ? 0 : this.clock.second1 + 1 )
            .addClass("before")
            .removeClass("active");
        $("ul.second1Play li").eq( this.clock.second1 )
            .addClass("active")
            .closest(".clock-container-second")
            .addClass("play");

        $(".clock-container-minute").removeClass("play");
        $("ul.minute2Play li").eq( this.clock.minute2 === 9 ? 0 : this.clock.minute2 + 1 )
            .addClass("before")
            .removeClass("active");
        $("ul.minute2Play li").eq( this.clock.minute2 )
            .addClass("active")
            .closest(".clock-container-minute")
            .addClass("play");
        $("ul.minute1Play li").eq( this.clock.minute1 === 5 ? 0 : this.clock.minute1 + 1 )
            .addClass("before")
            .removeClass("active");
        $("ul.minute1Play li").eq( this.clock.minute1 )
            .addClass("active")
            .closest(".clock-container-minute")
            .addClass("play");

        $(".clock-container-hour").removeClass("play");
        $("ul.hour2Play li").eq( this.clock.hour2 === 9 ? 0 : this.clock.hour2 + 1 )
            .addClass("before")
            .removeClass("active");
        $("ul.hour2Play li").eq( this.clock.hour2 )
            .addClass("active")
            .closest(".clock-container-hour")
            .addClass("play");
        $("ul.hour1Play li").eq( this.clock.hour1 === 2 ? 0 : this.clock.hour1 + 1 )
            .addClass("before")
            .removeClass("active");
        $("ul.hour1Play li").eq( this.clock.hour1 )
            .addClass("active")
            .closest(".clock-container-minute")
            .addClass("play");

        $(".clock-container-day").removeClass("play");
        $("ul.day2Play li").eq( this.clock.day2 === 9 ? 0 : this.clock.day2 + 1 )
            .addClass("before")
            .removeClass("active");
        $("ul.day2Play li").eq( this.clock.day2 )
            .addClass("active")
            .closest(".clock-container-day")
            .addClass("play");
        $("ul.day1Play li").eq( this.clock.day1 === 9 ? 0 : this.clock.day1 + 1 )
            .addClass("before")
            .removeClass("active");
        $("ul.day1Play li").eq( this.clock.day1 )
            .addClass("active")
            .closest(".clock-container-minute")
            .addClass("play");
    };

    day1Play = () => {
        $(".clock-container").removeClass("play");
        let $item = $("ul.day1Play li.active");
    
        if ($item.is(":first-child")) {
            $("ul.day1Play li").removeClass("before");
            $item.addClass("before").removeClass("active");
            $item = $("ul.day1Play li").eq(5);
            $item
                .addClass("active")
                .closest(".clock-container")
                .addClass("play");
        } else {
            $("ul.day1Play li").removeClass("before");
            $item
                .addClass("before")
                .removeClass("active")
                .prev("li")
                .addClass("active")
                .closest(".clock-container")
                .addClass("play");
        }
    };

    day2Play = () => {
        $(".clock-container").removeClass("play");
        let $item = $("ul.day2Play li.active");
    
        if ($item.is(":first-child")) {
            if ( ! this.animeDay1 ) {
                this.day1Play();
                this.animeDay1 = setInterval( () => this.day1Play(), (10 * 24 * 6 * 10 * 6 * 10 * 1000));
            }
            $("ul.day2Play li").removeClass("before");
            $item.addClass("before").removeClass("active");
            $item = $("ul.day2Play li").eq(9);
            $item
                .addClass("active")
                .closest(".clock-container")
                .addClass("play");
        } else {
            $("ul.day2Play li").removeClass("before");
            $item
                .addClass("before")
                .removeClass("active")
                .prev("li")
                .addClass("active")
                .closest(".clock-container")
                .addClass("play");
        }
    };

    hour1Play = () => {
        $(".clock-container").removeClass("play");
        let $item = $("ul.hour1Play li.active");
    
        if ($item.is(":first-child")) {
            if ( ! this.animeDay2 ) {
                this.day2Play();
                this.animeDay2 = setInterval( () => this.day2Play(), (24 * 6 * 10 * 6 * 10 * 1000));
            } 
            $("ul.hour1Play li").removeClass("before");
            $item.addClass("before").removeClass("active");
            $item = $("ul.hour1Play li").eq(2);
            $item
                .addClass("active")
                .closest(".clock-container")
                .addClass("play");
        } else {
            $("ul.hour1Play li").removeClass("before");
            $item
                .addClass("before")
                .removeClass("active")
                .prev("li")
                .addClass("active")
                .closest(".clock-container")
                .addClass("play");
        }
    };

    hour2Play = () => {
        $(".clock-container").removeClass("play");
        let $item = $("ul.hour2Play li.active");
    
        if ($item.is(":first-child")) {
            if ( $("ul.hour1Play li.active .inn").html() === '0' ) {
                clearInterval( this.animeHour1 );
                this.hour1Play();
                this.animeHour1 = setInterval( () => this.hour1Play(), (4 * 6 * 10 * 6 * 10 * 1000));
                $("ul.hour2Play li").removeClass("before");
                $item.addClass("before").removeClass("active");
                $item = $("ul.hour2Play li").eq(3);
                $item
                    .addClass("active")
                    .closest(".clock-container")
                    .addClass("play");
            } else {
                clearInterval( this.animeHour1 );
                this.hour1Play();
                this.animeHour1 = setInterval( () => this.hour1Play(), (10 * 6 * 10 * 6 * 10 * 1000));
                $("ul.hour2Play li").removeClass("before");
                $item.addClass("before").removeClass("active");
                $item = $("ul.hour2Play li").eq(9);
                $item
                    .addClass("active")
                    .closest(".clock-container")
                    .addClass("play");
            }
        } else {
            $("ul.hour2Play li").removeClass("before");
            $item
                .addClass("before")
                .removeClass("active")
                .prev("li")
                .addClass("active")
                .closest(".clock-container")
                .addClass("play");
        }
    };

    minute1Play = () => {
        $(".clock-container").removeClass("play");
        let $item = $("ul.minute1Play li.active");
    
        if ($item.is(":first-child")) {
            if ( ! this.animeHour2 ) {
                this.hour2Play();
                this.animeHour2 = setInterval( () => this.hour2Play(), (6 * 10 * 6 * 10 * 1000));
            } 
            $("ul.minute1Play li").removeClass("before");
            $item.addClass("before").removeClass("active");
            $item = $("ul.minute1Play li").eq(5);
            $item
                .addClass("active")
                .closest(".clock-container")
                .addClass("play");
        } else {
            $("ul.minute1Play li").removeClass("before");
            $item
                .addClass("before")
                .removeClass("active")
                .prev("li")
                .addClass("active")
                .closest(".clock-container")
                .addClass("play");
        }
    };

    minute2Play = () => {
        $(".clock-container").removeClass("play");
        let $item = $("ul.minute2Play li.active");
    
        if ($item.is(":first-child")) {
            if ( ! this.animeMinute1 ) {
                this.minute1Play();
                this.animeMinute1 = setInterval( () => this.minute1Play(), (10 * 6 * 10 * 1000));
            } 
            $("ul.minute2Play li").removeClass("before");
            $item.addClass("before").removeClass("active");
            $item = $("ul.minute2Play li").eq(9);
            $item
                .addClass("active")
                .closest(".clock-container")
                .addClass("play");
        } else {
            $("ul.minute2Play li").removeClass("before");
            $item
                .addClass("before")
                .removeClass("active")
                .prev("li")
                .addClass("active")
                .closest(".clock-container")
                .addClass("play");
        }
    };

    second1Play = () => {
        $(".clock-container-second").removeClass("play");
        let $item = $("ul.second1Play li.active");
    
        if ($item.is(":first-child")) {
            if ( ! this.animeMinute2 ) {
                this.minute2Play();
                this.animeMinute2 = setInterval( () => this.minute2Play(), (6 * 10 * 1000));
            } 
            $("ul.second1Play li").removeClass("before");
            $item.addClass("before").removeClass("active");
            $item = $("ul.second1Play li").eq(5);
            $item
                .addClass("active")
                .closest(".clock-container-second")
                .addClass("play");
        } else {
            $("ul.second1Play li").removeClass("before");
            $item
                .addClass("before")
                .removeClass("active")
                .prev("li")
                .addClass("active")
                .closest(".clock-container-second")
                .addClass("play");
        }
    };

    second2Play = () => {
        $(".clock-container-second").removeClass("play");
        let $item = $("ul.second2Play li.active");
    
        if ( $item.html() === undefined ) {
            this.initClock();
        } else if ($item.is(":first-child")) {
            if ( ! this.animeSecond1 ) {
                this.second1Play();
                this.animeSecond1 = setInterval( () => this.second1Play(), (10 * 1000));
            } 
            $("ul.second2Play li").removeClass("before");
            $item.addClass("before").removeClass("active");
            $item = $("ul.second2Play li").eq(9);
            $item
                .addClass("active")
                .closest(".clock-container-second")
                .addClass("play");
        } else {
            $("ul.second2Play li").removeClass("before");
            $item
                .addClass("before")
                .removeClass("active")
                .prev("li")
                .addClass("active")
                .closest(".clock-container-second")
                .addClass("play");
        }
    };

    renderDigit = int => {
        return (
            <div className="clock-cards">
                <div className="up">
                    <div className="shadow"></div>
                    <div className="inn">{ int }</div>
                </div>
                <div className="down">
                    <div className="shadow"></div>
                    <div className="inn">{ int }</div>
                </div>
            </div>
        );
    };

	render() {
        const { language } = this.props;

		return (
            <div className="clock-container">
                <div className="clock-container-day">
                    <ul className="flip day1Play">
                        <li>{ this.renderDigit( 0 ) }</li>
                        <li>{ this.renderDigit( 1 ) }</li>
                        <li>{ this.renderDigit( 2 ) }</li>
                        <li>{ this.renderDigit( 3 ) }</li>
                        <li>{ this.renderDigit( 4 ) }</li>
                        <li>{ this.renderDigit( 5 ) }</li>
                        <li>{ this.renderDigit( 6 ) }</li>
                        <li>{ this.renderDigit( 7 ) }</li>
                        <li>{ this.renderDigit( 8 ) }</li>
                        <li>{ this.renderDigit( 9 ) }</li>
                    </ul>
                    <ul className="flip day2Play">
                        <li>{ this.renderDigit( 0 ) }</li>
                        <li>{ this.renderDigit( 1 ) }</li>
                        <li>{ this.renderDigit( 2 ) }</li>
                        <li>{ this.renderDigit( 3 ) }</li>
                        <li>{ this.renderDigit( 4 ) }</li>
                        <li>{ this.renderDigit( 5 ) }</li>
                        <li>{ this.renderDigit( 6 ) }</li>
                        <li>{ this.renderDigit( 7 ) }</li>
                        <li>{ this.renderDigit( 8 ) }</li>
                        <li>{ this.renderDigit( 9 ) }</li>
                    </ul>
                    <div className="clock-label">{ t( 'clock:days', language ) }</div>
                </div>
                <div className="separator-colon">:</div>
                <div className="clock-container-hour">
                    <ul className="flip hour1Play">
                        <li>{ this.renderDigit( 0 ) }</li>
                        <li>{ this.renderDigit( 1 ) }</li>
                        <li>{ this.renderDigit( 2 ) }</li>
                    </ul>
                    <ul className="flip hour2Play">
                        <li>{ this.renderDigit( 0 ) }</li>
                        <li>{ this.renderDigit( 1 ) }</li>
                        <li>{ this.renderDigit( 2 ) }</li>
                        <li>{ this.renderDigit( 3 ) }</li>
                        <li>{ this.renderDigit( 4 ) }</li>
                        <li>{ this.renderDigit( 5 ) }</li>
                        <li>{ this.renderDigit( 6 ) }</li>
                        <li>{ this.renderDigit( 7 ) }</li>
                        <li>{ this.renderDigit( 8 ) }</li>
                        <li>{ this.renderDigit( 9 ) }</li>
                    </ul>
                    <div className="clock-label">{ t( 'clock:hours', language ) }</div>
                </div>
                <div className="separator-colon">:</div>  
                <div className="clock-container-minute">
                    <ul className="flip minute1Play">
                        <li>{ this.renderDigit( 0 ) }</li>
                        <li>{ this.renderDigit( 1 ) }</li>
                        <li>{ this.renderDigit( 2 ) }</li>
                        <li>{ this.renderDigit( 3 ) }</li>
                        <li>{ this.renderDigit( 4 ) }</li>
                        <li>{ this.renderDigit( 5 ) }</li>
                    </ul>
                    <ul className="flip minute2Play">
                        <li>{ this.renderDigit( 0 ) }</li>
                        <li>{ this.renderDigit( 1 ) }</li>
                        <li>{ this.renderDigit( 2 ) }</li>
                        <li>{ this.renderDigit( 3 ) }</li>
                        <li>{ this.renderDigit( 4 ) }</li>
                        <li>{ this.renderDigit( 5 ) }</li>
                        <li>{ this.renderDigit( 6 ) }</li>
                        <li>{ this.renderDigit( 7 ) }</li>
                        <li>{ this.renderDigit( 8 ) }</li>
                        <li>{ this.renderDigit( 9 ) }</li>
                    </ul>
                    <div className="clock-label">{ t( 'clock:minutes', language ) }</div>
                </div>
                <div className="separator-colon">:</div>
                <div className="clock-container-second">
                    <ul className="flip second1Play">
                        <li>{ this.renderDigit( 0 ) }</li>
                        <li>{ this.renderDigit( 1 ) }</li>
                        <li>{ this.renderDigit( 2 ) }</li>
                        <li>{ this.renderDigit( 3 ) }</li>
                        <li>{ this.renderDigit( 4 ) }</li>
                        <li>{ this.renderDigit( 5 ) }</li>
                    </ul>
                    <ul className="flip second2Play">
                        <li>{ this.renderDigit( 0 ) }</li>
                        <li>{ this.renderDigit( 1 ) }</li>
                        <li>{ this.renderDigit( 2 ) }</li>
                        <li>{ this.renderDigit( 3 ) }</li>
                        <li>{ this.renderDigit( 4 ) }</li>
                        <li>{ this.renderDigit( 5 ) }</li>
                        <li>{ this.renderDigit( 6 ) }</li>
                        <li>{ this.renderDigit( 7 ) }</li>
                        <li>{ this.renderDigit( 8 ) }</li>
                        <li>{ this.renderDigit( 9 ) }</li>
                    </ul>
                    <div className="clock-label">{ t( 'clock:seconds', language ) }</div>
                </div>
            </div>
		);
	}
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
});

export default connect( stateToProps )( CountDownCards );