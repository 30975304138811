import {
	DATA_CART, DATA_LANGUAGE, DATA_VLOG, DATA_VLOG_ALT,
	LANGUAGE,
	STORE_PURCHASE_STATES,
	VERSION_PROFILE, VERSION_PROFILE_EDIT, VERSION_PROFILE_NOCLI,
} from '../constants';
import { translation } from '../translations';

export const authFailed = () => {
    localStorage.removeItem( DATA_VLOG );
	localStorage.removeItem( DATA_VLOG_ALT );
	window.location.href = '/browse/#/';
};

export const checkEmail = email => {
    // eslint-disable-next-line
    const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test( ( email ).toLowerCase() );
};

export const checkiOS = () => {
	let iOS = false;
	if ( [
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod' ].includes(navigator.platform)
			|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
	) {
		iOS = true;
	}
	return iOS;
};

export const convertPurchaseState = ( state, language ) => {
	switch( state ) {
		case STORE_PURCHASE_STATES.DELIVERED: return t( 'store:delivered', language );
		case STORE_PURCHASE_STATES.DELIVERYPROCESS: return t( 'store:deliveryProgress', language );
		case STORE_PURCHASE_STATES.PAID: return t( 'store:deliveryStart', language );
		default: return state;
	}
};

export const convertSecondsToDuration = seconds => {
	if ( ! seconds || seconds < 60 ) return null;
	const hours = Math.floor( seconds / 3600 );
	const hoursStg = hours > 0 ? `${ hours } h ` : '';
	const minutes = Math.floor( seconds / 60 );
	const minutesStg = minutes > 0 ? `${ minutes } min` : ''; 
	return `${ hoursStg }${ minutesStg }`;
};

export const convertToCsaKey = csa => csa;

export const convertToDefKey = def => {
	switch ( def ) {
		case '4K': return 'def4K';
		case '4-3': return 'def43';
		case 'high-definition': return 'defHD';
		default: return 'def43';
	}
};

export const convertTypeAbo = ( TypeAbo, language ) => {
	switch( TypeAbo ) {
		case '1':
		case '2':
		case '3':
			return {
				adj: t( 'subscription:month1Adj', language ),
				int: 1,
				stg: t( 'subscription:month1Stg', language ),
				stgLong: t( 'subscription:month1StgLong', language ),
			};
		case '13':
		case '23':
			return {
				adj: t( 'subscription:month3Adj', language ),
				int: 3,
				stg: t( 'subscription:month3Stg', language ),
				stgLong: t( 'subscription:month3StgLong', language ),
			};
		case '16':
			return {
				adj: t( 'subscription:month6Adj', language ),
				int: 6,
				stg: t( 'subscription:month6Stg', language ),
				stgLong: t( 'subscription:month6StgLong', language ),
			};
		case '112':
		case '212':
			return {
				adj: t( 'subscription:month12Adj', language ),
				int: 12,
				stg: t( 'subscription:month12Stg', language ),
				stgLong: t( 'subscription:month12StgLong', language ),
			};
		default :
			return { adj: '', int: 0, stg: '', stgLong: '' };
	}
};

export const detectBrowser = () => {
	const userAgent = navigator.userAgent;
	const match = userAgent.match( /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i ) || [];
	let test;
		
    if( /trident/i.test( match[1] ) ) {
		test = /\brv[ :]+(\d+)/g.exec( userAgent ) || [];
		return 'IE'+ ( test[1] || '' );
	}
	
    if( match[1] === 'Chrome' ) {
        test = userAgent.match( /\b(OPR|Edge)\/(\d+)/ );
        if( test ) return test.slice(1).join( '' ).replace( 'OPR', 'Opera' );
	}

	// let resultArr = match[2] ? [ match[1], match[2] ] : [ navigator.appName, navigator.appVersion, '-?' ];
	let resultArr = match[2] ? [ match[1], match[2] ] : [ 'unknown', '' ];
	test = userAgent.match( /version\/(\d+)/i );
	if ( test ) resultArr.splice( 1, 1, test[1] );

    return resultArr.join( '' );
};

export const detectEnv = () => {
	const userAgent = navigator.userAgent;

	if ( [ 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod' ].includes( navigator.platform )
			|| ( userAgent.includes("Mac") && "ontouchend" in document ) ) return 'iOS';

	const match = userAgent.match( /(Android|BlackBerry|Opera Mini|IEMobile|WPDesktop)/i );
	if ( match ) {
		if ( match[ 0 ] === 'Opera Mini' ) return 'Opera';
		if ( [ 'IEMobile', 'WPDesktop' ].includes( match[ 0 ] ) ) return 'Windows';

		return match[ 0 ];
	}
	
	return 'desktop';
};

export const findProperNextIndex = ( index, array ) => {
	if ( ! array ) return null;
	return index + 1 >= array.length ? 0 : index + 1;
};

export const formatCartEuroPriceTotal = cart => {
	if ( cart && cart.length > 0 ) {
		let total = 0;
		cart.forEach( el => {
			if( el.price && el.productQuantity ) total += ( el.price * el.productQuantity );
		});
		if ( total ) return formatEuroPrice( total );
	}
	return '';
};

export const formatDateForAvailability = ( timestamp, language ) => {
	const date = new Date( timestamp * 1000 );
	return `${ t( 'shared:availableDate', language ) } ${ formatDateOnly( timestamp, language ) } ${ t( 'shared:atDate', language ) } ${ date.getHours() }h${ date.getMinutes().toString().padStart( 2, '0' ) }`;
};

export const formatDateForUser = ( timestamp, language ) => {
	const date = new Date( timestamp * 1000 );
	return new Intl.DateTimeFormat( language, { dateStyle: 'full' } ).format(date);
};

export const formatDateOnly = ( timestamp, language ) => {
	const date = new Date( timestamp * 1000 );
	return new Intl.DateTimeFormat( language, { day: 'numeric', month: 'long' } ).format(date); 
};

export const formatEuroPrice = price => {
	return `${ ( price / 100 ).toString().replace( '.', ',' ) }€`;
};

export const formatTimeLeftForAvailability = ( timestamp, language ) => {
	const diff = Math.floor( ( ( timestamp * 1000 ) - Date.now() ) / ( 24 * 60 * 60 * 1000 ) );

	if ( diff <= 0 ) return t( 'available:last', language );
	if ( diff === 1 ) return t( 'available:one', language );
	return `${ t( 'available:morePart1', language ) }${ diff }${ t( 'available:morePart1', language ) }`;
};

export const formatTimeProgress = ( duration, seconds ) => {
	if ( seconds < 0 ) return ``;
	if ( duration <= 0 ) return ``;
	const width = `${parseInt( ( seconds * 100 ) / duration )}%`;

	return `<div id="progressBar" style="width:${ width }">`;
};

export const formatTimeAdvertising = seconds => {
	if ( seconds < 0 ) return "";
	const minutes = Math.floor( seconds / 60 );
	const secondsFormated = ( seconds % 60 ).toString().padStart( 2, '0' );
	return `(${ minutes }:${ secondsFormated })`;
};

export const formatTimeSince = ( timestamp, language ) => {
	const diff = Math.floor( ( ( Date.now() / 1000 ) - timestamp ) / 60 );
	if ( diff < 2 ) return `${ t( 'time:since', language ) } 1 ${ t( 'time:minute', language ) }`;
	if ( diff < 60 ) return `${ t( 'time:since', language ) } ${ diff } ${ t( 'time:minutes', language ) }`;
	if ( diff < 2 * 60 ) return `${ t( 'time:since', language ) } 1 ${ t( 'time:hour', language ) }`;
	if ( diff < 24 * 60 ) return `${ t( 'time:since', language ) } ${ Math.floor( diff / 60 ) } ${ t( 'time:hours', language ) }`;
	if ( diff < 2 * 24 * 60 ) return `${ t( 'time:since', language ) } 1 ${ t( 'time:day', language ) }`;
	if ( diff < 31 * 24 * 60 ) return `${ t( 'time:since', language ) } ${ Math.floor( diff / ( 24 * 60 ) ) } ${ t( 'time:days', language ) }`;
	if ( diff < 2 * 31 * 24 * 60 ) return `${ t( 'time:since', language ) } 1 ${ t( 'time:month', language ) }`;
	if ( diff < 365 * 24 * 60 ) return `${ t( 'time:since', language ) } ${ Math.floor( diff / ( 31 * 24 * 60 ) ) } ${ t( 'time:months', language ) }`;
	if ( diff < 2 * 365 * 24 * 60 ) return `${ t( 'time:since', language ) } 1 ${ t( 'time:year', language ) }`;
	return `${ t( 'time:since', language ) } ${ Math.floor( diff / ( 365 * 24 * 60 ) ) } ${ t( 'time:years', language ) }`;
};

export const getLanguage = () => {
	const language = localStorage.getItem( DATA_LANGUAGE );
	if ( language ) return language;
	return LANGUAGE.FRENCH;
};

export const getCartInfo = () => {
	if ( localStorage.getItem( DATA_CART ) ) {
		const localCart = JSON.parse( localStorage.getItem( DATA_CART ) );
		let localCartProductsTotal = 0;
		localCart.forEach( el => {
			localCartProductsTotal += el.productQuantity;
		});

		return {
			cart: localCart,
			cartLenght: localCart.length,
			cartProductsTotal: localCartProductsTotal,
		};
	}
	return {
		cart: [],
		cartLenght: 0,
		cartProductsTotal: 0,
	};
};

export const getMovieAdditionalInfo = ( movie, language ) => {
	let additionalInfoArr = [];
	if ( movie.type === "series" ) {
		additionalInfoArr.push( `${ movie.episodes.length } ${ t( 'shared:episodeSmall', language ) }${ movie.episodes.length > 1 ? 's' : '' }` );
	}
	if ( movie.durationSeconds && movie.durationSeconds !== '0' ) additionalInfoArr.push( convertSecondsToDuration( parseInt( movie.durationSeconds ) ) );
	if ( movie.shootingDate && movie.shootingDate !== '' ) additionalInfoArr.push( movie.shootingDate );
	if ( movie.shootingCountry && movie.shootingCountry.length > 0 ) {
		additionalInfoArr.push( movie.shootingCountry[ 0 ] );
		if ( movie.shootingCountry[ 0 ] === 'France' && movie.shootingCountryAddOn && movie.shootingCountryAddOn.length > 0 ) {
			additionalInfoArr.push( movie.shootingCountryAddOn[ 0 ] );
		} 
	}

	return additionalInfoArr.join( ' - ' );
};

export const getProfileVersion = () => {
	if ( localStorage.getItem( VERSION_PROFILE ) ) {
		return parseInt( localStorage.getItem( VERSION_PROFILE ) );
	}
	return 0;
};

export const getProfileVersionEdit = () => {
	if ( sessionStorage.getItem( VERSION_PROFILE_EDIT ) ) {
		return parseInt( sessionStorage.getItem( VERSION_PROFILE_EDIT ) );
	}
	return 0;
};

export const getProfileVersionNoCli = () => {
	if ( localStorage.getItem( VERSION_PROFILE_NOCLI ) ) {
		return localStorage.getItem( VERSION_PROFILE_NOCLI );
	}
	return '';
};

export const getProgressBarStyle = ( progressTime, timeTotal ) => {
	return { width: `${ parseInt( ( progressTime * 100 ) / timeTotal ) }%` };
};

export const getRoles = ( role, language ) => {
	switch ( role ) {
		case 'actor': return t( 'distribution:actor', language );
		case 'producer': return t( 'distribution:producer', language );
		case 'director': return t( 'distribution:director', language );
		default : return 'Acteur';
	}
};

export const getShortSynopsis = synopsis => {
	if ( synopsis ) {
		if ( synopsis.length > 150 ) return synopsis.slice(0, 150) + "...";
		return synopsis;
	}
	return '';
};

export const getUrlParams = () => {
    let paramsArray = {};

	const urlEnd = window.location.href.split( '/#/' );
	if ( urlEnd.length > 1 ) {
		const urlArray = urlEnd[ 1 ].split( '?' );
		if ( urlArray.length > 1 ) {
			urlArray[ 1 ].split( '&' ).forEach( el => {
				let tmpArray = el.split( '=' );
				paramsArray[ tmpArray[ 0 ] ] = tmpArray[ 1 ];
			});    
		}
	}

    return paramsArray;
};

export const getUserInfo = () => {		
    let data = localStorage.getItem( DATA_VLOG );
	let dataJSON = {};
	if ( data ) {
		dataJSON = JSON.parse( data );
	} else {
        data = localStorage.getItem( DATA_VLOG_ALT );
        if ( data ) dataJSON = JSON.parse( data );
    }
    return dataJSON;
};

export const getStarWidth = ( index, average ) => {
	return average < index ? ( ( ( average + 1 ) > index ) ? 4 + 13.5 * ( average - ( index - 1 ) ) : 0 ) : 20;
};

export const handleLoader = ( type, action ) => {
    const $loader = document.getElementById( type );
    if ( $loader ) {
		if ( action === 'add' ) $loader.classList.add( 'show' );
		if ( action === 'remove' ) $loader.classList.remove( 'show' );
    };
};

export const isGuest = () => {
	return getUserInfo().is_guest !== '0';
};

export const isProgressTimeValid = ( progressTime, timeTotal ) => {
	return progressTime && parseInt( progressTime ) > 0 && timeTotal && parseInt( timeTotal ) >= parseInt( progressTime );
};

export const removeUrlParams = () => {
	const urlEnd = window.location.href.split( '/#/' );
	if ( urlEnd.length > 1 ) {
		const urlArray = urlEnd[ 1 ].split( '?' );
		if ( urlArray.length > 1 ) {
			window.location.href = `${ urlEnd[ 0 ] }/#/${ urlArray[ 0 ] }`
		}
	}
};

export const t = ( label, language ) => {
	const labelArr = label.split( ':' );
	if ( ! labelArr[ 1 ] ) return '';
	const labelPage = labelArr[ 0 ];
	const labelKey = labelArr[ 1 ];

    if ( language === LANGUAGE.ENGLISH && translation[ LANGUAGE.ENGLISH ][ labelPage ] && translation[ LANGUAGE.ENGLISH ][ labelPage ][ labelKey ] )
		return translation[ LANGUAGE.ENGLISH ][ labelPage ][ labelKey ];
    if ( translation[ LANGUAGE.FRENCH ][ labelPage ] && translation[ LANGUAGE.FRENCH ][ labelPage ][ labelKey ] )
		return translation[ LANGUAGE.FRENCH ][ labelPage ][ labelKey ];
    return '';
};

// export const shuffleArray = ( array ) => {
//     for ( let i = array.length - 1; i > 0; i-- ) {
//         const j = Math.floor( Math.random() * ( i + 1 ) );
//         [ array[i], array[j] ] = [ array[j], array[i] ];
//     }
// 	return array;
// };