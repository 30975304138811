import React from "react";
import { connect } from "react-redux";

import Fade from 'react-reveal/Fade';

import { ICONS } from "../../constants";
import { t } from "../../utils";

class PSectionAboutus extends React.Component {
    render() {
		const { language } = this.props;

        const $content =
            <>
                <div id='profile_container'>
                    <div id='profile_nav'>
                        <span className='profile_nav' onClick={ () => { this.props.handlePageChange( 'left', 'menu' ) } }>{ t( 'profile:title', language ) }</span>
                        <span><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></span>
                        <span>{ t( 'profile:aboutUs', language ) }</span>
                    </div>
					<div id='profile_header' className='profile_header_title'>
						<div><img className='profile_icon title_icon' src={ ICONS.info } alt='icone info'/></div>
						<h1>{ t( 'profile:aboutUs', language ) }</h1>
					</div>
                    <div id='profile_body'>
						<div className='profile_menu_title'>
							<div>{ t( 'profile:social', language ) }</div>
						</div>
						<a className='collect_flag profile_menu_small profile_hover' id='profile_menu_facebook' href='https://www.facebook.com/zone300/'>
                            <div><img className='profile_icon' src={ ICONS.facebook } alt='icone facebook'/></div>
                            <div className='profile_scale'>Facebook</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</a>
						<a className='collect_flag profile_menu_small profile_hover' id='profile_menu_instagram' href='https://www.instagram.com/zone300.chasse/'>
                            <div><img className='profile_icon' src={ ICONS.instagram } alt='icone instagram'/></div>
                            <div className='profile_scale'>Instagram</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</a>
						<a className='collect_flag profile_menu_small profile_hover' id='profile_menu_youtbe' href='https://www.youtube.com/c/zone300/'>
                            <div><img className='profile_icon' src={ ICONS.youtube } alt='icone youtube'/></div>
                            <div className='profile_scale'>Youtube</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</a>
						<a className='collect_flag profile_menu_small profile_hover' id='profile_menu_twitter' href='https://twitter.com/Zone300off/'>
                            <div><img className='profile_icon' src={ ICONS.twitter } alt='icone twitter'/></div>
                            <div className='profile_scale'>Twitter</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</a>
						<a className='collect_flag profile_menu_small profile_hover' id='profile_menu_email_contact' href='mailto:contact@zone300.com'>
                            <div><img className='profile_icon' src={ ICONS.mail } alt='icone email'/></div>
                            <div className='profile_scale'>Email</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</a>
						<div className='profile_menu_title'>
							<div>Zone300</div>
						</div>
						<a className='collect_flag profile_menu_small profile_hover' id='profile_about_us' href='/qui-sommes-nous/'>
                            <div className='profile_scale'>{ t( 'profile:whoAreWe', language ) }</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</a>
						<a className='collect_flag profile_menu_small profile_hover' id='profile_distributors' href='/nos-distributeurs/'>
                            <div className='profile_scale'>{ t( 'profile:distributors', language ) }</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</a>
                        <a className='collect_flag profile_menu_small profile_hover' id='profile_partners' href='/nos-partenaires/'>
                            <div className='profile_scale'>{ t( 'profile:partners', language ) }</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</a>
						<a className='collect_flag profile_menu_small profile_hover' id='profile_media' href='/media/'>
                            <div className='profile_scale'>{ t( 'profile:media', language ) }</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</a>
                        <div className='profile_menu_title'>
							<div>{ t( 'profile:legal', language ) }</div>
						</div>
						<a className='collect_flag profile_menu_small profile_hover' id='profile_rgpd' href='/mentions-legales/rgpd/'>
                            <div className='profile_scale'>{ t( 'profile:data', language ) }</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</a>
						<a className='collect_flag profile_menu_small profile_hover' id='profile_cgu' href='/mentions-legales/abonnement/'>
                            <div className='profile_scale'>{ t( 'profile:terms', language ) }</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</a>
                        <a className='collect_flag profile_menu_small profile_hover' id='profile_cookies' href='/mentions-legales/cookies/'>
                            <div className='profile_scale'>{ t( 'profile:cookies', language ) }</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</a>
						<a className='collect_flag profile_menu_small profile_hover' id='profile_confidentiality' href='/mentions-legales/confidentialite/'>
                            <div className='profile_scale'>{ t( 'profile:confidentiality', language ) }</div>
							<div className='f-grow-1'></div>
							<div><img className='profile_icon mirror_icon' src={ ICONS.backArrow } alt='icone suivant'/></div>
						</a>
					</div>
				</div>
            </>;

        if ( this.props.direction === 'right' ) return <Fade right duration={ 800 } >{ $content }</Fade>;
        if ( this.props.direction === 'left' ) return <Fade left duration={ 800 } >{ $content }</Fade>;

        return <></>;
    };
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
});

export default connect( stateToProps )( PSectionAboutus );