import React from 'react';

import ListHeader from '../ListRows/ListHeader';
import ListMovies from '../ListRows/ListMovies';

export default class RowRender extends React.Component {
    
    getClassName = display => {
        let searchClassName = '';
        if ( display.searchType && display.searchType !== 'usual' ) searchClassName = 'withSSR';
        if ( display.searchType && display.searchType === 'videos_participants' ) searchClassName = 'withSSR circleDisplay';

        return `GBListItem ${ display.color || '' } ${ display.effect || '' } ${ display.extraClass || '' } ${ searchClassName }`;
    };

    render() {
        const { movieListInfo } = this.props;
        
        return (
            <>
                {
                    movieListInfo && movieListInfo.length > 0 &&
                    movieListInfo.map( ( item, index ) =>
                        <div className={ this.getClassName( item.display ) } key={ index }>
                            <ListHeader display={ item.display } isMozaicPage={ true } />
                            <ListMovies
                                display={ item.display }
                                isMozaicPage={ true }
                                movieList={ item.movies }
                                movieListIndex={ item.display.id } />
                        </div>
                    )
                }
            </>
        )
    }
}