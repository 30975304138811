import React from "react";
import { connect } from "react-redux";

import { API_IMAGE, IMAGES } from "../../constants";
import { formatEuroPrice, t } from "../../utils";

class ListProductItem extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.state = { imageLoadNoCache: true, imageLoadError: true };
	};

	handleClick = () => {
		const { product } = this.props;
		if ( product.url ) window.location.href = `/browse/?boutique=${ product.url }/#/boutique/${ product.url }`;
	};

	isWithDefaultDiscount = () => {
        const { userDiscarded, userInfoDB } = this.props;
        return userInfoDB && userInfoDB.default_discount && ! userDiscarded;
    };

	renderPic = product => {
		if ( product.productId !== null ) {
			const imageAlt = product.name;
			const srcImage = `${ API_IMAGE }${ product.mainFileName }.png&w=500&h=500&crop-to-fit`;
			const srcImage_ = `${ API_IMAGE }${ product.gallery1FileName }.png&w=500&h=500&crop-to-fit`;
			const srcErrorImage = IMAGES.placeholderSquare;
			
			return (
				<div className='moving-image-box'>
					<img
						className='tile-img mainFileName'
						alt={ imageAlt }
						src={ srcImage } 
						onError={e => {
							if ( this.state.imageLoadNoCache ) {
								this.setState({ imageLoadNoCache: false });
								e.target.src = `${ srcImage }&no-cache`;
							} else if ( this.state.imageLoadError ) {
								this.setState({ imageLoadError: false });
								e.target.src = srcErrorImage;
							}
						}} />
					<img
						className='tile-img gallery1FileName'
						alt={ imageAlt }
						src={ srcImage_ } 
						onError={e => {
							if ( this.state.imageLoadNoCache ) {
								this.setState({ imageLoadNoCache: false });
								e.target.src = `${ srcImage }&no-cache`;
							} else if ( this.state.imageLoadError ) {
								this.setState({ imageLoadError: false });
								e.target.src = srcErrorImage;
							}
						}} />
				</div>
				
			);
		}

		return (
			<img
				alt="img"
				className="tile-img"
				src={ IMAGES.placeholderSquare } />
		);
	};

	render() {
		const { catId, language, product, userInfoDB } = this.props;

		let price;
		let priceInitial;
		if ( product && product.price ) {
			if ( this.isWithDefaultDiscount() ) {
				price = Math.floor( product.price - ( ( product.price * userInfoDB.default_discount ) / 100 ) );
				priceInitial = product.price;
			} else {
				price = product.price;
			}
		}

		return (
			<div className='shine-container'>
				<figure
					className={ `effect-thumbnail-movie collect_flag` }
					id={ `cat-${ catId }-movie-${ product.productId }` }
					onClick={ this.handleClick }>
					{ this.renderPic( product ) }
					{
						product.isOutOfStock && product.isOutOfStock === '1' &&
						<figcaption>
							<div className="containerBadges">
								<div className="badge-outOfStock">{ t( 'store:outOfStock', language ) }</div>
							</div>
						</figcaption>
					}
				</figure>
				<div className='with-description-info'>
					<div className="with-description-colors">
						{
							product.colorsCss && product.colorsCss.length > 0 &&
							product.colorsCss.map( ( item, index ) =>
								<div className="with-description-colors-dots" style={{ 'backgroundColor' : item }} key={ index }></div>
							)
						}
					</div>
					<div className="with-description-price">
						{ priceInitial && <span className='with-description-price-initial'>{ formatEuroPrice( priceInitial ) }</span> }
						{ formatEuroPrice( price ) }
					</div>
					<div className="with-description-title product-item">
						{ product.name }
					</div>
				</div>
			</div>
		);
	};
}

const stateToProps = state => ({
	language: state.translatingReducer.language,
	userDiscarded: state.userReducer.userDiscarded,
	userInfoDB: state.userReducer.userInfoDB,
});

export default connect( stateToProps )( ListProductItem );