import React from "react";
import { connect }  from "react-redux";

import { Elements } from "@stripe/react-stripe-js";

import ButtonClose from "../components/ButtonClose";
import HeaderLogo from "../components/HeaderLogo";

import PaymentRecapBox from "./components/PaymentRecapBox";
import StripeCheckoutForm from "./components/StripeCheckoutForm";

import { CURRENCY, IMAGES, PAYMENT_REDUCER, POPUP_REDUCER, STRIPE_KEY } from "../../../constants";
import { paymentAction, popupAction } from "../../../redux/actions";
import { getUserInfo, t } from "../../../utils";

import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe( STRIPE_KEY );

class PopupPaymentConfirm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            discountAmountStg: '',
            isCodeValid: false,
            priceStgCurrent: '',
            priceStgOld: '',
            productSelectedInfo: {},
            errorMessage: '',
            successMessage: '',
        };
    };

    componentDidMount() {
        const { codeId, fetchProductPaymentInfo, productId } = this.props;
        if ( productId && getUserInfo().NoCli ) fetchProductPaymentInfo( productId, getUserInfo().NoCli, codeId );
  	};

    componentDidUpdate( prevProps ) {
        const { codeId, fetchProductPaymentInfo, productId } = this.props;
		if ( this.props.productId !== prevProps.productId ) {
            if ( productId && getUserInfo().NoCli ) fetchProductPaymentInfo( productId, getUserInfo().NoCli, codeId );
		}
	};

    componentWillReceiveProps( newProps ) {
        const { productPaymentInfo, productType, userGeoInfo } = newProps;
        const currency = userGeoInfo && userGeoInfo.currency ? userGeoInfo.currency : CURRENCY.EUR;
        if ( productPaymentInfo !== undefined && productPaymentInfo.id ) {
            if ( productPaymentInfo.codeInfo ) {
                this.setState({
                    discountAmountStg: productPaymentInfo.codeInfo.amountStg,
                    isCodeValid: true,
                    priceStgCurrent: productType === PAYMENT_REDUCER.TYPE_RENT ? productPaymentInfo.codeInfo.pricesRent[ currency ].priceStg : productPaymentInfo.codeInfo.prices[ currency ].priceStg,
                    priceStgOld: productType === PAYMENT_REDUCER.TYPE_RENT ? productPaymentInfo.pricesRent[ currency ].priceStg : productPaymentInfo.prices[ currency ].priceStg,
                    productSelectedInfo: productPaymentInfo
                });
            } else {
                this.setState({
                    priceStgCurrent: productType === PAYMENT_REDUCER.TYPE_RENT ? productPaymentInfo.pricesRent[ currency ].priceStg : productPaymentInfo.prices[ currency ].priceStg,
                    productSelectedInfo: productPaymentInfo
                });
            }
        }
        return null;
    };

    render() {
        const { codeId, language, productId, productType, popupOpen, userGeoInfo } = this.props;
        const { discountAmountStg, isCodeValid, priceStgCurrent, priceStgOld, productSelectedInfo } = this.state;
        const currency = userGeoInfo && userGeoInfo.currency ? userGeoInfo.currency : CURRENCY.EUR;

        return (
            <>
                <HeaderLogo />
                <div className="popup-payment-progress-nav">
                    <div className="popup-payment-progress-nav-item">{ t( 'payment:nav1', language ) }</div>
                    <div className="popup-payment-progress-nav-item selected">{ t( 'payment:nav2', language ) }</div>
                    <div className="popup-payment-progress-nav-item">&nbsp;</div>
                </div>
                <div className="popup-payment-recap">     
                    <PaymentRecapBox
                        currency={ currency }
                        discountAmountStg={ discountAmountStg }
                        isCodeValid={ isCodeValid }
                        priceStgCurrent={ priceStgCurrent }
                        priceStgOld={ priceStgOld }
                        productInfo={ productSelectedInfo } />
                </div>
                <div className="local_stripe_container">
                    <Elements stripe={ stripePromise }>
                        <StripeCheckoutForm
                            codeId={ codeId }
                            currency={ currency }
                            language={ language }
                            priceStgCurrent={ priceStgCurrent }
                            popupOpen={ popupOpen }
                            productId={ productId }
                            productType={ productType }
                            userId={ getUserInfo().NoCli } />
                    </Elements>
                    <div className="local_stripe_info">
                        <div className="local_stripe_info_text">
                            <i className="fas fa-lock" aria-hidden="true"></i>
                            { t( 'payment:info', language ) }
                        </div>
                        <div className="local_stripe_info_img">
                            <img src={IMAGES.cbAcceptedImg} alt='paiements acceptes'/>
                        </div>
                    </div>
                </div>        
                <ButtonClose popupName={ POPUP_REDUCER.POPUP_PAYMENT_CONFIRM } />
            </>
        );
    }
}

const stateToProps = state => ({
    codeId: state.paymentReducer.codeId,
    language: state.translatingReducer.language,
    productId: state.paymentReducer.productId,
    productPaymentInfo: state.paymentReducer.productPaymentInfo,
    productType: state.paymentReducer.productType,
    userGeoInfo: state.userReducer.userGeoInfo,
});

const dispatchToProps = dispatch => ({
    fetchProductPaymentInfo: ( productId, userId, codeId ) => dispatch(paymentAction.fetchProductPaymentInfo( productId, userId, codeId )),
    popupOpen: popupName => dispatch(popupAction.popupOpen( popupName )),
});

export default connect(stateToProps, dispatchToProps)(PopupPaymentConfirm);
