import React from 'react';
import { connect } from "react-redux";

import InputControl from './InputControl';
import { t } from '../../../utils';

class SearchModule extends React.Component {
    render() {
        const { language } = this.props;

        return (
            <div className="fade" >
            <InputControl
                placeHolder={ t( 'categories:search', language ) }
                id={ this.props.typeCat === "chasse" ? "searchChasse": "searchPeche"}
                type="text"
                handleOnChange={ this.props.handleChange }
                value={ this.props.search }
                >
                { t( 'categories:title', language ) }
            </InputControl>
            <div className="d-flex flex-wrap">
                { this.props.path(this.props.typeCat) }
            </div>
            <div className="cat-selects-title">
                { t( 'categories:advice', language ) }
            </div>
            <div className="cat-selects-container">
                { this.props.catToDisplay( this.props.typeCat ) }
            </div>
        </div>
        )
    }
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
});

export default connect( stateToProps )( SearchModule );
