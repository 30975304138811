import React from "react";
import { connect }  from "react-redux";

import { PAYMENT_REDUCER } from "../../../../constants";
import { t } from "../../../../utils";

class PaymentRecapBox extends React.Component {
    render() {
        const { currency, discountAmountStg, isCodeValid, language, priceStgCurrent, priceStgOld, productInfo, productType } = this.props;

        return (
            <>
                <div className="popup-payment-recap-info-box">
                    <div className="popup-payment-recap-info">
                        <div className="popup-payment-recap-title">
                            { productType === PAYMENT_REDUCER.TYPE_RENT && `${ t( 'payment:rent', language ) } : ` }
                            {
                                productInfo[ t( 'keys:title', language ) ] ?
                                productInfo[ t( 'keys:title', language ) ]
                                :
                                productInfo.title
                            }
                        </div>
                        {
                            productInfo.details ?
                            <div className="popup-payment-recap-details">
                                {
                                    productInfo[ t( 'keys:details', language ) ] ?
                                    productInfo[ t( 'keys:details', language ) ]
                                    :
                                    productInfo.details
                                }
                            </div>
                            :
                            <></>
                        }
                    </div>
                    <div className="f-grow-1"></div>
                    {
                        productType === PAYMENT_REDUCER.TYPE_PURCHASE && productInfo.prices && productInfo.prices[ currency ].priceStg ?
                        <>
                            <div className="popup-payment-recap-priceOverlineStg">{ productInfo.prices[ currency ].priceOverlineStg }</div>
                            <div className="popup-payment-recap-priceStg">{ productInfo.prices[ currency ].priceStg }</div>
                        </>
                        :
                        <></>
                    }
                    {
                        productType === PAYMENT_REDUCER.TYPE_RENT && productInfo.pricesRent && productInfo.pricesRent[ currency ].priceStg ?
                        <>
                            <div className="popup-payment-recap-priceOverlineStg">{ productInfo.pricesRent[ currency ].priceOverlineStg }</div>
                            <div className="popup-payment-recap-priceStg">{ productInfo.pricesRent[ currency ].priceStg }</div>
                        </>
                        :
                        <></>
                    }
                </div>
                <div className="popup-payment-recap-total-box">
                    {
                        isCodeValid &&
                        <div className="popup-payment-recap-total-code-box">
                            <div className="popup-payment-recap-total-code-label">{ t( 'payment:codeLabel', language ) }</div>
                            <div className="f-grow-1"></div>
                            <div className="popup-payment-recap-total-code-badge">{ discountAmountStg }</div>
                        </div>
                    }
                    <div className="popup-payment-recap-total-price-box">
                        <div className="popup-payment-recap-total-price-label">{ t( 'payment:total', language ) }</div>
                        <div className="f-grow-1"></div>
                        <div className="popup-payment-recap-total-price">
                            {
                                isCodeValid && priceStgOld &&
                                <span className="popup-payment-recap-total-price-priceOverlineStg">{ priceStgOld }</span>
                            }
                            {
                                priceStgCurrent &&
                                <span className="popup-payment-recap-total-price-priceStg">{ priceStgCurrent }</span>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const stateToProps = state => ({
    language: state.translatingReducer.language,
	productType: state.paymentReducer.productType,
});

export default connect( stateToProps )( PaymentRecapBox );
