import React from 'react';

import { connect } from "react-redux";

import Header from '../../components/Header';
import ListCats from '../../components/ListCats';
import ListRows from "./ListRows";

import { routingAction } from "../../redux/actions";

class Home extends React.Component {
    componentDidMount() {
        const fixParamArr = window.location.toString().split( '/#/' );

		if ( fixParamArr ) {
			if ( fixParamArr[ 0 ].split( '?search=' )[ 1 ] ) {
				const fixParam = fixParamArr[ 0 ].split( '?search=' )[ 1 ];
				window.location.assign( "/browse/?search=" + fixParam + "/#/search/" + fixParam );
				return;
			} else if ( ( fixParamArr[ 0 ].split( '?chasse' )[ 1 ] || fixParamArr[ 0 ].split( '?chasse' )[ 1 ] === '' ) ) {
				window.location.assign( "/browse/?chasse/#/chasse/" );
				return;
			} else if ( ( fixParamArr[ 0 ].split( '?peche' )[ 1 ] || fixParamArr[ 0 ].split( '?peche' )[ 1 ] === '' ) ) {
				window.location.assign( "/browse/?peche/#/peche/" );
				return;
			} else if ( ( fixParamArr[ 0 ].split( '?continu' )[ 1 ] || fixParamArr[ 0 ].split( '?continu' )[ 1 ] === '' ) ) {
				window.location.assign( "/browse/?continu/#/continu/" );
				return;
			} else if ( fixParamArr[ 0 ].split( '?formations=' )[ 1 ] ) {
				const fixParam = fixParamArr[ 0 ].split( '?formations=' )[ 1 ];
				window.location.assign( "/browse/?formations=" + fixParam + "/#/formations/" + fixParam );
				return;
			} else if ( ( fixParamArr[ 0 ].split( '?formations' )[ 1 ] || fixParamArr[ 0 ].split( '?formations' )[ 1 ] === '' ) ) {
				window.location.assign( "/browse/?formations/#/formations/" );
				return;
			} else if ( fixParamArr[ 0 ].split( '?boutique=' )[ 1 ] ) {
				const fixParam = fixParamArr[ 0 ].split( '?boutique=' )[ 1 ];
				window.location.assign( "/browse/?boutique=" + fixParam + "/#/boutique/" + fixParam );
				return;
			} else if ( ( fixParamArr[ 0 ].split( '?boutique' )[ 1 ] || fixParamArr[ 0 ].split( '?boutique' )[ 1 ] === '' ) ) {
				window.location.assign( "/browse/?boutique/#/boutique/" );
				return;
			}
		}
		
        const { fetchCurrentRouting } = this.props;
        fetchCurrentRouting( 0 );
  	};

    render() {
        return (
            <div className="homepage-container">
                <Header />
                <ListCats />
                <ListRows />
            </div>
        )
    }
}

const stateToProps = state => ({ });

const dispatchToProps = dispatch => ({
	fetchCurrentRouting: themeId => dispatch(routingAction.fetchCurrentRouting( themeId )),
});

export default connect( stateToProps, dispatchToProps )( Home );