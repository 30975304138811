import { TRAINING_REDUCER } from "../../constants";

const initialState = {
	trainingDetailsDescription: [],
	trainingMovies: [],
	trainingSliderMovies: [],
};

export default (state = initialState, action) => {
	const newState = Object.assign({}, state);

	switch (action.type) {
		case TRAINING_REDUCER.FETCH_TRAINING_DETAILS_DESCRIPTION:
			newState.trainingDetailsDescription = action.payload;
			return newState;	
		case TRAINING_REDUCER.FETCH_TRAINING_MOVIES:
			newState.trainingMovies = action.payload;
			return newState;	
		case TRAINING_REDUCER.FETCH_TRAINING_SLIDER_MOVIES:
			newState.trainingSliderMovies = action.payload;
			return newState;	
		default:
			return state;
	}
};
